import React, { useEffect, useState } from 'react';

import { Modal, Text, Select } from 'v3/components';
import { usePolicyRegister } from '../../context';
import Space from 'v3/components/Space';
import { StyledModalButton } from 'v3/pages/Policy/styles';
import { validateVehicle } from '../../utils';
import { Row, Col } from 'react-bootstrap';
import { cooperplaceApi } from 'services/api';

export function VehiclesModal() {
  const {
    vehicleModal,
    operations,
    setOperations,
    currentOpIndex,
    currentPdIndex,
    currentVehicleIndex,
    setCurrentOpIndex,
    setCurrentPdIndex,
    setCurrentVehicleIndex,
    setErrors,
  } = usePolicyRegister();
  const [newVehicles, setNewVehicles] = useState([]);

  const [bodyTypeList, setBodyTypeList] = useState();
  const [vehicleTypeList, setVehicleTypeList] = useState();
  const [vehicleImplementList, setVehicleImplementList] = useState();
  const [vehicleType, setVehicleType] = useState();

  const [error, setError] = useState({});
  const [isTractor, setIsTractor] = useState(false);

  async function fetchVehicleType() {
    try {
      const response = await cooperplaceApi.get('policy-vehicles/vehicles');
      setVehicleTypeList(response.data);
    } catch (error) {
      return [];
    }
  }

  async function fetchBodyType() {
    try {
      const response = await cooperplaceApi.get('policy-vehicles/bodyTypes');
      setBodyTypeList(response.data);
    } catch (error) {
      return [];
    }
  }

  async function fetchVehicleImplement() {
    try {
      const response = await cooperplaceApi.get('policy-vehicles/implementTypes');
      setVehicleImplementList(response.data);
    } catch (error) {
      return [];
    }
  }

  function validate() {
    const vehicleBody = newVehicles.filter(vehicle => vehicle.type === 'body')
    const vehicleType = newVehicles.filter(vehicle => vehicle.type === 'vehicle')
    const vehicleImplement = newVehicles.filter(vehicle => vehicle.type === 'implement')

    const errors = { hasErrors: false };
    try {
      if (vehicleBody.length === 0) {
        errors.bodyType = 'Informe a carroceria';
        errors.hasErrors = true;
      }

      if (vehicleType.length === 0) {
        errors.vehicleType = 'Informe o veículo';
        errors.hasErrors = true;
      }

      if (isTractor) {
        if (!vehicleImplement || vehicleImplement.length < 1) {
          errors.vehicleImplement = 'Informe o implemento';
          errors.hasErrors = true;
        }
      }
    } catch (error) {
      return errors;
    }

    return errors;
  }

  useEffect(() => {

    let oldVehicles = operations[currentOpIndex]?.products[currentPdIndex]?.vehicles[currentVehicleIndex]?.vehicles
    oldVehicles = newVehicles.length > 0 ? newVehicles : oldVehicles

    if (oldVehicles?.some(vehicle => vehicle?.type === 'vehicle' && vehicle?.id === 18)) {
      setIsTractor(true);
    } else {
      setNewVehicles(prev => {
        const otherTypes = prev.filter(vehicle => vehicle.type !== 'implement');
        return [...otherTypes];
      });

      setIsTractor(false);
    }
  }, [vehicleType, currentOpIndex, currentPdIndex, currentVehicleIndex]);

  useEffect(() => {
    fetchBodyType();
    fetchVehicleType();
    fetchVehicleImplement();
  }, []);

  function handleAdd() {

    const err = validate();
    setError(err);

    if (err.hasErrors) {
      return;
    }

    let newState = [...operations];

    let vehicles = newState[currentOpIndex].products[currentPdIndex].vehicles
      ?.map(vehicle => vehicle.vehicles)
    let newVehiclesOptions = newVehicles?.map(vehicle => vehicle);

    const isValid = validateVehicle(vehicles, newVehiclesOptions);

    if (isValid) {
      setError({
        vehicle:
          `Este conjunto de veículos já está registrado nesta apólice. Não é possível adicioná-lo novamente.`,
      });
      return;
    }

    newState[currentOpIndex].products[currentPdIndex] = {
      ...newState[currentOpIndex].products[currentPdIndex],
      vehicles: [
        ...newState[currentOpIndex].products[currentPdIndex]?.vehicles,
        { vehicles: [...newVehicles], ranges: [] },
      ],
    };

    setOperations(newState);
    setCurrentOpIndex(null);
    setCurrentPdIndex(null);
    setCurrentVehicleIndex(null);
    setNewVehicles([]);
    setError({});
    setErrors({});
    setVehicleType(null)
    vehicleModal.close();
  }

  function handleEdit() {
    const err = validate();
    setError(err);

    if (err.hasErrors) {
      return;
    }

    let newState = JSON.parse(JSON.stringify(operations));
    newState[currentOpIndex].products[currentPdIndex].vehicles[
      currentVehicleIndex
    ].vehicles = [];

    let vehicles = newState[currentOpIndex].products[currentPdIndex].vehicles
      ?.map(vehicle => vehicle.vehicles)
    let newVehiclesOptions = newVehicles?.map(vehicle => vehicle);

    const isValid = validateVehicle(vehicles, newVehiclesOptions);

    if (isValid) {
      setError({
        vehicle:
          ` Esse conjunto de veículos já está incluído na apólice referente a esta categoria de produto.`,
      });
      return;
    }

    newState[currentOpIndex].products[currentPdIndex].vehicles[
      currentVehicleIndex
    ].vehicles = [...newVehicles];

    setOperations(newState);
    setCurrentOpIndex(null);
    setCurrentPdIndex(null);
    setCurrentVehicleIndex(null);
    setNewVehicles([]);
    setError({});
    vehicleModal.close();
  }

  function handleCloseVehicleModal() {
    setError({});
    setNewVehicles([]);
    setCurrentOpIndex(null);
    setCurrentPdIndex(null);
    setCurrentVehicleIndex(null);
    setVehicleType(null)
    vehicleModal.close();
  }

  useEffect(() => {
    if (
      currentOpIndex !== null &&
      currentPdIndex !== null &&
      currentVehicleIndex !== null &&
      vehicleModal.isOpen
    ) {
      setNewVehicles(
        operations[currentOpIndex].products[currentPdIndex].vehicles[
          currentVehicleIndex
        ].vehicles
      );
    }
  }, [
    vehicleModal,
    currentOpIndex,
    currentPdIndex,
    currentVehicleIndex
  ]);

  return (
    <Modal
      size="lg"
      show={vehicleModal.isOpen}
      handleClose={handleCloseVehicleModal}
      heading={
        <Text type="body" color="dark" weight="400">
          {currentOpIndex !== null &&
            currentPdIndex !== null &&
            currentVehicleIndex !== null
            ? 'Editar veículo'
            : 'Novo veículo'}
        </Text>
      }
      body={
        <Row>
          <Col xs={12}>
            <Select
              options={vehicleTypeList}
              label="Veículos *"
              value={newVehicles.filter(vehicle => vehicle.type === 'vehicle')}
              onChange={value => {
                setNewVehicles(prev => {
                  const updatedVehicles = value || [];
                  const otherTypes = prev.filter(vehicle => vehicle.type !== 'vehicle');

                  return [
                    ...otherTypes,
                    ...updatedVehicles
                  ];
                });
                setVehicleType(value)
              }}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              multiple
              error={error?.vehicle || error?.vehicleType}
            />
          </Col>

          {isTractor && (
            <Col xs={12}>
              <Select
                options={vehicleImplementList}
                label="Tipo de implemento *"
                value={newVehicles.filter(vehicle => vehicle.type === 'implement')}
                onChange={value => {
                  setNewVehicles(prev => {
                    const updatedImplements = value || [];
                    const otherTypes = prev.filter(vehicle => vehicle.type !== 'implement');

                    return [
                      ...otherTypes,
                      ...updatedImplements
                    ];
                  });
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                multiple
                error={error?.vehicle || error?.vehicleImplement}
              />
            </Col>
          )}

          <Col xs={12}>
            <Select
              options={bodyTypeList}
              label="Tipo da carroceria *"
              value={newVehicles.filter(vehicle => vehicle.type === 'body')}
              onChange={value => {
                setNewVehicles(prev => {
                  const updatedBodies = value || [];
                  const otherTypes = prev.filter(vehicle => vehicle.type !== 'body');

                  return [
                    ...otherTypes,
                    ...updatedBodies
                  ];
                });
              }}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              multiple
              error={error?.vehicle || error?.bodyType}
            />
          </Col>
        </Row>
      }
      footer={
        <Space x={'0.5rem'}>
          <StyledModalButton
            variant="secondary"
            size="sm"
            onClick={handleCloseVehicleModal}
          >
            <Text>Cancelar</Text>
          </StyledModalButton>
          <StyledModalButton
            size="sm"
            onClick={
              currentOpIndex !== null &&
                currentPdIndex !== null &&
                currentVehicleIndex !== null
                ? handleEdit
                : handleAdd
            }
            disabled={newVehicles?.length === 0 || newVehicles === null}
          >
            <Text>
              {currentOpIndex !== null &&
                currentPdIndex !== null &&
                currentVehicleIndex !== null
                ? 'Editar'
                : 'Adicionar'}
            </Text>
          </StyledModalButton>
        </Space>
      }
    />
  );
}
