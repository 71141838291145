import styled from 'styled-components';

export const DestinationContainer = styled.div`
  max-height: 150px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.light_gray || '#f1f1f1'};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary || '#888'};
    border-radius: 10px;
    border: 2px solid ${({ theme }) => theme.colors.light_gray || '#f1f1f1'};
    transition: background-color 0.3s ease, border 0.3s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.colors.secondary || '#555'};
    border-color: ${({ theme }) => theme.colors.primary || '#888'};
  }

  scroll-behavior: smooth;
`;
