import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { cooperplaceApi } from 'services/api';
import {
  fetchAnttTypes,
  fetchBodies,
  fetchOnlyCities,
  fetchImplements,
  fetchOwners,
  fetchVehiclesTypes,
} from 'utils/fetches';

import { Button, Input, Modal, Select, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { DefaultLoadingComponent } from 'v3/components/List';
import { wagonRegistrationSchema } from '../../validator';

export function RegisterWagon({ isOpen, onClose, wagonKey, sendResponse }) {
  const [plate, setPlate] = useState('');
  const [city, setCity] = useState(null);
  const [yearManufacture, setYearManufacture] = useState(null);
  const [yearModel, setYearModel] = useState(null);
  const [chassi, setChassi] = useState('');
  const [vehicleType, setVehicleType] = useState(null);
  const [vehicleBodyType, setVehicleBodyType] = useState(null);
  const [vehicleImplementType, setVehicleImplementType] = useState(null);
  const [anttType, setAnttType] = useState(null);
  const [antt, setAntt] = useState('');
  const [ownerData, setOwnerData] = useState(null);
  const [beneficiary, setBeneficiary] = useState(null);
  const [anttAdherence, setAnttAdherence] = useState(null);
  const [renavam, setRenavam] = useState('');
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [vehicleTypesOptions, setVehicleTypesOptions] = useState(null);
  const [vehicleBodyTypesOptions, setVehicleBodyTypesOptions] = useState(null);
  const [vehicleImplementTypesOptions, setVehicleImplementTypesOptions] =
    useState(null);
  const [anttTypesOptions, setAnttTypesOptions] = useState(null);

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const snackbar = useSnackbar();

  const hasSelectDollyImplementType = vehicleImplementType?.name === 'Dolly';

  async function fetchOptions() {
    try {
      setLoadingOptions(true);
      let [
        vehicleTypesData,
        vehiclesBodyTypesData,
        anttTypesData,
        implementsData,
      ] = await Promise.all([
        fetchVehiclesTypes(),
        fetchBodies(),
        fetchAnttTypes(),
        fetchImplements(),
      ]);
      vehicleTypesData = vehicleTypesData.filter(
        vtype => vtype?.name?.toLowerCase() === 'semi reboque'
      );
      setVehicleTypesOptions(vehicleTypesData);
      setVehicleBodyTypesOptions(vehiclesBodyTypesData);
      setAnttTypesOptions(anttTypesData);
      setVehicleImplementTypesOptions(implementsData);
    } catch (error) {
      //
    } finally {
      setLoadingOptions(false);
    }
  }
  async function verifySchema(payload) {
    try {
      await wagonRegistrationSchema.validate(payload, {
        abortEarly: false,
      });
      return true;
    } catch (error) {
      const errorResponse = {};
      if (error.inner) {
        error.inner.forEach(error => {
          errorResponse[error.path] = error.message;
        });
        setErrors(errorResponse);
      }
      return false;
    }
  }

  function clearStates() {
    setPlate('');
    setCity(null);
    setYearManufacture(null);
    setYearModel(null);
    setChassi(null);
    setVehicleType(null);
    setVehicleBodyType(null);
    setVehicleImplementType(null);
    setAnttType(null);
    setAntt(null);
    setOwnerData(null);
    setAnttAdherence(null);
    setRenavam(null);
    setBeneficiary(null)
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      let payload = {
        plate,
        yearManufacture,
        chassi,
        renavam,
        yearModel,
        vehicleType,
        vehicleImplementType,
        vehicleImplementTypeName: vehicleImplementType?.name,
        vehicleBodyType,
        anttType,
        antt,
        anttAdherence,
        idOwner: ownerData?.id,
        plateCityId: city?.id,
        beneficiaryId: beneficiary?.id
      };
      const isValid = await verifySchema(payload);
      if (isValid === false) {
        setLoading(false);
        return;
      }
      payload = {
        ...payload,
        plateState: city?.province?.uf,
        plateCity: city?.name,
        anttType: anttType?.id,
        vehicleTypeId: vehicleType?.id,
        vehicleBodyTypeId: vehicleBodyType?.id,
        vehicleImplementTypeId: vehicleImplementType?.id,
      };

      const response = await cooperplaceApi.post('wagons', payload);
      snackbar.show(<Text>Cadastro realizado com sucesso</Text>, {
        type: 'success',
      });

      if (response.status === 201) {
        clearStates();
        sendResponse({ wagonKey, response });
        setLoading(false);
        setErrors({});
        onClose();
      }
    } catch (error) {
      setErrors({});
      setLoading(false);
      snackbar.show(
        <Text>
          {error?.response?.data?.message || 'Erro ao cadastrar implemento'}
        </Text>,
        {
          type: 'error',
        }
      );
    }
  }

  function handleCloseModal() {
    clearStates();
    setErrors({});
    onClose();
  }
  const implementId = {
    implement: 'um',
    implement2: 'dois',
    implement3: 'tres',
  };

  useEffect(() => {
    if (isOpen) fetchOptions();
  }, [isOpen]);

  return (
    <Modal
      size="md"
      show={isOpen}
      onHide={() => handleCloseModal()}
      heading={
        <Text type="header" color="dark" weight="500">
          Cadastrar implemento
        </Text>
      }
      body={
        loadingOptions ? (
          <DefaultLoadingComponent />
        ) : (
          <Row className="mx-0 mt-1">
            <Col md={4} xs={12} className="mb-3">
              <Input
                label="Placa *"
                value={plate}
                onChange={event =>
                  event.target.value.length > 7
                    ? snackbar.show(<Text>Limite de dígitos para placa</Text>, {
                      type: 'error',
                    })
                    : setPlate(event.target.value?.toUpperCase())
                }
                error={errors?.plate}
                id={`input-novo-implemento-${implementId[wagonKey]}-modal-placa`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Select.Async
                label="Cidade *"
                onSearch={fetchOnlyCities}
                value={city}
                getOptionLabel={option =>
                  `${option?.name}, ${option?.province?.uf ?? option?.uf}`
                }
                getOptionValue={option => option.id}
                onChange={value => {
                  setCity(value);
                }}
                error={errors?.plateCityId}
                id={`select-novo-implemento-${implementId[wagonKey]}-modal-cidade`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Input
                label="Ano de fabricação *"
                type="number"
                value={yearManufacture}
                onChange={event =>
                  event.target.value.length > 4
                    ? snackbar.show(<Text> Limite de dígitos para Ano </Text>, {
                      type: 'error',
                    })
                    : setYearManufacture(event.target.value)
                }
                error={errors?.yearManufacture}
                id={`input-novo-implemento-${implementId[wagonKey]}-modal-ano-fabricacao`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Input
                label="Ano modelo *"
                type="number"
                value={yearModel}
                onChange={event =>
                  event.target.value.length > 4
                    ? snackbar.show(<Text> Limite de dígitos para Ano </Text>, {
                      type: 'error',
                    })
                    : setYearModel(event.target.value)
                }
                error={errors?.yearModel}
                id={`input-novo-implemento-${implementId[wagonKey]}-modal-ano-modelo`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Input
                label="Chassi *"
                value={chassi}
                onChange={event =>
                  event.target.value.length > 20
                    ? snackbar.show(
                      <Text> Limite de dígitos para Chassi </Text>,
                      {
                        type: 'error',
                      }
                    )
                    : setChassi(event.target.value)
                }
                error={errors?.chassi}
                id={`input-novo-implemento-${implementId[wagonKey]}-modal-chassi`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Select
                label="Tipo do veículo *"
                options={vehicleTypesOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={vehicleType}
                onChange={value => setVehicleType(value)}
                error={errors?.vehicleType}
                id={`select-novo-implemento-${implementId[wagonKey]}-modal-tipo-veiculo`}
              />
            </Col>

            <Col md={4} xs={12} className="mb-3">
              <Select
                label="Tipo de implemento *"
                options={vehicleImplementTypesOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={vehicleImplementType}
                onChange={value => {
                  if (value?.name === 'Dolly') {
                    setVehicleImplementType(value);
                    setVehicleBodyType(null);
                    setErrors(old => ({ ...old, vehicleBodyType: null }));
                  } else {
                    setVehicleImplementType(value);
                  }
                }}
                error={errors?.vehicleImplementType}
                id={`select-novo-implemento-${implementId[wagonKey]}-modal-tipo-implemento`}
              />
            </Col>

            <Col md={4} xs={12} className="mb-3">
              <Select
                label="Tipo do carroceria *"
                options={vehicleBodyTypesOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={vehicleBodyType}
                onChange={value => setVehicleBodyType(value)}
                error={errors?.vehicleBodyType}
                isDisabled={hasSelectDollyImplementType}
                id={`select-novo-implemento-${implementId[wagonKey]}-modal-tipo-carroceria`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Input
                label="Renavam *"
                type="number"
                value={renavam}
                onChange={event =>
                  event.target.value.length > 11
                    ? snackbar.show(
                      <Text> Limite de dígitos para Renavam </Text>,
                      {
                        type: 'error',
                      }
                    )
                    : setRenavam(event.target.value)
                }
                error={errors?.renavam}
                id={`input-novo-implemento-${implementId[wagonKey]}-modal-renavam`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Select
                label="Tipo de RNTRC *"
                options={anttTypesOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={anttType}
                onChange={value => setAnttType(value)}
                error={errors?.anttType}
                id={`select-novo-implemento-${implementId[wagonKey]}-modal-tipo-rntrc`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Input
                label="RNTRC *"
                type="number"
                value={antt}
                onChange={event => setAntt(event.target.value)}
                error={errors?.antt}
                id={`input-novo-implemento-${implementId[wagonKey]}-modal-tipo-rntrc`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Input
                label="Data de emissão da RNTRC *"
                type="date"
                value={anttAdherence}
                onChange={event => setAnttAdherence(event.target.value)}
                error={errors?.anttAdherence}
                id={`input-novo-implemento-${implementId[wagonKey]}-modal-emissao-rntrc`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Select.Async
                label="Proprietário do documento *"
                onSearch={fetchOwners}
                onChange={value => setOwnerData(value)}
                value={ownerData}
                getOptionLabel={option => option.label || option.socialName}
                getOptionValue={option => option.id}
                error={errors?.idOwner}
                id={`select-novo-implemento-${implementId[wagonKey]}-modal-proprietario-documento`}
              />
            </Col>
            <Col md={4} xs={12} className="mb-3">
              <Select.Async
                label="Beneficiário do veículo  *"
                onSearch={fetchOwners}
                onChange={value => setBeneficiary(value)}
                value={beneficiary}
                getOptionLabel={option => option.label || option.socialName}
                getOptionValue={option => option.id}
                error={errors?.beneficiaryId}
              />
            </Col>
            <Col
              md={12}
              className="mb-3 d-flex align-items-center justify-content-center"
            >
              <Button
                className="mt-1 py-2"
                onClick={handleSubmit}
                loading={loading}
                id={`button-novo-implemento-${implementId[wagonKey]}-modal-salvar`}
              >
                <Text weight="500" type="regular">
                  Salvar
                </Text>
              </Button>
            </Col>
          </Row>
        )
      }
    />
  );
}
