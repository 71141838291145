import React from 'react';
import { OverlayTrigger, Tooltip as RawTooltip } from 'react-bootstrap';

export default function Tooltip({
  placement = 'top',
  delay,
  content,
  children,
}) {
  return (
    <OverlayTrigger
      placement={placement}
      delay={delay}
      overlay={<RawTooltip style={{ zIndex: 999999 }}>{content}</RawTooltip>}
    >
      {children}
    </OverlayTrigger>
  );
}
