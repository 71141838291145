import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import api from 'services/apis/cooperplace';
import { SwitchOption } from 'v3/components';
import moment from 'moment';
import { usePermission } from 'hooks';
import { validate } from '../shared/validator';
import { useCostCenter } from '../shared/context';
import SharedData from '../shared/Section';
import Inputs from '../shared/Data/index';
import Editable from '../shared/Editable';

export default function Data() {
  const costCenter = useCostCenter();
  const params = useParams();
  const snackbar = useSnackbar();

  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(!costCenter.deletedAt);
  const hasPermissionToEditCostCenter = usePermission('EDITAR_CENTRO_CUSTO');

  useEffect(() => {
    if (costCenter.data.deletedAt) {
      setActive(false);
    } else setActive(true);
  }, [costCenter]);

  async function saveData() {
    setLoading(true);

    const isValid = await validate(
      'data',
      { data: costCenter.data },
      costCenter.setErrors
    );
    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      await api.put(`/cost-center/${params.id}`, {
        name: costCenter.data.name,
        externalId: costCenter.data.externalId || null,
        identifier: costCenter.data.identifier || null,
        limitSchedule: costCenter.data.limitSchedule || false,
        showLoadingOrder: costCenter.data.showLoadingOrder || false,
      });

      costCenter.setData(costCenter.data);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
      setEditing(false);
    } catch (error) {
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  async function toggleActive() {
    try {
      await api.delete(`/cost-center/${costCenter.data.id}`);
      costCenter.data.deletedAt = active ? moment() : null;
      setActive(old => !old);
      costCenter.setPrevCostCenter(costCenter.data);
    } catch {
      snackbar.show(<Text> Erro ao alterar centro de custo! </Text>, {
        type: 'error',
      });
    }
  }

  return (
    <SharedData
      Title="Dados gerais"
      HeaderRightComponent={
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={saveData}
          isLoading={loading}
          hasPermissionToEditCostCenter={hasPermissionToEditCostCenter}
        />
      }
      loading={costCenter.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12} md={12} as="p">
            <SwitchOption
              disabled={!hasPermissionToEditCostCenter}
              value={active}
              onClick={() => toggleActive()}
            />
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Nome
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {costCenter.data?.name || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Id externo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {costCenter.data?.externalId || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={6}>
            <Text type="label" color="dark">
              Identificador
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {costCenter.data?.identifier || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12} md={12}>
            <Text type="label" color="dark">
              Limite de Agendamento
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {costCenter.data?.limitSchedule ? 'Sim' : 'Não'}
            </Text>
          </Col>
          <Col xs={12} md={12}>
            <Text type="label" color="dark">
              Mostrar Ordem de Carregamento
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {costCenter.data?.showLoadingOrder ? 'Sim' : 'Não'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedData>
  );
}
