/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Input from 'v3/components/Input';
import Select from 'v3/components/Select';
import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from 'v3/pages/Travel';
import { usePermission, useValidation } from 'hooks';
import api, { cooperplaceApi } from 'services/api';
import { parseErrors } from 'v3/utils/formatter';
import moment from 'moment';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { dataSchema } from '../validator';
import EditableContent from '../EditableContent';
import { fetchTags } from './controller';
import { IMPORT_PAYMENTS_VALID_TRAVEL_STATUS } from '../../Details/Map/Progress';
import DialogContracts from './DialogContracts';

function Data() {
  const {
    isTravelBlocked,
    isFetchingBenner,
    isFetchingMargin,
  } = useContext(BlockedTravelContext);
  const travel = useTravel();
  const hasEditPermission = usePermission('ALTERAR_VIAGEM');
  const hasChangeTagsPermission = usePermission('SELECIONAR_TAGS');
  const snackbar = useSnackbar();
  const dataTravel = useSelector(store => store.dataTravel);
  const [errors, { validate, setErrors }] = useValidation(dataSchema);
  const forbiddenTravelStatus = ['ST7', 'ST10'];

  const isFocused = useRef({
    travelNumber: false,
    externalId: false,
  });
  const [isEditing, setEditing] = useState(false);
  const [showContractsDialog, setShowContratsDialog] = useState(false);
  const [isInFlight, setInFlight] = useState(false);

  const [importFromBenner, setImportFromBenner] = useState(false);
  const status_issue_options = [
    { value: 1, label: 'Aprovado emissão' },
    { value: 2, label: 'Reprovado emissão' },
  ];
  const checkedEdit = {
    all: false,
    partial: false,
    nothing: false,
  };


  useEffect(() => {
    if (isEditing) {
      travel.setData(old => ({
        ...old,
        old_travel_number: travel.data.travelNumber,
      }));
    }
  }, [isEditing]);

  if (travel.isLoading) {
    return null;
  }
  async function submitData() {
    try {
      setInFlight(true);
      const [isValid, data] = await validate(travel.data, {
        context: { clearances: travel.data?.loads?.riskManagers },
      });

      if (!isValid) {
        return;
      }

      await api.put(`travel-data/${travel.data.id}`, {
        data: {
          shipping_company: data.shipper,
          old_shipping_company: data.old_shipping_company,
          margin: data?.margin,
          travel_number: data?.travelNumber,
          old_travel_number: data?.old_travel_number,

          status_issue: data.statusIssue,
        },
        tags: data.tags.map(tag => tag.id),
        load: {
          id_externo: data?.loads?.externalId,
          old_id_externo: data?.loads?.old_id_externo,
          fare_company: data?.loads?.fareCompany,
          total_inputs: data?.loads?.totalInputs,
        },
      });

      snackbar.show(<Text>Dados da viagem atualizados com sucesso.</Text>, {
        type: 'success',
      });
      await travel.fetchTravel();
    } catch (ex) {
      if (ex.response) {
        const parsedErrors = parseErrors(ex.response.data, {
          getPath: error => error.field,
        });
        setErrors(parsedErrors);
      }
    } finally {
      setEditing(false);
      setInFlight(false);
    }
  }

  async function handleImportFromBenner() {
    try {
      setImportFromBenner(true);
      const response = await cooperplaceApi.post(
        `travel-documents/importFromBenner/${travel?.data?.id}`,
        {
          externalId: travel?.data?.loads?.externalId,
          statusFrom: travel?.data?.travelStatus?.id,
          loadId: travel?.data?.loads?.id,
        }
      );
      if (
        IMPORT_PAYMENTS_VALID_TRAVEL_STATUS.includes(
          travel?.data?.travelStatus?.id
        )
      ) {
        await cooperplaceApi.post(
          `payments/importFromBenner/${travel.data.id}`
        );
      }
      if (response.data.itemsExist === true) {
        await sendNotificationToDriver();
      }
      await cooperplaceApi.post(`freight/importContracts/${travel?.data?.id}`, {
        travelNumber: travel?.data?.loads?.externalId,
      });
      const typeNotification = response?.data?.typeNotification;
      const message = response?.data?.message;

      if (typeNotification === 'partialDocuments') {
        snackbar.show(<Text>{message}</Text>, {
          type: 'warning',
        });
      }
      if (typeNotification === 'noDocuments') {
        snackbar.show(<Text>{message}</Text>, {
          type: 'error',
        });
      }
      if (typeNotification === 'allDocuments') {
        snackbar.show(<Text>{message}</Text>, {
          type: 'success',
        });
      }
      if (!typeNotification) {
        snackbar.show(
          <Text>{`Não foi possível importar a emissão do Benner. Tente novamente mais
        tarde.`}</Text>,
          {
            type: 'error',
          }
        );
      }
    } catch (error) {
      snackbar.show(
        <Text>{`Erro ao importar da emissão do Benner: ${error.message}`}</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setImportFromBenner(false);
    }
  }

  function onButtonClick() {
    if (isEditing) {
      if (
        travel.data.old_travel_number &&
        travel.data.travelNumber !== travel.data.old_travel_number &&
        travel?.travelFreightContracts.length > 0
      ) {
        setShowContratsDialog(true);
        return;
      }
      submitData();
      return;
    }
    setEditing(true);
  }

  function checkStatusOfTravel() {
    const currentTripPosition = [];
    dataTravel.forEach(({ obs }) => {
      if (obs) {
        const obsTravel = obs.toLowerCase();
        const notPossibleEdit = [
          'em viagem',
          'em análise',
          'finalizado',
          'cancelado',
          'transbordado',
        ];
        notPossibleEdit.filter(status => {
          if (
            obsTravel.includes(status) &&
            !currentTripPosition.includes(status)
          ) {
            currentTripPosition.push(status);
          }
        });
      }
    });
    return currentTripPosition;
  }

  function checkGrOrRegistration() {
    const approvedGr = 'aprovado gr';
    const approvedRegistration = 'aprovado cadastro';
    const checkStatus = dataTravel.some(({ obs }) => {
      if (obs) {
        return obs.toLowerCase().includes(approvedGr || approvedRegistration);
      }
    });
    return checkStatus;
  }

  function isCheckedStatusTravel(userPermission) {
    if (!userPermission) {
      if (checkStatusOfTravel().length > 0) {
        return (checkedEdit.nothing = true);
      }
      if (checkStatusOfTravel().length === 0 && !checkGrOrRegistration()) {
        return (checkedEdit.all = true);
      }
      if (checkStatusOfTravel().length === 0 && checkGrOrRegistration()) {
        return (checkedEdit.partial = true);
      }
      return (checkedEdit.all = true);
    }
  }

  async function removeContracts() {
    try {
      await cooperplaceApi.delete(`freight/byTravelId/${travel.data.id}`);
    } catch (err) {
      snackbar.show(<Text>Não foi possível remover contratos</Text>, {
        type: 'error',
      });
    }
  }

  function onConfirmContracts() {
    setShowContratsDialog(false);
    removeContracts();
    submitData();
  }

  isCheckedStatusTravel(hasEditPermission);

  async function getUserIdByCgcCpf() {
    try {
      const cgcCpf = travel.data.driver.cpfCnpj;
      const user = await cooperplaceApi.get(`users/users/${cgcCpf}`);
      return user;
    } catch (error) {
      snackbar.show('Usuário não encontrado', {
        type: 'error',
      });
    }
  }
  async function sendNotificationToDriver() {
    const user = await getUserIdByCgcCpf();
    if (!user) return;
    try {
      const data = {
        title: 'Documentos Disponíveis',
        text: 'Olá, os documentos logística já estão disponíveis para visualização e download.',
        type: 'APP_NOTIFICATION',
        userId: `${user.data.id}`,
        url: `{"action":"OPEN_TRAVEL", "travelId": ${travel.data.id}}`,
      };
      await cooperplaceApi.post(`notifications/`, data);
      snackbar.show('Notificacao encaminhada com sucesso!', {
        type: 'success',
      });
    } catch (error) {
      snackbar.show('Erro ao solicitar documento', {
        type: 'error',
      });
    }
  }
  function handleCancelEdit() {
    setEditing(false);
    travel.handleCancel();
  }

  return (
    <Card
      loading={travel.isLoading}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={3} perLine={2} />
      )}
      header={
        <Row>
          <Col xs={12} className="mb-2">
            <Text color="dark" type="header">
              Dados da viagem
            </Text>
          </Col>
        </Row>
      }
      HeaderRightComponent={
        <div>
          {isEditing && (
            <Button
              variant="secondary"
              loading={isInFlight}
              className="mr-2"
              onClick={() => handleCancelEdit()}
            >
              <Text color="dark" type="regular" weight="500">
                Cancelar
              </Text>
            </Button>
          )}
          {(checkedEdit.all || checkedEdit.partial || hasEditPermission) && (
            <Button
              onClick={onButtonClick}
              variant={isEditing ? 'primary' : 'secondary'}
              loading={isInFlight || isFetchingBenner || isFetchingMargin}
              className="py-2"
              disabled={isTravelBlocked}
              title={
                travel?.isDisabledGROAnalysis &&
                'Não é possível edição durante período de análise GRO'
              }
            >
              <Text
                color={isEditing ? 'white' : 'dark'}
                weight="500"
                type="regular"
              >
                {isEditing ? 'Salvar' : 'Editar'}
              </Text>
            </Button>
          )}
          {travel?.data?.loads?.externalId && !isEditing ? (
            <Button
              className="py-2 ml-2"
              onClick={handleImportFromBenner}
              loading={importFromBenner}
              disabled={forbiddenTravelStatus.includes(
                travel?.data?.travelStatus?.code
              )}
              title={
                forbiddenTravelStatus.includes(
                  travel?.data?.travelStatus?.code
                ) &&
                'Não é possível importar para viagens canceladas/transbordadas'
              }
            >
              <Text color="white" weight="500" type="regular">
                Importar Documentos
              </Text>
            </Button>
          ) : null}
        </div>
      }
    >
      <Row>
        <Col xs={6}>
          <EditableContent
            label="Número da viagem"
            display={travel.data.travelNumber}
            isEditing={
              (isEditing && hasEditPermission) ||
              ((checkedEdit.all || checkedEdit.partial) && isEditing)
            }
            EditingComponent={({ label }) => (
              <div className="mb-4">
                <Input
                  label={label}
                  value={travel.data.travelNumber}
                  onChange={({ target }) =>
                    travel.setData(old => ({
                      ...old,
                      travelNumber: target.value,
                    }))
                  }
                  /**
                   * Workaround because this was removing focus from input it was₢
                   * re-rendering because of `travel.data` state change.
                   * TODO: Fix this behaviour
                   */
                  autoFocus={isFocused.current.travelNumber}
                  onFocus={() => {
                    isFocused.current.travelNumber = true;
                  }}
                  onBlur={() => {
                    isFocused.current.travelNumber = false;
                  }}
                />
              </div>
            )}
          />
        </Col>
        <Col xs={6}>
          <EditableContent
            label="ID externo (integração)"
            tooltip="Campo que vincula nossa viagem com o sistema externo"
            display={travel.data.loads?.externalId}
            isEditing={
              (isEditing && hasEditPermission) ||
              ((checkedEdit.all || checkedEdit.partial) && isEditing)
            }
            EditingComponent={({ label, tooltip }) => (
              <div className="mb-4">
                <Input
                  label={label}
                  tooltip={tooltip}
                  value={travel.data.loads?.externalId}
                  onChange={({ target }) =>
                    travel.setData(old => ({
                      ...old,
                      loads: {
                        old_id_externo: travel.data.loads?.externalId,
                        ...old.loads,
                        externalId: target.value,
                      },
                    }))
                  }
                  /**
                   * Workaround because this was removing focus from input it was
                   * re-rendering because of `travel.data` state change.
                   * TODO: Fix this behaviour
                   */
                  autoFocus={isFocused.current.externalId}
                  onFocus={() => {
                    isFocused.current.externalId = true;
                  }}
                  onBlur={() => {
                    isFocused.current.externalId = false;
                  }}
                />
              </div>
            )}
          />
        </Col>
        <Col xs={6}>
          <EditableContent
            label="Status Emissão"
            display={
              <Text type="regular" color="gray">
                {status_issue_options.find(
                  item => item.value === travel.data.statusIssue
                )?.label || 'Não informado'}
              </Text>
            }
            required
            isEditing={
              (isEditing && hasEditPermission) || (checkedEdit.all && isEditing)
            }
            EditingComponent={({ label }) => (
              <div className="mb-4">
                <Select
                  style={{ paddingLeft: '20px' }}
                  value={status_issue_options.find(
                    item => item.value === travel.data.statusIssue
                  )}
                  onChange={e =>
                    travel.setData(old => ({
                      ...old,
                      statusIssue: e?.value,
                    }))
                  }
                  label={label}
                  disableOutline
                  disableArrow
                  options={status_issue_options}
                  placeholder="Selecione..."
                />
              </div>
            )}
          />
        </Col>

        <Col md={6} xs={6}>
          <Text type="label" color="dark">
            Nível de serviço
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data.shippeProps?.descriptionServiceLevel ||
              'Não informado'}
          </Text>
        </Col>
        <Col md={6} xs={12}>
          <Text type="label" color="dark">
            Validade da RNTRC
          </Text>
          <Text type="regular" color="gray" as="p" className="mt-2">
            {travel.data.shipper?.rntrcExpDate
              ? moment(travel.data.shipper?.rntrcExpDate, 'YYYY-MM-DD').format(
                'DD/MM/YYYY'
              )
              : 'Não informado'}
          </Text>
        </Col>
        <Col xs={6}>
          <EditableContent
            label="Tags"
            display={travel.data.tags?.map(tag => tag.name).join(', ')}
            isEditing={
              (isEditing && hasEditPermission) || (checkedEdit.all && isEditing)
            }
            EditingComponent={({ label }) => (
              <div className="mb-4">
                <Select.Async
                  label={label}
                  value={travel.data.tags}
                  onSearch={fetchTags}
                  multiple
                  horizontal
                  isDisabled={!hasChangeTagsPermission}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  onChange={value =>
                    travel.setData(old => ({ ...old, tags: value || [] }))
                  }
                  error={errors?.tags}
                />
              </div>
            )}
          />
        </Col>
      </Row>
      <DialogContracts
        open={showContractsDialog}
        handleConfirm={onConfirmContracts}
        handleClose={() => setShowContratsDialog(false)}
      />
    </Card>
  );
}

export default Data;
