import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import CardRaw from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Input from 'components/Input';
import UploadInput from 'v3/components/UploadInput';
import { cooperplaceApi } from 'services/api';
import * as yup from 'yup';
import { useSnackbar } from 'v3/components/Snackbar';

const acceptanceSchema = yup.object().shape({
  description: yup
    .string()
    .max(255, 'O campo "description" deve ter no máximo 255 caracteres.')
    .required('O campo "description" é obrigatório.'),
});

function AddAcceptanceCard({ acceptance, setIsAdding, reloadAcceptances }) {
  const snackbar = useSnackbar();
  const [inputDescription, setInputDescription] = useState('');
  const [inputFile, setInputFile] = useState(null);
  const [errors, setErrors] = useState({});

  const handleSubmit = async () => {
    setErrors({});
    const formData = new FormData();
    formData.append('title', acceptance?.title);
    formData.append('code', acceptance?.code);
    formData.append('description', inputDescription);
    formData.append('app', true);

    if (!inputFile || inputFile.length === 0) {
      setErrors(prevErrors => ({
        ...prevErrors,
        file: 'O arquivo é obrigatório.',
      }));

      snackbar.show(<Text>O arquivo de upload é obrigatório</Text>, {
        type: 'error',
      });
      return;
    }

    formData.append('file', inputFile[0]);

    try {
      await acceptanceSchema.validate(
        {
          description: inputDescription,
        },
        { abortEarly: false }
      );

      const response = await cooperplaceApi.post('/acceptances', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        setIsAdding(false);
        reloadAcceptances();
        snackbar.show(
          <Text>Nova versão do manual/termo cadastrada com sucesso</Text>,
          {
            type: 'success',
          }
        );
      } else {
        snackbar.show(
          <Text>
            Erro ao cadastrar nova versão do manual/termo. Tente novamente mais
            tarde
          </Text>,
          {
            type: 'error',
          }
        );
      }
    } catch (validationError) {
      if (validationError.name === 'ValidationError') {
        const validationErrors = {};
        validationError.inner.forEach(err => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
        snackbar.show(<Text>Verifique os dados preenchidos</Text>, {
          type: 'error',
        });
      } else {
        snackbar.show(
          <Text>
            Erro ao cadastrar nova versão do manual/termo. Tente novamente mais
            tarde
          </Text>,
          {
            type: 'error',
          }
        );
      }
    }
  };

  return (
    <Col md={6}>
      <CardRaw
        header={
          <div>
            <Text transform="uppercase" color="dark" type="regular">
              {acceptance?.title}
            </Text>
          </div>
        }
        HeaderRightComponent={
          <div className="d-flex justify-content-end">
            <Button
              variant="secondary"
              className="py-2 mr-2"
              onClick={() => setIsAdding(false)}
            >
              <Text>Cancelar</Text>
            </Button>
            <Button variant="success" className="py-2" onClick={handleSubmit}>
              <Text color="" type="regular" weight="500">
                Salvar
              </Text>
            </Button>
          </div>
        }
      >
        <Row className="form">
          <Col xs={12} md={6}>
            <Input
              label="Descrição"
              value={inputDescription}
              onChange={e => setInputDescription(e.target.value)}
              error={errors.description}
            />
            {errors.description && (
              <Text type="small" color="danger">
                {errors.description}
              </Text>
            )}
          </Col>
          <Col lg={6} md={6} xs={12}>
            <UploadInput
              label="Anexo"
              sizeIcon={10}
              onChange={event => setInputFile(event.target.files)}
              value={inputFile}
              id="inputFile"
              accept={['.pdf', '.PDF']}
              error={errors.file}
            />
            {errors.file && (
              <Text type="small" color="danger">
                {errors.file}
              </Text>
            )}
          </Col>
        </Row>
      </CardRaw>
    </Col>
  );
}

export default AddAcceptanceCard;
