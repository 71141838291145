import React, { useMemo } from 'react';
import { useTravel } from 'v3/pages/Travel';
import { Fallback, Input, Select, Text, Tooltip } from 'v3/components';
import { processGroupOptionsData } from 'v3/pages/Travel/Register/GRO/utils';
import { Col, Row } from 'react-bootstrap';
import { DefaultLoadingBodyComponent } from 'v3/components/Card';
import theme from 'v3/theme';
import { FaQuestionCircle } from 'react-icons/fa';
import TextArea from 'v3/components/TextArea';
import { StyledMonitoringGroupHeader, ToolTipLabel } from '../../styles';
import { useRiskManager } from '../../context';
import {
  RISK_MANAGER_STATUSES,
  RISK_MANAGER_STATUSES_OBJECT,
} from '../../controller';

export function PolicyRiskManagerEditing() {
  const travel = useTravel();
  const {
    hasEditPermissionRiskManager,
    policyErrors,
    selectedMonitoringGroup,
    rangeOptions,
    hasntMonitoringGroups,
    loadingRange,
    processedRangeOptions,
    monitoringGroupFields,
    responsibilitySecurityOptions,
  } = useRiskManager();

  const monitoringGroupOptionsTooltip = useMemo(() => {
    if (
      rangeOptions?.equipments_groups?.length > 0 &&
      selectedMonitoringGroup
    ) {
      return processGroupOptionsData(selectedMonitoringGroup);
    }
    if (hasntMonitoringGroups)
      return 'Apólice não possui informações de grupo de monitoramento';
    return '';
  }, [selectedMonitoringGroup, hasntMonitoringGroups]);

  return (
    <Col md={12} className="my-2">
      <Fallback
        fall={loadingRange}
        component={
          loadingRange ? (
            <DefaultLoadingBodyComponent linesCount={8} perLine={2} />
          ) : (
            <Col xs={12} className="align-items-center d-flex flex-column my-2">
              <Text color={theme.colors.danger}>
                Não foi possível carregar opções do range
              </Text>
            </Col>
          )
        }
      >
        <Row>
          <Col md={6} sm={12}>
            <Row>
              <Col md={12} className="my-2">
                <StyledMonitoringGroupHeader>
                  <Text type="body" color="dark" weight={500}>
                    Equipamentos de monitoramento
                  </Text>
                  <Tooltip
                    content={
                      <ToolTipLabel type="label">
                        {monitoringGroupOptionsTooltip}
                      </ToolTipLabel>
                    }
                  >
                    <FaQuestionCircle style={{ zIndex: 0 }} />
                  </Tooltip>
                </StyledMonitoringGroupHeader>
              </Col>
              <Col md={6} sm={12} className="mt-2 mb-3">
                <Text type="label" color="dark" weight={500}>
                  Rastreado
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel?.data?.loads?.crawledLoad ? 'Sim' : 'Não'}
                </Text>
              </Col>
              <Col md={6} sm={12} className="mt-2 mb-3">
                <Text type="label" color="dark" weight={500}>
                  Escoltado
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel?.data?.loads?.isEscorted ? 'Sim' : 'Não'}
                </Text>
              </Col>
              <Col md={6} sm={12} className="my-2">
                <Select
                  label={
                    monitoringGroupFields?.isTrackerRequired
                      ? 'Tecnologia rastreador *'
                      : 'Tecnologia rastreador'
                  }
                  value={travel?.data?.tracker}
                  onChange={value =>
                    travel.setData(old => ({
                      ...old,
                      tracker: value,
                      trackerType:
                        travel?.data?.trackerType?.trackerId === value?.id
                          ? travel?.data?.trackerType
                          : null,
                      trackerNumber:
                        travel?.data?.trackerType?.trackerId === value?.id
                          ? travel?.data?.trackerNumber
                          : '',
                    }))
                  }
                  tooltip={
                    hasntMonitoringGroups
                      ? 'Tecnologia depende da existência de grupo de monitoramento'
                      : monitoringGroupFields?.isTrackerRequired
                      ? 'Opções do grupo de monitoramento'
                      : 'Obrigatoriedade depende do grupo de monitoramento'
                  }
                  options={selectedMonitoringGroup?.tecnologies}
                  getOptionLabel={option => option?.name}
                  getOptionValue={option => option?.id}
                  isDisabled={
                    !monitoringGroupFields?.isTrackerRequired &&
                    !travel?.data?.tracker?.id
                  }
                  error={policyErrors?.tracker}
                />
              </Col>
              <Col md={6} sm={12} className="my-2" />
              <Col md={6} sm={12} className="my-2">
                <Select
                  label={'Modelo do rastreador'}
                  tooltip={
                    hasntMonitoringGroups
                      ? 'Modelo depende da existência de grupo de monitoramento'
                      : monitoringGroupFields?.isTrackerModelRequired &&
                        !travel.data?.loadGROData?.techTracker?.id
                      ? 'Selecione uma tecnologia para liberar as opções'
                      : 'Obrigatoriedade depende do grupo de monitoramento e tecnologia selecionada'
                  }
                  value={travel?.data?.trackerType}
                  onChange={value =>
                    travel.setData(old => ({
                      ...old,
                      trackerType: value,
                      trackerNumber: value ? old?.trackerNumber : '',
                    }))
                  }
                  options={selectedMonitoringGroup?.equipments?.filter(
                    option => option?.tracker_id === travel.data?.tracker?.id
                  )}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  isDisabled={
                    !hasEditPermissionRiskManager ||
                    !travel.data?.tracker?.id ||
                    (!monitoringGroupFields?.isTrackerModelRequired &&
                      !travel?.data?.trackerType?.id)
                  }
                  error={policyErrors?.trackerType}
                />
              </Col>
              <Col md={6} sm={12} className="my-2">
                <Input
                  label={'Número do rastreador'}
                  value={travel?.data?.trackerNumber}
                  onChange={({ target }) =>
                    travel.setData(old => ({
                      ...old,
                      trackerNumber: target.value,
                    }))
                  }
                  tooltip={
                    hasntMonitoringGroups
                      ? 'Número depende da existência de grupo de monitoramento'
                      : monitoringGroupFields?.isTrackerModelRequired &&
                        !travel.data?.trackerType?.id
                      ? 'Informe um modelo para liberar campo'
                      : 'Obrigatoriedade depende do grupo de monitoramento e modelo selecionados'
                  }
                  error={policyErrors?.trackerNumber}
                  disabled={
                    !hasEditPermissionRiskManager ||
                    !travel.data?.trackerType?.id
                  }
                />
              </Col>
              <Col md={6} sm={12} className="my-2">
                <Select
                  label={
                    monitoringGroupFields?.isLocatorRequired
                      ? 'Modelo localizador *'
                      : 'Modelo localizador'
                  }
                  value={travel?.data?.trackerLocator}
                  onChange={value =>
                    travel.setData(old => ({
                      ...old,
                      trackerLocator: value,
                      locatorNumber: value ? old?.locatorNumber : '',
                    }))
                  }
                  tooltip={
                    hasntMonitoringGroups
                      ? 'Modelo localizador depende da existência de grupo de monitoramento'
                      : monitoringGroupFields?.isLocatorRequired
                      ? 'Opções do grupo de monitoramento'
                      : 'Obrigatoriedade depende do grupo de monitoramento'
                  }
                  options={selectedMonitoringGroup?.locators}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  error={policyErrors?.trackerLocator}
                  isDisabled={
                    !monitoringGroupFields?.isLocatorRequired &&
                    !travel?.data?.trackerLocator?.id
                  }
                />
              </Col>
              <Col md={6} sm={12} className="my-2">
                <Input
                  label={'Número do localizador'}
                  value={travel?.data?.locatorNumber}
                  onChange={({ target }) =>
                    travel.setData(old => ({
                      ...old,
                      locatorNumber: target.value,
                    }))
                  }
                  tooltip={
                    hasntMonitoringGroups
                      ? 'Número depende da existência de grupo de monitoramento'
                      : monitoringGroupFields?.isLocatorRequired &&
                        !travel.data?.trackerLocator?.id
                      ? 'Selecione um localizador para liberar campo'
                      : 'Obrigatoriedade depende do grupo de monitoramento e localizador selecionados'
                  }
                  error={policyErrors?.locatorNumber}
                  disabled={
                    !hasEditPermissionRiskManager ||
                    !travel.data?.trackerLocator?.id
                  }
                />
              </Col>
              <Col md={6} sm={12} className="my-2">
                <Select
                  label={
                    monitoringGroupFields?.isImmobilizerRequired
                      ? 'Modelo imobilizador *'
                      : 'Modelo imobilizador'
                  }
                  value={travel?.data?.trackerImmobilizer}
                  onChange={value =>
                    travel.setData(old => ({
                      ...old,
                      trackerImmobilizer: value,
                      immobilizerNumber: value ? old?.immobilizerNumber : '',
                    }))
                  }
                  tooltip={
                    hasntMonitoringGroups
                      ? 'Imobilizador depende da existência de grupo de monitoramento'
                      : monitoringGroupFields?.isImmobilizerRequired
                      ? 'Opções do grupo de monitoramento'
                      : 'Obrigatoriedade depende do grupo de monitoramento'
                  }
                  options={selectedMonitoringGroup?.immobilizers}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  isDisabled={
                    !monitoringGroupFields?.isImmobilizerRequired &&
                    !travel?.data?.trackerImmobilizer?.id
                  }
                  error={policyErrors?.trackerImmobilizer}
                />
              </Col>
              <Col md={6} sm={12} className="my-2">
                <Input
                  label={'Número do imobilizador'}
                  value={travel?.data?.immobilizerNumber}
                  onChange={({ target }) =>
                    travel.setData(old => ({
                      ...old,
                      immobilizerNumber: target.value,
                    }))
                  }
                  tooltip={
                    hasntMonitoringGroups
                      ? 'Número depende da existência de grupo de monitoramento'
                      : monitoringGroupFields?.isImmobilizerRequired &&
                        !travel.data?.trackerImmobilizer?.id
                      ? 'Selecione um imobilizador para liberar campo'
                      : 'Obrigatoriedade depende do grupo de monitoramento e imobilizador selecionados'
                  }
                  error={policyErrors?.immobilizerNumber}
                  disabled={
                    !hasEditPermissionRiskManager ||
                    !travel.data?.trackerImmobilizer?.id
                  }
                />
              </Col>
              <Col
                xs={12}
                className="d-flex justify-content-center align-items-center"
              >
                {policyErrors?.baits || policyErrors?.baitsNumber ? (
                  <Text color="danger" weight={500}>
                    {policyErrors?.baits || policyErrors?.baitsNumber}
                  </Text>
                ) : null}
              </Col>
              {travel?.data?.baits?.map((bait, index) => {
                return (
                  <>
                    <Col md={6} sm={12} className="my-2">
                      <Select
                        label={`Isca ${index + 1} *`}
                        tooltip={
                          monitoringGroupFields?.isBaitRequired
                            ? 'Opções do grupo de monitoramento'
                            : 'Obrigatoriedade depende do grupo de monitoramento'
                        }
                        value={bait?.tracker}
                        onChange={value => {
                          travel.setData(old => ({
                            ...old,
                            baits: old?.baits?.map((bt, idx) => {
                              if (index === idx) {
                                return {
                                  ...bt,
                                  trackerId: value?.id,
                                  tracker: value,
                                  number: value ? bt?.number : '',
                                };
                              }
                              return bt;
                            }),
                          }));
                        }}
                        options={selectedMonitoringGroup?.baits}
                        getOptionLabel={option =>
                          option?.tracker?.name || option?.name
                        }
                        getOptionValue={option =>
                          option?.tracker?.id || option.id
                        }
                        isDisabled={
                          !monitoringGroupFields?.isBaitRequired &&
                          !bait?.tracker?.id
                        }
                        error={
                          policyErrors[`baits[${index}]`] ||
                          policyErrors[`baits[${index}].id`]
                        }
                      />
                    </Col>
                    <Col md={6} sm={12} className="my-2">
                      <Input
                        label={`Número da isca ${index + 1}`}
                        tooltip={
                          monitoringGroupFields?.isBaitRequired &&
                          !travel.data?.loadGROData?.[`bait${index + 1}`]?.id
                            ? 'Selecione um isca para liberar campo'
                            : 'Obrigatoriedade depende do grupo de monitoramento e isca selecionada'
                        }
                        value={travel.data?.baits?.[index]?.number}
                        onChange={e => {
                          travel.setData(old => ({
                            ...old,
                            baits: old?.baits?.map((bt, idx) => {
                              if (index === idx) {
                                return {
                                  ...bt,
                                  number: e?.target?.value,
                                };
                              }
                              return bt;
                            }),
                          }));
                        }}
                        error={policyErrors[`baitsNumber[${index}]`]}
                        disabled={!bait?.tracker?.id}
                      />
                    </Col>
                  </>
                );
              })}
            </Row>
          </Col>
          <Col md={6} sm={12}>
            <Row>
              <Col md={12} className="my-2">
                <Text type="body" color="dark" weight={500}>
                  Gerenciamento de risco
                </Text>
              </Col>
              <Col md={6} sm={12} className="my-2">
                {travel?.data?.policyInfo ? (
                  <>
                    <Text type="label" color="dark" weight={500}>
                      Responsabilidade seguro
                    </Text>
                    {travel?.data?.policyInfo?.product?.responsabilities ?
                      travel?.data?.policyInfo?.product?.responsabilities.map((responsability) => {
                      return (
                        <Text type="regular" color="gray" as="p" className="mb-0">
                          {responsability.name}
                        </Text>
                      )
                    }) : (
                      <Text type="regular" color="gray" as="p" className="mb-0">
                        Não informado
                      </Text>
                    )}
                  </>
                ) : (
                  <Select
                  label="Responsabilidade seguro"
                  value={travel?.data?.responsibilitySecurity}
                  onChange={value =>
                    travel.setData(old => ({
                      ...old,
                      responsibilitySecurity: value,
                    }))
                  }
                  options={responsibilitySecurityOptions}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  error={
                    policyErrors?.responsibilitySecurity ||
                    policyErrors?.['responsibilitySecurity.id']
                  }
                />
                )}
              </Col>
              <Col md={6} sm={12} className="my-2">
                {travel?.data?.policyInfo ? (
                  <>
                    <Text type="label" color="dark" weight={500}>
                      Perfil de segurança
                    </Text>
                    {travel?.data?.policyInfo?.range?.securityProfile ?
                      travel?.data?.policyInfo?.range?.securityProfile.map((profile) => {
                      return (
                        <Text type="regular" color="gray" as="p" className="mb-0">
                          {profile.name}
                        </Text>
                      )
                    }) : (
                      <Text type="regular" color="gray" as="p" className="mb-0">
                        Não informado
                      </Text>
                    )}
                  </>
                ) : (
                  <Select
                    label={'Perfil de segurança'}
                    tooltip={
                      processedRangeOptions?.securityProfileStatus?.required
                        ? ''
                        : 'Apólice não contempla Perfil de segurança'
                    }
                    value={travel?.data?.securityProfile}
                    onChange={value =>
                      travel.setData(old => ({
                        ...old,
                        securityProfile: value,
                      }))
                    }
                    options={
                      processedRangeOptions?.securityProfileStatus?.options
                    }
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    isDisabled={
                      !processedRangeOptions?.securityProfileStatus?.required &&
                      !travel?.data?.securityProfile?.id
                    }
                    error={
                      policyErrors?.securityProfile ||
                      policyErrors?.['securityProfile.id']
                    }
                  />
                )}
              </Col>
              <Col md={6} sm={12} className="my-2">
                {travel?.data?.policyInfo ? (
                  <>
                    <Text type="label" color="dark" weight={500}>
                      Gerenciadora para rastreamento
                    </Text>
                    {travel?.data?.policyInfo?.range?.managerTracker ?
                      travel?.data?.policyInfo?.range?.managerTracker.map((tracker) => {
                      return (
                        <Text type="regular" color="gray" as="p" className="mb-0">
                          {tracker.name}
                        </Text>
                      )
                    }) : (
                      <Text type="regular" color="gray" as="p" className="mb-0">
                        Não informado
                      </Text>
                    )}
                  </>
                ) : (
                  <Select
                    label={'Gerenciadora para rastreamento'}
                    tooltip={
                      processedRangeOptions?.riskManagerTrackerStatus?.required
                        ? ''
                        : 'Apólice não contempla gerenciadora para rastreamento'
                    }
                    value={travel?.data?.riskManager}
                    onChange={value =>
                      travel.setData(old => ({
                        ...old,
                        riskManager: value,
                      }))
                    }
                    options={
                      processedRangeOptions?.riskManagerTrackerStatus?.options
                    }
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    error={
                      policyErrors?.riskManager ||
                      policyErrors?.['riskManager.id']
                    }
                    isDisabled={
                      !processedRangeOptions?.riskManagerTrackerStatus
                        ?.required && !travel?.data?.riskManager?.id
                    }
                  />
                )}
              </Col>
              <Col md={6} sm={12} className="my-2">
                <Input
                  label="SM de risco"
                  tooltip="Código de solicitacão de rastreamento"
                  value={travel.data.riskManagerApprovalCode}
                  onChange={({ target }) =>
                    travel.setData(old => ({
                      ...old,
                      riskManagerApprovalCode: target.value,
                    }))
                  }
                  disabled={
                    !hasEditPermissionRiskManager
                  }
                />
              </Col>
              <Col md={6} sm={12} className="my-2">
                <Select
                  label={
                    processedRangeOptions?.riskManagerSearchStatus?.required
                      ? 'Gerenciadora cadastro/consulta *'
                      : 'Gerenciadora cadastro/consulta'
                  }
                  tooltip={
                    processedRangeOptions?.riskManagerSearchStatus?.required
                      ? ''
                      : 'Apólice não contempla gerenciadora para cadastro/consulta'
                  }
                  value={travel?.data?.riskManagerSearch}
                  onChange={value =>
                    travel.setData(old => ({
                      ...old,
                      riskManagerSearch: value,
                    }))
                  }
                  options={
                    processedRangeOptions?.riskManagerSearchStatus?.options
                  }
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  isDisabled={
                    !processedRangeOptions?.riskManagerSearchStatus?.required &&
                    !travel?.data?.riskManagerSearch?.id
                  }
                  error={
                    policyErrors?.riskManagerSearch ||
                    policyErrors?.['riskManagerSearch.id']
                  }
                />
              </Col>
              <Col md={6} sm={12} className="my-2">
                <Input
                  label="Pesquisa/ Consulta"
                  disabled={
                    !hasEditPermissionRiskManager
                  }
                  value={travel?.data?.riskManagerMonitoringRequest}
                  onChange={({ target }) =>
                    travel.setData(old => ({
                      ...old,
                      riskManagerMonitoringRequest: target.value,
                    }))
                  }
                />
              </Col>
              <Col md={6} sm={12} className="my-2">
                <Select
                  label={
                    processedRangeOptions?.riskManagerLogisctSMStatus?.required
                      ? 'Gerenciadora de risco para SM logística *'
                      : 'Gerenciadora de risco para SM logística'
                  }
                  tooltip={
                    processedRangeOptions?.riskManagerLogisctSMStatus?.required
                      ? ''
                      : 'Apólice não contempla gerenciadora de risco para SM logística'
                  }
                  value={travel?.data?.riskManagerLogisticSm}
                  onChange={value =>
                    travel.setData(old => ({
                      ...old,
                      riskManagerLogisticSm: value,
                    }))
                  }
                  options={
                    processedRangeOptions?.riskManagerLogisctSMStatus?.options
                  }
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  isDisabled={
                    !hasEditPermissionRiskManager ||
                    (!processedRangeOptions?.riskManagerLogisctSMStatus
                      ?.required &&
                      !travel?.data?.riskManagerLogisticSm?.id)
                  }
                  error={
                    policyErrors?.riskManagerLogisticSm ||
                    policyErrors?.['riskManagerLogisticSm.id']
                  }
                />
              </Col>
              <Col md={6} sm={12} className="my-2">
                <Input
                  label="SM logística"
                  disabled={
                    !hasEditPermissionRiskManager ||
                    !processedRangeOptions?.riskManagerLogisctSMStatus?.required
                  }
                  value={travel?.data?.riskCodeLogisticSm}
                  onChange={e =>
                    travel.setData(old => ({
                      ...old,
                      riskCodeLogisticSm: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col md={6} sm={12} className="my-2">
                {travel?.data?.policyInfo ? (
                  <>
                    <Text type="label" color="dark" weight={500}>
                      Espelhamento logístico
                    </Text>
                    {travel?.data?.policyInfo?.range?.managerMirror ?
                      travel?.data?.policyInfo?.range?.managerMirror.map((mirror) => {
                      return (
                        <Text type="regular" color="gray" as="p" className="mb-0">
                          {mirror.name}
                        </Text>
                      )
                    }) : (
                      <Text type="regular" color="gray" as="p" className="mb-0">
                        Não informado
                      </Text>
                    )}
                  </>
                ) : (
                  <Select
                    label={
                      processedRangeOptions?.riskManagerLogisticMirrorStatus
                        ?.required
                        ? 'Espelhamento logístico *'
                        : 'Espelhamento logístico'
                    }
                    tooltip={
                      processedRangeOptions?.riskManagerLogisticMirrorStatus
                        ?.required
                        ? ''
                        : 'Apólice não contempla espelhamento logístico'
                    }
                    value={travel?.data?.riskManagerLogisticMirroring}
                    onChange={value =>
                      travel.setData(old => ({
                        ...old,
                        riskManagerLogisticMirroring: value,
                      }))
                    }
                    options={
                      processedRangeOptions?.riskManagerLogisticMirrorStatus
                        ?.options
                    }
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    isDisabled={
                      !hasEditPermissionRiskManager ||
                      (!processedRangeOptions?.riskManagerLogisticMirrorStatus
                        ?.required &&
                        !travel?.data?.riskManagerLogisticMirroring?.id)
                    }
                    error={
                      policyErrors?.riskManagerLogisticMirroring ||
                      policyErrors?.['riskManagerLogisticMirroring.id']
                    }
                  />
                )}
              </Col>
              <Col md={6} sm={12} className="mt-2 mb-3">
                <Text type="label" color="dark" weight={500}>
                  Checklist Rastreador
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {travel?.data?.policyInfo ?
                    <>{travel?.data?.policyInfo?.range?.checklistTracker || 'Não Informado'}</> :
                    <>{travel?.data?.loads?.checklistType || 'Não Informado'}</>}
                </Text>
              </Col>
              <Col md={6} sm={12} className="my-2">
                <Select
                  label="Status Checklist"
                  options={RISK_MANAGER_STATUSES}
                  value={
                    RISK_MANAGER_STATUSES_OBJECT[travel.data.riskManagerStatus]
                  }
                  onChange={value => {
                    travel.setData(old => ({
                      ...old,
                      riskManagerStatus: value?.value,
                    }));
                  }}
                  isDisabled={!hasEditPermissionRiskManager}
                />
              </Col>
              {travel.data.riskManagerStatus === 'WAIT_SCHEDULE' ? (
                <Col md={12} sm={12} className="my-2">
                  <Input
                    label="Observação GR"
                    tooltip="Observações"
                    value={travel.data.riskManagerObservation}
                    maxLength={1000}
                    onChange={({ target }) =>
                      travel.setData(old => ({
                        ...old,
                        riskManagerObservation: target.value,
                      }))
                    }
                    error={policyErrors?.riskManagerObservation}
                  />
                </Col>
              ) : null}
              <Col md={12} sm={12} className="my-2">
                <TextArea
                  label="Averbação"
                  tooltip="Dados de averbação da viagem (Data do envio, Data da averbação,Número da averbação, Protocolo, Valor averbado, Placa, Condutor)"
                  value={travel.data.endorsement}
                  onChange={e =>
                    travel.setData(old => ({
                      ...old,
                      endorsement: e.target.value,
                    }))
                  }
                  error={policyErrors?.endorsement}
                  disabled={!hasEditPermissionRiskManager}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fallback>
    </Col>
  );
}
