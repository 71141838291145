import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { validatePermission } from 'actions';
import api from 'services/api';
import {
  fetchCostCenter,
  fetchCustomer,
  fetchCustomers,
  fetchTags,
  fetchUser,
  fetchUsers,
} from 'utils/fetches';
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Radio,
  Select,
  Text,
} from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

export function Filter({
  occurrencesOptions,
  showAdvancedFiltersModal,
  setShowAdvancedFiltersModal,
  loadingInitialParams,
  query,
  setQtdAdvancedFilters,
  handleFilter,
  errors,
  setErrors,
}) {
  const history = useHistory();
  const snackbar = useSnackbar();
  const hasPermissionToSelectTags = validatePermission('SELECIONAR_TAGS');
  const [advancedFilters, setAdvancedFilters] = useState({
    users: [],
    customers: [],
    costCenters: [],
    tags: [],
    occurrenceTypes: [],
    bennerTravelId: null,
    cooperplaceTravelId: null,
    allCustomers: query.has('allCustomers')
      ? Number(query.get('allCustomers'))
      : 1,
    pendency: query.has('pendency') ? Number(query.get('pendency')) : -1,
    startDate: '',
    startMaxDate: '',
    endDate: '',
    endMaxDate: '',
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.replace({ search: query.toString() });
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [advancedFilters]);

  useEffect(() => {
    const isNotEmpty = value =>
      value !== null && value !== undefined && value !== '' && value !== 0;

    const validateAdvancedFilters = {
      pendency: advancedFilters?.pendency !== null,
      allCustomers: advancedFilters?.allCustomers !== null,
      customers: advancedFilters?.customers?.length,
      bennerTravelId: advancedFilters?.bennerTravelId,
      costCenters: advancedFilters?.costCenters?.length,
      users: advancedFilters?.users?.length,
      tags: advancedFilters?.tags?.length,
    };

    setQtdAdvancedFilters(
      Object.values(validateAdvancedFilters).filter(isNotEmpty).length
    );
  }, [advancedFilters]);

  async function handleCostCentersParams(ccIds) {
    try {
      if (ccIds && ccIds.length > 0) {
        const allCCs = [];
        await Promise.all(
          ccIds.map(async cc => {
            const responseCostCenter = await api.get(`v3/cost-center/${cc}`);
            if (responseCostCenter?.data?.id) {
              allCCs.push(responseCostCenter?.data);
            }
          })
        );
        setAdvancedFilters(old => ({
          ...old,
          costCenters: allCCs,
        }));
      }
    } catch (error) {
      snackbar.show(
        <Text weight={500}>
          Não foi possível recuperar todos os filtros de Centro de Custo setados
          na url
        </Text>,
        { type: 'warning' }
      );
    }
  }
  async function handleCustomersParams(customersIds) {
    try {
      if (customersIds && customersIds.length > 0) {
        const customersFinalData = [];
        await Promise.all(
          customersIds.map(async item => {
            const responseUser = await fetchCustomer(item);
            if (responseUser?.id) {
              customersFinalData.push(responseUser);
            }
          })
        );
        setAdvancedFilters(old => ({
          ...old,
          customers: customersFinalData,
        }));
      }
    } catch (error) {
      snackbar.show(
        <Text weight={500}>
          Não foi possível recuperar todos os filtros de Cliente setados na url
        </Text>,
        { type: 'warning' }
      );
    }
  }

  async function handleUsersParams(usersIds) {
    try {
      if (usersIds && usersIds.length > 0) {
        const usersFinalData = [];
        await Promise.all(
          usersIds.map(async item => {
            const responseUser = await fetchUser(item);
            if (responseUser?.id) {
              usersFinalData.push(responseUser);
            }
          })
        );
        setAdvancedFilters(old => ({
          ...old,
          users: usersFinalData,
        }));
      }
    } catch (error) {
      snackbar.show(
        <Text weight={500}>
          Não foi possível recuperar todos os filtros de Usuário setados na url
        </Text>,
        { type: 'warning' }
      );
    }
  }
  async function handleTagsParams(tagsIds) {
    try {
      if (tagsIds && tagsIds.length > 0) {
        const tagsFinalData = [];
        await Promise.all(
          tagsIds.map(async tag => {
            const response = await api.get(`tags/${tag}`);
            tagsFinalData.push(response.data);
          })
        );
        setAdvancedFilters(old => ({ ...old, tags: tagsFinalData }));
      }
    } catch (e) {
      snackbar.show(
        <Text weight={500}>
          Não foi possível recuperar todos os filtros de Tags setados na url
        </Text>,
        { type: 'warning' }
      );
    }
  }

  async function handleInitializeParams() {
    if (query.has('startDate')) {
      setAdvancedFilters(old => ({
        ...old,
        startDate: moment(query.get('startDate'), 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        ),
      }));
    } else {
      const startDateWithTime = moment().subtract(30, 'days').startOf('D');
      query.set(
        'startDate',
        moment(startDateWithTime).format('YYYY-MM-DD HH:mm:ss')
      );
      setAdvancedFilters(old => ({
        ...old,
        startDate: moment().subtract(30, 'days').format('DD/MM/YYYY'),
      }));
    }
    if (query.has('endDate')) {
      setAdvancedFilters(old => ({
        ...old,
        endDate: moment(query.get('endDate'), 'YYYY-MM-DD').format(
          'DD/MM/YYYY'
        ),
      }));
    } else {
      const endDateWithTime = moment().endOf('D');
      query.set(
        'endDate',
        moment(endDateWithTime).format('YYYY-MM-DD HH:mm:ss')
      );
      setAdvancedFilters(old => ({
        ...old,
        endDate: moment().format('DD/MM/YYYY'),
      }));
    }
    if (query.has('occurrenceNumber')) {
      setAdvancedFilters(old => ({
        ...old,
        occurrenceNumber: query.get('occurrenceNumber'),
      }));
    }
    if (query.has('occurrenceTypeIds[]')) {
      const occurrenceTypesIds = query
        .getAll('occurrenceTypeIds[]')
        ?.map(Number);
      const selectedTypes = occurrencesOptions.filter(option =>
        occurrenceTypesIds.includes(option.id)
      );
      setAdvancedFilters(old => ({
        ...old,
        occurrenceTypes: selectedTypes,
      }));
    }
    if (query.has('cooperplaceTravelId')) {
      setAdvancedFilters(old => ({
        ...old,
        cooperplaceTravelId: query.get('cooperplaceTravelId'),
      }));
    }
    if (query.has('bennerTravelId')) {
      setAdvancedFilters(old => ({
        ...old,
        bennerTravelId: query.get('bennerTravelId'),
      }));
    }
    if (query.has('usersIds[]')) {
      const usersIds = query.getAll('usersIds[]');
      await handleUsersParams(usersIds);
    }
    if (query.has('customersIds[]')) {
      const customersIds = query.getAll('customersIds[]');
      await handleCustomersParams(customersIds);
    }
    if (query.has('costCenterIds[]')) {
      const ccIds = query.getAll('costCenterIds[]');
      await handleCostCentersParams(ccIds);
    }
    if (query.has('tagsIds[]')) {
      const tagsIds = query.getAll('tagsIds[]');
      await handleTagsParams(tagsIds);
    }
  }

  useEffect(() => {
    if (!loadingInitialParams) {
      handleInitializeParams();
    }
  }, [loadingInitialParams]);

  function parseDate(value, isEndDate = false) {
    if (moment(value, 'DD/MM/YYYY').isValid() && !isEndDate) {
      return moment(value, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    }
    if (isEndDate && moment(value, 'DD/MM/YYYY').isValid()) {
      const endDateWithTime = moment(value, 'DD/MM/YYYY').endOf('D');
      return moment(endDateWithTime, 'DD/MM/YYYY HH:mm:ss').format(
        'YYYY-MM-DD HH:mm:ss'
      );
    }

    return null;
  }

  function handleAttemptFilter() {
    setShowAdvancedFiltersModal(false);
    handleFilter();
  }
  function handleSearchType(search) {
    if (!search) {
      return occurrencesOptions;
    }
    const parsed = search?.toLocaleLowerCase();
    return occurrencesOptions?.filter(
      option => option?.name?.toLocaleLowerCase().search(parsed) >= 0
    );
  }

  return (
    <>
      <Modal
        heading={
          <Text color="dark" type="header">
            Filtro Avançado
          </Text>
        }
        show={showAdvancedFiltersModal}
        handleClose={() => setShowAdvancedFiltersModal(false)}
        body={
          <Row>
            <Col sm={12} md={3} className="my-2">
              <Text type="label" color="dark" weight={500} as="p">
                Status Ocorrências
              </Text>
              <Radio.Group
                onChange={({ target }) => {
                  if (target.value === -1) {
                    query.delete('pendency');
                  } else {
                    query.set('pendency', target.value);
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    pendency: target.value,
                  }));
                }}
                value={advancedFilters?.pendency}
                horizontal
              >
                <Radio value={1}>
                  <Text color="dark" type="label">
                    Pendentes
                  </Text>
                </Radio>
                <Radio value={0}>
                  <Text color="dark" type="label">
                    Concluidas
                  </Text>
                </Radio>
                <Radio value={-1}>
                  <Text color="dark" type="label">
                    Todas
                  </Text>
                </Radio>
              </Radio.Group>
            </Col>
            <Col sm={12} md={2} className="my-2">
              <Text type="label" color="dark" weight={500} as="p">
                Todos os Clientes?
              </Text>
              <Radio.Group
                onChange={({ target }) => {
                  if (target.value === 0) {
                    query.delete('allCustomers');
                    setAdvancedFilters(old => ({
                      ...old,
                      allCustomers: target.value,
                    }));
                  } else {
                    query.delete('customersIds[]');
                    query.set('allCustomers', target.value);
                    setAdvancedFilters(old => ({
                      ...old,
                      allCustomers: target.value,
                      customers: [],
                    }));
                  }
                }}
                value={advancedFilters?.allCustomers}
                horizontal
              >
                <Radio value={1}>
                  <Text color="dark" type="label">
                    Sim
                  </Text>
                </Radio>
                <Radio value={0}>
                  <Text color="dark" type="label">
                    Não
                  </Text>
                </Radio>
              </Radio.Group>
            </Col>
            <Col sm={12} md={4} className="my-2">
              <Select.Async
                label="Nome do cliente ou CPF/ CNPJ"
                onSearch={search => fetchCustomers({ search })}
                value={advancedFilters?.customers}
                onChange={value => {
                  if (Array.isArray(value)) {
                    query.delete('customersIds[]');
                    value
                      .map(i => i.id)
                      .forEach(processed => {
                        query.append('customersIds[]', processed);
                      });
                  } else {
                    query.delete('customersIds[]');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    customers: value,
                  }));
                }}
                getOptionLabel={option => {
                  let label = `${option?.social_name} - ${option?.cgccpf}`;
                  if (option.city) {
                    label += ` - ${option?.city}`;
                  }
                  return label;
                }}
                getOptionValue={option => option.id}
                horizontal
                multiple
                modalHeading="Selecione um Cliente"
                modalBodyTitle="Clientes:"
                isDisabled={advancedFilters?.allCustomers}
              />
            </Col>
            <Col sm={12} md={3} className="my-2">
              <Input
                label="Nº Viagem Benner"
                value={advancedFilters?.bennerTravelId}
                onChange={e => {
                  if (e?.target?.value?.length > 14) {
                    return;
                  }
                  if (e.target.value) {
                    query.set('bennerTravelId', e.target.value);
                  } else {
                    query.delete('bennerTravelId');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    bennerTravelId: e?.target?.value,
                  }));
                }}
              />
            </Col>
            <Col xs={12} md={4} className="my-2">
              <Select.Async
                label="Centro de custo"
                onSearch={search => fetchCostCenter({ search })}
                value={advancedFilters?.costCenters}
                onChange={value => {
                  if (Array.isArray(value)) {
                    query.delete('costCenterIds[]');
                    value
                      .map(i => i.id)
                      .forEach(processed =>
                        query.append('costCenterIds[]', processed)
                      );
                  } else {
                    query.delete('costCenterIds[]');
                  }
                  setAdvancedFilters(old => ({ ...old, costCenters: value }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                horizontal
                multiple
                modalHeading="Selecione um Centro de Custo"
                modalBodyTitle="Centros de Custo:"
              />
            </Col>
            <Col sm={12} md={4} className="my-2">
              <Select.Async
                label="Usuário"
                tooltip="Filtrar por usuário que criou a ocorrência"
                getOptionLabel={option => {
                  let label = `${option.username}`;
                  if (option?.cpf) {
                    label += ` - ${option?.cpf}`;
                  }
                  return label;
                }}
                getOptionValue={option => option.id}
                value={advancedFilters?.users}
                onSearch={search => fetchUsers(search)}
                onChange={value => {
                  if (Array.isArray(value)) {
                    query.delete('usersIds[]');
                    value
                      .map(i => i.id)
                      .forEach(processed => {
                        query.append('usersIds[]', processed);
                      });
                  } else {
                    query.delete('usersIds[]');
                  }
                  setAdvancedFilters(old => ({
                    ...old,
                    users: value,
                  }));
                }}
                horizontal
                multiple
                modalHeading="Selecione um Usuário"
                modalBodyTitle="Usuários:"
              />
            </Col>
            <Col md={4} xs={12} className="my-2">
              <Select.Async
                multiple
                label="Tags"
                onSearch={fetchTags}
                value={advancedFilters?.tags}
                horizontal
                onChange={value => {
                  if (Array.isArray(value)) {
                    query.delete('tagsIds[]');
                    value
                      .map(i => i.id)
                      .forEach(processed =>
                        query.append('tagsIds[]', processed)
                      );
                  } else {
                    query.delete('tagsIds[]');
                  }
                  setAdvancedFilters(old => ({ ...old, tags: value }));
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                isDisabled={!hasPermissionToSelectTags}
              />
            </Col>
          </Row>
        }
        footer={
          <Row>
            <Col>
              <Button size="sm" onClick={() => handleAttemptFilter()}>
                <Text weight={500}>Filtrar</Text>
              </Button>
            </Col>
          </Row>
        }
      />
      <Row>
        <Col md={2} sm={12} className="my-2">
          <DatePicker
            label="Abertura inicial:*"
            value={advancedFilters.startDate}
            onChange={e => {
              if (!e?.target?.value) {
                query.delete('startDate');
                setAdvancedFilters(old => ({
                  ...old,
                  startDate: e.target?.value,
                }));
                return;
              }
              const parsedDate = parseDate(e?.target?.value);
              query.set('startDate', parsedDate);
              setAdvancedFilters(old => ({
                ...old,
                startDate: e.target?.value,
              }));
              setErrors(old => ({ ...old, startDate: null, startMaxDate: null }));
            }}
            error={errors?.startDate}
            labelColor="white"
          />
          <Input
            labelColor={windowWidth < 768 ? 'dark' : '#fff'}
            label="Nº Viagem Cooperplace"
            value={advancedFilters?.cooperplaceTravelId}
            onChange={e => {
              if (e?.target?.value?.length > 14) {
                return;
              }
              if (e.target.value) {
                query.set('cooperplaceTravelId', e.target.value);
              } else {
                query.delete('cooperplaceTravelId');
              }
              setAdvancedFilters(old => ({
                ...old,
                cooperplaceTravelId: e?.target?.value,
              }));
            }}
          />
        </Col>
        <Col md={2} sm={12} className="my-2">
          <DatePicker
            label="Abertura final:"
            value={advancedFilters.startMaxDate}
            onChange={e => {
              if (!e?.target?.value) {
                query.delete('startMaxDate');
                setAdvancedFilters(old => ({
                  ...old,
                  startMaxDate: e.target?.value,
                }));
                return;
              }
              const parsedDate = parseDate(e?.target?.value, true);
              query.set('startMaxDate', parsedDate);
              setAdvancedFilters(old => ({
                ...old,
                startMaxDate: e.target?.value,
              }));
              setErrors(old => ({ ...old, startDate: null, startMaxDate: null }));
            }}
            error={errors?.startMaxDate}
            labelColor="white"
          />
          <Input
            label="Nº da Ocorrência"
            labelColor={windowWidth < 768 ? 'dark' : '#fff'}
            value={advancedFilters?.occurrenceNumber}
            onChange={e => {
              if (e?.target?.value?.length > 14) {
                return;
              }
              if (e.target.value) {
                query.set('occurrenceNumber', e.target.value);
              } else {
                query.delete('occurrenceNumber');
              }
              setAdvancedFilters(old => ({
                ...old,
                occurrenceNumber: e?.target?.value,
              }));
            }}
          />
        </Col>
        <Col sm={12} md={2} className="my-2">
        {/* <Text color='white'>Termino Ocorrência</Text> */}
          <DatePicker
            label="Termino inicial:"
            value={advancedFilters.endDate}
            onChange={e => {
              if (!e?.target?.value) {
                query.delete('endDate');
                setAdvancedFilters(old => ({
                  ...old,
                  endDate: e.target?.value,
                }));
                return;
              }
              const parsedDate = parseDate(e?.target?.value);
              query.set('endDate', parsedDate);
              setAdvancedFilters(old => ({
                ...old,
                endDate: e.target?.value,
              }));
              setErrors(old => ({ ...old,endDate: null, endMaxDate: null }));
            }}
            error={errors?.endDate}
            labelColor="white"
          />
        </Col>
        <Col sm={12} md={2} className="my-2">
        <DatePicker
            label="Termino final:"
            value={advancedFilters.endMaxDate}
            onChange={e => {
              if (!e?.target?.value) {
                query.delete('endMaxDate');
                setAdvancedFilters(old => ({
                  ...old,
                  endMaxDate: e.target?.value,
                }));
                return;
              }
              const parsedDate = parseDate(e?.target?.value, true);
              query.set('endMaxDate', parsedDate);
              setAdvancedFilters(old => ({
                ...old,
                endMaxDate: e.target?.value,
              }));
              setErrors(old => ({ ...old, endDate: null, endMaxDate: null }));
            }}
            error={errors?.endMaxDate}
            labelColor="white"
          />
        </Col>
        <Col md={4} sm={12} className="my-2">
          <Select.Async
            labelTextColor={windowWidth < 768 ? 'dark' : '#fff'}
            label="Tipo ocorrência"
            labelColor={windowWidth < 768 ? 'dark' : '#fff'}
            value={advancedFilters?.occurrenceTypes}
            onChange={value => {
              if (Array.isArray(value)) {
                query.delete('occurrenceTypeIds[]');
                value
                  .map(i => i.id)
                  .forEach(processed =>
                    query.append('occurrenceTypeIds[]', processed)
                  );
              } else {
                query.delete('occurrenceTypeIds[]');
              }
              setAdvancedFilters(old => ({
                ...old,
                occurrenceTypes: value,
              }));
            }}
            options={occurrencesOptions}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            multiple
            horizontal
            onSearch={handleSearchType}
            modalHeading="Selecione um tipo"
            modalBodyTitle="Tipos:"
          />
        </Col>
      </Row>
    </>
  );
}
