import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Text } from 'v3/components';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'v3/utils/formatter';
import { CardStyled} from './styles';


function MapDetailCard({driver}) {

  function formatCurrency(value) {
    const number = parseFloat(value);
    return `R$ ${number.toFixed(2).replace('.', ',')}`;
  }

  return (
    <CardStyled>
      <Col md={12}>
        <Row md={12} lg={12} className="mt-2">
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Motorista:
              <br/>
              <Link
              to={`/motoristas/${driver?.id}`}
              target="_blank"
            >
              {driver?.motorista}
            </Link>
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Ativo no Portal:
              <br/>
              {driver?.ativo_portal || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Ativo no App:
              <br/>
              {driver?.ativo_app || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Telefone:
              <br/>
              {driver?.telefone_cooperplace || 'Não informado'}
            </Text>
          </Col>
        </Row>
        <Row md={12} lg={12} className="mt-2">
        <Col md={3}>
            <Text fontSize={12} weight={500}>
              Placa tração:
              <br/>
              {driver?.veiculo_tracao || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Tipo de tração:
              <br/>
              {driver?.tipo_tracao || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Placas implementos:
              <br/>
              {driver?.placa_implemento || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Tipo de implemento:
              <br/>
              {driver?.tipo_implemento || 'Não informado'}
            </Text>
          </Col>
        </Row>
        <Row md={12} className="mt-2">
        <Col md={3}>
            <Text fontSize={12} weight={500}>
              Tipo de carrocerias:
              <br/>
              {driver?.carroceria || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Quantidade de viagens:
              <br/>
              {driver?.quantidade_viagens || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Dias desde a ultima viagem:
              <br/>
              {driver?.ultima_viagem || 'Não informado'}
            </Text>
          </Col>
          <Col md={3}>
            <Text fontSize={12} weight={500}>
              Faturamento Bruto das viagens:
              <br/>
              {formatCurrency(driver?.faturamento_viagens) || 'Não informado'}
            </Text>
          </Col>
        </Row>
        <Row md={12} lg={12} className="mt-2">
          <Col md={3}>
              <Text fontSize={12} weight={500}>
                Id da última viagem:
                <br/>
                {<Link
                  to={`/viagens/${driver?.id_ult_viagem}`}
                  target="_blank"
                >
                  {driver?.id_ult_viagem}
                </Link> || 'Não informado'}
              </Text>
            </Col>
            <Col md={3}>
              <Text fontSize={12} weight={500}>
                Negociador da última viagem:
                <br/>
                {driver?.negociador_ult_viagem || 'Não informado'}
              </Text>
            </Col>
            <Col md={3}>
              <Text fontSize={12} weight={500}>
                PCP da última viagem:
                <br/>
                {driver?.pcp_ult_viagem || 'Não informado'}
              </Text>
            </Col>
            <Col md={3}>
              <Text fontSize={12} weight={500}>
                Centro de custo da última viagem:
                <br/>
                {driver?.cc_ult_viagem || 'Não informado'}
              </Text>
            </Col>
          </Row>
          <Row md={12} lg={12} className="mt-2">
            <Col md={3}>
              <Text fontSize={12} weight={500}>
                Data e hora da ultima localização:
                <br/>
                {driver?.data_posicao ? formatDateTime(driver?.data_posicao) : 'Não informado'}
              </Text>
            </Col>
            <Col md={3}>
              <Text fontSize={12} weight={500}>
                avaliação do motorista:
                <br/>
                {driver?.geral || 'Não informado'}
              </Text>
            </Col>
          </Row>
      </Col>
    </CardStyled>
  );
}

export default MapDetailCard;
