import React, { useState } from 'react';
import routes from 'config/MenuNavConstants';
import { validatePermission } from 'actions';
import { Link } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import useWindowSize from 'hooks/useWindowSize';
import UpAnchor from 'v3/components/UpAnchor';
import * as Styled from '../styles';

const SubItems = ({ item, isAllMenusClosed }) => {
  const [isOpenSubMenu, setIsOpenSUbMenu] = useState(false);
  if (item.children) {
    return (
      <Styled.NavDropdownItem
        title={item.title}
        key={item.title}
        onToggle={setIsOpenSUbMenu}
        show={!isAllMenusClosed && isOpenSubMenu}
        drop="down"
        id={item.id}
      >
        {item.children
          .filter(({ permissionRequired }) => {
            if (!permissionRequired) {
              return true;
            }
            return validatePermission(permissionRequired);
          })
          .map(sub => {
            return (
              <NavDropdown.Item
                style={{ maxWidth: '200px' }}
                to={sub.path}
                as={Link}
                key={sub.path}
                id={sub.id}
              >
                <span
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                  }}
                >
                  {sub.title}
                </span>
              </NavDropdown.Item>
            );
          })}
      </Styled.NavDropdownItem>
    );
  }
  return (
    <NavDropdown.Item to={item.path} as={Link} key={item.path} id={item.id}>
      <span
        style={{
          display: 'flex',
          alignContent: 'center',
        }}
      >
        {item.title}
      </span>
    </NavDropdown.Item>
  );
};

const Items = ({ link, path, isAllMenusClosed }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (link.children) {
    const active = link.children.find(item => {
      return item.path === path;
    });

    return (
      <div
        style={{
          display: 'flex',
          gap: 4,
          alignItems: 'center',
        }}
      >
        <div style={{ width: 25 }}>{link.icon}</div>
        <Styled.NavDropdown
          active={active}
          title={link.title}
          id={link.id}
          key={link.path}
          show={!isAllMenusClosed && isOpen}
          onToggle={setIsOpen}
        >
          {link.children
            .filter(({ hidden }) => !hidden)
            .filter(({ permissionRequired }) => {
              if (!permissionRequired) {
                return true;
              }
              return validatePermission(permissionRequired);
            })
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(item => (
              <SubItems isAllMenusClosed={isAllMenusClosed} item={item} />
            ))}
        </Styled.NavDropdown>
      </div>
    );
  }
  const active = path && path === link.path;

  return (
    <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
      <div style={{ width: 25 }}>{link.icon}</div>
      <Styled.NavLink
        as={Link}
        to={link.path}
        key={link.path}
        active={active}
        id={link.id}
      >
        {link.title}
      </Styled.NavLink>
    </div>
  );
};

export default function Sidebar() {
  const path = useSelector(store => store.path);
  const [isAllMenusClosed, setIsAllMenusClosed] = useState(true);
  const [windowSize] = useWindowSize();

  const currentHtmlFullHeight = document.querySelector('html');
  const { height } = currentHtmlFullHeight.getBoundingClientRect();

  return (
    <Styled.DefaultContainer>
      <UpAnchor reachedEnd={windowSize.userScroll.y >= 300} />
      <Styled.BackDrop
        $heigth={
          height > windowSize.rootComponent.height
            ? `${height}px`
            : `${windowSize.rootComponent.height}px`
        }
      />
      <Styled.MenuContainer
        onMouseLeave={() => setIsAllMenusClosed(true)}
        onMouseEnter={() => setIsAllMenusClosed(false)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            alignItems: 'flex-start',
            paddingTop: 100,
          }}
        >
          {routes
            .filter(({ hidden }) => !hidden)
            .filter(({ permissionRequired }) =>
              validatePermission(permissionRequired)
            )
            .map(link => (
              <Items
                link={link}
                path={path}
                isAllMenusClosed={isAllMenusClosed}
              />
            ))}
        </div>
      </Styled.MenuContainer>
    </Styled.DefaultContainer>
  );
}
