import moment from 'moment';
import { createContext } from 'utils/react-helpers';

const [FleetManagementProvider, useFleetManagement] = createContext(
  'FleetManagementContext'
);

export const DEFAULT_COLUMNS = ['PLATE', 'STATUS_OPERATIONAL'];

const formatBoolean = (value) => {

  if (typeof value === 'boolean') {
    switch (value) {
      case true:
        return 1;
      case false:
        return 0;
      default:
        return -1;
    }
  }

  return value;
}

const validDate = (date) => {
  if (date && moment(date, 'DD/MM/YYYY', true).isValid()) {
    return moment(date, 'DD-MM-YYYY').format(
      'YYYY-MM-DD'
    )
  }
}


export const filtersMapper = (originalFilters, rowsPerPage) => {

  const mappedFilters = {
    startDate: moment(originalFilters.startDate, 'DD/MM/YYYY').isValid()
      ? moment(originalFilters.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : moment().subtract(1, 'month').format('YYYY-MM-DD'),
    endDate: moment(originalFilters.endDate).isValid()
      ? moment(originalFilters.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
    statusOperator: originalFilters?.statusOperator?.map(({ id }) => id) || [],
    operation: originalFilters?.tags?.map(({ id }) => id) || [],
    wagonPlate: originalFilters?.plates
      .filter(({ vehicleType }) => vehicleType === 'implement')
      .map(({ id }) => id) || [],
    vehiclePlate: originalFilters?.plates
      .filter(({ vehicleType }) => vehicleType === 'vehicle')
      .map(({ id }) => id) || [],
    wagonType: originalFilters?.implementTypes.map(({ id }) => id) || [],
    vehicleType: originalFilters?.vehicleTypes.map(({ id }) => id) || [],
    bodyType: originalFilters.bodyTypes?.map(({ id }) => id) || [],
    startAvailabilityForecast: validDate(originalFilters.startAvailabilityForecast),
    endAvailabilityForecast: validDate(originalFilters.endAvailabilityForecast),
    operator: originalFilters?.operators?.map(({ id }) => id) || [],
    driver: originalFilters?.drivers?.map(({ id }) => id) || [],
    aggregation: originalFilters.aggregation?.value,
    branchOfficeOrigin: originalFilters?.branchOffices?.map(({ id }) => id) || [],
    travelStatus: originalFilters.travelStatus.map(({ id }) => id),
    withOccurrences: formatBoolean(originalFilters?.withOccurrences) || -1,
    active: formatBoolean(originalFilters?.active) || -1,
    page: originalFilters?.page || rowsPerPage?.page || 1,
    perPage: originalFilters?.perPage || rowsPerPage?.rowsPerPage || 10,
    fleet: originalFilters?.fleet?.map(({ id }) => id) || [],
    shipping: originalFilters?.shipping?.map(({ id }) => id) || [],
    emptyTravel: undefined,
  };

  return mappedFilters;
};

export { FleetManagementProvider, useFleetManagement };
