import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { Box } from '@material-ui/core';
import theme from 'v3/theme';
import {
  formatCurrency,
  formatDateTime,
  formatPercentage,
} from 'v3/utils/formatter';
import { capitalize } from 'pages/relatorios/acompanhamento-viagem/MuiTable/utils';
import { formatDistanceToNow } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { FaClock, FaMapMarkedAlt } from 'react-icons/fa';
import { StyledCell } from './styles';
import { getCustomSortFunction } from './utils';

export function Grid({ vehicleData }) {
  function getValue(cell, capitalizeText = false) {
    if (capitalizeText) {
      return capitalize(cell.getValue()) ?? 'Não informado';
    }
    return cell.getValue() ?? 'Não informado';
  }
  const columns = useMemo(
    () => [
      {
        header: 'Última posição rastreador',
        accessorKey: 'lastRasterPosition',
        id: 'lastRasterPosition',
        Cell: ({ cell, row }) => {
          return (
            <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              <StyledCell
                title={`${cell.column.columnDef.header}:${' '} ${getValue(
                  cell,
                  true
                )}`}
              >
                <span
                  style={{ display: 'inline-block', marginRight: '0.25rem' }}
                >
                  <FaMapMarkedAlt color={theme.colors.gray} size={14} />
                </span>
                {getValue(cell, true)}
              </StyledCell>

              <StyledCell
                title={`${cell.column.columnDef.header}:${' '} ${getValue(
                  cell,
                  true
                )}`}
              >
                <span
                  style={{ display: 'inline-block', marginRight: '0.25rem' }}
                >
                  <FaClock color={theme.colors.gray} size={14} />
                </span>
                {formatDateTime(row.original.lastDateInfo)}
              </StyledCell>
            </span>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'UF atual',
        accessorKey: 'currentProvinceName',
        id: 'currentProvinceName',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {getValue(cell)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Data criação última viagem',
        accessorKey: 'lastTravelDate',
        id: 'lastTravelDate',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {formatDateTime(cell.getValue())}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Nível de serviço',
        accessorKey: 'serviceLevel',
        id: 'serviceLevel',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(
                cell,
                true
              )}`}
            >
              {getValue(cell, true)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Motorista',
        accessorKey: 'driveName',
        id: 'driveName',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(
                cell,
                true
              )}`}
            >
              {getValue(cell, true)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Placa tração',
        accessorKey: 'tractionPlate',
        id: 'tractionPlate',
        Cell: ({ cell, row }) => {
          return (
            <>
              <StyledCell
                title={`${cell.column.columnDef.header}:${' '} ${getValue(
                  cell
                )}`}
              >
                {getValue(cell)}
              </StyledCell>
            </>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Placas implementos última viagem',
        accessorKey: 'wagonslate',
        id: 'wagonslate',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {getValue(cell)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Tipo de veículo',
        accessorKey: 'tractionVehicleType',
        id: 'tractionVehicleType',
        Cell: ({ cell, row }) => {
          return (
            <>
              <StyledCell
                title={`${cell.column.columnDef.header}:${' '} ${getValue(
                  cell
                )}`}
              >
                {`Tração: ${getValue(cell)}`}
              </StyledCell>
              <StyledCell
                title={`${cell.column.columnDef.header}:${' '} ${row.original.vehicle_type ?? 'Não informado'
                  }`}
                style={{ display: 'block' }}
              >
                {`Implemento(s): ${row.original.vehicle_type ?? 'Não informado'
                  }`}
              </StyledCell>
            </>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Tipo carroceria',
        accessorKey: 'truck_body',
        id: 'truck_body',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {getValue(cell)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Transportador',
        accessorKey: 'shippingCompanyName',
        id: 'shippingCompanyName',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(
                cell,
                true
              )}`}
            >
              {getValue(cell, true)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Município entrega última viagem',
        accessorKey: 'lastCitieName',
        id: 'lastCitieName',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(
                cell,
                true
              )}`}
            >
              {getValue(cell, true)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Uf última viagem',
        accessorKey: 'lastTravelProvinceName',
        id: 'lastTravelProvinceName',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {getValue(cell)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Centro de custo última viagem',
        accessorKey: 'costCenterLasttravel',
        id: 'costCenterLasttravel',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(
                cell,
                true
              )}`}
            >
              {getValue(cell, true)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'PCP última viagem',
        accessorKey: 'pcpLastTravel',
        id: 'pcpLastTravel',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(
                cell,
                true
              )}`}
            >
              {getValue(cell, true)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Negociador última viagem',
        accessorKey: 'negociatorLastTravel',
        id: 'negociatorLastTravel',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(
                cell,
                true
              )}`}
            >
              {getValue(cell, true)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Status última viagem',
        accessorKey: 'statusLastTravel',
        id: 'statusLastTravel',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(
                cell,
                true
              )}`}
            >
              {getValue(cell, true)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Nº última carga',
        accessorKey: 'lastLoadId',
        id: 'lastLoadId',
        Cell: ({ cell, row }) => {
          return (
            <Link
              to={`/cargas/${cell.getValue()}`}
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {getValue(cell)}
            </Link>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Nº última viagem',
        accessorKey: 'lastTravelNumber',
        id: 'lastTravelNumber',
        Cell: ({ cell, row }) => {
          return cell.getValue() ? (
            <Link
              to={`/viagens/${cell.getValue()}`}
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {getValue(cell)}
            </Link>
          ) : (
            <StyledCell>{getValue(cell)}</StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Nº última viagem externa',
        accessorKey: 'lastExternalTravelNumber',
        id: 'lastExternalTravelNumber',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {getValue(cell)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Quantidade de viagens finalizadas',
        accessorKey: 'totalTravelFiltered',
        id: 'totalTravelFiltered',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {getValue(cell)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Último carregamento Coopercarga',
        accessorKey: 'lastTimeCoperCargaLoad',
        id: 'lastTimeCoperCargaLoad',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header
                }:${' '} ${formatDistanceToNow(
                  new Date(row.original.lastTravelDate),
                  {
                    addSuffix: true,
                    locale: ptBR,
                  }
                )}`}
            >
              {row.original.lastTravelDate
                ? formatDistanceToNow(new Date(row.original.lastTravelDate), {
                  addSuffix: true,
                  locale: ptBR,
                })
                : 'Não informado'}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Margem bruta veículo tração',
        accessorKey: 'margin',
        id: 'margin',
        Cell: ({ cell, row }) => {
          const value =
            cell.getValue() !== null && cell.getValue() !== undefined
              ? formatPercentage(getValue(cell) / 100)
              : 'Não informado';
          return (
            <StyledCell title={`${cell.column.columnDef.header}:${value}`}>
              {value}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Rentabilidade bruta veículo tração',
        accessorKey: 'profitability',
        id: 'profitability',
        Cell: ({ cell, row }) => {
          const value =
            cell.getValue() !== null && cell.getValue() !== undefined
              ? formatCurrency(getValue(cell))
              : formatCurrency(0.0);
          return (
            <StyledCell title={`${cell.column.columnDef.header}:${value}`}>
              {value}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
      {
        header: 'Empresa',
        accessorKey: 'companyName',
        id: 'companyName',
        Cell: ({ cell, row }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(
                cell,
                true
              )}`}
            >
              {getValue(cell, true)}
            </StyledCell>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          const customFunction = getCustomSortFunction(rowA, rowB, columnId);
          return customFunction(rowA, rowB);
        },
      },
    ],
    []
  );

  const [columnOrder, setColumnOrder] = useState(() => initializeColumnOrder());
  const [columnSizing, setColumnSizing] = useState(() =>
    initializeColumnSizing()
  );

  function insertNewColumns(columnsOrder, newColumns) {
    const newColumnsOrder = [...columnsOrder];
    newColumns.forEach(item => {
      newColumnsOrder.push(item);
    });
    return newColumnsOrder;
  }

  function initializeColumnOrder() {
    const userColumnOrderPreference = JSON.parse(
      localStorage.getItem('userColumnOrderPreference')
    );
    const columnsSlugs = columns.map(item => item.id);
    const newColumnsInGrid = [];
    columnsSlugs.forEach(item => {
      if (!userColumnOrderPreference?.includes(item)) {
        newColumnsInGrid.push(item);
      }
    });
    if (userColumnOrderPreference && newColumnsInGrid.length === 0) {
      return userColumnOrderPreference;
    }
    if (userColumnOrderPreference && newColumnsInGrid.length > 0) {
      const newOrder = insertNewColumns(
        userColumnOrderPreference,
        newColumnsInGrid
      );
      return newOrder;
    }
    return columns
      .map(c => c.accessorKey)
      .sort(function (a, b) {
        return a - b;
      });
  }

  function initializeColumnSizing() {
    const userColumnSizingPreference = JSON.parse(
      localStorage.getItem('userColumnSizingPreference')
    );
    if (userColumnSizingPreference) {
      return userColumnSizingPreference;
    }
    return {};
  }

  useEffect(() => {
    localStorage.setItem(
      'userColumnOrderPreference',
      JSON.stringify(columnOrder)
    );
  }, [columnOrder]);

  useEffect(() => {
    localStorage.setItem(
      'userColumnSizingPreference',
      JSON.stringify(columnSizing)
    );
  }, [columnSizing]);

  return (
    <MaterialReactTable
      columns={columns}
      data={vehicleData}
      enableColumnOrdering
      enableColumnDragging
      enableColumnResizing
      enableColumnActions={false}
      renderTopToolbar={({ table }) => <Box style={{ padding: '1rem 0' }} />}
      initialState={{
        density: 'compact',
      }}
      state={{ columnOrder, columnSizing }}
      onColumnOrderChange={setColumnOrder}
      onColumnSizingChange={setColumnSizing}
      defaultColumn={{
        maxSize: 400,
        minSize: 80,
        size: 150,
      }}
      enableStickyHeader
      muiTableBodyProps={{
        sx: {
          textAlign: 'center',
        },
      }}
      muiTablePaperProps={{
        sx: {
          boxShadow: 'none',
          zIndex: 0,
          position: 'relative',
        },
      }}
      muiTableContainerProps={{
        sx: {
          maxHeight: '500px',
          '&:hover::-webkit-scrollbar': {
            width: 10,
            height: 10,
          },
          '&:hover::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
          '&:hover::-webkit-scrollbar-thumb': {
            backgroundColor: theme.colors.medium_gray,
            borderRadius: 2,
          },
          '&:not(:hover)::-webkit-scrollbar': {
            opacity: 0,
            width: 10,
            height: 10,
          },
          '&:not(:hover)::-webkit-scrollbar-thumb': {
            opacity: 0,
            backgroundColor: 'transparent',
          },
        },
      }}
      muiTablePaginationProps={{
        labelRowsPerPage: 'Veículos por página',
        rowsPerPageOptions: [5, 10],
      }}
      localization={MRT_Localization_PT_BR}
      muiTableHeadCellDragHandleProps={{
        sx: {
          marginRight: '0.75rem',
        },
      }}
    />
  );
}
