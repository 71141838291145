/* eslint-disable no-shadow */
import React, { useState, useEffect, useContext, useRef } from 'react';
import api, { cooperplaceApi } from 'services/api';
import moment from 'moment';
import { validatePermission } from 'actions/index';
import { useParams } from 'react-router-dom';
import Tooltip from 'v3/components/Tooltip';
import { FaInfoCircle, FaMapMarked, FaPhoneAlt, FaPlus, FaTrash, FaTruck } from 'react-icons/fa';
import { Row, Col } from 'react-bootstrap';
import dealAPI from 'services/apis/deal';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import InputCurrency from 'v3/components/Input/Currency';
import Button from 'v3/components/Button';
import Select from 'v3/components/Select';
import TextArea from 'v3/components/TextArea';
import DatePicker from 'v3/components/DatePicker';
import { useSnackbar } from 'v3/components/Snackbar';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import {
  fetchProductAndCategorie,
  fetchCostCenter,
  fetchBranchOffices,
  fetchAllNegotiationsByPersonAndCostCenter,
  fetchCustomers,
} from 'utils/fetches';
import {
  currencyOptions,
  currencyWithSymbol,
  makeConversion,
  conversionParams,
  formatCurrency,
} from 'v3/utils/formatter';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import theme from 'v3/theme';
import { MinimumMarginModal } from 'v3/pages/Travel/shared';
import { useModal } from 'hooks';
import { extractGroupOptions } from 'v3/pages/Travel/Register/GRO/utils';
import { Link } from 'v3/components';
import { fetchCountries } from '../controller';
import * as Styled from './styled';
import {
  appendToMessage,
  buildRangeFieldsMessage,
  extractRequiredFields,
  validateDriverWithPolicy,
  validateVehicleWithPolicy,
} from '../utils';
import { updateTemperature } from './validator';

function Data({
  load,
  getLoad,
  loadingCard,
  onGoingTravelStatusId,
  editingLoadData,
  setEditingLoadData,
  setLoad,
  setLoadGroRequiredField,
  errors,
  setErrors,
  tracker,
  setTracker,
  rangeId,
  setRangeId,
  initialRangeId,
  setNewRange,
  groModal,
  setGroAttendedData,
  cargoValue,
  setCargoValue,
  updatedTravelData,
  setUpdatedTravelData,
  isDisabledGROAnalysis,
}) {
  const { isTravelBlocked, isMarginAbove } = useContext(BlockedTravelContext);
  const snackbar = useSnackbar();
  const params = useParams();
  const [countryList, setCountryList] = useState([]);
  const [hasDeal, setDeal] = useState([]);
  const [taker, setTaker] = useState();
  const [product, setProduct] = useState();
  const [branchOffice, setBranchOffice] = useState([]);
  const [dueDate, setDueDate] = useState();
  const [negotiator, setNegotiator] = useState();
  const [country, setCountry] = useState();
  const [phone, setPhone] = useState();
  const [externalInvoiceNumber, setInvoice] = useState();
  const [comments, setComments] = useState();
  const [tags, setTags] = useState([]);
  const [crt, setCrt] = useState('');
  const [crts, setCrts] = useState([]);
  const [clientOrderNumber, setClientOrderNumber] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  const [negotiation, setNegotiation] = useState({});
  const [ddi, setDdi] = useState('');
  const [hasPermissionToEditNegotiator, setEditNegotiator] = useState();
  const [tagRegisterPermitted] = useState(validatePermission('CRIAR_TAGS'));
  const [initialCargoValue, setInitialCargoValue] = useState(0);
  const [costCenter, setCostCenter] = useState();
  const [loadCurrency, setLoadCurrency] = useState();
  const [loadCurrencyName, setLoadCurrencyName] = useState('');
  const [loadCurrencySymbol, setLoadCurrencySymbol] = useState('');
  const [exchange, setExchange] = useState();
  const [conversion, setConversion] = useState({});
  const [negotiationOptions, setNegotiationOptions] = useState([]);
  const [loadingNegotiations, setLoadingNegotiations] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [validatingCargoValue, setValidatingCargoValue] = useState(false);
  const [debouncedCargo, setDebouncedCargo] = useState(0);
  const [cargoValueSignal, setCargoValueSignal] = useState(false);
  const cargoValueInputRef = useRef();
  const [maxTemperature, setMaxTemperature] = useState();
  const [minTemperature, setMinTemperature] = useState();
  const lowerMarginModal = useModal();
  async function fetchAllNegotiations(takerId) {
    try {
      setLoadingNegotiations(true);

      const externalId = costCenter?.external_id ? costCenter?.external_id : costCenter.externalId;

      const response = await fetchAllNegotiationsByPersonAndCostCenter({
        personId: takerId,
        costCenterExternalId: externalId,
      });
      setNegotiationOptions(response);
    } catch (error) {
      setNegotiationOptions([]);
    } finally {
      setLoadingNegotiations(false);
    }
  }

  async function updateClientNegotiations(cpfCnpj, takerId) {
    try {
      setLoadingClient(true);
      await importFromBenner(cpfCnpj);
      if (costCenter) {
        await fetchAllNegotiations(takerId);
      }
    } catch (error) {
      //
    } finally {
      setLoadingClient(false);
    }
  }

  useEffect(() => {

    if (taker && costCenter) {
      fetchAllNegotiations(taker?.id);
    }

  }, [costCenter, negotiation]);

  useState(() => {
    setPermissionToEdit(validatePermission('EDITAR_CARGAS'));
    setEditNegotiator(validatePermission('EDITAR_NEGOCIADORES'));
    fetchCountries(setCountryList);
  }, []);

  useEffect(() => {
    setCountry(countryList.find(element => element.abbreviation === 'br'));
  }, [countryList]);

  useEffect(() => {
    if (ddi) {
      setCountry(countryList.find(country => country.ddi === ddi));
    }
  }, [ddi, countryList]);

  useEffect(() => {
    async function getDeals() {
      try {
        const response = await dealAPI.get(`deals`, {
          params: {
            load_id: params.id || null,
          },
        });
        setDeal(response.data.data);
      } catch (error) {
        // Suppress exception
      }
    }
    getDeals();
  }, []);

  useEffect(() => {
    async function getLoadTags() {
      try {
        const response = await api.get(`load-tags/${params.id}`);

        const tags = response.data.map(tag => {
          return {
            id: tag.name.id,
            name: tag.name.name,
          };
        });
        setTags(tags);
      } catch (error) {
        //
      }
    }

    async function setLoadInitialData() {
      setLoading(true);
      try {
        // Bloquear botão quando a carga for emitida
        if (load.emitteds?.length > 0) {
          snackbar.show(
            <Text className="text-center">
              Essa carga não pode ser editada porque já foi emitida!
            </Text>,
            {
              type: 'error',
              duration: 5000,
            }
          );
          return;
        }

        setTaker(load.taker);

        if (load.product) {
          let productLabel = load.product?.name;
          if (load.product?.ncm) {
            productLabel = `${load?.product?.name} - ${load?.product?.ncm}`;
          }
          if (load.productType) {
            productLabel = `${load?.product?.name} - ${load?.productType?.name}`;
          }

          setProduct({
            name: productLabel,
            value: load?.product?.id,
            id: load?.product?.id,
            productTypeId: load?.product?.productTypeId,
          });
        }

        setBranchOffice(load.branchOffice);
        setInitialCargoValue(load.cargoValue);
        setDueDate(moment(load.dueDate).format('DD/MM/YYYY HH:mm'));
        setNegotiator(load.negotiators);
        setMinTemperature(load.temperatureMin);
        setMaxTemperature(load.temperature);
        setComments(load.comments);
        setInvoice(load.externalInvoiceNumber);
        setCostCenter(load?.costCenter);
        const aux = currencyOptions.find(
          item => item.value === load?.loadCurrency
        );
        setLoadCurrency(aux);
        setLoadCurrencyName(aux?.label);
        setLoadCurrencySymbol(
          currencyWithSymbol.find(item => item.currency === load?.loadCurrency)
            ?.symbol
        );

        setExchange(load?.exchange);
        setNegotiation(load?.negotiation);
        setCrt(load?.crt);
        setCrts(load?.crts || []);
        setClientOrderNumber(load?.clientOrderNumber);
      } catch (error) {
        snackbar.show(
          <Text>
            Algo deu errado ao carregar a carga, tente novamente.[Data]
          </Text>,
          { type: 'error' }
        );
      } finally {
        setLoading(false);
      }
    }

    if (load && editingLoadData) {
      setLoadInitialData();
      getLoadTags();
    }
  }, [
    load?.id,
    load?.emitteds,
    load?.taker,
    load?.product,
    load?.branchOffice,
    load?.cargoValue,
    load?.temperature,
    load?.dueDate,
    load?.negotiators,
    load?.comments,
    load?.externalInvoiceNumber,
    load?.costCenter,
    load?.loadCurrency,
    load?.exchange,
    load?.negotiation,
    load?.userCreated,
    load?.riskManagers,
    load?.crt,
    load?.clientOrderNumber,
    editingLoadData,
  ]);

  useEffect(() => {
    if (load) {
      const PHONE_MATCHER = /^\+?(\d{2,3})/;
      if (load.contactInfo && load.contactInfo.match(PHONE_MATCHER)) {
        const [, ddi, phone] = load.contactInfo.split(PHONE_MATCHER);
        setDdi(ddi);
        setPhone(phone.trim());
      } else {
        setPhone(load.contactInfo);
      }
      const aux = currencyOptions.find(
        item => item.value === load?.loadCurrency
      );
      setLoadCurrency(aux);
      setLoadCurrencyName(aux?.label);
      setLoadCurrencySymbol(
        currencyWithSymbol.find(item => item.currency === load?.loadCurrency)
          ?.symbol
      );
    }
  }, [load?.contactInfo, load?.loadCurrency]);

  async function importFromBenner(document) {
    try {
      const url = `negotioation/importFromBenner?cpfCnpj=${document}`;
      await cooperplaceApi.get(url);
    } catch (error) {}
  }

  async function handleTakerChange(value) {
    let isTakerValid = false;

    if (!load?.loadPolicy && value?.customerPolicy?.length) {
      snackbar.show(
        <Text>
          CNPJ {value.cgccpf} selecionado possui apólice ativa, favor cadastrar
          uma nova carga
        </Text>,
        {
          type: 'error',
        }
      );
      return;
    }

    setTaker(value);
    setNegotiation({});
    setNegotiationOptions([]);
    if (value) {
      isTakerValid = await validateClientBlacklist(value?.cgccpf);
    }
    if (isTakerValid) {
      await updateClientNegotiations(value?.cgccpf, value?.id);
    }
  }

  async function validateClientBlacklist(value) {
    try {
      const response = await api.get(`v3/blacklist-person/?value=${value}`);
      if (response.data.total > 0) {
        snackbar.show(
          <>
            <Text>
              Não é possivel selecionar o Tomador{' '}
              {response.data.data[0].description} - {value} pois ele está
              banido!{' '}
            </Text>
            <Text>Motivo: {response.data.data[0].reason}</Text>
          </>,
          {
            type: 'error',
          }
        );
        setTaker(null);
      }
      return true;
    } catch (error) {
      snackbar.show(
        <Text>
          Error ao validar o Tomador de CNPJ: {value} na lista de banidos!
        </Text>,
        {
          type: 'error',
        }
      );
      setTaker(null);
      return false;
    }
  }

  useEffect(() => {
    setLoadCurrencySymbol(
      currencyWithSymbol.find(item => item.currency === loadCurrency?.value)
        ?.symbol
    );
  }, [loadCurrency]);

  useEffect(() => {
    setConversion(conversionParams[loadCurrencySymbol]);
  }, [loadCurrencySymbol]);

  async function validateTemperature() {
    try {
      const temperatureData = {
        maxTemperature,
        minTemperature,
      };
      await updateTemperature.validate(temperatureData, { abortEarly: false });
      return {
        valid: true,
        messages: {},
      };
    } catch (error) {
      const errorResponse = {};

      if (error.inner) {
        error.inner.forEach(error => {
          errorResponse[error.path] = error.message;
        });
      }
      return {
        valid: false,
        messages: {
          ...errorResponse,
        },
      };
    }
  }

  async function validate() {
    const errors = { hasErrors: false };
    try {
      if (!taker) {
        errors.taker = 'Informe o tomador';
        errors.hasErrors = true;
      }
      if (!product) {
        errors.product = 'Informe o produto';
        errors.hasErrors = true;
      }
      if (!dueDate) {
        errors.dueDate = 'Informe a data';
        errors.hasErrors = true;
      }

      if (dueDate && !moment(dueDate, 'DD/MM/YYYY HH:mm', true).isValid()) {
        errors.dueDate = 'Data de vencimento inválida';
        errors.hasErrors = true;
      }

      if (!negotiator) {
        errors.negotiator = 'Informe o negociador';
        errors.hasErrors = true;
      }

      if (!country) {
        errors.country = 'Informe o país';
        errors.hasErrors = true;
      }

      if (!phone) {
        errors.phone = 'Informe o número';
        errors.hasErrors = true;
      }

      if (!costCenter) {
        errors.costCenter = 'Informe o centro de custo';
        errors.hasErrors = true;
      }

      if (crts.length) {
        let invalidCrt = false
        for (const crt of crts) {
          if (!crt.customer) {
            crt.error_customer = 'Informe o tomador'
            invalidCrt = true
          }
          
          if (!crt.number) {
            crt.error_number = 'Informe o número'
            invalidCrt = true
          }
        }

        if (invalidCrt) {
          errors.crts = 'CRT adicionais inválidos'
          errors.hasErrors = true
        }

      }

      if (!negotiation || Object.keys(negotiation).length === 0) {
        errors.negotiation = 'Informe a negociação';
        errors.hasErrors = true;
      }
      if (crt?.length > 50) {
        errors.crt = 'Valor máximo de 50 caracteres';
        errors.hasErrors = true;
      }
      if (clientOrderNumber?.length > 25) {
        errors.clientOrderNumber = 'Valor máximo de 25 caracteres';
        errors.hasErrors = true;
      }

      const { valid, messages } = await validateTemperature();
      if (!valid) {
        errors.maxTemperature = messages.maxTemperature;
        errors.minTemperature = messages.minTemperature;
        errors.hasErrors = true;
      }
    } catch (error) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
    }

    return errors;
  }

  async function fetchTaker(search) {
    try {
      const response = await api.get('persons/customers', {
        params: { search, policy_id: load?.loadPolicy?.policyId },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  async function fetchNegotiator(negotiator) {
    try {
      const response = await api.get('user/name?', {
        params: { negotiator },
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async function fetchTags(search) {
    try {
      const response = await api.get('tags', { params: { search } });
      return response.data.data;
    } catch (ex) {
      return [];
    }
  }

  async function handleSubmit(submitOptions) {
    const err = await validate();
    setErrors(err);

    if (err.hasErrors) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      return;
    }

    try {
      setLoading(true);
      if (!submitOptions?.confirmMinimiumMargin && load?.attended?.id) {
        const isMarginERPAbove = await isMarginAbove({
          costCenterId: costCenter.external_id,
          clientId: taker.benner_id,
        });

        if (isMarginERPAbove) {
          lowerMarginModal.open();
          return;
        }
      }

      const negotiators_ids = negotiator ? negotiator.map(item => item.id) : [];

      dealAPI
        .post(`deals/changeNegotiator`, {
          negotiators_ids,
          load_id: params.id,
        })
        .catch(() => {});

      await cooperplaceApi.put(`loads/${load?.id}`, {
        ...load,
        extraFields: load?.extraFields?.map(extra => {
          return {
            ...extra,
            type: extra?.type ? 1 : 0,
          };
        }),
        loadOrigins: load?.loadOrigins[0],
        dispositionId: load?.disposition?.id,
        vehicleTypeIds: load?.vehicleTypes?.map(vh => vh?.id),
        vehicleBodyTypeIds: load?.vehicleBodyTypes?.map(vh => vh?.id),
        vehicleImplementTypeIds:
          load?.vehicleImplementTypes?.length > 0
            ? load?.vehicleImplementTypes?.map(imp => imp?.id)
            : null,
        mopp: load?.mopp ? 1 : 0,
        adValoremIncluded: load?.adValoremIncluded ? 1 : 0,
        personClientId: taker?.id,
        productId: product?.id,
        productTypeId: product?.productTypeId,
        branchOfficeId: branchOffice?.id,
        costCenterId: costCenter?.id,
        takerValue: +load?.takerValue,
        negotiationId: negotiation?.id,
        negotiatorId: negotiator[0]?.id,
        negotiatorIds: negotiator?.map(negotiator => negotiator?.id),
        trackerId: tracker?.id,
        crawledLoad: load?.crawledLoad,
        temperature: maxTemperature,
        temperatureMin: minTemperature,
        cargoValue: +cargoValue || load?.cargoValue,
        loadCurrency: loadCurrency?.value,
        dueDate: dueDate ? moment(dueDate, 'DD/MM/YYYY HH:mm') : null,
        contactInfo: `+${country.ddi} ${phone}`,
        comments,
        tagIds: tags?.map(tg => tg?.id),
        externalInvoiceNumber,
        crt,
        crts: crts.map(crt => ({
          number: crt.number,
          personId: crt.customer.id,
        })),
        clientOrderNumber,
        policyId: load?.loadPolicy?.policyId,
        policyRangeId: updatedTravelData?.shouldUpdatePolicyData
          ? updatedTravelData?.policyRangeId
          : rangeId !== load?.loadPolicy?.policyRangeId
            ? rangeId
            : load?.loadPolicy?.policyRangeId,
        policyOperationId: load?.loadPolicy?.policyOperationId,
        policyGroupEquipamentId: updatedTravelData?.shouldUpdatePolicyData
          ? updatedTravelData?.policyGroupEquipamentId
          : load?.loadPolicy?.policyGroupEquipamentId,

        travel: updatedTravelData?.shouldUpdatePolicyData
          ? {
            checklistType: updatedTravelData?.checklistType,
            monitoringEquipmentGroupId:
              updatedTravelData?.shouldUpdatePolicyData
                ? updatedTravelData?.policyGroupEquipamentId
                : load?.loadPolicy?.policyGroupEquipamentId,
            driverId: updatedTravelData?.driverId,
            vehicleId: updatedTravelData?.vehicleId,
            wagonIds: updatedTravelData?.wagons?.map(wg => wg.id),
            riskManagerId: updatedTravelData?.riskManagerId,
            riskManagerSearchId: updatedTravelData?.riskManagerSearchId,
            riskManagerLogisticMirroringId:
              updatedTravelData?.riskManagerLogisticMirroringId,
            riskManagerLogisticSmId:
              updatedTravelData?.riskManagerLogisticSmId,
            riskCodeLogisticSM: updatedTravelData?.riskCodeLogisticSM,
            trackerId: updatedTravelData?.trackerId,
            trackerTypeId: updatedTravelData?.trackerTypeId,
            trackerLocatorId: updatedTravelData?.trackerLocatorId,
            trackerImmobilizerId: updatedTravelData?.trackerImmobilizerId,
            securityProfileId: updatedTravelData?.securityProfileId,
            trackerNumber: updatedTravelData?.trackerNumber,
            locatorNumber: updatedTravelData?.locatorNumber,
            immobilizerNumber: updatedTravelData?.immobilizerNumber,
            baits: updatedTravelData?.baits?.map(bait => {
              return {
                id: bait?.id,
                number: '',
              };
            }),
          }
          : load?.travel
            ? {
              ...load?.travel,
              baits: load?.travel?.baits
                ? load?.travel?.baits?.map(bait => {
                  return {
                    id: bait?.trackerId || bait?.id,
                    number: bait?.number,
                  };
                })
                : [],
            }
            : null,
      });
      setLoadGroRequiredField({
        riskManager: false,
      });
      setUpdatedTravelData(null);
      getLoad();
      setEditingLoadData(false);
    } catch (error) {
      const message = error?.response?.data?.message || 'Erro ao salvar';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }
  const [, setIsValidNewOption] = useState(false);
  const [inputTag, setInputTag] = useState('');

  function tagTreatment(input) {
    if (input) {
      const inputFiltered = input.trimStart().replace(/[^a-zA-Z0-9 ]/g, '');
      setInputTag(inputFiltered);
      setIsValidNewOption(inputFiltered.trim().length >= 3);
    } else {
      setInputTag('');
      setIsValidNewOption(false);
    }
  }
  async function handleTagCreate(value) {
    let tagValue = value;

    if (tagRegisterPermitted) {
      if (tagValue) {
        tagValue = tagValue.trim();
        if (tagValue.length >= 3) {
          try {
            const response = await api.post('tags', { name: tagValue });
            if (response.status === 200) {
              const newTag = {
                id: response.data.id,
                name: tagValue,
              };
              setInputTag('');
              setTags(tags ? [...tags, newTag] : [newTag]);
            }
          } catch (err) {
            // Handle exception
          }
        }
      }
    } else {
      snackbar.show(<Text>Usuário sem permissão para criar tags</Text>, {
        type: 'error',
      });
    }
  }

  function handleDueDate(value) {
    const created_at = moment(load.createdAt);
    const now = moment();
    if (now.diff(created_at, 'hour') >= 24) {
      snackbar.show(
        <Text className="text-center">
          Não é possível alterar o vencimento desta carga após 24h de criação.
        </Text>,
        {
          type: 'error',
          duration: 5000,
        }
      );
      return;
    }

    setDueDate(value);
  }

  function getProductLabel(option) {
    if (option.product_type_name) {
      return `${option.name} - ${option.product_type_name}`;
    }

    if (option.ncm) {
      return `${option.name} - ${option.ncm}`;
    }

    return option.name;
  }

  function getProductInfo(load) {
    if (load?.product?.ncm && load?.productType) {
      return `NCM ${load?.product?.ncm} - ${load?.product?.name} - ${load.productType?.name}`;
    }

    if (load?.product?.ncm) {
      return `NCM ${load?.product?.ncm} -${load?.product?.name}`;
    }

    return load?.product?.name;
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      cargoValueInputRef.current = '';
      if (cargoValueSignal) {
        setDebouncedCargo(cargoValue);
      }
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [cargoValue, cargoValueSignal]);

  async function handleChangeCargoValue() {
    try {
      const response = await cooperplaceApi.get(
        `policies/${load?.loadPolicy?.policyId}/ranges`,
        {
          params: {
            value: cargoValue,
            policyProductId: load?.loadPolicy?.policyProductId,
            vehicleTypeIds: load?.vehicleTypes?.map(vt => vt.id),
            vehicleBodyTypeIds: load?.vehicleBodyTypes?.map(vb => vb.id),
            vehicleImplementIds: load?.vehicleImplementTypes?.map(vi => vi.id),
          },
        }
      );
      if (response?.data?.length === 0) {
        snackbar.show(
          <Text>
            Nenhuma faixa de valor cadastrada na apólice inclui o valor
            informado para mercadoria! Valor original será aplicado!
          </Text>,
          { type: 'warning' }
        );
        setCargoValue(load?.cargoValue);
        setCargoValueSignal(false);
        setValidatingCargoValue(false);
        return;
      }

      setRangeId(response.data[0]?.id);
      setNewRange(response?.data[0]);
      const isTracked = response.data[0].tracked;
      const isEscorted = response.data[0].equipments_groups?.every(
        item => item.escorted
      );
      setLoad(old => ({
        ...old,
        crawledLoad: isTracked,
        isEscorted,
      }));

      // mudou o range e a carga já está atendida
      if (rangeId !== response.data[0]?.id && load?.travel) {
        const newRangeRequiredData = extractRequiredFields(response?.data[0]);

        const isDriverValid = validateDriverWithPolicy(
          load?.travel?.driver?.driver?.travelsQuantity,
          response.data[0]?.driver_travel_min
        );
        const isMainVehicleValid = validateVehicleWithPolicy(
          load?.travel?.vehicle?.[0]?.vehicles?.yearManufacture,
          response.data[0]?.age_traction_max,
          load?.travel?.vehicle?.[0]?.vehicles?.rntrcValidated
        );
        const travelWagonsValidity = [];
        if (load?.travel?.vehicle?.[0]?.wagons)
          load.travel.vehicle[0].wagons.forEach(wagon => {
            const isValid = validateVehicleWithPolicy(
              wagon?.yearManufacture,
              response.data[0]?.age_implement_max,
              wagon?.rntrcValidated
            );

            travelWagonsValidity.push(isValid);
          });

        let message = buildRangeFieldsMessage(newRangeRequiredData);

        message = appendToMessage(!isDriverValid, 'Motorista', message);
        message = appendToMessage(!isMainVehicleValid, 'Veículo', message);
        message = appendToMessage(
          travelWagonsValidity?.some(item => !item),
          'Implemento (s)',
          message
        );
        message = appendToMessage(
          response.data[0]?.equipments_groups?.length > 0,
          'Grupo de monitoramento',
          message
        );

        if (!message) {
          setLoadGroRequiredField({
            riskManager: false,
          });
          setTracker(null);
          setUpdatedTravelData({
            policyRangeId: response.data[0]?.id,
            shouldUpdatePolicyData: true,
            riskManagerId: null,
            riskManagerSearchId: null,
            riskManagerLogisticMirroringId: null,
            riskManagerLogisticSmId: null,
            securityProfileId: null,
            monitoringEquipmentGroupId: null,
            trackerId: null,
            trackerTypeId: null,
            trackerLocatorId: null,
            trackerImmobilizerId: null,
            checklistType: response.data[0]?.checklist_tracker,
            baits: null,
            trackerNumber: null,
            riskCodeLogisticSM: null,
            locatorNumber: null,
            immobilizerNumber: null,
            driverId: load?.travel?.driver?.id,
            vehicleId: load?.travel?.vehicle?.[0]?.vehicles?.id,
            wagons:
              load?.travel?.vehicle?.[0]?.wagons?.length > 0
                ? [...load?.travel?.vehicle?.[0]?.wagons]
                : [],
          });
          setValidatingCargoValue(false);

          return;
        }
        const options = {
          security_profile: extractGroupOptions(
            response.data[0]?.groups,
            'security_profile'
          ),
          manager_tracker: extractGroupOptions(
            response.data[0]?.groups,
            'manager_tracker'
          ),
          manager_query: extractGroupOptions(
            response.data[0]?.groups,
            'manager_query'
          ),
          manager_mirror: extractGroupOptions(
            response.data[0]?.groups,
            'manager_mirror'
          ),
          logistic_sm: extractGroupOptions(
            response.data[0]?.groups,
            'logistic_sm'
          ),
        };
        setLoadGroRequiredField({
          riskManager: false,
        });
        setGroAttendedData(old => ({
          ...old,
          message,
          sameDriver: isDriverValid,
          driver: isDriverValid ? load?.travel?.driver : null,
          sameVehicle: isMainVehicleValid,
          vehicle: isMainVehicleValid
            ? load?.travel?.vehicle?.[0]?.vehicles
            : null,
          wagons:
            travelWagonsValidity?.length > 0
              ? travelWagonsValidity.map((wagonStatus, idx) => {
                return {
                  status: wagonStatus,
                  wagon: !wagonStatus
                    ? null
                    : load?.travel?.vehicle?.[0]?.wagons[idx],
                };
              })
              : [],
          securityProfile: {
            required: newRangeRequiredData.security_profile,
            options: options.security_profile,
            value: options.security_profile
              .map(item => item.id)
              .includes(load?.travel?.securityProfileId?.id)
              ? {
                id: load?.travel?.securityProfileId?.id,
                name: load?.travel?.securityProfileId?.name,
              }
              : null,
          },
          riskManagerSearch: {
            required: newRangeRequiredData.manager_query,
            options: options.manager_query,
            value: options.manager_query
              .map(item => item.id)
              .includes(load?.travel?.riskManagerSearch?.id)
              ? {
                id: load?.travel?.riskManagerSearch?.id,
                name: load?.travel?.riskManagerSearch?.name,
              }
              : null,
          },
          riskManagerTracker: {
            required: newRangeRequiredData.manager_tracker,
            options: options.manager_tracker,
            value: options.manager_tracker
              .map(item => item.id)
              .includes(load?.travel?.riskManager?.id)
              ? {
                id: load?.travel?.riskManager?.id,
                name: load?.travel?.riskManager?.name,
              }
              : null,
          },
          riskManagerMirror: {
            required: newRangeRequiredData.manager_mirror,
            options: options.manager_mirror,
            value: options.manager_mirror
              .map(item => item.id)
              .includes(load?.travel?.riskManagerLogisticMirroring?.id)
              ? {
                id: load?.travel?.riskManagerLogisticMirroring?.id,
                name: load?.travel?.riskManagerLogisticMirroring?.name,
              }
              : null,
          },
          riskManagerLogistic: {
            required: newRangeRequiredData.logistic_sm,
            options: options.logistic_sm,
            value: options.logistic_sm
              .map(item => item.id)
              .includes(load?.travel?.riskManagerLogisticSmId?.id)
              ? {
                id: load?.travel?.riskManagerLogisticSmId?.id,
                name: load?.travel?.riskManagerLogisticSmId?.name,
              }
              : null,
          },
          equipmentsGroupsOptions: response.data[0]?.equipments_groups,
          selectedMonitoringGroup: {
            required: response.data[0]?.equipments_groups?.length > 0,
            value: null,
          },
          checklistType: response.data[0]?.checklist_tracker,
        }));

        setValidatingCargoValue(false);
        groModal.open();

        return;
      }

      setValidatingCargoValue(false);
      return;
    } catch (error) {
      snackbar.show(<Text>Erro ao validar valor da mercadoria!</Text>, {
        type: 'error',
      });
      setCargoValue(load?.cargoValue);
      setCargoValueSignal(false);
      setValidatingCargoValue(false);
      setRangeId(initialRangeId);
    }
  }

  useEffect(() => {
    if (Number(debouncedCargo) > 0 && load?.loadPolicy?.id) {
      setValidatingCargoValue(true);
      handleChangeCargoValue();
    } else if (Number(debouncedCargo) < 0 && load?.loadPolicy?.id) {
      setCargoValue(null);
    } else {
      setValidatingCargoValue(false);
    }
  }, [debouncedCargo]);

  function handleConfirmLowerMargin(value) {
    handleSubmit(value);
  }

  function changeCrtNumber(value, index) {
    const crt = crts[index]
    if (!crt) {
      return
    }

    crt.number = value
    setCrts([...crts])
  }

  function changeCrtCustomer(customer, index) {
    console.log(customer)
    const crt = crts[index]
    if (!crt) {
      return
    }

    const customerIsLoadTaker = customer && taker && customer.id === taker.id
    const hasCustomer = customer && crts.find(document => document?.customer?.id && document.customer.id == customer.id) 

    if (customerIsLoadTaker || hasCustomer) {
      snackbar.show(
        <Text>CRT para esse tomador já foi adicionado</Text>,
        {
          type: 'error',
        }
      )
      return
    }

    crt.customer = customer
    setCrts([...crts])
  }

  function addNewCrt() {
    crts.push({
      number: '',
      customer: null,
      error: ''
    })

    setCrts([...crts])
  }

  function removeCrt(index) {
    crts.splice(index, 1)
    setCrts([...crts])
  }

  return (
    <>
      <Card
        loading={loadingCard}
        LoadingBodyComponent={() => (
          <DefaultLoadingBodyComponent linesCount={7} perLine={2} />
        )}
        header={
          <Text color="#464E5F" type="header">
            Geral
          </Text>
        }
        HeaderRightComponent={
          hasPermissionToEdit && (
            <div>
              {editingLoadData && (
                <Button
                  onClick={async () => {
                    setEditingLoadData(false);
                    setCargoValue(null);
                    await getLoad();
                  }}
                  variant="secondary"
                  loading={loading}
                  className="mr-2"
                  id="button-cancelar-card-geral"
                >
                  <Text color="dark" type="regular">
                    Cancelar
                  </Text>
                </Button>
              )}
              <span className="d-inline-block">
                <Button
                  id={`button-${
                    editingLoadData ? 'salvar' : 'editar'
                    }-card-geral`}
                  onClick={
                    editingLoadData
                      ? () => handleSubmit()
                      : () => setEditingLoadData(true)
                  }
                  variant={editingLoadData ? 'primary' : 'secondary'}
                  disabled={
                    load?.emitteds?.length > 0 ||
                    onGoingTravelStatusId(load?.travel?.statusId) ||
                    isTravelBlocked ||
                    validatingCargoValue ||
                    cargoValueInputRef.current?.length > 0 ||
                    cargoValueInputRef.current === document.activeElement
                  }
                  loading={loading}
                  title={
                    load?.emitteds?.length > 0
                      ? 'Não é possível editar cargas que já foram emitidas'
                      : ''
                  }
                >
                  <Text
                    color={editingLoadData ? 'white' : 'dark'}
                    type="regular"
                  >
                    {editingLoadData ? 'Salvar' : 'Editar'}
                  </Text>
                </Button>
              </span>
            </div>
          )
        }
      >
        {editingLoadData ? (
          <Row className="form">
            <Col xs={12}>
              <Select.Async
                onSearch={fetchTaker}
                value={taker}
                label="Cliente Tomador *"
                onChange={value => handleTakerChange(value)}
                getOptionLabel={option => {
                  let label =
                    option?.socialName && option?.cpfCnpj
                      ? `${option.socialName} - ${option.cpfCnpj}`
                      : `${option.social_name} - ${option.cgccpf}`;

                  if (option.city) {
                    label += ` - ${option.city}`;
                  }

                  return label;
                }}
                getOptionValue={option => option.id}
                error={errors.taker}
                id="select-tomador"
              />
            </Col>

            {load?.loadPolicy?.id ? (
              <Col md={12} className="mb-3">
                <Select.Async
                  label="Operação"
                  isDisabled
                  value={load?.loadPolicy?.operation}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                  id="select-operacao"
                />
              </Col>
            ) : null}

            <Col md={12} className="mb-3">
              <Select.Async
                label="Centro de custo *"
                onSearch={search => fetchCostCenter({ search })}
                value={costCenter}
                onChange={value => {
                  setCostCenter(value);
                  setNegotiation({});
                  setNegotiationOptions([]);
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                error={errors?.costCenter}
                id="select-centro-custo"
              />
            </Col>

            <Col xs={12} className="mb-3">
              <Select
                label={
                  <div className="d-flex align-items-center">
                    <FaInfoCircle
                      title="Negociação depende do tomador e centro de custo "
                      className="mr-2"
                      size={14}
                    />
                    Negociação *
                  </div>
                }
                options={negotiationOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={negotiation}
                onChange={value => setNegotiation(value)}
                isDisabled={!costCenter || !taker}
                error={errors?.negotiation}
                noOptionsMessageText="Nenhuma negociação disponível"
                loading={
                  (loadingClient && taker && costCenter) ||
                  (loadingNegotiations && taker && costCenter)
                }
                id="select-negociacao"
              />
            </Col>

            <Col xs={12}>
              <Select.Async
                onSearch={fetchProductAndCategorie}
                value={product}
                label="Produto *"
                onChange={value => setProduct(value)}
                getOptionLabel={option => getProductLabel(option)}
                getOptionValue={option => option.id}
                error={errors.product}
                isDisabled={load?.loadPolicy?.id}
                id="select-produto"
              />
            </Col>
            <Col xs={6} className="mb-3">
              <Input
                label="Temperatura mínima da carga"
                type="number"
                value={minTemperature}
                id="input-temperature-min"
                onChange={({ target }) => {
                  target.value.length > 6
                    ? snackbar.show(
                      <Text>Limite de dígitos para temperatura</Text>,
                      {
                        type: 'error',
                      }
                    )
                    : setMinTemperature(
                      target.value ? parseInt(target.value) : null
                    );
                }}
                error={errors.minTemperature}
              />
            </Col>
            <Col xs={6} className="mb-3">
              <Input
                label="Temperatura máxima da carga"
                type="number"
                value={maxTemperature}
                id="input-temperature-max"
                onChange={({ target }) => {
                  target.value.length > 6
                    ? snackbar.show(
                      <Text>Limite de dígitos para temperatura</Text>,
                      {
                        type: 'error',
                      }
                    )
                    : setMaxTemperature(
                      target.value ? parseInt(target.value) : null
                    );
                }}
                error={errors.maxTemperature}
              />
            </Col>
            <Col xs={12} className="mb-3">
              <Input
                label="Empresa"
                disabled
                value={load?.company?.name}
                id="input-empresa"
              />
            </Col>
            <Col xs={12}>
              <Select.Async
                onSearch={fetchBranchOffices}
                value={branchOffice}
                label="Filial"
                onChange={value => setBranchOffice(value)}
                getOptionLabel={option => `${option.id} - ${option.name}`}
                getOptionValue={option => option?.id}
                error={errors.branchOffice}
                id="select-filial"
              />
            </Col>
            <Col xs={6} className="mb-3">
              <Select
                label="Moeda valor da carga"
                options={currencyOptions}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                value={loadCurrency}
                onChange={value => {
                  setLoadCurrency(value);
                }}
                isDisabled={load?.loadPolicy?.id}
                id="select-moeda-carga"
              />
            </Col>
            <Col md={6} xs={12}>
              <InputCurrency
                ref={cargoValueInputRef}
                label={`Valor da carga (${loadCurrencySymbol || 'R$'})`}
                value={cargoValue || initialCargoValue}
                onChange={(_event, value) => {
                  cargoValueInputRef.current = value;
                  setInitialCargoValue(null);
                  setCargoValueSignal(true);
                  setCargoValue(value);
                }}
                error={errors.cargoValue}
                loading={validatingCargoValue}
                disabled={isDisabledGROAnalysis}
                tooltip={
                  isDisabledGROAnalysis &&
                  'Não é possível edição durante período de análise GRO'
                }
                id="input-valor-carga"
              />
              {(cargoValue || initialCargoValue) && conversion ? (
                <Text type="label" color="black" weight="bold" className="mt-2">
                  Convertido:{' '}
                  {formatCurrency(
                    makeConversion(
                      cargoValue || initialCargoValue,
                      conversion.operation,
                      exchange
                    ),
                    conversion.currencyName,
                    conversion.symbol
                  )}
                </Text>
              ) : null}
            </Col>
            <Col xs={12}>
              <div className="mb-3">
                <Tooltip
                  content={
                    <Text type="label">
                      Não é possível alterar o vencimento após 24hrs da criação
                      da carga.
                    </Text>
                  }
                >
                  <Styled.QuestionIcon style={{ zIndex: 0 }} />
                </Tooltip>
              </div>
              <div>
                <DatePicker
                  label="Vencimento da carga *"
                  value={dueDate}
                  onChange={e => handleDueDate(e.target.value)}
                  error={errors.dueDate}
                  disabled={moment().diff(load?.created_at, 'hour') >= 24}
                  withTime
                  id="input-data-vencimento"
                />
              </div>
            </Col>

            <Col xs={12}>
              <div className="mb-3">
                {hasPermissionToEditNegotiator ? (
                  hasDeal.length > 0 ? (
                    <Tooltip
                      content={
                        <Text type="label">
                          Edição de negociadores bloqueada porque essa carga já
                          está em negociação
                        </Text>
                      }
                    >
                      <Styled.QuestionIcon />
                    </Tooltip>
                  ) : null
                ) : (
                  <Tooltip
                    content={
                      <Text type="label">
                        Edição de negociadores bloqueada porque seu usuário não
                        tem permissão
                      </Text>
                    }
                  >
                    <Styled.QuestionIcon />
                  </Tooltip>
                )}
              </div>
              <div>
                <Select.Async
                  isDisabled={
                    !hasPermissionToEditNegotiator || hasDeal.length > 0
                  }
                  onSearch={fetchNegotiator}
                  modalHeading="Adicione um negociador"
                  modalBodyTitle="Negociador:"
                  label="Negociador da carga *"
                  value={negotiator}
                  onChange={value => setNegotiator(value)}
                  getOptionLabel={option => option.username}
                  getOptionValue={option => option.id}
                  error={errors.negotiator}
                  multiple
                  horizontal
                  id="select-negociador"
                />
              </div>
            </Col>
            <Col md={6} xs={12}>
              <Select
                options={countryList}
                label="País"
                value={country}
                onChange={value => setCountry(value)}
                getOptionLabel={option => `+${option.ddi} ${option.name}`}
                getOptionValue={option => option.id}
                error={errors.country}
                isDisabled={!hasPermissionToEditNegotiator}
                id="select-pais"
              />
            </Col>
            <Col md={6} xs={12}>
              <Input
                icon={<FaPhoneAlt color="#494950" size={14} />}
                value={phone}
                onChange={e => setPhone(e.target.value)}
                label="Whatsapp *"
                error={errors.phone}
                guide={false}
                masked={country ? country.abbreviation === 'br' : false}
                mask={[
                  '(',
                  /[0-9]/,
                  /\d/,
                  ')',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                disabled={!hasPermissionToEditNegotiator}
                id="input-whatsapp"
              />
            </Col>
            <Col xs={12}>
              <TextArea
                label="Observações"
                value={comments}
                onChange={e => setComments(e.target.value)}
                id="textarea-observacoes"
              />
            </Col>
            <Col xs={12}>
              <Select.Async
                onSearch={fetchTags}
                label="Tags"
                multiple
                value={tags}
                onChange={value => setTags(value)}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onInputChange={e => tagTreatment(e)}
                inputValue={inputTag}
                onCreateOption={handleTagCreate}
                creatable
                id="select-tags"
              />
            </Col>
            <Col md={6} xs={12}>
              <Input
                value={externalInvoiceNumber}
                onChange={e => setInvoice(e.target.value)}
                label="Invoice"
                id="input-invoice"
              />
            </Col>
            <Col xs={12}>
              <div className='d-flex align-items-end mb-3'>
                <Input
                  label="CRT"
                  value={crt}
                  onChange={event => setCrt(event.target.value)}
                  error={errors.crt}
                  id="input-crt"
                />
                {!crts.length ? (
                  <Styled.TransparentButton onClick={addNewCrt} className='mb-2 ml-1' title='Adicionar novo CRT'>
                    <FaPlus color={theme.colors.success}/>
                  </Styled.TransparentButton>
                ) : null}
              </div>
              {crts.map((crt, index) => (
                <div index={index} className='d-flex align-items-start mb-4'>
                  <Input
                    label="Número CRT *"
                    value={crt.number}
                    onChange={(event) => changeCrtNumber(event.target.value, index)}
                    className="mr-3"
                    id={`input-crt-${index}`}
                    error={crt.error_number}
                  />  
                  <Select.Async
                    onSearch={(value) => fetchCustomers({ search: value })}
                    label="Cliente *"
                    onChange={(value) => changeCrtCustomer(value, index)}
                    value={crt.customer}
                    getOptionLabel={option => {
                      let label =
                        option?.socialName && option?.cpfCnpj
                          ? `${option.socialName} - ${option.cpfCnpj}`
                          : `${option.social_name} - ${option.cgccpf}`;

                      if (option.city) {
                        label += ` - ${option.city}`;
                      }

                      return label;
                    }}
                    getOptionValue={option => option.id}
                    id={`import-crt-tomador-${index}`}
                    error={crt.error_customer}
                  />
                  <Styled.TransparentButton onClick={() => removeCrt(index)} className='ml-1' title='Remover CRT'>
                    <FaTrash color={theme.colors.danger}/>
                  </Styled.TransparentButton>
                  {(index === (crts.length - 1)) && (
                    <Styled.TransparentButton onClick={addNewCrt} className='ml-1' title='Adicionar novo CRT'>
                      <FaPlus color={theme.colors.success}/>
                    </Styled.TransparentButton>
                  )}
                </div>
              ))}
            </Col>
            <Col xs={12} md={6}>
              <Input
                label="Número do pedido cliente"
                value={clientOrderNumber}
                onChange={event => setClientOrderNumber(event.target.value)}
                error={errors.clientOrderNumber}
                id="input-numero-pedido-cliente"
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Cliente Tomador
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {load ? load.taker.label : 'Não informado'}
              </Text>
            </Col>
            {load?.loadPolicy?.operation && load?.loadPolicy?.policy && (
              <Col xs={12}>
                <Text type="label" color="#464E5F">
                  Apólice - Operação
                </Text>
                <Text type="regular" color="gray" as="p" className="mt-2">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`/apolices/${load.loadPolicy.policy.id}`}
                  >
                    {`${load.loadPolicy.policy.name} - ${load.loadPolicy.operation.name}`}
                  </Link>
                </Text>
              </Col>
            )}

            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Centro de custo
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {load ? load.costCenter?.name : 'Não informado'}
              </Text>
            </Col>

            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Negociação
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {load ? load.negotiation?.name : 'Não informado'}
              </Text>
            </Col>

            <Col md={12} xs={12}>
              <Text type="label" color="#464E5F">
                Produto
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {getProductInfo(load)}
              </Text>
            </Col>
            <Col md={6} xs={12}>
              <Text type="label" color="#464E5F">
                Temperatura mìnima da carga
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {typeof load?.temperatureMin === 'number' ||
                  load?.temperatureMin === 0
                  ? `${load.temperatureMin}°C`
                  : 'Não informado'}
              </Text>
            </Col>
            <Col md={6} xs={12}>
              <Text type="label" color="#464E5F">
                Temperatura máxima da carga
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {typeof load?.temperature === 'number' ||
                  load?.temperature === 0
                  ? `${load.temperature}°C`
                  : 'Não informado'}
              </Text>
            </Col>
            <Col md={6} xs={12}>
              <Text type="label" color="#464E5F">
                Empresa
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {load?.company?.name ? load?.company.name : 'Não informado'}
              </Text>
            </Col>
            <Col md={6} xs={12}>
              <Text type="label" color="#464E5F">
                Filial
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {load?.branchOffice ? load?.branchOffice.name : 'Não informado'}
              </Text>
            </Col>
            <Col md={6} xs={12}>
              <Text type="label" color="#464E5F">
                Moeda Valor da carga
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {loadCurrencyName || 'Real Brasileiro'}
              </Text>
            </Col>

            <Col md={6} xs={12}>
              <Text type="label" color="dark">
                Câmbio
              </Text>

              <Text type="regular" color="gray" as="p" className="mt-2">
                {formatCurrency(load?.exchange, 'USD', 'R$') || 'Não informado'}
              </Text>
            </Col>

            <Col md={6} xs={12} className="mb-2">
              <Text type="label" color="#464E5F">
                Valor da carga
              </Text>
              <Text
                type="regular"
                color="gray"
                as="p"
                className="mt-2"
                style={{ marginBottom: 0 }}
              >
                {formatCurrency(
                  load?.cargoValue,
                  loadCurrency?.value,
                  loadCurrencySymbol
                )}
              </Text>
              {load?.cargoValue > 0 && conversion ? (
                <Text type="label" color="black" weight="bold" className="mt-2">
                  Convertido:{' '}
                  {formatCurrency(
                    makeConversion(
                      load?.cargoValue,
                      conversion.operation,
                      load?.exchange
                    ),
                    conversion.currencyName,
                    conversion.symbol
                  )}
                </Text>
              ) : null}
            </Col>
            <Col md={6} xs={12}>
              <Text type="label" color="#464E5F">
                Vencimento da carga
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {load
                  ? moment(load.dueDate).format('DD/MM/YYYY HH:mm')
                  : 'Não informado'}
              </Text>
            </Col>

            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Negociador da carga
              </Text>
            </Col>
            <Col md={12} className="mb-2">
              {load?.negotiators?.map((item, index) => (
                <Text type="regular" color="gray" className="mt-2">
                  {load?.negotiators && item.username}
                  {index !== load.negotiators.length - 1 ? ', ' : null}
                </Text>
              ))}
            </Col>

            <Col lg={6} md={6} xs={12}>
              <Text type="label" color="#464E5F">
                País
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {country ? country.name : '-'}
              </Text>
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Text type="label" color="#464E5F">
                Whatsapp
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {phone}
              </Text>
            </Col>

            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Observações
              </Text>
              <Text
                type="regular"
                color="gray"
                as="p"
                className="mt-2"
                wordwrap
              >
                {load?.comments ? load.comments : 'Não informado'}
              </Text>
            </Col>

            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Tags
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {load && load.tags.length > 0
                  ? load.tags.map(item => item.name).join(', ')
                  : 'Não informado'}
              </Text>
            </Col>
            <Col xs={12}>
              <Text type="label" color="#464E5F">
                Invoice
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {load?.externalInvoiceNumber
                  ? load.externalInvoiceNumber
                  : 'Não informado'}
              </Text>
            </Col>
            {load?.id_externo && (
              <Col md={6} xs={12}>
                <Text type="label" color="dark">
                  Id Externo
                </Text>

                <Text type="regular" color="gray" as="p" className="mt-2">
                  {load?.idExterno ? load?.idExterno : 'Não informado'}
                </Text>
              </Col>
            )}
            <Col xs={12}>
              <Text type="label" color="#464E5F">
                CRT
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {load?.crt || 'Não informado'}
              </Text>
            </Col>
            <Col xs={12} className='mb-3'>
              <Text type="label" color="#464E5F">
                CRT Adicionais
              </Text>
              {load?.crts?.map((crt) => (
                <Text type="regular" color="gray" as="p" className="mt-2">
                  {crt?.customer?.socialName} - {crt?.number}
                </Text> 
              ))}
            </Col>
            <Col xs={12} md={6}>
              <Text type="label" color="#464E5F">
                Número do pedido cliente
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {load?.clientOrderNumber || 'Não informado'}
              </Text>
            </Col>
            <Col xs={12} md={6}>
              <Text type="label" color="#464E5F">
                Buscar motorista no APP?
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {load?.searchDriver ? 'Sim' : 'Não'}
              </Text>
            </Col>
            {load?.searchDriver ? (
              <>
                <Col xs={12} md={6}>
                  <Text type="label" color="#464E5F">
                    Raio de busca de motorista no APP
                  </Text>
                  <Text type="regular" color="gray" as="p" className="mt-2">
                    {`${load?.searchDriverRadius}km`}
                  </Text>
                </Col>
                <Col xs={12}>
                  <Text type="label" color="#464E5F">
                    Status notificações motoristas
                  </Text>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                      marginTop: '0.5rem',
                    }}
                  >
                    <span
                      title={`${
                        load?.searchDriver >= 2
                        ? 'Enviado para  motoristas à caminho'
                        : ' Motoristas à caminho'
                        }`}
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                        marginTop: '0.5rem',
                      }}
                    >
                      <FaTruck
                        fill={
                          load?.searchDriver >= 2
                            ? theme.colors.success
                            : theme.colors.gray
                        }
                      />
                      <Text as="span" type="medium" color="gray">
                        Motoristas à caminho
                      </Text>
                    </span>
                    <span
                      title={`${
                        load?.searchDriver >= 3
                        ? 'Enviado para motoristas próximos'
                        : 'Motoristas próximos'
                        }`}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                      }}
                    >
                      <FaMapMarked
                        fill={
                          load?.searchDriver >= 3
                            ? theme.colors.success
                            : theme.colors.gray
                        }
                      />
                      <Text as="span" type="medium" color="gray">
                        Motoristas próximos
                      </Text>
                    </span>
                  </div>
                </Col>
              </>
            ) : null}
          </Row>
        )}
      </Card>
      <MinimumMarginModal
        isOpen={lowerMarginModal.isOpen}
        onClose={lowerMarginModal.close}
        confirm={handleConfirmLowerMargin}
      />
    </>
  );
}

export default Data;
