import React, { useState, useRef } from 'react';

import { useEffectThrottled, useIsInitialRender } from 'hooks';
import Table from '../Table';
import Text from '../Text';
import Input from '../Input';
import Select from '../Select';
import Fallback from '../Fallback';
import { DefaultLoadingBodyComponent } from '../Card';
import Pagination from './Pagination';

export default function DataTable({
  data,
  columns,
  total,
  pages,
  currentPage,
  defaultPerPage = 5,
  searchPlaceholder,
  onSearchChanged,
  onPageChanged,
  onPerPageChanged,
  loading,
  withFilterOption = true,
  perPageOptions = [{ v: 3 }, { v: 5 }, { v: 10 }],
  withPagination = true,
  className,
  tableLayout = 'auto',
  tableStyles = {},
}) {
  const [perPage, setPerPage] = useState({ v: defaultPerPage });
  const [search, setSearch] = useState('');

  const paginationRef = useRef(null);
  const isInitialRender = useIsInitialRender();

  function handlePerPageChange(value) {
    setPerPage(value);

    if (onPerPageChanged) {
      onPerPageChanged(value.v);
    }
  }

  function handleSearchChange(value) {
    setSearch(value);
  }

  useEffectThrottled(() => {
    if (!isInitialRender) {
      paginationRef.current.resetPage();

      if (onSearchChanged) {
        onSearchChanged(search);
      }
    }
  }, [search]);

  return (
    <div className={className}>
      <div className="d-flex align-items-center justify-content-between">
        {withPagination && (
          <div className="d-flex align-items-center">
            <Text type="label" color="dark">
              Exibir
            </Text>
            <Select
              onChange={handlePerPageChange}
              value={perPage}
              options={perPageOptions}
              getOptionLabel={option => option.v}
              getOptionValue={option => option.v}
              placeholder={null}
              disableSearch
              disableClear
              components={{
                IndicatorsContainer: () => null,
              }}
              styles={{
                containerStyle: {
                  width: 'auto',
                  minWidth: 40,
                  marginBottom: 0,
                  marginRight: 5,
                  marginLeft: 5,
                },
              }}
            />
            <Text type="label" color="dark">
              por página
            </Text>
          </div>
        )}

        {withFilterOption ? (
          <div className="d-flex align-items-center">
            <Text type="label" color="dark">
              Filtrar
            </Text>
            <Input
              placeholder={searchPlaceholder}
              value={search}
              onChange={({ target }) => handleSearchChange(target.value)}
              containerStyle={{
                marginBottom: 0,
                marginLeft: 10,
                maxWidth: 350,
              }}
            />
          </div>
        ) : null}
      </div>
      <div style={{ overflowX: 'auto' }}>
        <Table tableLayout={tableLayout} tableStyles={tableStyles}>
          <thead>
            <tr>
              {columns?.map(column => (
                <th>
                  <Text color="dark" weight="500" title={column?.title}>
                    {column.name}
                  </Text>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <Fallback fall={loading} component={<DefaultLoadingBodyComponent />}>
              {data?.map(row => {
                return (
                  <tr>
                    {columns.map(column => {
                      if (column.selector) {
                        const value = column.selector(row);

                        return (
                          <td>
                            <Text type="regular" weight="400" color="dark">
                              {value}
                            </Text>
                          </td>
                        );
                      }

                      return <td>{column.render(row)}</td>;
                    })}
                  </tr>
                );
              })}
            </Fallback>
          </tbody>
        </Table>
      </div>
      {withPagination && (
        <div className="d-flex align-items-center justify-content-between">
          <Text type="label" color="gray">
            Exibindo {Math.min(perPage.v, data?.length)} de {total} registros
          </Text>
          <div>
            <Pagination
              currentPage={currentPage}
              pages={pages}
              onPageChanged={onPageChanged}
              ref={paginationRef}
            />
          </div>
        </div>
      )}
    </div>
  );
}