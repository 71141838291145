import { CF_SLUGS } from 'components/ModalOccurenceAditionalFields/utils';
import { useModal } from 'hooks';
import moment from 'moment';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import {
  fetchAditionalFieldsByOccurrence,
  fetchAllDisponiblesOccurencesAditionalField,
  fetchOccurencesLogs,
  postAditionalFieldsInOccurrence,
} from 'utils/fetches';
import { Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { cooperplaceApi } from 'services/api';

const defaultStates = {
  fields: {
    allAditionalFields: [],
    aditionalFields: [],
  },
  handleSubmitAditionalFields: () => { },
  isFetching: false,
  aditionalItensModal: () => { },
  extraFields: [],
  setExtraFields: () => { },
  availableFields: [],
  getAditionalFieldsThisOccurrence: async () => { },
  selectedOccurrence: {},
  setSelectedOccurrence: () => { },
  handleCloseModal: () => { },
};

export const OccurrencesAditionalFieldsContext = createContext(defaultStates);

export function OccurrencesAditionalFieldsProvider({ children }) {
  const [fields, setFields] = useState({
    allAditionalFields: [],
    aditionalFields: [],
  });
  const [extraFields, setExtraFields] = useState([]);
  const [selectedOccurrence, setSelectedOccurrence] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [availableFields, setAvailableFields] = useState([]);
  const aditionalItensModal = useModal();
  const snackbar = useSnackbar();

  const formatError = error => {
    let errorMessage = '';
    if (error?.response?.data?.errors) {
      error.response.data.errors.forEach(message => {
        errorMessage += message;
      });
    } else if (error?.response?.data?.error) {
      errorMessage = error.response.data.error;
    } else {
      errorMessage = 'Erro ao atualizar campos';
    }
    return errorMessage;
  };

  const getAditionalFieldsThisOccurrence = useCallback(async () => {
    try {
      const response = await fetchAditionalFieldsByOccurrence(
        selectedOccurrence?.id
      );
      setFields(prevState => ({
        ...prevState,
        aditionalFields: response.aditionalFields,
      }));
    } catch (error) {
      snackbar.show(<Text>{formatError(error)}</Text>, { type: 'error' });
    }
  }, [selectedOccurrence]);

  const validateDate = value => {
    const data = moment(value, 'DD/MM/YYYY HH:mm', true);
    return data.isValid();
  };

  const validateCf = extraField => {
    const pattern = /^\d{4}\/\d{6}-\d{2}$|^\d{4}\/\d{6}-\d{1}$/;
    return pattern.test(extraField);
  };

  const validateAditionalFields = useCallback(() => {
    let errors = 0;
    const newExtraFields = extraFields.map(extraField => {
      if (!extraField.field.label || !extraField.field.value) {
        errors += 1;
        return {
          ...extraField,
          field: {
            ...extraField.field,
            error: 'Selecione uma opção',
          },
        };
      }

      if (
        extraField.field.fieldType === 'datetime' &&
        !validateDate(extraField.value)
      ) {
        errors += 1;
        return {
          ...extraField,
          error: 'Digite uma data válida',
        };
      }

      if (
        extraField.field.fieldType === 'string' &&
        CF_SLUGS.includes(extraField.field.value)
      ) {
        const isValid = validateCf(extraField.value);
        if (isValid) {
          return extraField;
        }
        errors += 1;
        return {
          ...extraField,
          error:
            'Digite uma CF nos formatos válidos: XXXX/XXXXXX-XX ou XXXX/XXXXXX-X',
        };
      }

      if (extraField.value < 1) {
        errors += 1;
        return {
          ...extraField,
          error: 'Conteúdo necessário',
        };
      }
      return extraField;
    });
    if (errors === 0) {
      return newExtraFields;
    }
    setExtraFields(newExtraFields);
    return false;
  }, [extraFields]);

  const handleSubmitAditionalFields = useCallback(async () => {
    setIsFetching(true);
    try {
      const validatedExtraFields = validateAditionalFields();
      if (validatedExtraFields) {
        let fieldValidad = true;
        let fieldTravelId;
        await Promise.all(
          validatedExtraFields.filter(fieldType => fieldType.field.value === 'STE_NUMBER').map(async extraField => {
            const fieldId = extraField.field.id;
            const fieldValue = extraField.value;
            const res = await cooperplaceApi.post(`occurrences/verify`, {
              type: fieldId,
              occurrenceId: selectedOccurrence.id,
              value: fieldValue,
            });
            if (fieldValidad && res.data.message != null) {
              fieldValidad = false;
              fieldTravelId = res.data.message.id;
            }
          })
        );
        if (!fieldValidad) {
          snackbar.show(<Text>Atenção! Este número de STE já foi utilizado na viagem {fieldTravelId}</Text>, {
            type: 'danger',
          });
          setIsFetching(false);
          return;
        }

        const formatedAditionalFields = validatedExtraFields.map(
          extraField => ({
            itemId: extraField.field.id,
            value: extraField.value,
          })
        );

        const responsePostAditionalFields =
          await postAditionalFieldsInOccurrence(selectedOccurrence.id, {
            aditionalFields: formatedAditionalFields,
          });

        const response = await fetchOccurencesLogs(selectedOccurrence.id);
        setSelectedOccurrence(response.message[0]);

        aditionalItensModal.close();
        snackbar.show(<Text>{responsePostAditionalFields.message}</Text>, {
          type: 'success',
        });
      } else {
        return;
      }
    } catch (error) {
      snackbar.show(<Text>{formatError(error)}</Text>, { type: 'error' });
    } finally {
      setIsFetching(false);
    }
  }, [extraFields]);

  const requireAditionalFields = useCallback(async () => {
    const response = await fetchAllDisponiblesOccurencesAditionalField();
    setFields(prevState => ({
      ...prevState,
      allAditionalFields: response,
    }));
  }, []);

  useEffect(() => {
    requireAditionalFields();
  }, []);

  useEffect(() => {
    if (selectedOccurrence?.id) {
      getAditionalFieldsThisOccurrence(selectedOccurrence.id);
    }
  }, [selectedOccurrence]);

  const formatExtraFields = () => {
    const formatedExtrafields =
      fields?.aditionalFields.length > 0
        ? fields.aditionalFields.map(aditionalField => {
          const aditionalFieldName = fields.allAditionalFields.find(
            ({ id }) => id === aditionalField.item_id
          );
          return {
            field: {
              label: aditionalFieldName.name,
              value: aditionalFieldName.code,
              fieldType: aditionalFieldName.type,
              id: aditionalFieldName.id,
            },
            value: aditionalField.value,
          };
        })
        : [];
    setExtraFields(formatedExtrafields);
  };

  const handleCloseModal = useCallback(() => {
    aditionalItensModal.close();
    formatExtraFields();
  }, [extraFields]);

  useEffect(() => {
    formatExtraFields();
  }, [fields.aditionalFields]);

  useEffect(() => {
    const notAvailableFields = extraFields.map(
      aditionalField => aditionalField.field.value
    );
    const newAvailableFields = fields.allAditionalFields
      .filter(
        aditionalField => !notAvailableFields.includes(aditionalField.code)
      )
      .map(aditionalField => ({
        label: aditionalField.name,
        value: aditionalField.code,
        fieldType: aditionalField.type,
        id: aditionalField.id,
      }));
    setAvailableFields(newAvailableFields);
  }, [extraFields]);

  return (
    <OccurrencesAditionalFieldsContext.Provider
      value={{
        fields,
        handleSubmitAditionalFields,
        isFetching,
        aditionalItensModal,
        extraFields,
        setExtraFields,
        availableFields,
        getAditionalFieldsThisOccurrence,
        selectedOccurrence,
        setSelectedOccurrence,
        handleCloseModal,
      }}
    >
      {children}
    </OccurrencesAditionalFieldsContext.Provider>
  );
}
