import React, { useState, useEffect } from 'react';
import { usePermission } from 'hooks';
import { DataTable, InfiniteScroll, Modal, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';
import { cooperplaceApi } from 'services/api';
import { formatDateTime } from 'utils/formatter';
import AcceptanceCard from './AcceptanceCard';

export function ManualsTerms() {
  const snackbar = useSnackbar();
  usePermission('VISUALIZAR_MANUAIS_E_TERMOS', { redirect: true });
  const [loading, setLoading] = useState(false);
  const [manualsTerms, setManualsTerms] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [acceptanceTitle, setAcceptanceTitle] = useState();

  async function fetchManualsTerms() {
    setLoading(true);
    try {
      const { data } = await cooperplaceApi.get(`acceptances?page=1&limit=100`);
      const response = data.data;
      setManualsTerms(response);
    } catch (error) {
      snackbar.show(
        <Text>
          Erro ao buscar manuais e termos. Tente novamente mais tarde.
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setTimeout(() => {
        setLoading(false);
      });
    }
  }

  async function fetchHistory(code) {
    try {
      const { data } = await cooperplaceApi.get(
        `acceptances/code-history?code=${code}`,
        {}
      );
      setHistoryList(data);
    } catch (error) {
      snackbar.show(
        <Text>
          Não foi possível recuperar o histórico. Tente novamente mais tarde.
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }

  async function handleOpenHistory(code, title) {
    setAcceptanceTitle(title);
    await fetchHistory(code);
    setOpenHistoryModal(true);
  }

  useEffect(() => {
    fetchManualsTerms();
  }, []);

  function renderItem(acceptance) {
    return (
      <AcceptanceCard
        handleOpenHistory={handleOpenHistory}
        acceptance={acceptance}
        reloadAcceptances={fetchManualsTerms}
        key={acceptance.id}
      />
    );
  }

  return (
    <ApplicationLayout title="Manuais e Termos">
      <Modal
        show={openHistoryModal}
        heading={`${acceptanceTitle}`}
        handleClose={() => setOpenHistoryModal(false)}
        body={
          <DataTable
            withPagination={false}
            withFilterOption={false}
            data={historyList}
            columns={[
              {
                name: 'Descrição',
                selector: row => {
                  return row?.description || 'Não informado';
                },
              },
              {
                name: 'Incluido por',
                selector: row => row?.user?.username,
              },
              {
                name: 'Incluido em',
                selector: row => {
                  return formatDateTime(row?.createdAt) || 'Não informado';
                },
              },
              {
                name: 'Aceitos',
                selector: row => {
                  return row?.acceptQuantity.toString() || 'Não informado';
                },
              },
              {
                name: 'Versão',
                selector: row => {
                  return `V ${(row?.version).toFixed(2)}` || 'Não informado';
                },
              },
              {
                name: 'Anexo',
                selector: row =>
                  row?.media?.name && row?.media?.path ? (
                    <button
                      type="button"
                      onClick={() =>
                        window.open(
                          ` ${process.env.REACT_APP_BASE_STORAGE_URL}${row?.media?.container}/${row?.media?.path}`,
                          '_blank'
                        )
                      }
                      style={{
                        background: 'none',
                        border: 'none',
                        padding: 0,
                        textDecoration: 'underline',
                        color: 'blue',
                        cursor: 'pointer',
                      }}
                    >
                      {row?.media?.name}
                    </button>
                  ) : (
                    <Text>Nenhum anexo disponível</Text>
                  ),
              },
            ]}
          />
        }
      />
      <InfiniteScroll
        data={manualsTerms}
        scrollThreshold={0.7}
        renderItem={renderItem}
        loading={loading}
      />
    </ApplicationLayout>
  );
}
