import styled, { css }from 'styled-components';

export const CellContainer = styled.div`
  ${({ $wasEdited, theme }) =>
    `background: ${$wasEdited ? theme.colors.warning : 'none'}`}
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${({ $bgColor = 'none' }) => $bgColor};
  white-space: nowrap
  overflow: hidden

${({ travelOutside }) =>
    travelOutside &&
    css`
      display: flex;
      justify-content: center;
    `}
`;
