import React, { useState } from 'react';
import { Button, Modal, Select, Text } from 'v3/components';
import { fetchVehicle } from 'utils/fetches';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { BodyContainer } from './styles';
import { useFleetManagement } from '../../shared';

function CreateNewRegister() {
  const {
    loading,
    setLoading,
    newRegisterModal: modalHook,
    handleGetData
  } = useFleetManagement();
  const [vehicles, setVehicles] = useState([]);
  const snackbar = useSnackbar();

  const handleCreateRegister = async () => {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.post(
        `fleet/management/vehicleManagement`,
        {
          vehicleIds: vehicles.map(vehicle => vehicle.id),
        }
      );

      const errors = data?.error.map(
        error => `${error.vehicle} ${error.description}`
      );

      if (errors.length > 0) {
        snackbar.show(
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {errors.map(error => (
              <Text key={error}>{error}</Text>
            ))}
          </div>,
          { type: 'warning' }
        );
      } else {
        snackbar.show(<Text>Registros adicionados com sucesso</Text>, {
          type: 'success',
        });
      }

      setVehicles([]);
      modalHook.close();
    } catch (error) {
      snackbar.show('Ocorreu um erro ao adicionar os registros', {
        type: 'error',
      });
    } finally {
      setLoading(false);
      await handleGetData({ page: 1 })
    }
  };

  const handleClose = () => {
    setVehicles([]);
    modalHook.close();
  };

  return (
    <Modal
      show={modalHook.isOpen}
      heading="Adicionar novo veículo"
      body={
        <BodyContainer>
          <Text type="subtitle" className="text-title">
            Adicionar novo registro:
          </Text>

          <Text>
            Adicione todos os veículos que gostaria de importar para a gestão de
            frotas
          </Text>

          <Select.Async
            onSearch={fetchVehicle}
            label="Veículos"
            getOptionLabel={option => option.plate}
            getOptionValue={option => option.id}
            onChange={vehiclesOption => {
              return vehiclesOption?.length > 20
                ? snackbar.show(
                  <Text>
                    Não é possível inserir mais de 20 veículos.
                  </Text>,
                  {
                    type: 'error'
                  }
                ) : setVehicles(vehiclesOption || [])
            }}
            value={vehicles}
            multiple
          />

          <Button loading={loading} onClick={handleCreateRegister} modal={true}>
            Adicionar registros
          </Button>
        </BodyContainer>
      }
      handleClose={handleClose}
    />
  );
}

export default CreateNewRegister;
