import { Col, Divider, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { cooperplaceApi } from 'services/api';
import { Link, Text } from 'v3/components';
import { DefaultLoadingBodyComponent } from 'v3/components/Card';
import Pagination from 'v3/components/DataTable/Pagination';
import { useSnackbar } from 'v3/components/Snackbar';
import { useFleetManagement } from '../../shared';
import moment from 'moment';

function TravelOutside() {
  const { selected, travelOutside, setTravelOutside } =
    useFleetManagement();
  const [fetchOptions, setFetchOptions] = useState({
    page: 1,
    perPage: 5,
    total: 0,
    lastPage: 0,
  });
  const [isFetching, setIsFetching] = useState(false);
  const snackbar = useSnackbar();
  const paginationRef = useRef(null);

  const formatTypeTravel = (type) => {
    switch (type) {
      case 'OUTSIDE_TRAVEL':
        return 'Viagem externa';
      case 'EMPTY_TRAVEL':
        return 'Viagem vazia';
      default:
        return 'Não informado';
    }
  }

  const fetchData = async (select, page = 1) => {
    setIsFetching(true);
    try {
      const {
        data: { data: travelOutside, meta },
      } = await cooperplaceApi.get(
        `fleet/management/travelOutside/${select.id}`,
        {
          params: { ...fetchOptions, page },
        }
      );
      setFetchOptions({
        page: meta.current_page,
        perPage: meta.per_page,
        total: meta.total,
        lastPage: meta.last_page,
      });
      setTravelOutside({ data: travelOutside, meta });
    } catch (error) {
      setTravelOutside({ data: [] });
      snackbar.show(
        <Text>
          {error?.response?.data?.error ||
            'Erro ao trazer as viagens externas'}
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setIsFetching(false);
    }
  };

  const onPageChanged = async page => {
    await fetchData(selected, page);
  };

  useEffect(() => {
    if (selected?.id) {
      fetchData(selected);
    }
  }, [selected]);

  return (
    <div style={{ overflowX: 'auto' }}>
      <Row style={{ minWidth: 1000, justifyContent: 'space-around' }}>
        <Col span={2} className="p-1">
          <Text weight={500} color="dark">
            Usuário
          </Text>
        </Col>
        <Col span={4} className="p-1">
          <Text weight={500} color="dark">
            Origem
          </Text>
        </Col>
        <Col span={4} className="p-1">
          <Text weight={500} color="dark">
            Destino
          </Text>
        </Col>
        <Col span={4} className="p-1">
          <Text weight={500} color="dark">
            Tipo de viagem
          </Text>
        </Col>
        <Col span={4} className="p-1">
          <Text weight={500} color="dark">
            Data de criação
          </Text>
        </Col>
      </Row>
      {isFetching ? (
        <DefaultLoadingBodyComponent />
      ) : travelOutside.data?.length > 0 ? (
        <>
          <Row className="mt-2" align="middle" style={{ minWidth: 1000, justifyContent: 'space-around' }}>
            {travelOutside.data.map(travel => (
              <React.Fragment key={travel?.id}>
                <Col span={2} className="p-1">
                  <Link
                    target="_blank"
                    to={`/usuarios/${travel?.createdUser?.id}`}>
                    <Text type='medium'>
                      {travel?.createdUser?.username}
                    </Text>
                  </Link>
                </Col>
                <Col span={4} className="p-1">
                  <Text type="medium" weight={500} color="dark">
                    {`${travel?.originCity?.name} - ${travel?.originCity?.province?.name}`}
                  </Text>
                  <Text type="medium" weight={500} color="dark" className="d-block mt-1">
                    <b>{moment(travel?.dateOrigin).format('DD/MM/YYYY HH:mm')}</b>
                  </Text>
                </Col>
                <Col span={4} className="p-1">
                  <Text type="medium" weight={500} color="dark">
                    {`${travel?.destinationCity?.name} - ${travel?.destinationCity?.province?.name}`}
                  </Text>
                  <Text type="medium" weight={500} color="dark" className="d-block mt-1">
                    <b>{moment(travel?.dateDestination).format('DD/MM/YYYY HH:mm')}</b>
                  </Text>
                </Col>

                <Col span={4} className="p-1">
                  <Text type="medium" weight={500} color="dark">
                    {formatTypeTravel(travel?.typeTravel)}
                  </Text>
                </Col>
                <Col span={4} className="p-1">
                  <Text type="medium" weight={500} color="dark" className="d-block mt-1">
                    <b>{moment(travel?.ceratedAt).format('DD/MM/YYYY HH:mm')}</b>
                  </Text>
                </Col>
                <Divider />
              </React.Fragment>
            )
            )}
          </Row>
          <div className="d-flex align-items-center justify-content-between">
            <Text type="label" color="gray">
              Exibindo {travelOutside?.data?.length} de {fetchOptions.total}{' '}
              registros
            </Text>
            <div>
              <Pagination
                currentPage={fetchOptions.page}
                pages={fetchOptions.lastPage}
                onPageChanged={onPageChanged}
                ref={paginationRef}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="mt-2 p-1">
          <Text type="label" weight={500} color="dark">
            O veículos não possui viagens externas
          </Text>
        </div>
      )}
    </div>
  );
}

export default TravelOutside;
