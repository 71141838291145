import React, { useState } from 'react';
import { Input, Select, Text } from 'v3/components';
import { fetchSelectOptions } from 'utils/fetches';
import { sanitize } from 'dompurify';
import { currencyOptions } from 'v3/utils/formatter';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { usePolicyRegister } from '../context';
import { PolicyHeader } from '../../styles';

export function Header() {
  const {
    policyCurrency,
    setPolicyCurrency,
    policyName,
    setPolicyName,
    policyExpirationDate,
    setPolicyExpirationDate,
    policyLastRevisionDate,
    setPolicyLastRevisionDate,
    policyClients,
    setPolicyClients,
    windowWidth,
    errors,
    operations,
    policy,
  } = usePolicyRegister();
  const snackbar = useSnackbar();
  const [validatingClient, setValidatingClient] = useState(false);

  async function handleChangeClients(value) {
    try {
      if (value && (value?.length > policyClients?.length || !policyClients)) {
        setValidatingClient(true);
        const opNames = operations?.map(op => op.name);
        if (opNames?.length > 0) {
          const { data } = await cooperplaceApi.post(
            'policies/validate/operation',
            {
              operationsNames: opNames,
              customers: value?.map(client => client.id),
              type: 'customer',
              policyId: policy?.id ?? undefined,
            }
          );
          if (!data?.success) {
            snackbar.show(
              <Text style={{ whiteSpace: 'pre' }}>{data?.message}</Text>,
              {
                type: 'error',
                duration: 20000,
                showClose: true,
              }
            );
            setValidatingClient(false);
            return;
          }
        }
        setPolicyClients(
          value?.map(item => {
            return {
              id: item.id,
              label: item.label,
              cgccpf: item.cgccpf,
            };
          })
        );
        setValidatingClient(false);
      } else {
        setPolicyClients(
          value?.map(item => {
            return {
              id: item.id,
              label: item.label,
              cgccpf: item.cgccpf,
            };
          })
        );
      }
    } catch (error) {
      snackbar.show(<Text>Erro ao validar cliente!</Text>, {
        type: 'error',
      });
      setValidatingClient(false);
    }
  }

  const [clientsOptions, setClientsOptions] = useState([]);

  return (
    <PolicyHeader>
      <div className="info">
        <Input
          label="Nome apólice *"
          placeholder="Informe"
          value={policyName}
          onChange={e => setPolicyName(e.target.value)}
          error={errors?.policy_name}
        />
        <Input
          label="Vencimento da apólice *"
          value={policyExpirationDate}
          onChange={e => setPolicyExpirationDate(e.target.value)}
          type="date"
          error={errors?.due_date}
        />
        <Input
          label="Última revisão apólice"
          value={policyLastRevisionDate}
          onChange={e => setPolicyLastRevisionDate(e.target.value)}
          type="datetime-local"
          error={errors?.last_revision_date}
        />
      </div>

      <div>
        <Select.Async
          closeMenuOnSelect={false}
          labelColor="dark"
          label="CNPJ tomador *"
          value={policyClients}
          placeholder="CNPJ"
          multiple
          horizontal={windowWidth <= 992}
          modalHeading="CNPJs"
          modalBodyTitle="Selecione um cnpj"
          enableSelectAll
          onSearch={async search => {
            setClientsOptions([]);
            const data = await fetchSelectOptions('persons/customers', {
              search: sanitize(search),
            });
            setClientsOptions(data);
            return data;
          }}
          onChange={value => handleChangeClients(value)}
          onBlur={() => setClientsOptions([])}
          options={clientsOptions}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          selectValuesContainerMaxHeight="128px"
          selectValuesContainerOverflowY="scroll"
          error={errors?.customers}
          loading={validatingClient}
        />
        <Select
          label="Moeda *"
          options={currencyOptions}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          disableClear
          value={policyCurrency}
          onChange={value => setPolicyCurrency(value)}
          error={errors?.currency}
        />
      </div>
    </PolicyHeader>
  );
}
