import React from 'react';
import { Modal, Text } from 'v3/components';
import { useFleetManagement } from '../../shared';
import { BodyContainer } from './styles';

function ErrorsModal() {
  const { errorsModal: modalHook, errors, setErrors } = useFleetManagement();

  const handleClose = () => {
    setErrors([]);
    modalHook.close();
  };
  return (
    <Modal
      show={modalHook.isOpen}
      heading="Erros ao atualizar registros"
      body={
        <BodyContainer>
          <Text type="subtitle" className="text-title">
            Os seguintes registros não puderam ser atualizados:
          </Text>
          <ul>
            {errors.map(({ managementId, description }) => (
              <li key={managementId}>
                <Text>{`${managementId} - ${description}`}</Text>
              </li>
            ))}
          </ul>
        </BodyContainer>
      }
      handleClose={handleClose}
    />
  );
}

export default ErrorsModal;
