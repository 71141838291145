import api, { cooperplaceApi } from 'services/api';

export async function getProvinces() {
  const response = await api.get('provinces');
  return response.data.map(province => ({
    ...province,
    label: `${province.uf} (${province.name})`,
    value: province.id,
  }));
}

export async function retrieveUserDefinitions() {
  const response = await cooperplaceApi.get(
    '/user/definitions?types[]=tower_control_columns'
  );
  return response.data;
}

export async function fetchAvailableColumns() {
  const response = await cooperplaceApi.get('/control-tower');
  const processedColumns = response.data.reduce(
    (acc, curr) => {
      if (curr.group === 'Geral') {
        acc[0].itens.push(curr);
        return acc;
      }
      if (curr.group === 'Viagem') {
        acc[1].itens.push(curr);
        return acc;
      }
      if (curr.group === 'Origem / Destino') {
        acc[2].itens.push(curr);
        return acc;
      }
      acc[3].itens.push(curr);
      return acc;
    },
    [
      { title: 'Geral', itens: [] },
      { title: 'Viagem', itens: [] },
      { title: 'Origem / Destino', itens: [] },
      { title: 'Agenda', itens: [] },
    ]
  );
  processedColumns.map(item => {
    return {
      ...item.group,
      itens: item.itens.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      ),
    };
  });

  return processedColumns;
}
