import React, { useState } from 'react';
import { useTravel } from 'v3/pages/Travel';
import { Col, Row } from 'react-bootstrap';
import { Text } from 'v3/components';
import { GroApprovedOptions } from 'v3/utils/constants';
import { useRiskManager } from '../../context';
import { RISK_MANAGER_STATUSES_OBJECT } from '../../controller';
import { GRAttachments } from '../../Attachments';
import { MonitoringAttachment } from '../../MonitoringAttachment';

export function GRORiskManagerDetails() {
  const travel = useTravel();
  const { hasEditPermissionRiskManager } = useRiskManager();
  const [grAttachments, setGrAttachments] = useState([]);
  const riskApprovedItem = GroApprovedOptions.find(
    item => item?.slug === travel?.data?.riskApproved
  );

  return (
    <Col md={12} className="my-2">
      <Row>
        <Col md={6} sm={12}>
          <Row>
            <Col md={12} className="my-2">
              <Text type="body" color="dark" weight={500}>
                Equipamentos de monitoramento
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Rastreado
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.loads?.crawledLoad ? 'Sim' : 'Não'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Escoltado
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.loads?.isEscorted ? 'Sim' : 'Não'}
              </Text>
            </Col>
            <Col md={12} sm={12} className="my-2">
              <Text type="label" color="dark">
                Tecnologia rastreador
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.tracker
                  ? travel?.data?.tracker?.name
                  : 'Não Informado'}
              </Text>
            </Col>

            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Modelo rastreador
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.tracker && travel?.data?.trackerType?.name
                  ? travel?.data?.trackerType?.name
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Número rastreador
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.trackerType && travel?.data?.trackerNumber
                  ? travel?.data?.trackerNumber
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Modelo localizador
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.trackerLocator?.name
                  ? travel?.data?.trackerLocator?.name
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Número localizador
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.trackerLocator && travel?.data?.locatorNumber
                  ? travel?.data?.locatorNumber
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Modelo imobilizador
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.trackerImmobilizer?.name
                  ? travel?.data?.trackerImmobilizer?.name
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Número imobilizador
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.trackerImmobilizer &&
                travel?.data?.immobilizerNumber
                  ? travel?.data?.immobilizerNumber
                  : 'Não Informado'}
              </Text>
            </Col>
            {travel?.data?.baits?.map(bait => {
              return (
                <>
                  <Col md={6} sm={12}>
                    <Text type="label" color="dark">
                      Modelo Isca
                    </Text>
                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {bait?.tracker?.name || 'Não Informado'}
                    </Text>
                  </Col>
                  <Col md={6} sm={12}>
                    <Text type="label" color="dark">
                      Número Isca
                    </Text>
                    <Text type="regular" color="gray" as="p" className="mt-2">
                      {bait?.number || 'Não Informado'}
                    </Text>
                  </Col>
                </>
              );
            })}
            <Col xs={12}>
              <Text type="label" color="dark" className="d-block mt-5 mb-3">
                Anexos SM logística
              </Text>
              <MonitoringAttachment />
            </Col>
          </Row>
        </Col>
        <Col md={6} sm={12}>
          <Row>
            <Col md={12} className="my-2">
              <Text type="body" color="dark" weight={500}>
                Gerenciamento de risco
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark" weight={500}>
                Responsabilidade seguro
              </Text>
              {travel?.data?.policyInfo?.product?.responsabilities ? 
                travel?.data?.policyInfo?.product?.responsabilities.map((responsability) => {
                return (
                  <Text type="regular" color="gray" as="p" className="mb-0">
                    {responsability.name}
                  </Text>
                )
              }) : (
                <Text type="regular" color="gray" as="p" className="mb-0">
                  Não informado
                </Text>
              )}
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark" weight={500}>
                Perfil de segurança
              </Text>
                {travel?.data?.policyInfo?.range?.securityProfile ?
                  travel?.data?.policyInfo?.range?.securityProfile.map((profile) => {
                  return (
                    <Text type="regular" color="gray" as="p" className="mb-0">
                      {profile.name}
                    </Text>
                  )
                }) : (
                  <Text type="regular" color="gray" as="p" className="mb-0">
                    Não informado
                  </Text>
                )}
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark" weight={500}>
                Gerenciadora para rastreamento
              </Text>
              {travel?.data?.policyInfo?.range?.managerTracker ?
                travel?.data?.policyInfo?.range?.managerTracker.map((tracker) => {
                return (
                  <Text type="regular" color="gray" as="p" className="mb-0">
                    {tracker.name}
                  </Text>
                )
              }) : (
                <Text type="regular" color="gray" as="p" className="mb-0">
                  Não informado
                </Text>
              )}
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                SM de Risco
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.riskManagerApprovalCode || 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Gerenciamento Cadastro/Consulta
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.riskManagerSearch
                  ? travel?.data?.riskManagerSearch?.name
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Pesquisa/Consulta
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.riskManagerMonitoringRequest || 'Não Informado'}
              </Text>
            </Col>

            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Gerenciamento de risco para SM Logística
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.riskManagerLogisticSm
                  ? travel?.data?.riskManagerLogisticSm?.name
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Sm Logística
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.riskCodeLogisticSm || 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark" weight={500}>
                Espelhamento logístico
              </Text>
              {travel?.data?.policyInfo?.range?.managerMirror ?
                travel?.data?.policyInfo?.range?.managerMirror.map((mirror) => {
                return (
                  <Text type="regular" color="gray" as="p" className="mb-0">
                    {mirror.name}
                  </Text>
                )
              }) : (
                <Text type="regular" color="gray" as="p" className="mb-0">
                  Não informado
                </Text>
              )}
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Checklist Rastreador
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel?.data?.policyInfo?.range?.checklistTracker || 'Não Informado'}
              </Text>
            </Col>

            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Aprovação GR
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {riskApprovedItem ? riskApprovedItem?.name : 'Não Informado'}
              </Text>
            </Col>
            <Col md={6} sm={12} className="my-2">
              <Text type="label" color="dark">
                Status checklist
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel.data?.riskManagerStatus
                  ? RISK_MANAGER_STATUSES_OBJECT[travel.data.riskManagerStatus]
                      ?.label
                  : 'Não Informado'}
              </Text>
            </Col>
            <Col md={12} sm={12} className="my-2">
              <Text type="label" color="dark">
                Averbação
              </Text>
              <Text type="regular" color="gray" as="p" className="mt-2">
                {travel.data?.endorsement || 'Não Informado'}
              </Text>
            </Col>
            <Col md={12}>
              <Text type="label" color="dark">
                Rotograma
              </Text>
              <GRAttachments
                attachments={grAttachments}
                setAttachments={setGrAttachments}
                permissionToUploadGRAttachment={hasEditPermissionRiskManager}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
