import React from 'react';
import { Link } from 'react-router-dom';

import { Col, Row } from 'react-bootstrap';

import { Card, Text, Button } from 'v3/components';

import * as Styled from './styles';

export function ImplementCard({ implement }) {
  return (
    <Col md={6} xs={12}>
      <Card
        header={
          <div>
            <Text>{implement?.plate}</Text>
            {implement.deletedAt ? (
              <Styled.Badge pill light type="error" className="mr-2">
                <Text type="label">Inativo</Text>
              </Styled.Badge>
            ) : (
              <Styled.Badge pill light type="success" className="mr-2">
                <Text type="label">Ativo</Text>
              </Styled.Badge>
            )}
          </div>
        }
        HeaderRightComponent={
          <div>
            <Link to={`/implementos/${implement.id}`}>
              <Button className="mr-2" variant="secondary">
                <Text color="dark" weight={500}>
                  Detalhes
                </Text>
              </Button>
            </Link>
          </div>
        }
      >
        <Row>
          <Col md={12}>
            <Text type="label" color="dark">
              Proprietário do documento
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.ownerWagon?.socialName || 'Não informado'}
            </Text>
          </Col>
          <Col md={12}>
            <Text type="label" color="dark">
              Proprietário do RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {implement?.ownerAntt?.socialName || 'Não informado'}
            </Text>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}
