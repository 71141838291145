import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import moment from 'moment';
import { useModal } from 'hooks';
import api, { cooperplaceApi } from 'services/api';

import { Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';

import { applyMonetaryMask } from 'v3/utils/functions';
import { currencyOptions } from 'v3/utils/formatter';
import { fetchTrackers } from 'utils/fetches';
import { PolicyDetails } from '../Details';
import { CreateRouteModal } from '../Modals/CreateRoute';
import { PolicySchema } from '../validators';
import { VehiclesModal } from './components/Modals/Vehicle';
import { OperationsModal } from './components/Modals/Operation';
import { ProductsModal } from './components/Modals/Product';
import { RangeModal } from './components/Modals/Range';
import { RiskAreaModal } from './components/Modals/RiskArea';
import { SectionDialog } from './components/Dialogs/SectionDialog';
import { fetchTrackerTypes, processPolicyData, processSubmissionData } from './utils';
import { RegisterPolicy } from './Register';
import { PolicyRegisterProvider } from './context';
import { PolicyInUseDialog } from './components/Dialogs/PolicyInUseDialog';

export function Register() {
  const history = useHistory();
  const params = useParams();
  const operationModal = useModal();
  const productsModal = useModal();
  const vehicleModal = useModal();
  const rangesModal = useModal();
  const riskAreaModal = useModal();
  const routeModal = useModal();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [trackerModelOptions, setTrackerModelOptions] = useState([]);
  const [fetchingOptions, setFetchingOptions] = useState(false);
  const [currentOpIndex, setCurrentOpIndex] = useState(null);
  const [currentPdIndex, setCurrentPdIndex] = useState(null);
  const [currentVehicleIndex, setCurrentVehicleIndex] = useState(null);
  const [currentRangeIndex, setCurrentRangeIndex] = useState(null);
  const [
    currentMonitoringEquipamentIndex,
    setCurrentMonitoringEquipamentIndex,
  ] = useState(null);
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [active, setActive] = useState(true);
  const [policyCurrency, setPolicyCurrency] = useState(currencyOptions[0]);
  const [policyName, setPolicyName] = useState('');
  const [policyExpirationDate, setPolicyExpirationDate] = useState('');
  const [policyLastRevisionDate, setPolicyLastRevisionDate] = useState('');
  const [policyClients, setPolicyClients] = useState([]);
  const [operations, setOperations] = useState([]);
  const [dialogStates, setDialogStates] = useState({
    op: false,
    product: false,
    vehicle: false,
    range: false,
  });
  const [dialogPolicyInUse, setDialogPolicyInUse] = useState({
    open: false,
  });
  const [policyItemsOptions, setPolicyItemsOptions] = useState([]);
  const [riskManagersOptions, setRiskManagersOptions] = useState([]);
  const [trackerOptions, setTrackerOptions] = useState([]);
  const [hasRangeValueError, setHasRangeValueError] = useState(false);
  const [index, setIndex] = useState(0);
  const [policy, setPolicy] = useState([]);
  const [prevPolicy, setPrevPolicy] = useState([]);
  const [policyFetchError, setPolicyFetchError] = useState({
    status: false,
    message: '',
  });
  const [editing, setEditing] = useState(false);
  const [rangesInUse, setRangesInUse] = useState([]);

  const handleSelect = selectedIndex => {
    setIndex(selectedIndex);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleUpClick = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleOpenProductsModal = useCallback(operationIndex => {
    setCurrentOpIndex(operationIndex);
    productsModal.open();
  }, []);

  const handleOpenVehiclesModal = useCallback(
    (operationIndex, productIndex) => {
      setCurrentOpIndex(operationIndex);
      setCurrentPdIndex(productIndex);
      vehicleModal.open();
    },
    []
  );

  const handleOpenRangeModal = useCallback(
    (operationIndex, productIndex, vehicleIndex) => {
      setCurrentOpIndex(operationIndex);
      setCurrentPdIndex(productIndex);
      setCurrentVehicleIndex(vehicleIndex);
      rangesModal.open();
    },
    []
  );

  const handleOpenRiskAreaModal = useCallback(
    (operationIndex, productIndex, vehicleIndex, rangeIndex) => {
      setCurrentOpIndex(operationIndex);
      setCurrentPdIndex(productIndex);
      setCurrentVehicleIndex(vehicleIndex);
      setCurrentRangeIndex(rangeIndex);
      riskAreaModal.open();
    },
    []
  );

  async function fetchRiskManagers() {
    try {
      const { data } = await api.get('risk-manager-for-select');
      setRiskManagersOptions(data);
    } catch (error) {
      return [];
    }
  }

  async function fetchTrackerOptions() {
    const response = await fetchTrackers();
    setTrackerOptions(response);
  }

  async function fetchPolicyItems() {
    try {
      const { data } = await cooperplaceApi.get('/policies/items');
      setPolicyItemsOptions(data);
    } catch (error) {
      return [];
    }
  }
  const sensorsOptions = useMemo(() => {
    return policyItemsOptions?.filter(item => item?.group?.code === 'sensors');
  }, [policyItemsOptions]);

  const responsibilitySecurityOptions = useMemo(() => {
    return policyItemsOptions?.filter(
      item => item?.group?.code === 'responsibility_security'
    );
  }, [policyItemsOptions]);

  const rdoOptions = useMemo(() => {
    return policyItemsOptions?.filter(item => item?.group?.code === 'rdo');
  }, [policyItemsOptions]);

  const searchConsultationOptions = useMemo(() => {
    return policyItemsOptions?.filter(
      item => item?.group?.code === 'search_consulting'
    );
  }, [policyItemsOptions]);

  const equipamentsOptions = useMemo(() => {
    return policyItemsOptions?.filter(
      item => item?.group?.code === 'equipment'
    );
  }, [policyItemsOptions]);

  async function fetchInitialOptions() {
    try {
      setFetchingOptions(true);
      Promise.all([
        fetchPolicyItems(),
        fetchRiskManagers(),
        fetchTrackerOptions(),
      ]);
    } catch (error) {
      //
    } finally {
      setFetchingOptions(false);
    }
  }

  useEffect(() => {
    fetchInitialOptions();
  }, []);

  const handleAddProductItem = useCallback(
    (opIndex, productIndex, value, key) => {
      const oldProducts = operations?.find(
        (op, idx) => idx === opIndex
      )?.products;
      const updated = oldProducts?.map((pd, pdIdx) => {
        if (pdIdx === productIndex) {
          return {
            ...pd,
            [key]: value,
          };
        }
        return pd;
      });
      setOperations(old =>
        old.map((op, idx) => {
          if (idx === opIndex) {
            return {
              ...op,
              products: [...updated],
            };
          }
          return op;
        })
      );
    },
    [operations]
  );

  const handleAddRangeItem = useCallback(
    (opIndex, productIndex, vehicleIdx, rangeIdx, key, value) => {
      const newState = [...operations];

      newState[opIndex].products[productIndex].vehicles[vehicleIdx].ranges[
        rangeIdx
      ][key] = value;
      setOperations(newState);
    },
    [operations]
  );

  function handleChangeTecnologies(
    opIndex,
    productIndex,
    vehicleIdx,
    rangeIdx,
    groupIdx,
    value
  ) {
    const newState = [...operations];
    const previousTecnologies =
      newState[opIndex].products[productIndex].vehicles[vehicleIdx].ranges[
        rangeIdx
      ].monitoringEquipaments[groupIdx].tecnologies?.length > 0
        ? [
            ...newState[opIndex].products[productIndex].vehicles[vehicleIdx]
              .ranges[rangeIdx].monitoringEquipaments[groupIdx].tecnologies,
          ]
        : [];

    const wasSomeTecnologyRemoved = previousTecnologies?.length > value?.length;
    if (value === null) {
      return { tecnologies: null, equipments: null };
    }
    if (wasSomeTecnologyRemoved) {
      const currentValuesIds = value?.map(item => item.id);
      const filteredEquipments =
        newState[opIndex].products[productIndex].vehicles[vehicleIdx].ranges[
          rangeIdx
        ].monitoringEquipaments[groupIdx].equipments?.length > 0
          ? newState[opIndex].products[productIndex].vehicles[
              vehicleIdx
            ].ranges[rangeIdx].monitoringEquipaments[
              groupIdx
            ].equipments.filter(item =>
              currentValuesIds.includes(item.tracker_id)
            )
          : null;
      return { tecnologies: value, equipments: filteredEquipments };
    }
    return { tecnologies: value };
  }

  async function setTrackerTypes(tecnologies) {
    setTrackerModelOptions([]);
    const trackerIds = tecnologies?.map(
      item => item.id
    );

    const data = await fetchTrackerTypes(
      trackerIds,
    );

    setTrackerModelOptions(data);
  }

  const handleAddRangeMonitoringEquipament = useCallback(
    (opIndex, productIndex, vehicleIdx, rangeIdx, groupIdx, key, value) => {
      const newState = [...operations];
      if (key === 'tecnologies') {
        const updatedTrackerData = handleChangeTecnologies(
          opIndex,
          productIndex,
          vehicleIdx,
          rangeIdx,
          groupIdx,
          value
        );
        newState[opIndex].products[productIndex].vehicles[vehicleIdx].ranges[
          rangeIdx
        ].monitoringEquipaments[groupIdx].tecnologies =
          updatedTrackerData?.tecnologies;
        if (updatedTrackerData?.equipments !== undefined) {
          newState[opIndex].products[productIndex].vehicles[vehicleIdx].ranges[
            rangeIdx
          ].monitoringEquipaments[groupIdx].equipments =
            updatedTrackerData?.equipments;
        }

        setTrackerTypes(updatedTrackerData?.tecnologies)
      } else {
        newState[opIndex].products[productIndex].vehicles[vehicleIdx].ranges[
          rangeIdx
        ].monitoringEquipaments[groupIdx][key] = value;
      }
      setOperations(newState);
    },
    [operations]
  );

  const handleAddNewMonitoringEquipamentGroup = useCallback(
    (operationIndex, productIndex, vehicleIndex, rangeIndex) => {
      const newState = [...operations];
      const size =
        newState[operationIndex].products[productIndex].vehicles[vehicleIndex]
          .ranges[rangeIndex].monitoringEquipaments?.length;
      newState[operationIndex].products[productIndex].vehicles[
        vehicleIndex
      ].ranges[rangeIndex].monitoringEquipaments = [
        ...newState[operationIndex].products[productIndex].vehicles[
          vehicleIndex
        ].ranges[rangeIndex].monitoringEquipaments,
        {
          bait_quantity: 0,
          tecnologies: [],
          equipments: [],
          locators: [],
          immobilizers: [],
          baits: [],
          escorted: false,
        },
      ];

      setOperations(newState);
      setCurrentMonitoringEquipamentIndex(null);
      handleSelect(size);
    },
    [operations]
  );

  const handleRemoveRiskAreaItem = useCallback(
    (opIndex, productIndex, vehicleIdx, rangeIdx, riskAreaIdx) => {
      const newState = [...operations];
      const old =
        newState[opIndex].products[productIndex].vehicles[vehicleIdx].ranges[
          rangeIdx
        ].riskAreas;
      const updated = old?.filter((_, idx) => idx !== riskAreaIdx);
      newState[opIndex].products[productIndex].vehicles[vehicleIdx].ranges[
        rangeIdx
      ].riskAreas = updated;
      setOperations(newState);
    },
    [operations]
  );

  const handleRemoveOpOption = useCallback(
    opIndex => {
      const updated = operations?.filter((_, idx) => idx !== opIndex);
      setOperations(updated);
      setCurrentOpIndex(null);
      setDialogStates(old => ({ ...old, op: false }));
    },
    [operations]
  );

  const handleRemoveProductOption = useCallback(
    (opIndex, productIndex) => {
      const newState = [...operations];
      newState[opIndex].products = newState[opIndex].products.filter(
        (_, idx) => idx !== productIndex
      );
      setOperations(newState);
      setCurrentOpIndex(null);
      setCurrentPdIndex(null);
      setDialogStates(old => ({ ...old, product: false }));
    },
    [operations]
  );

  const handleRemoveVehicleOption = useCallback(
    (opIndex, productIndex, vehicleIndex) => {
      const newState = [...operations];
      newState[opIndex].products[productIndex].vehicles = newState[
        opIndex
      ].products[productIndex].vehicles.filter(
        (_, idx) => idx !== vehicleIndex
      );
      setOperations(newState);
      setCurrentOpIndex(null);
      setCurrentPdIndex(null);
      setCurrentVehicleIndex(null);
      setDialogStates(old => ({ ...old, vehicle: false }));
    },
    [operations]
  );

  const handleRemoveRangeOption = useCallback(
    (opIndex, productIndex, vehicleIndex, rangeIndex) => {
      const newState = JSON.parse(JSON.stringify(operations));
      const rangesLength =
        newState[opIndex].products[productIndex].vehicles[vehicleIndex].ranges
          ?.length - 1;
      if (rangeIndex === 0 || rangeIndex === rangesLength) {
        newState[opIndex].products[productIndex].vehicles[vehicleIndex].ranges =
          newState[opIndex].products[productIndex].vehicles[
            vehicleIndex
          ].ranges.filter((_, idx) => idx !== rangeIndex);
      } else if (rangeIndex > 0 && rangeIndex < rangesLength) {
        let predecessorMax =
          newState[opIndex].products[productIndex].vehicles[vehicleIndex]
            .ranges[rangeIndex - 1]?.policy_range_max;
        if (typeof predecessorMax === 'string') {
          predecessorMax = predecessorMax
            .replace(/[^\d,]/g, '')
            .replace(/,/g, '.');
        }
        const successorIndex = rangeIndex + 1;
        newState[opIndex].products[productIndex].vehicles[vehicleIndex].ranges =
          newState[opIndex].products[productIndex].vehicles[vehicleIndex].ranges
            ?.map((rg, idx) => {
              if (idx === successorIndex) {
                return {
                  ...rg,
                  policy_range_min: applyMonetaryMask(
                    Number(predecessorMax) + 0.01
                  ),
                };
              }
              return rg;
            })
            .filter((_, idx) => idx !== rangeIndex);
      }
      setOperations(newState);
      setCurrentOpIndex(null);
      setCurrentPdIndex(null);
      setCurrentVehicleIndex(null);
      setCurrentRangeIndex(null);
      setDialogStates(old => ({ ...old, range: false }));
    },
    [operations]
  );

  const handleRemoveGroupOption = useCallback(
    (opIndex, productIndex, vehicleIndex, rangeIndex, groupIdx) => {
      const newState = [...operations];
      if (
        groupIdx === 0 &&
        newState[opIndex].products[productIndex].vehicles[vehicleIndex].ranges[
          rangeIndex
        ].monitoringEquipaments?.length === 1
      ) {
        newState[opIndex].products[productIndex].vehicles[vehicleIndex].ranges[
          rangeIndex
        ].monitoringEquipaments = [
          {
            bait_quantity: 0,
            tecnologies: [],
            equipments: [],
            locators: [],
            immobilizers: [],
            baits: [],
            escorted: null,
            name: '',
          },
        ];
      } else {
        newState[opIndex].products[productIndex].vehicles[vehicleIndex].ranges[
          rangeIndex
        ].monitoringEquipaments = newState[opIndex].products[
          productIndex
        ].vehicles[vehicleIndex].ranges[
          rangeIndex
        ].monitoringEquipaments.filter((_, idx) => idx !== groupIdx);
      }

      setOperations(newState);
      setCurrentOpIndex(null);
      setCurrentPdIndex(null);
      setCurrentVehicleIndex(null);
      setCurrentRangeIndex(null);
      setCurrentMonitoringEquipamentIndex(null);
      handleSelect(0);
      setOpenGroupModal(false);
    },
    [operations]
  );

  async function validate(data, setErrors) {
    const errorList = {};
    try {
      await PolicySchema.validate(data, { abortEarly: false });
      setErrors({ ...errorList });
      return true;
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(error => {
          errorList[error.path] = error.message;
        });
        setErrors({ ...errorList });
        return false;
      }
      return false;
    }
  }

  async function validateVehicles(operations) {
    const validation = [];
    operations.forEach((operation, opIndex) => {
      operation.products.forEach((product, pdIndex) => {
        product.vehicles.forEach((vehicle, vhIndex) => {
          const hasBody = vehicle.vehicles.some(vh => vh.type === 'body');
          const hasVehicle = vehicle.vehicles.some(vh => vh.type === 'vehicle');
          const hasImplement = vehicle.vehicles.some(
            vh => vh.type === 'implement'
          );
          const hasMainTracktor = vehicle.vehicles.some(
            vh =>
              vh.type === 'vehicle' &&
              vh.name?.toLowerCase() === 'cavalo mecânico'
          );

          if (!hasVehicle || !hasBody || (hasMainTracktor && !hasImplement)) {
            validation.push(
              `Verifique o cadastro de veículos da operação ${
                opIndex + 1
              }, no produto ${pdIndex + 1} e grupo de veículos ${
                vhIndex + 1
              }. É necessário ter pelo menos um tipo de veículo, um tipo de carroceria e, em caso de cavalo mecânico, um tipo de implemento`
            );
          }
        });
      });
    });
    return validation?.length > 0 ? validation.join(', ') : null;
  }

  async function handleValidatePolicyVehicles() {
    const validationStatus = await validateVehicles(operations);
    return validationStatus;
  }

  const handleCreate = useCallback(async () => {
    try {
      setLoading(true);
      const isValid = await validate(
        {
          policy_name: policyName,
          currency: policyCurrency?.value,
          customers: policyClients,
          due_date: policyExpirationDate,
          operations,
        },
        setErrors
      );
      const messages = await handleValidatePolicyVehicles();

      if (isValid === false || messages) {
        snackbar.show(
          <Text>
            {messages && !isValid
              ? `Verifique os campos obrigatórios em todos as hierarquias! ${messages}`
              : messages ||
                'Verifique os campos obrigatórios em todos as hierarquias!'}
          </Text>,
          {
            type: 'error',
            duration: 5000,
          }
        );
        setLoading(false);
        return;
      }

      const payload = await processSubmissionData(
        active,
        policy,
        policyCurrency,
        policyName,
        policyExpirationDate,
        policyLastRevisionDate,
        policyClients,
        operations
      );
      const { data } = await cooperplaceApi.post('policies', payload);
      snackbar.show(<Text>Apólice cadastrada com sucesso!</Text>, {
        type: 'success',
      });
      setLoading(false);
      history.push(`/apolices/${data?.id}`);
      return;
    } catch (error) {
      const message =
        error?.response?.data?.error || 'Erro ao cadastrar apólice';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
      setLoading(false);
    }
  }, [
    policyClients,
    policyExpirationDate,
    policyLastRevisionDate,
    policyName,
    policyCurrency,
    operations,
  ]);

  const fetchPolicy = useCallback(async () => {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get(`policies/${params.id}`);
      const processedPolicy = processPolicyData([response?.data]);
      setPrevPolicy(JSON.parse(JSON.stringify(processedPolicy[0])));
      setPolicy(JSON.parse(JSON.stringify(processedPolicy[0])));
      const rgInUse = processedPolicy?.[0]?.loads_gro?.map(
        item => item?.policy_range_id
      );
      setRangesInUse(rgInUse?.length > 0 ? Array.from(new Set(rgInUse)) : []);
      setPolicyCurrency(processedPolicy[0]?.currency);
      setPolicyName(processedPolicy[0]?.name);
      setPolicyClients(
        processedPolicy[0]?.customers?.map(c => {
          return {
            id: c?.id,
            label: c?.social_name,
            cgccpf: c?.document,
          };
        })
      );
      setOperations(JSON.parse(JSON.stringify(processedPolicy[0]?.operations)));
      setActive(processedPolicy[0]?.active);
      setPolicyExpirationDate(
        format(
          new Date(moment(processedPolicy[0]?.due_date).add(1, 'day')),
          'yyyy-MM-dd'
        )
      );
      setPolicyLastRevisionDate(
        processedPolicy[0]?.last_revision_date
          ? moment(processedPolicy[0]?.last_revision_date).format(
              'yyyy-MM-DD HH:mm'
            )
          : null
      );
    } catch (error) {
      if (error?.response?.status === 404)
        setPolicyFetchError({ status: true, message: 'Apólice inexistente' });
      else if (error?.response?.status === 401)
        setPolicyFetchError({
          status: true,
          message: 'Você não tem permissão para visualizar apólices',
        });
      else
        setPolicyFetchError({
          status: true,
          message: 'Erro ao recuperar apólice',
        });
    } finally {
      setLoading(false);
    }
  }, [params]);

  const handleRestorePolicyValues = useCallback(() => {
    setPolicy(JSON.parse(JSON.stringify(prevPolicy)));
    setPolicyCurrency(prevPolicy?.currency);
    setPolicyName(prevPolicy?.name);
    setPolicyClients(
      prevPolicy?.customers?.map(c => {
        return {
          id: c?.id,
          label: c?.social_name,
          cgccpf: c?.document,
        };
      })
    );
    setOperations(JSON.parse(JSON.stringify(prevPolicy?.operations)));
    setActive(prevPolicy?.active);
    setPolicyExpirationDate(
      format(new Date(moment(prevPolicy?.due_date).add(1, 'day')), 'yyyy-MM-dd')
    );
    setPolicyLastRevisionDate(
      moment(prevPolicy?.last_revision_date).format('yyyy-MM-DD HH:mm')
    );
  }, [prevPolicy]);

  useEffect(() => {
    if (params?.id) {
      fetchPolicy();
    }
  }, [params]);

  const handleSubmitEdit = useCallback(async () => {
    try {
      setLoading(true);
      const isValid = await validate(
        {
          policy_name: policyName,
          currency: policyCurrency?.value,
          customers: policyClients,
          due_date: policyExpirationDate,
          operations,
        },
        setErrors
      );
      const messages = await handleValidatePolicyVehicles();

      if (isValid === false || messages) {
        snackbar.show(
          <Text style={{ whiteSpace: 'pre-wrap' }}>
            {messages && !isValid
              ? `Verifique os campos obrigatórios em todos as hierarquias! \n ${messages}`
              : messages ||
                'Verifique os campos obrigatórios em todos as hierarquias!'}
          </Text>,
          {
            type: 'error',
            duration: 20000,
            showClose: true,
          }
        );
        setLoading(false);
        return;
      }
      const payload = await processSubmissionData(
        active,
        policy,
        policyCurrency,
        policyName,
        policyExpirationDate,
        policyLastRevisionDate,
        policyClients,
        operations
      );
      await cooperplaceApi.put(`policies/${policy?.id}`, payload);
      snackbar.show(<Text>Apólice atualizada com sucesso!</Text>, {
        type: 'success',
      });
      await fetchPolicy();
      setLoading(false);
      setEditing(false);
    } catch (error) {
      const message =
        error?.response?.data?.error || 'Erro ao atualizar apólice';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
      setLoading(false);
    }
  }, [
    policy,
    policyName,
    policyClients,
    policyExpirationDate,
    policyLastRevisionDate,
    policyCurrency,
    operations,
  ]);

  const filterPolicyInUse = useCallback(
    (type, id) => {
      if (!policy?.loads_gro) {
        return [];
      }

      return policy?.loads_gro?.filter(load => load[type] === id);
    },
    [policy]
  );

  function hasError(opIndex, productIndex, vehicleIdx, rangeIdx, equipamentIdx) {
    if (!errors) {
      return false
    }

    const errorKeys = Object.keys(errors);
    if (errorKeys.length === 0) {
      return false
    }

    let index = []
    if (opIndex !== undefined) {
      index.push(`operations[${opIndex}]`)
    }
    if (productIndex !== undefined) {
      index.push(`products[${productIndex}]`)
    }

    if (vehicleIdx !== undefined) {
      index.push(`vehicles[${vehicleIdx}]`)
    }

    if (rangeIdx !== undefined) {
      index.push(`ranges[${rangeIdx}]`)
    }

    if (equipamentIdx !== undefined) {
      index.push(`monitoringEquipaments[${equipamentIdx}]`)
    }

    const name = index.join('.')

    return errorKeys.find(key => key.includes(name));
  }

  return (
    <PolicyRegisterProvider
      value={{
        editing,
        fetchingOptions,
        setEditing,
        handleSubmitEdit,
        policy,
        setPolicy,
        prevPolicy,
        policyFetchError,
        fetchPolicy,
        loading,
        setLoading,
        handleCreate,
        handleUpClick,
        policyCurrency,
        setPolicyCurrency,
        policyName,
        setPolicyName,
        active,
        setActive,
        policyExpirationDate,
        setPolicyExpirationDate,
        policyLastRevisionDate,
        setPolicyLastRevisionDate,
        trackerModelOptions,
        setTrackerModelOptions,
        policyClients,
        setPolicyClients,
        windowWidth,
        errors,
        setErrors,
        operationModal,
        productsModal,
        vehicleModal,
        rangesModal,
        riskAreaModal,
        operations,
        setOperations,
        currentOpIndex,
        setCurrentOpIndex,
        currentPdIndex,
        setCurrentPdIndex,
        currentVehicleIndex,
        setCurrentVehicleIndex,
        currentRangeIndex,
        setCurrentRangeIndex,
        handleOpenVehiclesModal,
        handleOpenProductsModal,
        handleOpenRiskAreaModal,
        handleAddProductItem,
        handleAddNewMonitoringEquipamentGroup,
        handleOpenRangeModal,
        handleAddRangeItem,
        handleAddRangeMonitoringEquipament,
        handleRemoveRiskAreaItem,
        routeModal,
        dialogStates,
        setDialogStates,
        setDialogPolicyInUse,
        handleRemoveGroupOption,
        currentMonitoringEquipamentIndex,
        setCurrentMonitoringEquipamentIndex,
        openGroupModal,
        setOpenGroupModal,
        index,
        setIndex,
        handleSelect,
        sensorsOptions,
        responsibilitySecurityOptions,
        rdoOptions,
        searchConsultationOptions,
        equipamentsOptions,
        riskManagersOptions,
        trackerOptions,
        hasRangeValueError,
        setHasRangeValueError,
        handleRestorePolicyValues,
        filterPolicyInUse,
        rangesInUse,
        hasError
      }}
    >
      <OperationsModal />
      <ProductsModal />
      <VehiclesModal />
      <RangeModal />
      <RiskAreaModal />
      <CreateRouteModal createRoute={routeModal} />

      <SectionDialog
        open={dialogStates?.op}
        handleClose={() => {
          setCurrentOpIndex(null);
          setDialogStates(old => ({ ...old, op: false }));
        }}
        label="Operação"
        confirAction={() => {
          handleRemoveOpOption(currentOpIndex);
        }}
      />
      <SectionDialog
        open={dialogStates?.product}
        handleClose={() => {
          setCurrentOpIndex(null);
          setCurrentPdIndex(null);
          setDialogStates(old => ({ ...old, product: false }));
        }}
        label="Produto"
        confirAction={() =>
          handleRemoveProductOption(currentOpIndex, currentPdIndex)
        }
      />
      <SectionDialog
        open={dialogStates?.vehicle}
        handleClose={() => {
          setCurrentOpIndex(null);
          setCurrentPdIndex(null);
          setCurrentVehicleIndex(null);
          setDialogStates(old => ({
            ...old,
            vehicle: false,
          }));
        }}
        label="Veículo"
        confirAction={() =>
          handleRemoveVehicleOption(
            currentOpIndex,
            currentPdIndex,
            currentVehicleIndex
          )
        }
      />
      <SectionDialog
        open={dialogStates?.range}
        handleClose={() => {
          setCurrentOpIndex(null);
          setCurrentPdIndex(null);
          setCurrentVehicleIndex(null);
          setCurrentRangeIndex(null);
          setDialogStates(old => ({ ...old, range: false }));
        }}
        label="Faixa de Valor"
        confirAction={() =>
          handleRemoveRangeOption(
            currentOpIndex,
            currentPdIndex,
            currentVehicleIndex,
            currentRangeIndex
          )
        }
      />
      <PolicyInUseDialog
        handleClose={() => {
          setDialogPolicyInUse({ open: false });
        }}
        open={dialogPolicyInUse?.open}
        loads={dialogPolicyInUse?.loads}
        label={dialogPolicyInUse?.label}
      />

      {params.id ? <PolicyDetails /> : <RegisterPolicy />}
    </PolicyRegisterProvider>
  );
}
