import React, { useEffect, useState } from 'react';
import api, { cooperplaceApi } from 'services/api';
import { Button, Modal, Select, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import TextArea from 'v3/components/TextArea';

function RefuseLoadModal({
  modalHook,
  selected,
  setSelected,
  vehicleManagementId,
  refetch,
}) {
  const snackbar = useSnackbar();
  const [errors, setErrors] = useState({
    reason: '',
    observation: '',
  });
  const [responses, setResponses] = useState({
    reason: null,
    observation: '',
  });
  const [reasonsOptions, setReasonsOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const handleClose = () => {
    setSelected(null);
    setErrors({
      reason: '',
      observation: '',
    });
    setResponses({
      reason: null,
      observation: '',
    });
    modalHook.close();
  };

  const handleSubmit = async () => {
    const errorsObj = {
      reason: '',
      observation: '',
    };

    if (!responses.reason?.id) {
      errorsObj.reason = 'Campo obrigatório';
    }

    if (responses.observation === '') {
      errorsObj.observation = 'Campo obrigatório';
    }

    if (errorsObj.reason || errorsObj.observation) {
      setErrors(errorsObj);
    } else {
      setIsFetching(true);
      try {
        await cooperplaceApi.post('fleet/management/rejectedLoad', {
          vehicleManagementId,
          loadId: selected,
          rejectedReasonId: responses.reason?.id,
          obs: responses.observation,
        });
        snackbar.show(<Text>Carga recusada com sucesso</Text>, {
          type: 'success',
        });
        refetch();
        handleClose();
      } catch (error) {
        snackbar.show(
          <Text>
            {error?.response?.data?.error || 'Erro ao recusar carga'}
          </Text>,
          {
            type: 'error',
          }
        );
      } finally {
        setIsFetching(false);
      }
    }
  };

  const fetchReasons = async () => {
    try {
      const response = await api.get('load/cancel/reasons');
      setReasonsOptions(response.data);
    } catch (error) {
      snackbar.show(
        <Text>
          {error?.response?.data?.error || 'Erro ao buscar motivos de recusa'}
        </Text>,
        {
          type: 'error',
        }
      );
    }
  };

  useEffect(() => {
    fetchReasons();
  }, []);

  return (
    <Modal
      show={modalHook.isOpen}
      heading={`Recusa de carga - ${selected}`}
      body={
        <div>
          <Select
            label="Motivo *"
            value={responses.reason}
            error={errors.reason}
            options={reasonsOptions}
            onChange={value => {
              setResponses({ ...responses, reason: value });
              setErrors({ ...errors, reason: '' });
            }}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.id}
          />
          <TextArea
            label="Observação *"
            value={responses.observation}
            onChange={({ target: { value } }) => {
              setResponses({ ...responses, observation: value });
              setErrors({ ...errors, observation: '' });
            }}
            error={errors.observation}
          />
        </div>
      }
      footer={
        <>
          <Button
            variant="secondary"
            onClick={handleClose}
            loading={isFetching}
          >
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSubmit} loading={isFetching}>
            Salvar
          </Button>
        </>
      }
      handleClose={handleClose}
    />
  );
}

export default RefuseLoadModal;
