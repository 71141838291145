import styled from 'styled-components';

export const ContainerFile = styled.div`
  background-color: transparent;

  border: 1px solid ${({ theme }) => theme.colors.secondary_light};
  border-radius: ${({ theme }) => theme.borderRadius.input};

  padding: 7px 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  overflow: hidden;

  cursor: pointer;

  .icon {
    margin-bottom: 10px;
  }

  .form-control-file {
    display: none;
  }
`;

export const IconContainer = styled.div`
  margin: 2px 6px 4px 3px;
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;
