import React from 'react';
import ListaFabricantesVeiculos from 'pages/veiculos/vehicles-manufacturers';
import ListaModelosVeiculos from 'pages/veiculos/models-manufacturers';
import ListaMotoristasTransportadora from 'pages/transportadoras/ListaMotoristas';
import ListaTags from 'pages/tags/ListTags';
import TagDetails from 'pages/tags/TagDetails';
import GerenciadorasRisco from 'pages/gerenciadoras-de-risco/Gerenciadoras';
import Rastreadores from 'pages/rastreadores/Rastreadores';
import { Account } from 'v3/pages/Account';
import ReportCargasAtendidas from 'pages/relatorios/ReportCargasAtendidas';
import ReportCargasNaoAtendidas from 'pages/relatorios/ReportCargasNaoAtendidas';
import ReportCargasComPassos from 'pages/relatorio-com-passos/ReportComPassos';
import ReportTravelTracking from 'pages/relatorios/acompanhamento-viagem/AcompanhamentoViagem';
import FrotaPrioritaria from 'pages/clientes/frota-prioritaria/FrotaPrioritaria';
import ReportCobrancas from 'pages/relatorios/cobrancas';
import AdherenceReport from 'pages/relatorios/adherence';
import AdherenceDriverReport from 'pages/relatorios/adherence/driver';
import ReportFaturas from 'pages/relatorios/faturas/ReportFaturas';
import SpeedReport from 'pages/relatorios/velocidades/SpeedReport';
import ExtratoDetalhado from 'pages/relatorios/cobrancas/ExtratoDetalhado';
import Chat from 'pages/chat/main/index';
import { SystemLogsList } from 'pages/relatorios/systemlogs/index';
import MapaDeCalor from 'pages/mapa-de-calor';
import TravelsArrival from 'v3/pages/Reports/TravelsArrival';
import { ListMatches } from 'v3/pages/Aggregations/ListMatchVehicles';
import { Aggregations } from 'v3/pages/Aggregations/ListOffers';

import AggregationShared from 'v3/pages/Aggregations/shared';
import { ListAggregatedVehicles } from 'v3/pages/Aggregations/ListAggregatedVehicles';
import BlacklistItemDetails from 'v3/pages/BlackList/Pages/Details';
import LoadPanelOfBi from 'v3/pages/PowerBi/LoadPanelOfBi';
import { ImplementsList } from 'v3/pages/Wagons/List';
import { ImplementShared } from 'v3/pages/Wagons/shared';
import SecurityProfileList from 'v3/pages/SecurityProfiles/List';
import VehicleControlTower from 'v3/pages/Reports/VehicleControlTower';
import DriverCatalog from 'v3/pages/Reports/DriverCatalog';
import PanelManager from 'v3/pages/PowerBi/PanelManager';
import RasterBranchesShared from 'v3/pages/RasterBranches/shared';
import {
  FaHome,
  FaBroadcastTower,
  FaSolarPanel,
  FaTruckLoading,
  FaTruck,
  FaHandshake,
  FaDollarSign,
  FaCashRegister,
  FaClipboardList,
  FaShieldAlt,
  FaListUl,
  FaChartBar,
} from 'react-icons/fa';
import { ChecklistList } from 'v3/pages/Checklist/Checklists/List';
import { ChecklistShared } from 'v3/pages/Checklist/Checklists/shared';
import { QuestionsList } from 'v3/pages/Checklist/Questions/List';
import { QuestionDetails } from 'v3/pages/Checklist/Questions/Details';
import { List as PolicyList } from 'v3/pages/Policy/List';
import { Register as Policy } from 'v3/pages/Policy/Register';
import { PreLoadDetail } from 'v3/pages/PreLoad/Details';
import FleetManagement from 'v3/pages/FleetManagement';
import { OccurrencesReport } from 'v3/pages/Reports/Occurrences';
import { WhatsAppLogs } from 'v3/pages/Reports/WhatsAppLogs';
import { ManualsTerms } from 'v3/pages/ManualsTerms';
import DriverWorkJourneyReport from '../pages/relatorios/DriverWorkJourney';
import DriverJourneyReport from '../v3/pages/Reports/DriverJourney';
import DocumentoFisico from '../pages/financeiro/documento-fisico/DocumentoFisico';
import Notificacoes from '../pages/notificacoes/Notificacoes';
import RevisarDocumentos from '../pages/revisar-documentos/RevisarDocumentos';
import Contato from '../pages/contato/Contato';
import GruposUsuarios from '../pages/usuarios/grupos-usuarios/GruposUsuarios';
import PermissoesUsuarios from '../pages/usuarios/permissoes-usuarios/PermissoesUsuarios';
import UsersPanelManeger from '../v3/pages/PowerBi/UsersPanelManeger/index';
import Configuracoes from '../pages/configuracoes/Configuracoes';
import Fatura from '../pages/financeiro/Fatura';
import Financeiro from '../pages/financeiro/Financeiro';
import BlacklistList from '../v3/pages/BlackList/Pages/List';
import BlacklistRegister from '../v3/pages/BlackList/Pages/Register';
import DriverRegister from '../v3/pages/Driver/Register';
import DriverDetails from '../v3/pages/Driver/Details';
import DriverList from '../v3/pages/Driver/List';
import Loads from '../v3/pages/Load/List';
import LoadRegister from '../v3/pages/Load/Register';
import LoadDetail from '../v3/pages/Load/Details';
import PaymentMethodList from '../v3/pages/PaymentMethod/List';
import PaymentMethodDetails from '../v3/pages/PaymentMethod/Details';
import PaymentMethodRegister from '../v3/pages/PaymentMethod/Register';
import IssuerDetails from '../v3/pages/Issuer/Details';
import Issuers from '../v3/pages/Issuer/List';
import IssuerRegister from '../v3/pages/Issuer/Register';
import ChecklistCompany from '../v3/pages/Company/Checklist';
import TravelDetails from '../v3/pages/Travel/Details';
import Intern from '../v3/pages/Reports/Intern';
import ReportNfs from '../v3/pages/Reports/Nfs';
import RevenuePerUser from '../v3/pages/Reports/RevenuePerUser';
import TimeIndicators from '../v3/pages/Reports/TimeIndicators';
import RecordsPerUser from '../v3/pages/Reports/RecordsPerUser';
import GrRecordsPerUser from '../v3/pages/Reports/GrRecordsPerUser';
import LoadsAttendedUser from '../v3/pages/Reports/LoadsAttendedUser';
import Vehicle from '../v3/pages/Vehicle/shared';
import VehicleList from '../v3/pages/Vehicle/List';
import UserRegister from '../v3/pages/User/shared';
import TravelRegister from '../v3/pages/Travel/Register';
import UserList from '../v3/pages/User/List';
import TravelList from '../v3/pages/Travel/List';
import Company from '../v3/pages/Company/shared';
import CompanyList from '../v3/pages/Company/List';
import TravelAlertReport from '../v3/pages/Reports/TravelAlert';
import Client from '../v3/pages/Client/shared';
import ShippingCompany from '../v3/pages/ShippingCompany/shared';
import RasterBranchesList from '../v3/pages/RasterBranches/List';
import ClientList from '../v3/pages/Client/List';
import ShippingCompanyList from '../v3/pages/ShippingCompany/List';
import RouteRegister from '../v3/pages/Route/shared';
import Route from '../v3/pages/Route/List';
import CostCenterRegister from '../v3/pages/CostCenter/shared';
import CostCenter from '../v3/pages/CostCenter/List';
import ProductRegister from '../v3/pages/Product/shared';
import Product from '../v3/pages/Product/List';
import Dashboards from '../v3/pages/Dashboards';
import Home from '../v3/pages/Home';
import MinimunFreightTable from '../v3/pages/MinimumFreightTable/List';
import MinimunFreightTableRegister from '../v3/pages/MinimumFreightTable/Register';
import MinimunFreightTableDetails from '../v3/pages/MinimumFreightTable/Details';
import { DefaultMinimumMargin } from '../v3/pages/DefaultMinimumMargin/List';
import { ClientTravelList } from '../v3/pages/ClientTravel/List';
import { ClientTravelDetail } from '../v3/pages/ClientTravel/Detail';

import { List as PreLoadList } from '../v3/pages/PreLoad/List';
import { RegisterPreLoad } from '../v3/pages/PreLoad/Register';

export default [
  {
    new: true,
    title: 'Início',
    iconClass: 'fas fa-home',
    path: '/',
    permissionRequired: 'VISUALIZAR_INICIO',
    component: Home,
    id: 'menu-inicio',
    icon: <FaHome />,
  },
  {
    title: 'Torre de Controle',
    id: 'menu-torre-controle',
    permissionRequired: 'VISUALIZAR_TORRE_DE_CONTROLE',
    children: [
      {
        new: true,
        title: 'Acompanhamento de viagens',
        path: '/torre-de-controle/viagens',
        permissionRequired: 'VISUALIZAR_ACOMPANHAMENTO_VIAGEM',
        component: ReportTravelTracking,
        id: 'menu-torre-contole-opcao-viagens',
      },
      {
        new: true,
        title: 'Acompanhamento de veículos',
        path: '/torre-de-controle/veiculos',
        permissionRequired: 'VISUALIZAR_ACOMPANHAMENTO_DE_VEICULOS',
        component: VehicleControlTower,
        id: 'menu-torre-contole-opcao-veiculos',
      },
      {
        new: true,
        title: 'Easy Frota',
        path: '/torre-de-controle/frota-facil',
        permissionRequired: 'VISUALIZAR_FROTA_FACIL',
        component: DriverCatalog,
        id: 'menu-torre-contole-opcao-frota-facil',
      },
      {
        new: true,
        title: 'Cooperplace Analytics',
        path: '/torre-de-controle/Painel-de-Bi',
        permissionRequired: 'VISUALIZA_DASHBOARD_TORRE_DE_CONTROLE_BI',
        component: LoadPanelOfBi,
        id: 'menu-torre-contole-opcao-bi',
      },
      {
        title: 'Mapa de Calor de Veículos',
        path: '/torre-de-controle/mapa-de-calor',
        permissionRequired: 'VISUALIZAR_MAPA_DE_CALOR_DE_VEICULOS',
        component: MapaDeCalor,
        id: 'menu-torre-contole-opcao-mapa-calor',
      },
      {
        new: true,
        title: 'Dashboards',
        path: '/torre-de-controle/dashboards',
        permissionRequired: 'VISUALIZAR_DASHBOARD',
        component: Dashboards,
        id: 'menu-torre-contole-opcao-dashboards',
      },
      {
        new: true,
        title: 'Gestão de otimização de frota e cargas',
        path: '/torre-de-controle/gestao-de-otimização-de-frota-e-cargas',
        permissionRequired: 'VISUALIZAR_GESTAO_FROTA',
        component: FleetManagement,
        id: 'menu-torre-contole-opcao-gestao-frotas',
      },
    ],
    icon: <FaBroadcastTower />,
  },
  {
    new: true,
    title: 'Painel Logístico',
    permissionRequired: 'VIAGENS_PARA_CLIENTES',
    path: '/painel-logistico',
    component: ClientTravelList,
    id: 'menu-painel-logistico',
    icon: <FaSolarPanel />,
  },
  {
    new: true,
    title: 'Detalhamento viagem',
    permissionRequired: 'VIAGENS_PARA_CLIENTES',
    path: '/painel-logistico/:id',
    component: ClientTravelDetail,
    hidden: true,
  },
  {
    title: 'Cargas',
    id: 'menu-cargas',
    permissionRequired: ['VISUALIZAR_CARGAS', 'VISUALIZAR_PRECARGA'],
    icon: <FaTruckLoading />,
    children: [
      {
        new: true,
        title: 'Cargas',
        path: '/cargas',
        permissionRequired: 'VISUALIZAR_CARGAS',
        component: Loads,
        id: 'menu-cargas-opcao-cargas',
      },
      {
        new: true,
        title: 'Cadastro de carga',
        path: '/cargas/cadastrar',
        permissionRequired: 'CADASTRAR_CARGAS',
        component: LoadRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Detalhes da carga',
        path: '/cargas/:id',
        permissionRequired: 'VISUALIZAR_CARGAS',
        component: LoadDetail,
        hidden: true,
      },
      {
        new: true,
        title: 'Pré Cargas',
        path: '/pre-cargas',
        permissionRequired: 'VISUALIZAR_PRECARGA',
        component: PreLoadList,
        id: 'menu-cargas-opcao-pre-cargas',
      },
      {
        new: true,
        title: 'Cadastro pré carga',
        path: '/pre-cargas/cadastrar',
        permissionRequired: 'CRIAR_PRECARGA',
        component: RegisterPreLoad,
        hidden: true,
      },
      {
        new: true,
        title: 'Detalhe pré carga',
        path: '/pre-cargas/:id',
        permissionRequired: 'VISUALIZAR_PRECARGA',
        component: PreLoadDetail,
        hidden: true,
      },
    ],
  },
  {
    new: true,
    title: 'Cargas',
    path: '/cargas/cadastrar/gro',
    permissionRequired: 'CADASTRAR_CARGAS',
    component: LoadRegister,
    hidden: true,
  },

  {
    new: true,
    title: 'Cadastro de carga',
    path: '/cargas/:id/atender',
    permissionRequired: 'CADASTRAR_CARGAS',
    component: LoadRegister,
    hidden: true,
  },
  {
    title: 'Cadastrar Viagens',
    path: '/viagens/cadastrar/:loadId?/:driverId?',
    permissionRequired: 'CADASTRAR_VIAGENS',
    component: TravelRegister,
    hidden: true,
    new: true,
  },
  {
    title: 'Detalhes da viagem',
    path: '/viagens/:id/:emittedId?',
    permissionRequired: 'ALTERAR_VIAGENS',
    component: TravelDetails,
    hidden: true,
    new: true,
  },
  {
    new: true,
    title: 'Viagens',
    path: '/viagens',
    permissionRequired: 'VISUALIZAR_VIAGENS',
    component: TravelList,
    id: 'menu-viagens',
    icon: <FaTruck />,
  },
  {
    title: 'Negociações',
    iconClass: 'fas fa-boxes',
    path: '/negociacoes',
    permissionRequired: 'VISUALIZAR_NEGOCIACOES',
    component: Chat,
    id: 'menu-negociacoes',
    icon: <FaHandshake />,
  },
  {
    title: 'Financeiro',
    permissionRequired: 'VISUALIZAR_FINANCEIRO',
    id: 'menu-financeiro',
    children: [
      {
        title: 'Faturas',
        path: '/faturas',
        permissionRequired: 'VISUALIZAR_FINANCEIRO',
        component: Financeiro,
        id: 'menu-negociacoes-opcao-faturas',
      },
      {
        title: 'Documento físico',
        path: '/faturas/documento-fisico',
        permissionRequired: 'VISUALIZAR_FINANCEIRO_DOCUMENTO_FISICO',
        component: DocumentoFisico,
        id: 'menu-negociacoes-opcao-documento-fisico',
      },
      {
        title: 'Cadastrar fatura',
        path: '/faturas/cadastrar',
        permissionRequired: 'CADASTRAR_FATURA',
        component: Fatura,
        hidden: true,
      },
      {
        title: 'Editar fatura',
        path: '/faturas/:id',
        permissionRequired: 'CADASTRAR_FATURA',
        component: Fatura,
        hidden: true,
      },
    ],
    icon: <FaDollarSign />,
  },
  {
    title: 'Configurações',
    path: '/configuracoes',
    permissionRequired: 'VISUALIZAR_CONFIGURACOES',
    component: Configuracoes,
    hidden: true,
  },
  {
    title: 'Revisar Documentos',
    path: '/revisar-documentos',
    component: RevisarDocumentos,
    hidden: true,
  },
  {
    title: 'Contato',
    path: '/contato',
    component: Contato,
    hidden: true,
  },
  {
    title: 'Notificações',
    path: '/notificacoes',
    component: Notificacoes,
    hidden: true,
  },
  {
    new: true,
    title: 'Editar Conta',
    path: '/conta',
    component: Account,
    hidden: true,
  },
  {
    title: 'Cadastros',
    permissionRequired: 'VISUALIZAR_CADASTROS',
    id: 'menu-cadastros',
    children: [
      {
        new: true,
        title: 'Manuais e Termos',
        path: '/manuais-e-termos',
        permissionRequired: 'VISUALIZAR_MANUAIS_E_TERMOS',
        component: ManualsTerms,
        id: 'menu-cadastros-opcao-manuais-e-termos',
      },
      {
        new: true,
        title: 'Motoristas',
        path: '/motoristas',
        permissionRequired: 'VISUALIZAR_MOTORISTAS',
        component: DriverList,
        id: 'menu-cadastros-opcao-motoristas',
      },
      {
        new: true,
        title: 'Cadastrar Motorista',
        path: '/motoristas/cadastrar',
        permissionRequired: 'VISUALIZAR_USUARIOS',
        component: DriverRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Detalhes do Motorista',
        path: '/motoristas/:id',
        permissionRequired: 'VISUALIZAR_USUARIOS',
        component: DriverDetails,
        hidden: true,
      },
      {
        new: true,
        title: 'Clientes',
        path: '/clientes',
        permissionRequired: 'VISUALIZAR_CLIENTES',
        component: ClientList,
        id: 'menu-cadastros-opcao-clientes',
      },
      {
        title: 'Marca de Veículos',
        path: '/veiculos/fabricantes',
        permissionRequired: 'VISUALIZAR_FABRICANTES_VEICULOS',
        component: ListaFabricantesVeiculos,
        id: 'menu-cadastros-opcao-marca-veiculos',
      },
      {
        title: 'Modelos de Veículos',
        path: '/veiculos/:id/modelos',
        permissionRequired: 'VISUALIZAR_FABRICANTES_VEICULOS',
        component: ListaModelosVeiculos,
        hidden: true,
      },
      {
        new: true,
        title: 'Transportadoras',
        path: '/transportadoras',
        permissionRequired: 'VISUALIZAR_TRANSPORTADORAS',
        component: ShippingCompanyList,
        id: 'menu-cadastros-opcao-transportadoras',
      },
      {
        new: true,
        title: 'Adicionar Transportadora',
        path: '/transportadoras/cadastrar',
        permissionRequired: 'VISUALIZAR_TRANSPORTADORAS',
        component: ShippingCompany,
        hidden: true,
      },
      {
        new: true,
        title: 'Editar Transportadora',
        path: '/transportadoras/:id',
        permissionRequired: 'VISUALIZAR_TRANSPORTADORAS',
        component: ShippingCompany,
        hidden: true,
      },
      {
        title: 'Motoristas vinculados',
        path: '/transportadoras/:id/motoristas',
        permissionRequired: 'VISUALIZAR_TRANSPORTADORAS',
        component: ListaMotoristasTransportadora,
        hidden: true,
      },
      {
        new: true,
        title: 'Adicionar Cliente',
        path: '/clientes/cadastrar',
        permissionRequired: 'VISUALIZAR_CLIENTES',
        component: Client,
        hidden: true,
      },
      {
        new: true,
        title: 'Editar Cliente',
        path: '/clientes/:id',
        permissionRequired: 'VISUALIZAR_CLIENTES',
        component: Client,
        hidden: true,
      },
      {
        title: 'Frota Prioritária',
        path: '/clientes/:id/frota-prioritaria',
        permissionRequired: 'VISUALIZAR_CLIENTES',
        component: FrotaPrioritaria,
        hidden: true,
      },
      {
        title: 'Veículos',
        path: '/veiculos',
        permissionRequired: 'VISUALIZAR_VEICULOS',
        component: VehicleList,
        new: true,
        id: 'menu-cadastros-opcao-veiculos',
      },
      {
        title: 'Cadastrar Empresa',
        path: '/empresas/cadastrar',
        permissionRequired: 'CADASTRAR_EMPRESAS',
        component: Company,
        hidden: true,
        new: true,
      },
      {
        title: 'Empresas',
        path: '/empresas',
        permissionRequired: 'VISUALIZAR_EMPRESAS',
        component: CompanyList,
        new: true,
        id: 'menu-cadastros-opcao-empresas',
      },
      {
        title: 'Editar Empresa',
        path: '/empresas/:id',
        permissionRequired: 'ALTERAR_EMPRESAS',
        component: Company,
        hidden: true,
        new: true,
      },
      {
        new: true,
        title: 'Emissores',
        path: '/emissores/cadastrar',
        permissionRequired: 'CADASTRAR_EMISSORES',
        component: IssuerRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Checklist',
        path: '/empresas/:company_id/checklist',
        permissionRequired: 'VISUALIZAR_EMPRESAS',
        component: ChecklistCompany,
        hidden: true,
      },
      {
        new: true,
        title: 'Tabela Frete Mínimo (ANTT)',
        path: '/tabela-frete-minimo-antt',
        permissionRequired: 'VISUALIZAR_TABELA_FRETE_MINIMO',
        component: MinimunFreightTable,
        id: 'menu-cadastros-opcao-tabela-frete-minimo',
      },
      {
        new: true,
        title: 'Registro Tabela Frete Mínimo (ANTT)',
        path: '/tabela-frete-minimo-antt/cadastrar',
        component: MinimunFreightTableRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Detalhes de Item da Tabela de Frete Mínimo',
        path: '/tabela-frete-minimo-antt/:id',
        permissionRequired: 'ATUALIZAR_TABELA_FRETE_MINIMO',
        component: MinimunFreightTableDetails,
        hidden: true,
      },

      {
        title: 'Tags',
        path: '/tags',
        permissionRequired: 'VISUALIZAR_TAGS',
        component: ListaTags,
        id: 'menu-cadastros-opcao-tags',
      },
      {
        title: 'Detalhes da Tag',
        path: '/tag/:tag_id/:tag_name',
        permissionRequired: 'VISUALIZAR_TAGS',
        component: TagDetails,
        hidden: true,
      },
      {
        title: 'Permissões de Usuário',
        path: '/permissoes-usuario',
        permissionRequired: 'VISUALIZAR_PERMISSOES_USUARIOS',
        component: PermissoesUsuarios,
        id: 'menu-cadastros-opcao-permissoes-usuario',
      },
      {
        title: 'Grupos de Usuário',
        path: '/grupos-usuario',
        permissionRequired: 'VISUALIZAR_GRUPOS_USUARIOS',
        component: GruposUsuarios,
        id: 'menu-cadastros-opcao-grupos-usuario',
      },
      {
        new: true,
        title: 'Cadastrar Usuário',
        path: '/usuarios/cadastrar/:clone_id?',
        permissionRequired: 'VISUALIZAR_GRUPOS_USUARIOS',
        component: UserRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Cadastrar Rota',
        path: '/rotas/cadastrar',
        permissionRequired: 'CADASTRAR_ROTA',
        component: RouteRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Editar Rota',
        path: '/rotas/:id',
        permissionRequired: 'EDITAR_ROTA',
        component: RouteRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Cadastrar Produto',
        path: '/produtos/cadastrar',
        permissionRequired: 'CADASTRAR_PRODUTOS',
        component: ProductRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Editar Produto',
        path: '/produtos/:id',
        permissionRequired: 'EDITAR_PRODUTOS',
        component: ProductRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Editar Usuário',
        path: '/usuarios/:id',
        permissionRequired: 'VISUALIZAR_GRUPOS_USUARIOS',
        component: UserRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Usuários',
        path: '/usuarios',
        permissionRequired: 'VISUALIZAR_USUARIOS',
        component: UserList,
        hidden: false,
        id: 'menu-cadastros-opcao-usuarios',
      },
      {
        title: 'Cadastrar veículo',
        path: '/veiculos/cadastrar',
        permissionRequired: 'CADASTRAR_VEICULOS',
        component: Vehicle,
        hidden: true,
        new: true,
      },
      {
        title: 'Editar Veículo',
        path: '/veiculos/:id',
        permissionRequired: 'ALTERAR_VEICULOS',
        component: Vehicle,
        hidden: true,
        new: true,
      },
      {
        title: 'Blacklist',
        path: '/blacklist',
        permissionRequired: 'VISUALIZAR_BLACKLIST',
        component: BlacklistList,
        new: true,
        id: 'menu-cadastros-opcao-blacklist',
      },
      {
        new: true,
        title: 'Cadastro de blacklist',
        path: '/blacklist/cadastrar',
        permissionRequired: 'CADASTRAR_BLACKLIST',
        component: BlacklistRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Detalhes de item da blacklist',
        path: '/blacklist/:id',
        permissionRequired: 'VISUALIZAR_BLACKLIST',
        component: BlacklistItemDetails,
        hidden: true,
      },
      {
        new: true,
        title: 'Agregamentos',
        path: '/agregamentos',
        permissionRequired: 'VISUALIZAR_OFERTAS_AGREGAMENTOS',
        component: Aggregations,
        id: 'menu-cadastros-opcao-agregamentos',
      },
      {
        title: 'Rotas',
        path: '/rotas',
        permissionRequired: 'VISUALIZAR_ROTA',
        component: Route,
        new: true,
        id: 'menu-cadastros-opcao-rotas',
      },
      {
        title: 'Margem e ADNT. Padrão',
        path: '/margem-adnt-padrao',
        permissionRequired: 'VISUALIZAR_MARGEM_ADIANTAMENTO_MINIMO',
        component: DefaultMinimumMargin,
        new: true,
        id: 'menu-cadastros-opcao-margem-adiantamento-padrao',
      },
      {
        title: 'Produtos',
        path: '/produtos',
        permissionRequired: 'VISUALIZAR_PRODUTOS',
        component: Product,
        new: true,
        id: 'menu-cadastros-opcao-produtos',
      },
      {
        new: true,
        title: 'Cadastro de oferta',
        iconClass: 'fas fa-boxes',
        path: '/agregamentos/cadastrar',
        permissionRequired: 'EDITAR_OFERTAS_AGREGAMENTOS',
        component: AggregationShared,
        hidden: true,
      },
      {
        new: true,
        title: 'Edição de oferta',
        iconClass: 'fas fa-boxes',
        path: '/agregamentos/:id',
        permissionRequired: 'EDITAR_OFERTAS_AGREGAMENTOS',
        component: AggregationShared,
        hidden: true,
      },
      {
        title: 'Veículos agregados',
        iconClass: 'fas fa-boxes',
        path: '/agregamentos/:id/veiculos',
        permissionRequired: 'VISUALIZAR_AGREGAMENTOS',
        component: ListAggregatedVehicles,
        hidden: true,
      },
      {
        new: true,
        title: 'Busca de veículos',
        iconClass: 'fas fa-boxes',
        path: '/agregamentos/:id/busca',
        permissionRequired: 'VISUALIZAR_AGREGAMENTOS',
        component: ListMatches,
        hidden: true,
      },
      {
        new: true,
        title: 'Cadastrar formas de pagamento',
        path: '/formas-de-pagamento/cadastrar',
        permissionRequired: 'CADASTRAR_FORMAS_DE_PAGAMENTO',
        component: PaymentMethodRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Formas de pagamento',
        path: '/formas-de-pagamento',
        permissionRequired: 'VISUALIZAR_FORMAS_DE_PAGAMENTO',
        component: PaymentMethodList,
        id: 'menu-cadastros-opcao-formas-pagamento',
      },
      {
        new: true,
        title: 'Detalhes da Forma de pagamento',
        path: '/formas-de-pagamento/:id',
        permissionRequired: 'VISUALIZAR_FORMAS_DE_PAGAMENTO',
        component: PaymentMethodDetails,
        hidden: true,
      },
      {
        new: true,
        title: 'Detalhes do Emissor',
        path: '/emissores/:id',
        permissionRequired: 'VISUALIZAR_EMISSORES',
        component: IssuerDetails,
        hidden: true,
      },
      {
        new: true,
        title: 'Emissores',
        path: '/emissores',
        permissionRequired: 'VISUALIZAR_EMISSORES',
        component: Issuers,
        id: 'menu-cadastros-opcao-emissores',
      },
      {
        new: true,
        title: 'Centros de custo',
        path: '/centro-de-custo',
        permissionRequired: 'VISUALIZAR_CENTRO_CUSTO',
        component: CostCenter,
        id: 'menu-cadastros-opcao-centros-custo',
      },
      {
        new: true,
        title: 'Cadastrar Centro de custo',
        path: '/centro-de-custo/cadastrar',
        permissionRequired: 'CADASTRAR_CENTRO_CUSTO',
        component: CostCenterRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Editar Centro de custo',
        path: '/centro-de-custo/:id',
        permissionRequired: 'EDITAR_CENTRO_CUSTO',
        component: CostCenterRegister,
        hidden: true,
      },
      {
        new: true,
        title: 'Implementos',
        path: '/implementos',
        permissionRequired: 'VISUALIZAR_IMPLEMENTO',
        component: ImplementsList,
        id: 'menu-cadastros-opcao-implementos',
      },
      {
        new: true,
        title: 'Cadastrar Implemento',
        path: '/implementos/cadastrar',
        permissionRequired: 'CADASTRAR_IMPLEMENTO',
        component: ImplementShared,
        hidden: true,
      },
      {
        new: true,
        title: 'Editar Implemento',
        path: '/implementos/:id',
        permissionRequired: 'CADASTRAR_IMPLEMENTO',
        component: ImplementShared,
        hidden: true,
      },
    ],
    icon: <FaCashRegister />,
  },
  {
    title: 'Rastreadores',
    path: '/rastreadores',
    permissionRequired: 'VISUALIZAR_RASTREADORES',
    component: Rastreadores,
    hidden: true,
  },
  {
    new: true,
    title: 'Perfil de Segurança',
    permissionRequired: 'VISUALIZAR_PERFIL_SEGURANCA',
    path: '/perfil-seguranca',
    component: SecurityProfileList,
    hidden: true,
  },
  {
    title: 'Gerenciadoras de Risco',
    path: '/gerenciadoras-de-risco',
    permissionRequired: 'VISUALIZAR_GERENCIADORAS_DE_RISCO',
    component: GerenciadorasRisco,
    hidden: true,
  },
  {
    new: true,
    title: 'Filiais Raster',
    path: '/filiais-raster',
    permissionRequired: 'GERENCIAR_RASTER_BRANCHES',
    component: RasterBranchesList,
    hidden: true,
  },
  {
    new: true,
    hidden: true,
    title: 'Cadastrar Filial raster',
    path: '/filiais-raster/cadastrar',
    permissionRequired: 'GERENCIAR_RASTER_BRANCHES',
    component: RasterBranchesShared,
  },
  {
    new: true,
    hidden: true,
    title: 'Visualizar Filial raster',
    path: '/filiais-raster/:id',
    permissionRequired: 'GERENCIAR_RASTER_BRANCHES',
    component: RasterBranchesShared,
  },
  // {
  //   new: true,
  //   title: 'Apólices',
  //   path: '/apolices',
  //   permissionRequired: 'VISUALIZAR_APOLICE',
  //   component: PolicyList,
  //   hidden: true,
  // },
  {
    new: true,
    title: 'Cadastrar apólice',
    path: '/apolices/cadastrar',
    permissionRequired: 'SALVAR_APOLICE',
    component: Policy,
    hidden: true,
  },
  {
    new: true,
    title: 'Editar Apólice',
    path: '/apolices/:id',
    permissionRequired: 'SALVAR_APOLICE',
    component: Policy,
    hidden: true,
  },
  {
    new: true,
    title: 'Gerenciamento usuários por painel',
    path: '/PowerBi/PainelManeger',
    permissionRequired: 'GERENCIAMENTO_DE_USUARIOS_PAINEIS_BI',
    hidden: true,
    component: UsersPanelManeger,
  },
  {
    new: true,
    title: 'Cadastro de painéis',
    path: '/PowerBi/PainelUser',
    permissionRequired: 'GERENCIAMENTO_PAINEIS_BI',
    hidden: true,
    component: PanelManager,
  },
  {
    new: true,
    title: 'Perguntas',
    path: '/checklist/perguntas',
    permissionRequired: 'VISUALIZAR_PERGUNTAS',
    component: QuestionsList,
    hidden: true,
  },
  {
    new: true,
    title: 'Perguntas',
    path: '/checklist/perguntas/:id',
    permissionRequired: 'VISUALIZAR_PERGUNTAS',
    component: QuestionDetails,
    hidden: true,
  },
  {
    new: true,
    title: 'Checklist',
    path: '/checklist/checklists',
    permissionRequired: 'VISUALIZAR_CHECKLIST',
    component: ChecklistList,
    hidden: true,
  },
  {
    new: true,
    title: 'Cadastrar checklist',
    path: '/checklist/checklists/cadastrar',
    permissionRequired: 'CADASTRAR_CHECKLIST',
    component: ChecklistShared,
    hidden: true,
  },
  {
    new: true,
    title: 'Editar checklist',
    path: '/checklist/checklists/:id',
    permissionRequired: 'CADASTRAR_CHECKLIST',
    component: ChecklistShared,
    hidden: true,
  },
  {
    title: 'Analytics',
    permissionRequired: [
      'GERENCIAMENTO_DE_USUARIOS_PAINEIS_BI',
      'GERENCIAMENTO_PAINEIS_BI',
    ],
    children: [
      {
        new: true,
        title: 'Gerenciamento de usuários por painel',
        path: '/PowerBi/PainelManeger',
        permissionRequired: 'GERENCIAMENTO_DE_USUARIOS_PAINEIS_BI',
        component: UsersPanelManeger,
      },
      {
        new: true,
        title: 'Gerenciamento de painéis',
        path: '/PowerBi/PainelUser',
        permissionRequired: 'GERENCIAMENTO_PAINEIS_BI',
        component: PanelManager,
      },
    ],
    icon: <FaChartBar />,
  },
  {
    title: 'Checklist',
    permissionRequired: ['VISUALIZAR_CHECKLIST', 'VISUALIZAR_PERGUNTAS'],
    path: '/checklist',
    children: [
      {
        new: true,
        title: 'Checklists',
        path: '/checklist/checklists',
        permissionRequired: 'VISUALIZAR_CHECKLIST',
        component: ChecklistList,
      },
      {
        new: true,
        title: 'Perguntas',
        path: '/checklist/perguntas',
        permissionRequired: 'VISUALIZAR_PERGUNTAS',
        component: QuestionsList,
      },
    ],
    icon: <FaListUl />,
  },
  {
    title: 'Gro/Seguros',
    permissionRequired: ['VISUALIZAR_GERENCIADORAS_DE_RISCO'],
    children: [
      {
        new: true,
        title: 'Apólices',
        path: '/apolices',
        permissionRequired: 'VISUALIZAR_APOLICE',
        component: PolicyList,
        id: 'menu-gro-seguros-opcao-apolices',
      },
      {
        new: true,
        title: 'Filiais Raster',
        path: '/filiais-raster',
        permissionRequired: 'GERENCIAR_RASTER_BRANCHES',
        component: RasterBranchesList,
        id: 'menu-gro-seguros-opcao-filiais-raster',
      },
      {
        new: true,
        title: 'Gerenciadoras de Risco',
        path: '/gerenciadoras-de-risco',
        permissionRequired: 'VISUALIZAR_GERENCIADORAS_DE_RISCO',
        component: GerenciadorasRisco,
        id: 'menu-gro-seguros-opcao-gerenciadoras-riscos',
      },
      {
        new: true,
        title: 'Perfil de Segurança',
        permissionRequired: 'VISUALIZAR_PERFIL_SEGURANCA',
        path: '/perfil-seguranca',
        component: SecurityProfileList,
        id: 'menu-gro-seguros-opcao-perfil-seguranca',
      },
      {
        new: true,
        title: 'Rastreadores',
        path: '/rastreadores',
        permissionRequired: 'VISUALIZAR_RASTREADORES',
        component: Rastreadores,
        id: 'menu-gro-seguros-opcao-rastreadores',
      },
    ],
    icon: <FaShieldAlt />,
  },
  {
    title: 'Relatórios',
    permissionRequired: 'VISUALIZAR_RELATORIOS',
    path: '/relatorios',
    id: 'menu-relatorios',
    children: [
      {
        new: true,
        title: 'Alertas de viagem',
        path: '/relatorios/alertas',
        permissionRequired: 'VISUALIZAR_RELATORIOS_ALERTAS_VIAGEM',
        component: TravelAlertReport,
        id: 'menu-relatorios-opcao-alertas-viagem',
      },
      {
        title: 'Cargas atendidas',
        path: '/relatorios/viagens',
        permissionRequired: 'VISUALIZAR_RELATORIO_CARGAS_ATENDIDAS',
        component: ReportCargasAtendidas,
        id: 'menu-relatorios-opcao-cargas-atendidas',
      },
      {
        title: 'Cargas não atendidas',
        path: '/relatorios/cargas-nao-atendidas',
        permissionRequired: 'VISUALIZAR_RELATORIO_CARGAS_NAO_ATENDIDAS',
        component: ReportCargasNaoAtendidas,
        id: 'menu-relatorios-opcao-cargas-nao-atendidas',
      },
      {
        title: 'Viagens com passos',
        path: '/relatorios/viagem-com-passos',
        permissionRequired: 'VIAGEM_COM_PASSOS',
        component: ReportCargasComPassos,
        id: 'menu-relatorios-opcao-viagens-passos',
      },
      {
        title: 'Cobranças',
        path: '/relatorios/cobrancas',
        permissionRequired: 'VISUALIZAR_COBRANCAS',
        component: ReportCobrancas,
        id: 'menu-relatorios-opcao-cobrancas',
      },
      {
        title: 'Faturas',
        path: '/relatorios/financeiro',
        permissionRequired: 'VISUALIZAR_RELATORIO_FATURAS',
        component: ReportFaturas,
        id: 'menu-relatorios-opcao-faturas',
      },
      {
        title: 'Relatório de velocidades',
        path: '/relatorios/velocidades',
        permissionRequired: 'VISUALIZAR_RELATORIO_VELOCIDADES',
        component: SpeedReport,
        id: 'menu-relatorios-opcao-relatorio-velocidade',
      },
      {
        new: true,
        title: 'Relatório de indicadores de tempos operacionais',
        path: '/relatorios/time-indicators',
        permissionRequired:
          'VISUALIZAR_RELATORIO_INDICADORES_TEMPOS_OPERACIONAIS',
        component: TimeIndicators,
        id: 'menu-relatorios-opcao-relatorio-indicadores-tempos-operacionais',
      },
      {
        title: 'Relatório de jornada de trabalho',
        path: '/relatorios/jornada-trabalho',
        permissionRequired: 'VISUALIZAR_RELATORIO_JORNADA_TRABALHO',
        component: DriverWorkJourneyReport,
        id: 'menu-relatorios-opcao-relatorio-jornada-trabalho',
      },
      {
        new: true,
        title: 'Relatório de tempos de condução',
        path: '/relatorios/tempos-conducao',
        permissionRequired: 'VISUALIZAR_RELATORIO_JORNADA_MOTORISTA',
        component: DriverJourneyReport,
        id: 'menu-relatorios-opcao-relatorio-tempos-conducao',
      },
      {
        title: 'Relatório de adêrencia',
        path: '/relatorios/aderencia',
        permissionRequired: 'VISUALIZAR_RELATORIO_ADERENCIA',
        component: AdherenceReport,
        id: 'menu-relatorios-opcao-relatorio-aderencia',
      },
      {
        title: 'Motoristas Interessados',
        path: '/relatorios/aderencia-motoristas/:load_id',
        permissionRequired: 'VISUALIZAR_RELATORIO_ADERENCIA',
        component: AdherenceDriverReport,
        hidden: true,
      },
      {
        title: 'Extrato de Viagens',
        path: '/relatorios/cobrancas/:company/:client/:month/:year',
        permissionRequired: 'VISUALIZAR_COBRANCAS',
        component: ExtratoDetalhado,
        hidden: true,
      },
      {
        new: true,
        title: 'Rastreio de nota fiscal',
        path: '/relatorios/rastreio-nota-fiscal',
        permissionRequired: 'VISUALIZAR_RASTREIO_DE_NOTA_FISCAL',
        component: ReportNfs,
        id: 'menu-relatorios-opcao-rastreio-nota-fiscal',
      },
      {
        new: true,
        title: 'Relatórios internos',
        path: '/relatorios/internos',
        permissionRequired: 'VISUALIZAR_RELATORIOS_INTERNOS',
        component: Intern,
        id: 'menu-relatorios-opcao-relatorios-internos',
      },
      {
        new: true,
        title: 'Relatórios de faturamento por usuário',
        path: '/relatorios/revenue-per-user',
        permissionRequired: 'VISUALIZAR_RELATORIO_FATURAMENTO_POR_USUARIO',
        component: RevenuePerUser,
        id: 'menu-relatorios-opcao-relatorios-faturamento-usuario',
      },
      {
        new: true,
        title: 'Relatórios de validações de cadastros por usuário',
        path: '/relatorios/records-per-user',
        permissionRequired: 'VISUALIZAR_RELATORIO_CADASTROS_POR_USUARIO',
        component: RecordsPerUser,
        id: 'menu-relatorios-opcao-relatorios-validacoes-cadastros-usuario',
      },
      {
        new: true,
        title: 'Relatórios de validações de cadastros GR por usuário',
        path: '/relatorios/gr-records-per-user',
        permissionRequired: 'VISUALIZAR_RELATORIO_CADASTROS_GR_POR_USUARIO',
        component: GrRecordsPerUser,
        id: 'menu-relatorios-opcao-relatorios-validacoes-cadastros-gr-usuario',
      },
      {
        new: true,
        title: 'Relatório de previsão de chegada de viagens',
        path: '/relatorios/travels-arrival',
        permissionRequired:
          'VISUALIZAR_RELATORIO_DE_PREVISAO_DE_CHEGADA_DE_VIAGENS',
        component: TravelsArrival,
        id: 'menu-relatorios-opcao-relatorio-previsao-chegada-viagens',
      },
      {
        new: true,
        title: 'Relatórios de cargas atendidas por usuário',
        path: '/relatorios/loads-attended-user',
        permissionRequired:
          'VISUALIZAR_RELATORIOS_DE_CARGAS_ATENDIDAS_POR_USUARIO',
        component: LoadsAttendedUser,
        id: 'menu-relatorios-opcao-relatorios-carga-atendidas-usuario',
      },
      {
        new: true,
        title: 'Relatório de Ocorrências',
        path: '/relatorios/ocorrencias',
        permissionRequired: 'VISUALIZAR_RELATORIOS_DE_OCORRENCIAS',
        component: OccurrencesReport,
      },
      {
        new: true,
        title: 'Logs do Sistema',
        path: '/relatorios/systemlogs',
        permissionRequired: 'VISUALIZAR_LOGS',
        component: SystemLogsList,
        id: 'menu-relatorios-opcao-logs',
      },
      {
        new: true,
        title: 'WhatsApp Logs',
        path: '/relatorios/whatsapp_logs',
        component: WhatsAppLogs,
        id: 'menu-relatorios-opcao-logs-do-whatsapp',
      },
    ],
    icon: <FaClipboardList />,
  },
];
