import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';

import api from 'services/api';
import useModal from 'hooks/useModal';

import Details from '../Details';
import Register from '../Register';

import { VehicleProvider } from './context';
import * as Modals from './Modals';
import { format } from 'date-fns';
import moment from 'moment';

export default function VehicleShared() {
  const driversModal = useModal();
  const createdModal = useModal();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const params = useParams();

  const [vehicle, setVehicle] = useState({ drivers: [] });
  const [isFetching, setFetching] = useState(false);
  const [errors, setErrors] = useState({});
  const [prevVehicle, setPrevVehicle] = useState({});

  function showSnackbar(text, options) {
    snackbar.show(<Text> {text} </Text>, options);
  }

  async function fetchVehicle() {
    try {
      setFetching(true);

      const response = await api.get(`vehicles/${params.id}`);

      const data = {
        ...response.data,
        city: response.data.city?.id &&
          response.data.city?.name && {
          id: response.data.city?.id,
          name: response.data.city?.name,
          province: {
            id: response.data?.city?.province?.id,
            uf: response.data?.city?.province?.uf,
          },
        },
        province: {
          id: response.data?.city?.province?.id,
          uf: response.data?.city?.province?.uf,
        },
        anttType:
          (response.data.antt_type &&
            response.data.antt_type_name && {
            id: response.data.antt_type,
            name: response.data.antt_type_name,
          }) ||
          null,
        antt_adherence: response.data.antt_adherence
          ? format(
            new Date(moment(response?.data?.antt_adherence).add(1, 'day')),
            'yyyy-MM-dd'
          )
          : null,
        type: response.data.vehicleType,
        axis: response.data.axisType,
        brand: response.data.manufacturer_id
          ? {
            id: response.data?.manufacturer_id,
            name: response.data?.brand_name,
          }
          : null,
        model: response.data.model ? { label: response.data?.model } : null,
        owner:
          (response.data.ownerData?.id &&
            response.data.ownerData?.cgccpf && {
            id: response.data.ownerData?.id,
            social_name: response.data.ownerData?.social_name,
            cgccpf: response.data.ownerData?.cgccpf,
            label: response.data.ownerData?.label,
          }) ||
          null,
        beneficiary:
          (response.data.beneficiary?.id &&
            response.data.beneficiary?.cgccpf && {
            id: response.data.beneficiary?.id,
            social_name: response.data.beneficiary?.social_name,
            cgccpf: response.data.beneficiary?.cgccpf,
            label: response.data.beneficiary?.label,
          }) ||
          null,
        color: response.data.color
          ? { label: response.data.color, value: response.data.color }
          : null,
        fuel: response.data?.fuel_id
          ? { id: response.data.fuel_id, name: response.data.fuel_name }
          : null,
        isTracked: response.data?.tracked,
        tracker: response.data?.tracker
          ? {
            name: response.data.tracker?.name,
            id: response.data.tracker_id,
          }
          : null,
        tracker_type: response.data.trackerType
          ? {
            name: response.data.trackerType.name,
            id: response.data.trackerType.id,
          }
          : null,
        tracker_login: response.data.tracker_login,
        tracker_password: response.data.tracker_password,
        drivers: response.data.drivers.map(driver => ({
          value: driver.id,
          current: driver.pivot.current,
          social_name: driver.social_name,
          label: driver.label,
          cgccpf: driver.cgccpf,
        })),
        exclusive: response.data.exclusive === 1,
        implement: response.data?.implementType,
        implements: response.data.wagons.map(wagon => ({
          ...wagon,
          color: { label: wagon?.color, value: wagon?.color },
          province: wagon?.city?.province,
          brand: wagon.manufacturer?.id
            ? {
              id: wagon?.manufacturer?.id,
              name: wagon?.manufacturer?.name,
            }
            : null,
          model: wagon.model
            ? { id: wagon?.models?.id, label: wagon?.models?.name }
            : null,
          identifier: {
            id: wagon?.id,
            plate: wagon?.plate,
          },
        })),
        body: response.data.vehicleBodyType,
        vehicleManagement: response.data.vehicleManagement.length > 0  ? true : false
      };
      setVehicle(data);
      setPrevVehicle(data);
    } catch (ex) {
      snackbar.show(<Text>Não foi possível carregar esse veículo</Text>, {
        type: 'error',
      });
    } finally {
      setFetching(false);
    }
  }
  useEffect(() => {
    if (params.id) {
      fetchVehicle();
    }
  }, [params.id]);

  function setData(data) {
    setVehicle(prev => ({ ...prev, ...data }));
  }

  useEffect(() => {
    if (errors.drivers) {
      driversModal.open();
    }
  }, [errors]);

  useEffect(() => {
    if (!params.id && vehicle?.id) {
      createdModal.open();
    }
  }, [vehicle.id]);

  useEffect(() => {
    if (params.id) {
      dispatch({
        type: 'VIEW_INSTANCE',
        instance: vehicle?.plate,
      });
    }
  }, [vehicle]);

  return (
    <VehicleProvider
      value={{
        data: vehicle,
        setData,
        isFetching,
        errors,
        setErrors,
        showSnackbar,
        prevVehicle,
        setPrevVehicle,
        fetchVehicle,
      }}
    >
      <Modals.Drivers
        isOpen={driversModal.isOpen}
        onClose={driversModal.close}
      />
      <Modals.Created
        isOpen={createdModal.isOpen}
        onClose={createdModal.close}
      />
      {params.id ? <Details /> : <Register />}
    </VehicleProvider>
  );
}
