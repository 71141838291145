import React, { useCallback, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Text, Tooltip } from 'v3/components';
import Checkbox from 'components/CheckBox';
import { Deselect, SelectAll } from '@mui/icons-material';
import { Drawer } from '@mui/material';
import {
  AccordionDetailsContainer,
  DrawerContainer,
  DrawerHeader,
  ButtonDrawer,
} from './styles';
import { DEFAULT_COLUMNS, useFleetManagement } from '../shared';

function AccordionItem({ title, items, isExpanded, setNewColumns }) {
  const [expanded, setExpanded] = useState(false);

  const handleChangeAllGroup = bool => {
    setNewColumns(prev => {
      const newColumns = prev.map(column => {
        if (column.group === title) {
          return {
            ...column,
            items: column.items.map(item => ({
              ...item,
              selected: bool,
            })),
          };
        }
        return column;
      });
      return newColumns;
    });
  };

  const handleChangeCheckbox = (id, selected) => {
    setNewColumns(prev => {
      const newColumns = prev.map(column => {
        if (column.group === title) {
          return {
            ...column,
            items: column.items.map(item => {
              if (item.id === id) {
                return {
                  ...item,
                  selected: !selected,
                };
              }
              return item;
            }),
          };
        }
        return column;
      });
      return newColumns;
    });
  };

  return (
    <Accordion
      expanded={isExpanded || expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Text>{title}</Text>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionDetailsContainer>
          <DrawerHeader style={{ padding: 0, marginBottom: 16 }}>
            <ButtonDrawer
              variant="success"
              size="sm"
              onClick={() => handleChangeAllGroup(true)}
            >
              <Tooltip
                placement="bottom"
                content={
                  <Text>
                    Seleciona todas as colunas de {title.toLowerCase()}
                  </Text>
                }
              >
                <SelectAll />
              </Tooltip>
            </ButtonDrawer>
            <ButtonDrawer
              variant="error"
              size="sm"
              onClick={() => handleChangeAllGroup(false)}
            >
              <Tooltip
                placement="bottom"
                content={
                  <Text>Remove todas as colunas de {title.toLowerCase()}</Text>
                }
              >
                <Deselect />
              </Tooltip>
            </ButtonDrawer>
            <Text>
              Manipule todas as colunas que deseja exibir na tabela de {title}
            </Text>
          </DrawerHeader>
          {items
            .filter(item => {
              if (DEFAULT_COLUMNS.includes(item.slug)) return false;
              return item;
            })
            .map(({ name, selected, id }) => (
              <Checkbox
                key={`${id}-${name}`}
                checked={selected}
                onChange={() => handleChangeCheckbox(id, selected)}
                slug={name}
                id={name}
                label={name}
              />
            ))}
        </AccordionDetailsContainer>
      </AccordionDetails>
    </Accordion>
  );
}

function FilterColumns({ isExpanded, newColumns, setNewColumns }) {
  return (
    <div>
      {newColumns
        .sort((a, b) => a.group.localeCompare(b.group))
        .map(({ group, items, id }) => (
          <AccordionItem
            key={id}
            id={id}
            title={group}
            items={items.sort((a, b) => a.name.localeCompare(b.name))}
            isExpanded={isExpanded}
            setNewColumns={setNewColumns}
          />
        ))}
    </div>
  );
}

export default function DrawerColumns() {
  const { isFilterColumnsOpen, handleCloseFilterColumns, columns } =
    useFleetManagement();

  const [newColumns, setNewColumns] = useState(columns);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleAllColumns = useCallback(
    bool => {
      setIsExpanded(true);

      setNewColumns(prevState => {
        return prevState.map(column => {
          const newItems = column.items.map(item =>
            item.selected !== bool ? { ...item, selected: bool } : item
          );

          return column.items === newItems
            ? column
            : { ...column, items: newItems };
        });
      });
    },
    [setIsExpanded, setNewColumns]
  );

  return (
    <Drawer
      open={isFilterColumnsOpen}
      onClose={() => handleCloseFilterColumns(newColumns)}
      anchor="right"
    >
      <DrawerContainer>
        <DrawerHeader>
          <ButtonDrawer
            variant="success"
            size="sm"
            onClick={() => handleAllColumns(true)}
          >
            <Tooltip
              placement="bottom"
              content={
                <Text>
                  Seleciona todas as colunas disponíveis para visualização
                </Text>
              }
            >
              <SelectAll />
            </Tooltip>
          </ButtonDrawer>
          <ButtonDrawer
            variant="error"
            size="sm"
            onClick={() => handleAllColumns(false)}
          >
            <Tooltip
              placement="bottom"
              content={
                <Text>
                  Remove todas as colunas disponíveis para visualização
                </Text>
              }
            >
              <Deselect />
            </Tooltip>
          </ButtonDrawer>
          <Text>Manipule todas as colunas disponíveis para visualização</Text>
        </DrawerHeader>
        <FilterColumns
          newColumns={newColumns}
          setNewColumns={setNewColumns}
          isExpanded={isExpanded}
        />
      </DrawerContainer>
    </Drawer>
  );
}
