import { TablePagination } from '@mui/material';
import styled, { css } from 'styled-components';
import theme from 'v3/theme';

export const StyledTablePagination = styled(TablePagination)`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  p {
    margin: 0;
  }
`;

export const ErrorText = styled(Text)`
  margin-bottom: 5px;
  ${props =>
    props.color === 'error' &&
    css`
      color: #f64e60 !important;
    `};
`;

export const StyledControl = styled.div`
  align-items: center;
  background-color: #fff;
  border: 1px solid #e5eaee;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  font-family: Poppins;
  letter-spacing: 0.02em;
  min-height: 44px;
  font-size: 0.875rem;

  ${props =>
    props.children[0].props.isDisabled &&
    css`
      background-color: #e5eaee;
    `}

  ${props =>
    props.children[0].props.isMulti &&
    css`
      height: auto;
    `}

  ${({ error }) =>
    error &&
    css`
      border-color: #f64e60;
    `};

  @media (max-width: 768px) {
    height: 44px;
  }
`;

export const StyledValueContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 0.275rem 0.5rem;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  max-height: ${props =>
    props.selectValuesContainerMaxHeight
      ? props.selectValuesContainerMaxHeight
      : 'auto'};
  overflow-y: ${props =>
    props.selectValuesContainerOverflowY
      ? props.selectValuesContainerOverflowY
      : 'hidden'};
`;

export const StyledPlaceholder = styled.div`
  margin: 0 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: ${theme.colors.placeholder};
`;
