import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom';
import Select from 'v3/components/Select';
import { useVehicle } from '../context';
import { fetchOwners } from 'utils/fetches';

import Tooltip from 'v3/components/Tooltip'
import Text from 'v3/components/Text'
import { FaQuestionCircle } from 'react-icons/fa';
import theme from 'v3/theme/index';

export default function OwnerData() {
  const vehicle = useVehicle();
  const params = useParams();

  useEffect(() => {
    if (!params.id) {
      vehicle.setData({
        owner: null,
        owneOntt: null,
        beneficiary: null
      });
    }
  }, []);

  return (
    <Row className="form">
      <Col xs={12}>
        <Select.Async
          label={'Proprietário do documento *'}
          onSearch={fetchOwners}
          onChange={value => vehicle.setData({ owner: value })}
          value={vehicle.data.owner}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          error={vehicle.errors?.owner}
        />
      </Col>
      <Col xs={12} style={{ display: 'flex' }}>
        <Select.Async
          label={'Beneficiário do veículo*'}
          onSearch={fetchOwners}
          onChange={value => vehicle.setData({ beneficiary: value })}
          value={vehicle.data.beneficiary}
          isDisabled={vehicle.data.vehicleManagement}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          error={vehicle.errors?.beneficiary}
        />
        {vehicle?.data?.vehicleManagement && (
          <Tooltip
            content={
              <Text type="label" color="white">
                O veículo está cadastrado na gestão de otimização de frota e cargas. A edição de alguns campos está restrita ao seu operador.
              </Text>
            }
          >
            <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
          </Tooltip>
        )}
      </Col>
      <Col xs={12}>
        <Select.Async
          label={params.id ? 'Proprietário RNTRC*' : 'Proprietário RNTRC'}
          onSearch={fetchOwners}
          onChange={value => vehicle.setData({ anttOwner: value })}
          value={vehicle.data.anttOwner}
          getOptionLabel={option => option.label}
          getOptionValue={option => option.id}
          error={vehicle.errors?.anttOwner}
        />
      </Col>
    </Row>
  );
}
