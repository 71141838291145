import debounce from 'lodash.debounce';
import React, { useCallback, useMemo, useRef } from 'react';
import AsyncReactSelect from 'react-select/async';
import styled from 'styled-components';
import { components } from 'react-select';
import { Text } from 'v3/components';
import {
  ErrorText,
  StyledControl,
  StyledPlaceholder,
  StyledValueContainer,
} from './styles';

const StyledAsyncSelect = styled(AsyncReactSelect)`
  && {
    padding: 4px 8px;
  }
`;

function AsyncSelect({
  multiple,
  value,
  onChange,
  onSearch,
  getOptionLabel,
  getOptionValue,
  placeholder = 'Selecione...',
  style = {},
  error,
}) {
  const ref = useRef(null);
  const styledSelect = {
    singleValue: provided => ({
      ...provided,
      color: '#494950',
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    ...style,
  };

  const debouncedSearch = useRef(
    debounce((inputValue, callback) => {
      onSearch(inputValue).then(options => {
        callback(options);
      });
    }, 600)
  ).current;

  const handleSearch = useCallback(debouncedSearch, [debouncedSearch]);

  const customComponents = useMemo(
    () => ({
      Control: props => {
        const { innerRef, innerProps, children, selectProps } = props;
        const customStyle = selectProps.styles?.control?.({});

        return (
          <StyledControl
            ref={innerRef}
            {...innerProps}
            {...props}
            error={error}
            style={customStyle}
          >
            {children}
          </StyledControl>
        );
      },
      ValueContainer: ({ innerRef, innerProps, children }) => (
        <StyledValueContainer
          onClick={event => {
            event.preventDefault();
          }}
          ref={ref}
          innerRef={innerRef}
          {...innerProps}
        >
          {children}
        </StyledValueContainer>
      ),
      Placeholder: ({ innerRef, ...innerProps }) => (
        <StyledPlaceholder innerRef={innerRef} {...innerProps} />
      ),
      Menu: ({ innerRef, ...innerProps }) => (
        <components.Menu innerRef={innerRef} {...innerProps} />
      ),
    }),
    []
  );

  return (
    <>
      <StyledAsyncSelect
        isMulti={multiple}
        styles={styledSelect}
        value={value}
        onChange={onChange}
        loadOptions={handleSearch}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        components={customComponents}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => <Text>Nenhuma opção encontrada</Text>}
      />
      {error && (
        <ErrorText color="error" type="little">
          {error}
        </ErrorText>
      )}
    </>
  );
}

export default AsyncSelect;
