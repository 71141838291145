import React, { useEffect, useMemo, useState } from 'react';
import { Card, Input, Select, Text } from 'v3/components';
import { Col, Row } from 'react-bootstrap';
import { fetchRiskManagers } from 'utils/fetches';
import { TravelContext } from '../controller';
import { extractGroupOptions, processGroupOptionsData } from './utils';
import { fetchTrackers } from '../../shared/RiskManager/controller';

const [, useTravel] = TravelContext;

export function GROCard() {
  const travel = useTravel();
  const hasntMonitoringGroups =
    travel?.loadGRORangeData?.equipments_groups?.length === 0;
  const monitoringGroupOptionsTooltip = useMemo(() => {
    if (
      !travel.fetchingLoadGRORange &&
      travel.loadGRORangeData?.equipments_groups?.length > 0
    ) {
      return processGroupOptionsData(
        travel.loadGRORangeData?.equipments_groups
      );
    }
    if (hasntMonitoringGroups)
      return 'Apólice não possui informações de grupo de monitoramento';
    return '';
  }, [
    travel.loadGRORangeData,
    travel.fetchingLoadGRORange,
    hasntMonitoringGroups,
  ]);
  const riskManagerOptions = useMemo(() => {
    let managerQueryOptions = [];
    let managerTrackerOptions = [];
    if (
      !travel.fetchingLoadGRORange &&
      travel.loadGRORangeData?.groups?.length > 0
    ) {
      managerQueryOptions = extractGroupOptions(
        travel.loadGRORangeData?.groups,
        'manager_query'
      );
      managerTrackerOptions = extractGroupOptions(
        travel.loadGRORangeData?.groups,
        'manager_tracker'
      );
    }
    return { managerQueryOptions, managerTrackerOptions };
  }, [travel.loadGRORangeData, travel.fetchingLoadGRORange]);

  useEffect(() => {
    let newRiskManager = riskManagerOptions?.managerTrackerOptions?.length > 0 ? riskManagerOptions?.managerTrackerOptions[0] : undefined
    if (newRiskManager !== travel.data.riskManager) {
      travel.setData(old => ({
        ...old,
        riskManager: newRiskManager,
      }));
    }
  }, [riskManagerOptions])

  const [selectedMonitoringGroup, setSelectedMonitoringGroup] = useState(null);
  const monitoringGroupFields = useMemo(() => {
    let isTrackerRequired = false;
    let isTrackerModelRequired = false;
    let isImmobilizerRequired = false;
    let isLocatorRequired = false;
    let isBaitRequired = false;
    const baitAmount = selectedMonitoringGroup?.bait_quantity;

    if (selectedMonitoringGroup?.baits?.length > 0) {
      isBaitRequired = true;
    }
    if (selectedMonitoringGroup?.tecnologies?.length > 0) {
      isTrackerRequired = true;
    }
    if (selectedMonitoringGroup?.equipments?.length > 0) {
      isTrackerModelRequired = true;
    }
    if (selectedMonitoringGroup?.locators?.length > 0) {
      isLocatorRequired = true;
    }
    if (selectedMonitoringGroup?.immobilizers?.length > 0) {
      isImmobilizerRequired = true;
    }
    return {
      isTrackerRequired,
      isTrackerModelRequired,
      isImmobilizerRequired,
      isLocatorRequired,
      isBaitRequired,
      baitAmount,
    };
  }, [selectedMonitoringGroup]);

  useEffect(() => {
    let techTracker = null;
    let modelTracker = null
    
    if (!travel.data?.vehicle?.main) {
      return
    }

    if (monitoringGroupFields.isTrackerRequired) {
      techTracker = selectedMonitoringGroup?.tecnologies?.find(
        tech => tech.id === travel.data.vehicle.main.tracker_id
      )
    }
    if (monitoringGroupFields.isTrackerModelRequired) {
      modelTracker = selectedMonitoringGroup?.equipments?.find(equipament => equipament.id === travel.data.vehicle.main.tracker_type_id)
    }

    travel.setData(old => ({
      ...old,
      loadGROData: {
        ...old.loadGROData,
        modelTracker: modelTracker || null,
        techTracker: techTracker || null,
        trackerNumber: travel.data.vehicle.main.tracker_code,
      },
    }));

  }, [travel.data?.vehicle?.main, selectedMonitoringGroup, monitoringGroupFields])

  function handleChangeMonitoringGroup(value) {
    setSelectedMonitoringGroup(value);
    travel.setData(old => ({
      ...old,
      loadGROData: {
        ...old.loadGROData,
        techTracker: null,
        modelTracker: null,
        trackerNumber: null,
        immobilizer: null,
        immobilizerNumber: null,
        locator: null,
        locatorNumber: null,
        bait1: null,
        bait2: null,
        bait3: null,
        bait4: null,
        groupEquipment: value,
      },
    }));
  }

  return (
    <Card
      header={
        <Text color="dark" type="header">
          Gerenciamento de Risco
        </Text>
      }
    >
      {travel.isGROLoad?.id ? (
        <Row>
          <Col md={6} sm={12} className="my-2">
            <Select
              label={
                riskManagerOptions?.managerQueryOptions?.length > 0
                  ? 'Gerenciadora cadastro/consulta *'
                  : 'Gerenciadora cadastro/consulta'
              }
              tooltip={
                riskManagerOptions?.managerQueryOptions?.length > 0
                  ? ''
                  : 'Apólice não contempla gerenciadora para pesquisa/consulta'
              }
              value={travel.data?.loadGROData?.riskManagerSearch}
              onChange={value =>
                travel.setData(old => ({
                  ...old,
                  loadGROData: {
                    ...old.loadGROData,
                    riskManagerSearch: value,
                  },
                }))
              }
              options={riskManagerOptions?.managerQueryOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              isDisabled={riskManagerOptions?.managerQueryOptions?.length === 0}
              error={
                travel.errors?.riskManagerSearch ||
                travel.errors?.['riskManagerSearch.id']
              }
            />
          </Col>
          <Col md={6} sm={12} className="my-2">
            <Select
              label={'Gerenciadora para rastreamento'}
              tooltip={
                riskManagerOptions?.managerTrackerOptions?.length > 0
                  ? ''
                  : 'Apólice não contempla gerenciadora para rastreamento'
              }
              value={travel.data?.riskManager}
              options={riskManagerOptions?.managerTrackerOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              error={
                travel.errors?.riskManager || travel.errors?.['riskManager.id']
              }
              isDisabled
            />
          </Col>
          <Col md={12} className="my-2">
            <Select
              label="Grupo de monitoramento"
              tooltip={monitoringGroupOptionsTooltip}
              tooltipPlacement="auto"
              toolTipWhiteSpace="pre-wrap"
              value={selectedMonitoringGroup}
              options={travel.loadGRORangeData?.equipments_groups}
              onChange={value => handleChangeMonitoringGroup(value)}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              isDisabled={hasntMonitoringGroups}
              error={travel.errors.monitoringGroups}
            />
          </Col>
          <Col md={12} className="my-2">
            <Select
              label={
                monitoringGroupFields?.isTrackerRequired
                  ? 'Tecnologia rastreador *'
                  : 'Tecnologia rastreador'
              }
              tooltip={
                hasntMonitoringGroups
                  ? 'Tecnologia depende da existência de grupo de monitoramento'
                  : monitoringGroupFields?.isTrackerRequired
                  ? 'Selecione um grupo de monitoramento para liberar opções'
                  : 'Obrigatoriedade depende do grupo de monitoramento'
              }
              value={travel.data?.loadGROData?.techTracker}
              onChange={value => {
                if (!value) {
                  travel.setData(old => ({
                    ...old,
                    loadGROData: {
                      ...old.loadGROData,
                      modelTracker: null,
                      trackerNumber: '',
                    },
                  }));
                }
                travel.setData(old => ({
                  ...old,
                  loadGROData: {
                    ...old.loadGROData,
                    techTracker: value,
                  },
                }));
              }}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              options={selectedMonitoringGroup?.tecnologies}
              isDisabled={!monitoringGroupFields?.isTrackerRequired}
              error={travel.errors.techTracker}
            />
          </Col>
          <Col md={6} sm={12} className="my-2">
            <Select
              label={'Modelo do rastreador'}
              tooltip={
                hasntMonitoringGroups
                  ? 'Modelo depende da existência de grupo de monitoramento'
                  : monitoringGroupFields?.isTrackerModelRequired &&
                    !travel.data?.loadGROData?.techTracker?.id
                  ? 'Selecione uma tecnologia para liberar as opções'
                  : 'Obrigatoriedade depende do grupo de monitoramento e tecnologia selecionada'
              }
              value={travel.data?.loadGROData?.modelTracker}
              onChange={value => {
                if (!value) {
                  travel.setData(old => ({
                    ...old,
                    loadGROData: {
                      ...old.loadGROData,
                      trackerNumber: '',
                    },
                  }));
                }
                travel.setData(old => ({
                  ...old,
                  loadGROData: {
                    ...old.loadGROData,
                    modelTracker: value,
                  },
                }));
              }}
              options={selectedMonitoringGroup?.equipments?.filter(
                option =>
                  option?.tracker_id ===
                  travel.data?.loadGROData?.techTracker?.id
              )}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              isDisabled={
                !travel.data?.loadGROData?.techTracker?.id ||
                !monitoringGroupFields?.isTrackerModelRequired
              }
              error={travel.errors.modelTracker}
            />
          </Col>

          <Col md={6} sm={12} className="my-2">
            <Input
              label={'Número do rastreador'}
              tooltip={
                hasntMonitoringGroups
                  ? 'Número depende da existência de grupo de monitoramento'
                  : monitoringGroupFields?.isTrackerModelRequired &&
                    !travel.data?.loadGROData?.modelTracker?.id
                  ? 'Informe um modelo para liberar campo'
                  : 'Obrigatoriedade depende do grupo de monitoramento e modelo selecionados'
              }
              value={travel.data?.loadGROData?.trackerNumber}
              onChange={e =>
                travel.setData(old => ({
                  ...old,
                  loadGROData: {
                    ...old.loadGROData,
                    trackerNumber: e?.target?.value,
                  },
                }))
              }
              error={travel.errors.trackerNumber}
              disabled={!travel.data?.loadGROData?.modelTracker?.id}
            />
          </Col>
          <Col md={6} sm={12} className="my-2">
            <Select
              label={
                monitoringGroupFields?.isLocatorRequired
                  ? 'Modelo localizador *'
                  : 'Modelo localizador'
              }
              tooltip={
                hasntMonitoringGroups
                  ? 'Modelo localizador depende da existência de grupo de monitoramento'
                  : monitoringGroupFields?.isLocatorRequired
                  ? 'Selecione um grupo de monitoramento para liberar opções'
                  : 'Obrigatoriedade depende do grupo de monitoramento'
              }
              value={travel.data?.loadGROData?.locator}
              onChange={value => {
                if (!value) {
                  travel.setData(old => ({
                    ...old,
                    loadGROData: {
                      ...old.loadGROData,
                      locatorNumber: '',
                    },
                  }));
                }
                travel.setData(old => ({
                  ...old,
                  loadGROData: {
                    ...old.loadGROData,
                    locator: value,
                  },
                }));
              }}
              options={selectedMonitoringGroup?.locators}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              error={travel.errors.locator}
              isDisabled={!monitoringGroupFields?.isLocatorRequired}
            />
          </Col>
          <Col md={6} sm={12} className="my-2">
            <Input
              label={'Número do localizador'}
              tooltip={
                hasntMonitoringGroups
                  ? 'Número depende da existência de grupo de monitoramento'
                  : monitoringGroupFields?.isLocatorRequired &&
                    !travel.data?.loadGROData?.locator?.id
                  ? 'Selecione um localizador para liberar campo'
                  : 'Obrigatoriedade depende do grupo de monitoramento e localizador selecionados'
              }
              value={travel.data?.loadGROData?.locatorNumber}
              onChange={e =>
                travel.setData(old => ({
                  ...old,
                  loadGROData: {
                    ...old.loadGROData,
                    locatorNumber: e?.target?.value,
                  },
                }))
              }
              error={travel.errors.locatorNumber}
              disabled={!travel.data?.loadGROData?.locator?.id}
            />
          </Col>
          <Col md={6} sm={12} className="my-2">
            <Select
              label={
                monitoringGroupFields?.isImmobilizerRequired
                  ? 'Modelo imobilizador *'
                  : 'Modelo imobilizador'
              }
              tooltip={
                hasntMonitoringGroups
                  ? 'Imobilizador depende da existência de grupo de monitoramento'
                  : monitoringGroupFields?.isImmobilizerRequired
                  ? 'Selecione um grupo de monitoramento para liberar opções'
                  : 'Obrigatoriedade depende do grupo de monitoramento'
              }
              value={travel.data?.loadGROData?.immobilizer}
              onChange={value => {
                if (!value) {
                  travel.setData(old => ({
                    ...old,
                    loadGROData: {
                      ...old.loadGROData,
                      immobilizerNumber: '',
                    },
                  }));
                }
                travel.setData(old => ({
                  ...old,
                  loadGROData: {
                    ...old.loadGROData,
                    immobilizer: value,
                  },
                }));
              }}
              options={selectedMonitoringGroup?.immobilizers}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              isDisabled={!monitoringGroupFields?.isImmobilizerRequired}
              error={travel.errors.immobilizer}
            />
          </Col>
          <Col md={6} sm={12} className="my-2">
            <Input
              label={'Número do imobilizador'}
              tooltip={
                hasntMonitoringGroups
                  ? 'Número depende da existência de grupo de monitoramento'
                  : monitoringGroupFields?.isImmobilizerRequired &&
                    !travel.data?.loadGROData?.immobilizer?.id
                  ? 'Selecione um imobilizador para liberar campo'
                  : 'Obrigatoriedade depende do grupo de monitoramento e imobilizador selecionados'
              }
              value={travel.data?.loadGROData?.immobilizerNumber}
              onChange={e =>
                travel.setData(old => ({
                  ...old,
                  loadGROData: {
                    ...old.loadGROData,
                    immobilizerNumber: e?.target?.value,
                  },
                }))
              }
              error={travel.errors.immobilizerNumber}
              disabled={!travel.data?.loadGROData?.immobilizer?.id}
            />
          </Col>
          <Col
            xs={12}
            className="d-flex justify-content-center align-items-center"
          >
            {travel.errors?.baits || travel.errors?.baitsNumber ? (
              <Text color="danger" weight={500}>
                {travel.errors?.baits || travel.errors?.baitsNumber}
              </Text>
            ) : null}
          </Col>
          {Array.from({ length: monitoringGroupFields?.baitAmount }).map(
            (_, index) => (
              <>
                <Col md={6} sm={12} className="my-2">
                  <Select
                    label={`Isca ${index + 1} *`}
                    tooltip={
                      monitoringGroupFields?.isBaitRequired
                        ? 'Selecione um grupo de monitoramento para liberar opções'
                        : 'Obrigatoriedade depende do grupo de monitoramento'
                    }
                    value={travel.data?.loadGROData?.[`bait${index + 1}`]}
                    onChange={value => {
                      if (!value) {
                        travel.setData(old => ({
                          ...old,
                          loadGROData: {
                            ...old.loadGROData,
                            [`baitNumber${index + 1}`]: '',
                          },
                        }));
                      }
                      travel.setData(old => ({
                        ...old,
                        loadGROData: {
                          ...old.loadGROData,
                          [`bait${index + 1}`]: value,
                        },
                      }));
                    }}
                    options={selectedMonitoringGroup?.baits}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    isDisabled={!monitoringGroupFields?.isBaitRequired}
                    error={travel.errors[`baits[${index}]`]}
                  />
                </Col>
                <Col md={6} sm={12} className="my-2">
                  <Input
                    label={`Número da isca ${index + 1}`}
                    tooltip={
                      monitoringGroupFields?.isBaitRequired &&
                      !travel.data?.loadGROData?.[`bait${index + 1}`]?.id
                        ? 'Selecione um isca para liberar campo'
                        : 'Obrigatoriedade depende do grupo de monitoramento e isca selecionada'
                    }
                    value={travel.data?.loadGROData?.[`baitNumber${index + 1}`]}
                    onChange={e =>
                      travel.setData(old => ({
                        ...old,
                        loadGROData: {
                          ...old.loadGROData,
                          [`baitNumber${index + 1}`]: e?.target?.value,
                        },
                      }))
                    }
                    error={travel.errors[`baitsNumber[${index}]`]}
                    disabled={
                      !travel.data?.loadGROData?.[`bait${index + 1}`]?.id
                    }
                  />
                </Col>
              </>
            )
          )}
        </Row>
      ) : (
        <Row>
          <Col md={6} sm={12} className="my-2">
            <Select.Async
              label="Gerenciadora para rastreamento"
              value={travel.data?.riskManager}
              onSearch={fetchRiskManagers}
              onChange={value =>
                travel.setData(old => ({ ...old, riskManager: value }))
              }
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              error={
                travel.errors?.riskManager ||
                travel.errors?.riskManager?.id ||
                travel?.errors?.['riskManager.id']
              }
            />
          </Col>
          <Col md={6} sm={12} className="my-2">
            <Select.Async
              label="Tecnologia rastreador"
              value={travel.data?.tracker}
              onSearch={fetchTrackers}
              onChange={value =>
                travel.setData(old => ({ ...old, tracker: value }))
              }
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
            />
          </Col>
        </Row>
      )}
    </Card>
  );
}
