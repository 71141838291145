import React from 'react';
import { useFleetManagement } from '../../shared';
import { HeadingContainer, StyledModal } from './styles';
import TravelOutside from './TravelOutside';
import { Text } from 'v3/components';

const OutsideTravelModal = () => {

  const {
    outsideTravelModal: modalHook,
    setSelected
  } = useFleetManagement();

  const handleClose = () => {
    setSelected(null);
    modalHook.close();
  };

  return (
    <StyledModal
      show={modalHook.isOpen}
      body={<TravelOutside />}
      heading={
        <HeadingContainer>
          <Text type="header">Viagens externas</Text>
        </HeadingContainer>
      }
      handleClose={handleClose}
    />
  )
}

export default OutsideTravelModal;
