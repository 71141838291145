import React, { useState, useContext, useEffect } from 'react';
import api, { cooperplaceApi } from 'services/api';

import { Row } from 'react-bootstrap';
import Vehicle from "../Vehicle"
import Driver from "../Driver"
import ShippingCompany from '../ShippingCompany';

import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';

import { APPROVED_GRO_ANALYSIS_SLUG } from 'v3/utils/constants';

import { useModal } from 'hooks';
import { useTravel } from 'v3/pages/Travel';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { useSnackbar } from 'v3/components/Snackbar';

import Modal from 'v3/components/Modal';
import ConflictDriverModal from '../Modal/ConflictDriver';
import { MinimumMarginModal } from '..';
import ModalVehicle from '../Driver/Vehicle/';

import { canEditTravel } from 'v3/utils/permissions';

import { Divider } from '@mui/material';

function VehicleAndDriver() {

  const snackbar = useSnackbar();
  const travel = useTravel();
  const lowerMarginModal = useModal();
  const conflictDriverModal = useModal();

  const {
    isTravelBlocked,
    isFetchingBenner,
    isFetchingMargin,
  } = useContext(BlockedTravelContext);

  const [errorsDriver, setErrorsDriver] = useState('');
  const [errorsVehicle, setErrorsVehicle] = useState('');
  const [errorsShipping, setErrorsShipping] = useState('');
  const [vehicles, setVehicles] = useState();
  const [driver, setDriver] = useState();
  const [shippingCompany, setShippingCompany] = useState();
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [modalVehicle, setModalVehicle] = useState(false);
  const [hasPermissionToEdit, setPermissionToEdit] = useState();

  const [validateDriverProps, setValidateDriverProps] = useState({
    conflictTravels: [],
    driver: {},
  });

  useEffect(() => {
    setPermissionToEdit(canEditTravel(travel.data));
  }, [travel.data]);


  const [plates, setPlates] = useState({
    plate1: {},
    plate2: {},
    plate3: {},
    plate4: {},
  });

  async function fetchVehicle(driver_id) {
    try {
      const vehicle = await api.get(`drivers/vehicle/?driverId=${driver_id}`);
      setVehicles(vehicle.data.vehicle);
    } catch (error) {
      // Handle exeception
    }
  }


  function onUpdateDriver(driver) {
    if (driver) {
      fetchVehicle(driver.id);
      setModalVehicle(true);
    }
  }

  function handleConfirmLowerMargin(value) {
    handleSubmit(value);
  }

  const validInputs = (plate, driver, shipping) => {

    if (!plate && !driver) {
      setLoading(false);
      setErrorsVehicle('Insira uma placa');
      setErrorsDriver('Insira um motorista');
      return;
    }

    if (!plate) {
      setLoading(false);
      setErrorsVehicle('Insira uma placa');
      return;
    }

    if (!driver) {
      setErrorsDriver('Insira um motorista');
      return;
    }


    if (!shipping) {
      setErrorsShipping('Insira uma transportadora')
      return
    }

  }

  async function handleSubmit(submitOptions) {
    setLoading(true);
    validInputs(plates?.plate1, driver, shippingCompany);

    if (!submitOptions?.confirmMinimiumMargin) {
      const isMarginAbove = await travel.isMarginAbove({
        mainVehicle: plates.plate1,
      });

      if (isMarginAbove) {
        lowerMarginModal.open();
        return;
      }
    }

    try {

      const destination =
        travel?.data?.loads?.loadDestinations[
        travel?.data?.loads?.loadDestinations?.length - 1
        ];

      const payload = {
        driverId: driver.id,
        destinationScheduledTime: destination?.scheduledTime,
        originScheduledTime: travel?.data?.loads?.loadOrigins[0]?.startSchedule,
        travelId: travel?.data?.id,
        passValidation: Boolean(submitOptions?.passDriverValidation),
        vehicleId: plates.plate1?.id,
        shippingCompanyId: shippingCompany?.id,
        margin: travel?.data?.margin,
        wagonIds: [
          plates?.plate2?.id,
          plates?.plate3?.id,
          plates?.plate4?.id,
        ].filter(Boolean)
      };

      setValidateDriverProps({
        conflictTravels: [],
        driver: {},
      });

      const {
        data: { conflictTravels, success },
      } = await cooperplaceApi.put(`travels/${travel.data.id}/updateVehicleAndDriver`, payload);

      if (success && conflictTravels.length === 0) {
        snackbar.show(
          <Text className="text-center">Dados da viagem alterados !</Text>,
          {
            type: 'success',
            duration: 5000,
          }
        );
        travel.fetchTravel();
        setEditing(false);
        setErrorsDriver('');
        setErrorsVehicle('');
        return;
      }

      if (success && conflictTravels.length === 1) {
        setValidateDriverProps(old => ({ ...old, conflictTravels, driver }));
        conflictDriverModal.open();
        return;
      }

      if (success && conflictTravels.length >= 2) {
        const errorMessage = `O motorista está vinculado nas seguintes viagens nesse período: "${conflictTravels.join(
          ', '
        )}"`;

        setErrorsDriver(errorMessage);

        snackbar.show(<Text className="text-center">{errorMessage}</Text>, {
          type: 'error',
          duration: 5000,
        });
      }
    } catch (err) {
      const message = err?.response?.data?.message
        ? err?.response?.data?.message
        : err.response?.data?.error
          ? err.response?.data?.error
          : '';

      if (message.slice(0, 22) === 'FLEET_MANAGEMENT_ERROR') {

        const formatMessage = message.replace(/^FLEET_MANAGEMENT_ERROR/, '')

        snackbar.show(`Erro ao editar a viagem! ${formatMessage}`, {
          type: 'error',
          duration: 20000
        });
      } else {
        snackbar.show(`Erro ao editar viagem! ${message}`, {
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  }

  function onUpdateVehicle(vehicle) {
    setModalVehicle(false);
    const data = {
      plate1: vehicle.vehicle || {},
      plate2: {},
      plate3: {},
      plate4: {}
    }

    if (vehicle?.vehicle?.wagons?.length > 0) {
      data.plate2 = vehicle.vehicle?.wagons[0] || {}
      data.plate3 = vehicle.vehicle?.wagons[1] || {}
      data.plate4 = vehicle.vehicle?.wagons[2] || {}
    }

    setPlates(data)
  }

  return (
    <Card
      loading={travel.isLoading}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={3} perLine={2} />
      )}
      header={
        <Text weight={500} type="header">
          Operadores e Veículos
        </Text>
      }
      HeaderRightComponent={
        hasPermissionToEdit && (
          <Row className="form">
            {editing && (
              <Button
                variant="secondary"
                loading={loading}
                className="mr-2"
                onClick={() => {
                  setEditing(false);
                  setErrorsDriver('');
                  setErrorsVehicle('')
                }}
              >
                <Text color="dark" type="regular" weight="500">
                  Cancelar
                </Text>
              </Button>
            )}
            <Button
              onClick={
                editing ? () => handleSubmit() : () => setEditing(true)
              }
              variant={editing ? 'primary' : 'secondary'}
              loading={loading || isFetchingBenner || isFetchingMargin}
              className="py-2"
              disabled={
                isTravelBlocked ||
                travel?.isDisabledGROAnalysis ||
                travel?.groAnalysisStatus === APPROVED_GRO_ANALYSIS_SLUG
              }
              title={
                travel?.isDisabledGROAnalysis
                  ? 'Não é possível edição durante período de análise GRO'
                  : travel?.groAnalysisStatus === APPROVED_GRO_ANALYSIS_SLUG
                    ? 'Não é possível edição após aprovação do GRO'
                    : ''
              }
            >
              <Text
                color={editing ? 'white' : 'dark'}
                type="regular"
                weight="500"
              >
                {editing ? 'Salvar' : 'Editar'}
              </Text>
            </Button>
          </Row>
        )
      }>

      <ShippingCompany
        editing={editing}
        errors={errorsShipping}
        shippingCompany={shippingCompany}
        setShippingCompany={setShippingCompany}
      />
      <Divider />

      <Driver
        errors={errorsDriver}
        setDriver={setDriver}
        driver={driver}
        onUpdateDriver={onUpdateDriver}
        setVehicles={setVehicles}
        editing={editing} />

      <Divider />

      <Vehicle
        setPlates={setPlates}
        shippingCompany={shippingCompany}
        plates={plates}
        editing={editing}
        errors={errorsVehicle}
        setErrors={setErrorsVehicle}
      />

      <MinimumMarginModal
        isOpen={lowerMarginModal.isOpen}
        onClose={lowerMarginModal.close}
        confirm={handleConfirmLowerMargin}
      />

      {validateDriverProps?.conflictTravels.length > 0 && (
        <ConflictDriverModal
          isOpen={conflictDriverModal.isOpen}
          onClose={conflictDriverModal.close}
          onConfirm={handleSubmit}
          travels={validateDriverProps?.conflictTravels}
          travelData={{ driver: validateDriverProps.driver }}
        />
      )}
      <Modal
        size="md"
        heading="Veículo"
        centered
        scrollable
        show={modalVehicle}
        onHide={() => setModalVehicle(false)}
        body={
          <ModalVehicle
            vehicles={vehicles}
            driver={driver}
            onUpdateVehicle={onUpdateVehicle}
            onHide={() => setModalVehicle(false)}
          />
        }
      />
    </Card>

  )
}

export default VehicleAndDriver
