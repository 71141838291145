import React, { useEffect, useMemo, useState } from 'react';

import theme from 'v3/theme';
import { FaBolt } from 'react-icons/fa';
import { ProgressBarContext } from './context';

import * as S from './styles';
import Text from '../Text';

function Bar({ title, percentage, ...props }) {
  const color = useMemo(() => {
    if (percentage === 100) {
      return theme.colors.success;
    }
    return theme.colors.warning;
  }, [percentage]);
  return (
    <div key={props.key}>
      <div className="d-flex justify-content-between mb-1">
        <Text fontSize="12px">{title}</Text>
        <Text fontSize="12px" weight="bold">
          {percentage}%
        </Text>
      </div>
      <S.Bar
        {...props}
        style={{
          ...props.style,
        }}
      >
        <S.InProgressBar
          style={{
            backgroundColor: color,
            width: `${percentage}%`,
          }}
        />
      </S.Bar>
    </div>
  );
}

export function ProgressBarProvider({ children }) {
  const [hidden, setHidden] = useState(true);
  const [processes, setProcesses] = useState([]);

  function setProgressBar(name, title, percentage) {
    const progress = processes.find(p => p.name === name);
    if (progress) {
      progress.percentage = percentage;
    } else {
      processes.push({ name, title, percentage });
    }

    setProcesses([...processes]);
  }

  function removeProgressBar(name) {
    const index = processes.findIndex(p => p.name === name);
    if (index !== -1) {
      processes.splice(index, 1);
      setProcesses([...processes]);
    }
  }

  function onClose() {
    setHidden(true);
  }

  useEffect(() => {
    if (processes.length === 0) {
      setHidden(true);
    }
  }, [processes]);

  return (
    <ProgressBarContext
      value={{
        processes,
        setProgressBar,
        removeProgressBar,
      }}
    >
      {children}
      {processes.length > 0 && (
        <>
          {hidden ? (
            <S.ProgressBarButton type="button" onClick={() => setHidden(false)}>
              <S.ProgressBarTotal>{processes.length}</S.ProgressBarTotal>
              <FaBolt color={theme.colors.primary} />
            </S.ProgressBarButton>
          ) : (
            <ProgressBarBox processes={processes} onClose={onClose} />
          )}
        </>
      )}
    </ProgressBarContext>
  );
}

export function ProgressBarBox({ processes, onClose }) {
  return (
    <S.Container>
      <div className="d-flex justify-content-between align-items-center">
        <Text weight={500}>Processos em andamento</Text>
        <S.CloseButton type="button" onClick={onClose}>
          &times;
        </S.CloseButton>
      </div>
      <hr className="mb-4" />
      {processes.map(({ name, percentage, title }) => (
        <Bar key={name} title={title} percentage={percentage} />
      ))}
    </S.Container>
  );
}
