import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';
import api from 'services/api';
import { useValidation } from 'hooks';
import Radio from 'v3/components/Radio';
import { useSnackbar } from 'v3/components/Snackbar';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Input from 'v3/components/Input';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Badge from 'v3/components/Badge';

import {
  fetchVehiclesTypes,
  fetchBodies,
  fetchAnttTypes,
  fetchOnlyCities,
  fetchImplements,
  fetchOwners,
  fetchTrackerTypes,
  fetchTrackers,
} from 'utils/fetches';
import { DefaultLoadingComponent } from 'v3/components/List';
import { TravelContext } from '../../controller';

import { vehicleRegistrationSchema } from '../../validator';

const [, useTravel] = TravelContext;

function RegisterVehicle({ isOpen, onClose }) {
  const travel = useTravel();

  const [isTracked, setIsTracked] = useState(null);
  const [plate, setPlate] = useState('');
  const [city, setCity] = useState(null);
  const [yearManufacture, setYearManufacture] = useState(null);
  const [yearModel, setYearModel] = useState(null);
  const [chassi, setChassi] = useState('');
  const [vehicleType, setVehicleType] = useState(null);
  const [vehicleBodyType, setVehicleBodyType] = useState(null);
  const [vehicleImplementType, setVehicleImplementType] = useState(null);
  const [anttType, setAnttType] = useState(null);
  const [antt, setAntt] = useState('');
  const [ownerData, setOwnerData] = useState(null);
  const [beneficiary, setBeneficiary] = useState(null);
  const [anttAdherence, setAnttAdherence] = useState(null);
  const [renavam, setRenavam] = useState('');
  const [trackerCode, setTrackerCode] = useState(null);
  const [trackers, setTrackers] = useState([]);
  const [trackerType, setTrackerType] = useState(null);
  const [trackerTypes, setTrackerTypes] = useState(null);
  const [tracker, setTracker] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [vehicleTypesOptions, setVehicleTypesOptions] = useState(null);
  const [vehicleBodyTypesOptions, setVehicleBodyTypesOptions] = useState(null);
  const [vehicleImplementTypesOptions, setVehicleImplementTypesOptions] =
    useState(null);
  const [anttTypesOptions, setAnttTypesOptions] = useState(null);
  const snackbar = useSnackbar();
  const [errors, setErrors] = useState({});

  const [validationErrors, { validate }] = useValidation(
    vehicleRegistrationSchema
  );

  const shouldDisplayImplement = vehicleType?.qty_plates > 1 ?? false;

  useEffect(() => {
    setErrors(validationErrors);
  }, [validationErrors]);

  useEffect(() => {
    if (isOpen) fetchOptions();
  }, [isOpen]);

  async function fetchOptions() {
    try {
      setLoadingOptions(true);
      let [
        vehicleTypesData,
        vehiclesBodyTypesData,
        implementsData,
        trackersData,
        anttTypesData,
      ] = await Promise.all([
        fetchVehiclesTypes(),
        fetchBodies(),
        fetchImplements(),
        fetchTrackers(),
        fetchAnttTypes(),
      ]);
      vehicleTypesData = vehicleTypesData.filter(
        vtype => vtype.name !== 'Dolly' && vtype.name !== 'Semi Reboque'
      );
      setVehicleTypesOptions(vehicleTypesData);
      setVehicleBodyTypesOptions(vehiclesBodyTypesData);
      implementsData = implementsData.filter(vtype => vtype.name !== 'Dolly');
      setVehicleImplementTypesOptions(implementsData);
      setTrackers(trackersData);
      setAnttTypesOptions(anttTypesData);
    } catch (error) {
      //
    } finally {
      setLoadingOptions(false);
    }
  }

  function clearStates() {
    setPlate('');
    setCity(null);
    setYearManufacture(null);
    setYearModel(null);
    setChassi(null);
    setVehicleType(null);
    setVehicleBodyType(null);
    setVehicleImplementType(null);
    setAnttType(null);
    setAntt(null);
    setOwnerData(null);
    setBeneficiary(null)
    setAnttAdherence(null);
    setRenavam(null);
    setTrackers(null);
    setTracker(null);
    setTrackerTypes(null);
    setIsTracked(null);
    setTrackerCode('');
    setTrackerType();
  }

  async function handleCreation() {
    const payload = {
      plate,
      owner_id: ownerData?.id,
      antt,
      antt_adherence: anttAdherence,
      antt_type: anttType?.id,
      chassi,
      renavam,
      plate_city: city?.name,
      plate_state: city?.province?.uf,
      plate_city_id: city?.id,
      tracked: isTracked,
      tracker_id: tracker?.id,
      tracker_type_id: trackerType?.id,
      tracker_code: trackerCode,
      year_manufacture: yearManufacture,
      year_model: yearModel,
      vehicle_type_id: vehicleType?.id,
      vehicle_type_name: vehicleType?.name,
      vehicle_body_type_id: vehicleBodyType?.id,
      vehicle_implement_type: vehicleImplementType?.id,
      beneficiary_id: beneficiary?.id
    };
    const [isValid] = await validate(payload);
    if (!isValid) {
      snackbar.show(<Text>Erro ao cadastrar veículo</Text>, {
        type: 'error',
      });
      return false;
    }

    delete payload.vehicle_type_name;

    try {
      setLoading(true);
      const response = await api.post('vehicles', payload);
      snackbar.show(<Text>Cadastro realizado com sucesso</Text>, {
        type: 'success',
      });
      clearStates();
      travel.setData(old => ({
        ...old,
        vehicle: {
          ...old.vehicle,
          main: response.data?.vehicle,
        },
      }));
      setLoading(false);

      onClose();
    } catch (err) {
      snackbar.show(<Text>Erro ao cadastrar veículo</Text>, {
        type: 'error',
      });
      const errorsNew = err.response?.data;
      if (errorsNew && Array.isArray(errorsNew)) {
        setErrors(old => ({
          ...old,
          ...Object.fromEntries(
            errorsNew.map(error => [error.field, error.message])
          ),
        }));
      }

      setLoading(false);
      return false;
    }
    return true;
  }

  useEffect(() => {
    async function fetchTopTrackerTypes(track) {
      try {
        const response = await api.get('select/tracker-types', {
          params: {
            tracker: track.id,
          },
        });
        setTrackerTypes(response.data);
      } catch (error) {
        // Ignore exception
      }
    }
    if (tracker) {
      fetchTopTrackerTypes(tracker);
    }
  }, [tracker]);

  useEffect(() => {
    if (!isTracked) {
      setTrackerCode('');
      setTracker();
      setTrackerType();
    }
  }, [isTracked]);

  return (
    <>
      <Modal
        size="md"
        show={isOpen}
        onHide={onClose}
        heading={
          <Text type="header" color="dark" weight="500">
            Cadastrar veículo
          </Text>
        }
        body={
          loadingOptions ? (
            <DefaultLoadingComponent />
          ) : (
            <Row>
              <Col md={4} xs={12} className="mb-3">
                <Input
                  label="Placa *"
                  value={plate}
                  onChange={event =>
                    event.target.value.length > 7
                      ? snackbar.show(
                        <Text> Limite de dígitos para Placa </Text>,
                        {
                          type: 'error',
                        }
                      )
                      : setPlate(event.target.value.toUpperCase())
                  }
                  error={errors?.plate}
                  id="input-novo-veiculo-modal-placa"
                />
              </Col>
              <Col md={4} xs={12} className="mb-3">
                <Select.Async
                  label="Cidade *"
                  onSearch={fetchOnlyCities}
                  value={city}
                  getOptionLabel={option =>
                    `${option?.name}, ${option?.province?.uf ?? option?.uf}`
                  }
                  getOptionValue={option => option.id}
                  onChange={value => {
                    setCity(value);
                  }}
                  error={errors?.plate_city_id}
                  id="select-novo-veiculo-modal-cidade"
                />
              </Col>
              <Col md={4} xs={12} className="mb-3">
                <Input
                  label="Ano de fabricação *"
                  type="number"
                  value={yearManufacture}
                  onChange={event =>
                    event.target.value.length > 4
                      ? snackbar.show(
                        <Text> Limite de dígitos para Ano </Text>,
                        {
                          type: 'error',
                        }
                      )
                      : setYearManufacture(event.target.value)
                  }
                  error={errors?.year_manufacture}
                  id="input-novo-veiculo-modal-ano-fabricacao"
                />
              </Col>
              <Col md={4} xs={12} className="mb-3">
                <Input
                  label="Ano modelo *"
                  type="number"
                  value={yearModel}
                  onChange={event =>
                    event.target.value.length > 4
                      ? snackbar.show(
                        <Text> Limite de dígitos para Ano </Text>,
                        {
                          type: 'error',
                        }
                      )
                      : setYearModel(event.target.value)
                  }
                  error={errors?.year_model}
                  id="input-novo-veiculo-modal-ano-modelo"
                />
              </Col>
              <Col md={4} xs={12} className="mb-3">
                <Input
                  label="Chassi *"
                  value={chassi}
                  onChange={event =>
                    event.target.value.length > 20
                      ? snackbar.show(
                        <Text> Limite de dígitos para Chassi </Text>,
                        {
                          type: 'error',
                        }
                      )
                      : setChassi(event.target.value)
                  }
                  error={errors?.chassi}
                  id="input-novo-veiculo-modal-chassi"
                />
              </Col>
              <Col md={4} xs={12} className="mb-3">
                <Select
                  label="Tipo do veículo *"
                  options={vehicleTypesOptions}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  value={vehicleType}
                  onChange={value => {
                    setVehicleType(value);
                    setErrors(old => ({
                      ...old,
                      vehicle_implement_type: null,
                    }));
                  }}
                  error={errors?.vehicle_type_id}
                  id="select-novo-veiculo-modal-tipo-veiculo"
                />
              </Col>
              {shouldDisplayImplement && (
                <Col md={4} xs={12} className="mb-3">
                  <Select
                    label="Tipo de implemento *"
                    options={vehicleImplementTypesOptions}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    value={vehicleImplementType}
                    onChange={value => setVehicleImplementType(value)}
                    error={errors?.vehicle_implement_type}
                    id="select-novo-veiculo-modal-tipo-implemento"
                  />
                </Col>
              )}
              <Col md={4} xs={12} className="mb-3">
                <Select
                  label="Tipo do carroceria *"
                  options={vehicleBodyTypesOptions}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  value={vehicleBodyType}
                  onChange={value => setVehicleBodyType(value)}
                  error={errors?.vehicle_body_type_id}
                  id="select-novo-veiculo-modal-tipo-carroceria"
                />
              </Col>
              <Col md={4} xs={12} className="mb-3">
                <Input
                  label="Renavam *"
                  type="number"
                  value={renavam}
                  onChange={event =>
                    event.target.value.length > 11
                      ? snackbar.show(
                        <Text> Limite de dígitos para Renavam </Text>,
                        {
                          type: 'error',
                        }
                      )
                      : setRenavam(event.target.value)
                  }
                  error={errors?.renavam}
                  id="input-novo-veiculo-modal-renavam"
                />
              </Col>
              <Col md={4} xs={12} className="mb-3">
                <Select
                  label="Tipo de RNTRC *"
                  options={anttTypesOptions}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  value={anttType}
                  onChange={value => setAnttType(value)}
                  error={errors?.antt_type}
                  id="select-novo-veiculo-modal-tipo-rntrc"
                />
              </Col>
              <Col md={4} xs={12} className="mb-3">
                <Input
                  label="RNTRC *"
                  type="number"
                  value={antt}
                  onChange={event => setAntt(event.target.value)}
                  error={errors?.antt}
                  id="input-novo-veiculo-modal-rntrc"
                />
              </Col>
              <Col md={4} xs={12} className="mb-3">
                <Input
                  label="Data de emissão da RNTRC *"
                  type="date"
                  value={anttAdherence}
                  onChange={event => setAnttAdherence(event.target.value)}
                  error={errors?.antt_adherence}
                  id="input-novo-veiculo-modal-data-emissao-rntrc"
                />
              </Col>
              <Col md={4} xs={12} className="mb-3">
                <Select.Async
                  label="Proprietário do documento *"
                  onSearch={fetchOwners}
                  onChange={value => setOwnerData(value)}
                  value={ownerData}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.id}
                  error={errors?.owner_id}
                  id="select-novo-veiculo-modal-proprietario-documento"
                />
              </Col>
              <Col md={4} xs={12} className="mb-3">
                <Select.Async
                  label="Beneficiário do veículo *"
                  onSearch={fetchOwners}
                  onChange={value => setBeneficiary(value)}
                  value={beneficiary}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.id}
                  error={errors?.beneficiary_id}
                />
              </Col>

              <Col md={4} xs={12} className="mb-3">
                <Radio.Group
                  label="Rastreado *"
                  onChange={({ target }) => setIsTracked(target.value)}
                  value={isTracked}
                  horizontal
                  error={errors.isTracked}
                >
                  <Radio id="radio-novo-veiculo-modal-rastreado-sim" value>
                    <Text color="dark" type="label">
                      Sim
                    </Text>
                  </Radio>
                  <Radio
                    id="radio-novo-veiculo-modal-rastreado-nao"
                    value={false}
                  >
                    <Text color="dark" type="label">
                      Não
                    </Text>
                  </Radio>
                </Radio.Group>
                {errors?.tracked && (
                  <Badge className="mt-1" light variant="error">
                    Campo obrigatório
                  </Badge>
                )}
              </Col>
              <Col md={4} xs={12} className="mb-3">
                <Select
                  placeholder={
                    isTracked ? 'Selecione...' : 'Veículo não rastreado'
                  }
                  isDisabled={!isTracked}
                  label={isTracked ? 'Marca rastreador *' : 'Marca rastreador'}
                  options={trackers}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  value={isTracked ? tracker : null}
                  onChange={value => setTracker(value)}
                  error={errors?.tracker_id}
                  id="select-novo-veiculo-modal-marca-rastreador"
                />
              </Col>

              <Col md={4} xs={12} className="mb-3">
                <Select.Async
                  onSearch={search => fetchTrackerTypes(search, tracker)}
                  value={tracker ? trackerType : null}
                  onChange={value => setTrackerType(value)}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  label="Tipo de rastreador"
                  placeholder={
                    isTracked
                      ? tracker
                        ? 'Selecione...'
                        : 'Veículo sem rastreador'
                      : 'Veículo não rastreado'
                  }
                  options={trackerTypes}
                  isDisabled={!isTracked || !tracker}
                  error={errors?.tracker_type_id}
                  NoOptionsComponent={() => (
                    <Text>
                      Nenhum tipo cadastrado para o rastreador informado
                    </Text>
                  )}
                  id="select-novo-veiculo-modal-tipo-rastreador"
                />
              </Col>

              <Col md={4} xs={12} className="mb-3">
                <Input
                  placeholder={
                    isTracked ? 'Digite...' : 'Veículo não rastreado'
                  }
                  disabled={!isTracked}
                  label={'ID do rastreador'}
                  value={trackerCode}
                  onChange={event => setTrackerCode(event.target.value)}
                  error={errors?.tracker_code}
                  id="input-novo-veiculo-modal-id-rastreador"
                />
              </Col>
              <Col md={12} className="text-center my-3">
                <Button
                  className="py-2"
                  onClick={() => handleCreation()}
                  loading={loading}
                  id="button-novo-veiculo-modal-salvar"
                >
                  <Text type="regular" weight={500}>
                    Salvar
                  </Text>
                </Button>
              </Col>
            </Row>
          )
        }
      />
    </>
  );
}

export default RegisterVehicle;
