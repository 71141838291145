import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Card,
  DatePicker,
  Input,
  Link,
  Select,
  Text,
} from 'v3/components';
import { Row } from 'react-bootstrap';
import MaterialReactTable from 'material-react-table';
import theme from 'v3/theme';
import debounce from 'lodash.debounce';
import {
  fetchSelectTags,
  onSearchNegotiator,
} from 'pages/relatorios/acompanhamento-viagem/utils';
import {
  fetchBranchOffices,
  fetchOnlyCities,
  fetchDriver,
  fetchGroupVehicles,
  fetchShippingCompanies,
  fetchWagons,
} from 'utils/fetches';
import moment from 'moment';
import { useLocalStorage } from 'hooks';
import { IoMdClose } from 'react-icons/io';
import { DEFAULT_COLUMNS, useFleetManagement } from '../shared';
import { CellContainer } from '../styles';
import { StyledTablePagination } from './styles';
import AsyncSelect from './AsyncSelect';
import { validatePermission } from 'actions';

const hasPermissionRemove = validatePermission('REMOVER_GESTAO_FROTA');

const localization = {
  sortedByColumnAsc: 'Ordenar ascendente',
  sortedByColumnDesc: 'Ordenar descendente',
  unsorted: 'Sem ordenação',
  move: 'Mover coluna'
};

const STATUS_COLORS = {
  1: '#FFC107',
  2: '#FFC107',
  3: '#FFC107',
  4: '#2689D1',
  5: '#2689D1',
  6: '#E70000',
  7: '#FDE910',
  8: '#34DB39',
  9: '#FA7F72',
  10: '#E70000',
  11: '#2689D1',
  12: '#2689D1',
};

const InputCell = memo(
  function InputCell({
    inputType = 'input',
    initialValue,
    fleetRegisterId,
    columnName,
  }) {
    const { setEditedCells } = useFleetManagement();
    const [inputValue, setInputValue] = useState(
      inputType === 'date' && moment(initialValue).isValid()
        ? moment(initialValue).format('YYYY-MM-DDTHH:mm')
        : initialValue
    );
    const debouncedSave = useRef(
      debounce(value =>
        setEditedCells(prevState => {
          const updatedFleetRegister = { ...prevState[fleetRegisterId] };
  
          if (!value || value === 'Data inválida') {
            delete updatedFleetRegister[columnName];
          } else {
            updatedFleetRegister[columnName] = value;
          }
    
          return {
            ...prevState,
            [fleetRegisterId]: updatedFleetRegister,
          };
        }),
        600
      )
    ).current;

    const handleChange = useCallback(
      ({ target: { value } }) => {
        setInputValue(value);
        debouncedSave(moment(value).format('DD/MM/YYYY HH:mm'));
      },
      [debouncedSave]
    );

    return inputType === 'input' ? (
      <Input value={inputValue} onChange={handleChange} />
    ) : inputType === 'date' ? (
      <Input
        type="datetime-local"
        defaultValue={inputValue}
        value={inputValue}
        onKeyDown={(event) => event.preventDefault()}
        onChange={handleChange}
      />
    ) : (
      <Text>{initialValue}</Text>
    );
  },
  (prevProps, nextProps) => prevProps.initialValue === nextProps.initialValue
);

const AsyncSelectCell = memo(
  function SelectCell({
    initialValue,
    selectType,
    columnName,
    fleetRegisterId,
  }) {
    const returnObjectItens = useMemo(
      () => [
        'operator',
        'operation',
        'shipment',
        'branch_origin',
        'local_availability',
        'fleet'
      ],
      []
    );

    const initialValues = useMemo(() => {
      if (!initialValue) return null;
      if (initialValue) {
        if (columnName === 'plate_wagons') {
          return initialValue;
        }

        if (
          columnName === 'driver' ||
          columnName === 'shipment' ||
          columnName === 'operator' ||
          columnName === 'fleet'
        ) {
          return Object.keys(initialValue).length
            ? { label: initialValue.name, id: initialValue.id }
            : null;
        }

        if (returnObjectItens.includes(columnName)) {
          return Object.keys(initialValue).length ? initialValue : null;
        }
      }
      return null;
    }, [initialValue, columnName]);
    const { setEditedCells } = useFleetManagement();
    const [selectValue, setSelectValue] = useState(initialValues);
    const isMultiple = useMemo(
      () => selectType.includes('multi'),
      [selectType]
    );

    const handleChange = useCallback(
      option => {
        setEditedCells(prevState => ({
          ...prevState,
          [fleetRegisterId]: {
            ...prevState[fleetRegisterId],
            [columnName]: option || (isMultiple ? [] : null),
          },
        }));
        setSelectValue(option || (isMultiple ? [] : null));
      },
      [setEditedCells, fleetRegisterId, columnName, isMultiple]
    );

    const onSearch = useCallback(
      async search => {
        if (columnName === 'plate_wagons') {
          return fetchWagons(search);
        }

        if (columnName === 'driver') {
          return fetchDriver(search);
        }

        if (columnName === 'operation') {
          return fetchSelectTags({
            not_paginated: true,
            search,
          });
        }

        if (columnName === 'shipment') {
          return fetchShippingCompanies({ search });
        }

        if (columnName === 'branch_origin') {
          return fetchBranchOffices(search);
        }

        if (columnName === 'operator') {
          return onSearchNegotiator(search);
        }

        if (columnName === 'local_availability') {
          return fetchOnlyCities(search);
        }

        if (columnName === 'fleet') {
          return fetchGroupVehicles(search);
        }
        return [];
      },
      [columnName]
    );

    const getOptionLabel = useCallback(
      option => {
        if (columnName === 'operation') {
          return option.name;
        }

        if (columnName === 'plate_wagons') {
          return option.plate;
        }

        if (columnName === 'driver' || columnName === 'shipment') {
          return option.label;
        }

        if (columnName === 'branch_origin') {
          return `${option.id} - ${option.name}`;
        }

        if (columnName === 'operator') {
          return option.username || option.label;
        }

        if (columnName === 'fleet') {
          return option.description || option.label;
        }

        if (columnName === 'local_availability') {
          return `${option.name}${option?.province?.name ? ` - ${option.province.name}` : ''
            }`;
        }

        return option?.label;
      },
      [columnName]
    );

    const getOptionValue = useCallback(
      option => {
        const containId = [
          'plate_wagons',
          'driver',
          'operation',
          'shipment',
          'branch_origin',
          'operator',
          'local_availability',
          'fleet',
        ];
        if (containId.includes(columnName)) {
          return option.id;
        }

        return option?.value;
      },
      [columnName]
    );

    return (
      <AsyncSelect
        multiple={isMultiple}
        value={selectValue}
        onChange={handleChange}
        onSearch={onSearch}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
      />
    );
  },
  (prevProps, nextProps) =>
    prevProps.initialValue === nextProps.initialValue &&
    prevProps.selectType === nextProps.selectType &&
    prevProps.columnName === nextProps.columnName &&
    prevProps.fleetRegisterId === nextProps.fleetRegisterId
);

const SelectCell = memo(
  function SelectCell({
    initialValue,
    selectType,
    columnName,
    fleetRegisterId,
  }) {


    const initialValues = useMemo(() => {

      if (columnName === 'config_automatic_availability') {
        return initialValue ?
          { value: true, label: 'Sim' } :
          { value: false, label: 'Não' };
      }

      if (initialValue) {
        if (columnName === 'status_operational') {
          return Object.keys(initialValue).length ? initialValue : null;
        }

      }
      return null;
    }, [initialValue, columnName]);
    const { setEditedCells, filtersOptions } = useFleetManagement();
    const isMultiple = selectType.includes('multi');
    const [selectValue, setSelectValue] = useState(initialValues);

    const initialOptions = useMemo(() => {
      if (columnName === 'status_operational') {
        return filtersOptions.statusOperator;
      }
      if (columnName === 'config_automatic_availability') {
        return [
          { value: true, label: 'Sim' },
          { value: false, label: 'Não' },
        ];
      }
      return [];
    }, [filtersOptions?.statusOperator]);

    const handleChange = useCallback(
      value => {
        setEditedCells(prevState => ({
          ...prevState,
          [fleetRegisterId]: {
            ...prevState[fleetRegisterId],
            [columnName]: value || (isMultiple ? [] : null),
          },
        }));
        setSelectValue(value || (isMultiple ? [] : null));
      },
      [fleetRegisterId, columnName, isMultiple]
    );

    const getOptionValue = option => {
      if (columnName === 'status_operational') {
        return option.id;
      }

      return option?.value;
    };

    const getOptionLabel = option => {
      if (columnName === 'status_operational') {
        return option.name;
      }

      return option?.label;
    };

    return (
      <Select
        options={initialOptions}
        onChange={handleChange}
        value={selectValue}
        multiple={isMultiple}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
      />
    );
  },
  (prevProps, nextProps) => prevProps.initialValue === nextProps.initialValue
);

const Cell = memo(function Cell({ renderedCellValue, component, cell }) {

  const { editedCells, isEditing, newOutsideTravelModal, setSelected, outsideTravelModal } =
    useFleetManagement();
  const wasEdited = Object.entries(editedCells).find(([key, value]) => {
    const editedCell = Object.keys(value).find(item => item === cell.column.id);
    return +key === cell.row.original.id && editedCell;
  });
  const fleetRegisterId = cell.row.original.id;
  const columnName = cell.column.id;

  if (isEditing) {
    if (component === 'input' || component === 'date') {
      return (
        <CellContainer $wasEdited={wasEdited}>
          <InputCell
            initialValue={renderedCellValue}
            fleetRegisterId={fleetRegisterId}
            columnName={columnName}
            inputType={component}
          />
        </CellContainer>
      );
    }
    if (component === 'select' || component === 'multi-select') {
      return (
        <CellContainer $wasEdited={wasEdited}>
          <SelectCell
            initialValue={renderedCellValue}
            selectType={component}
            columnName={columnName}
            fleetRegisterId={fleetRegisterId}
          />
        </CellContainer>
      );
    }

    if (component.includes('async')) {
      return (
        <CellContainer $wasEdited={wasEdited}>
          <AsyncSelectCell
            selectType={component}
            initialValue={renderedCellValue}
            columnName={columnName}
            fleetRegisterId={fleetRegisterId}
          />
        </CellContainer>
      );
    }

    if (columnName === 'travel_external') {
      return (
        <CellContainer $wasEdited={wasEdited}>
          <Button
            onClick={() => {
              setSelected(cell.row.original);
              newOutsideTravelModal.open();
            }}
          >
            Adicionar
          </Button>
        </CellContainer>
      );
    }
  }

  if (columnName === 'config_automatic_availability') {
    return (
      <CellContainer $wasEdited={wasEdited}>
        <Text color={'fleet_management_gray'} type='regular'>{renderedCellValue ? 'Sim' : 'Não'}</Text>
      </CellContainer>
    )
  }

  if (columnName === 'travel_external') {
    return (
      <CellContainer $wasEdited={wasEdited} travelOutside={true}>
        <Button
          size='xs'
          onClick={() => {
            setSelected(cell.row.original);
            outsideTravelModal.open();
          }}
        >
          Histórico de viagens
        </Button>
      </CellContainer>
    );
  }

  if (columnName === 'plate_wagons') {
    return (
      <CellContainer $wasEdited={wasEdited}>
        <div style={{ display: 'flex', gap: 8 }}>
          {renderedCellValue.map(({ plate, wagonId }) => (
            <Link key={wagonId} to={`/implementos/${wagonId}`} target="_blank">
              {plate}
            </Link>
          ))}
        </div>
      </CellContainer>
    );
  }

  if (columnName === 'type_wagons') {
    return (
      <CellContainer $wasEdited={wasEdited}>
        <Text color={'fleet_management_gray'} type='regular' >{renderedCellValue.map(({ name }) => name).join(', ')}</Text>
      </CellContainer>
    );
  }

  if (columnName === 'body_types') {
    return (
      <CellContainer $wasEdited={wasEdited}>
        <Text color={'fleet_management_gray'} type='regular' >{renderedCellValue ? renderedCellValue?.join(', ') : 'Não informado'}</Text>
      </CellContainer>
    );
  }

  if (columnName === 'fleet_type_wagons') {
    return (
      <CellContainer $wasEdited={wasEdited}>
        <Text color={'fleet_management_gray'} type='regular' >{renderedCellValue.map(({ name }) => name).join(', ')}</Text>
      </CellContainer>
    );
  }

  if (columnName === 'status_operational' && renderedCellValue?.name) {
    return (
      <CellContainer
        $bgColor={STATUS_COLORS[renderedCellValue.id]}
        $wasEdited={wasEdited}
      >
        <Text type='regular'>{renderedCellValue.name}</Text>
      </CellContainer>
    );


  }

  const DATE_COLUMNS = [
    'availability_date',
    'availability_date_travel_external',
    'date_finish_travel',
    'date_forecast',
    'date_load_travel',
    'date_origin_travel_external',
    'date_unload_travel',
    'last_location_date',
    'next_maintenance_vehicle',
    'next_maintenance_wagon',
  ];

  const CALC_DATE_COLUMNS = [
    'time_travel_external',
    'time_maintenance',
    'time_travel',
    'time_last_travel',
    'time_available'
  ]


  if (CALC_DATE_COLUMNS.includes(columnName)) {
    return (
      <CellContainer $wasEdited={wasEdited}>
        <Text color={'fleet_management_gray'} type='regular'>
          {renderedCellValue} Dias
        </Text>
      </CellContainer>
    );
  }

  if (DATE_COLUMNS.includes(columnName)) {
    return (
      <CellContainer $wasEdited={wasEdited}>
        <Text color={'fleet_management_gray'} type='regular'>
          {moment(renderedCellValue).isValid()
            ? moment(renderedCellValue).format('DD/MM/YYYY HH:mm')
            : renderedCellValue}
        </Text>
      </CellContainer>
    );
  }

  if (columnName === 'travel_current') {
    return (
      <CellContainer $wasEdited={wasEdited}>
        {renderedCellValue && (
          <Link to={`/viagens/${renderedCellValue}`} target="_blank">{renderedCellValue}</Link>
        )}
      </CellContainer>
    );
  }

  if (columnName === 'plate') {
    return (
      <CellContainer $wasEdited={wasEdited}>
        <Link to={`/veiculos/${renderedCellValue?.id}`} target="_blank">
          {renderedCellValue?.plate}
        </Link>
      </CellContainer>
    );
  }

  if (
    typeof renderedCellValue === 'number' ||
    typeof renderedCellValue === 'string'
  ) {
    return (
      <CellContainer $wasEdited={wasEdited}>
        <Text color={'fleet_management_gray'} type='regular'>{renderedCellValue}</Text>
      </CellContainer>
    );
  }

  if (columnName === 'driver' && renderedCellValue?.name) {
    return (
      <CellContainer $wasEdited={wasEdited}>
        <Link to={`/motoristas/${renderedCellValue.id}`} target="_blank">
          {renderedCellValue.name}
        </Link>
      </CellContainer>
    );
  }

  if (columnName === 'shipment' && renderedCellValue?.name) {
    return (
      <CellContainer $wasEdited={wasEdited}>
        <Link to={`/transportadoras/${renderedCellValue.id}`} target="_blank">
          {renderedCellValue.name}
        </Link>
      </CellContainer>
    );
  }

  if (renderedCellValue?.name) {
    return (
      <CellContainer $wasEdited={wasEdited}>
        <Text color={'fleet_management_gray'} type='regular'>{renderedCellValue.name}</Text>
      </CellContainer>
    );
  }

  return (
    <CellContainer $wasEdited={wasEdited}>
      <Text color={'fleet_management_gray'} type='regular'>{renderedCellValue}</Text>
    </CellContainer>
  );
});

const RemoveCell = ({ rowInfo, modalHook, setSelected }) => {
  const handleDelete = () => {
    setSelected(rowInfo);
    modalHook.open();
  };
  return (
    <CellContainer>
      <IoMdClose style={{ cursor: 'pointer' }} onClick={handleDelete} />
    </CellContainer>
  );
};

export default function FleetManagementTable() {
  const {
    fetching,
    columns,
    isEditing,
    fleetData,
    loading,
    editedCells,
    rowsPerPage,
    setRowsPerPage,
    fetchConfig,
    deleteModal,
    setSelected,
    handleGetData,
    filters,
    suggestionsModal,
  } = useFleetManagement();
  const [columnOrder, setColumnOrder] = useLocalStorage(
    'fleet_management_column_order',
    []
  );

  const formatedColumns = useMemo(
    () => [
      hasPermissionRemove ? {
        header: '',
        accessorKey: 'remove',
        Cell: props => (
          <RemoveCell
            setSelected={setSelected}
            modalHook={deleteModal}
            rowInfo={props?.row?.original}
          />
        ),
        enableColumnDragging: false,
        enableResizing: false,
        enableSorting: false,
        size: 50,
      } : null,

      !isEditing ? {
        header: '',
        accessorKey: 'actions',
        Cell: props => {
          return (
            <CellContainer>
              <Button
                size='xs'
                disabled={isEditing}
                onClick={() => {
                  setSelected(props?.row?.original);
                  suggestionsModal.open();
                }}
              >
                Carregamentos
              </Button>
            </CellContainer>
          );
        },
        enableColumnDragging: false,
        enableResizing: false,
        enableSorting: false,
        size: 120,

      } : null,
      ...columns
        .flatMap(({ items }) =>
          items.filter(item => {
            if (DEFAULT_COLUMNS.includes(item.slug)) return true;
            return item.selected;
          })
        )
        .map(({ name, slug, component }) => {
          return {
            header: name,
            key: slug,
            accessorKey: slug.toLowerCase(),
            Cell: props => <Cell {...props} component={component} />,
            minSize: isEditing ?
              component === 'text' ? 200 : 300
              : component !== 'text' && 300,
            enableColumnDragging: true,
          };
        }),
    ].filter(Boolean),
    [columns, isEditing, editedCells]
  );

  const paginationLabel = ({ from, to, count }) => {
    return `${from}-${to} de ${count}`;
  };

  const onRowsPerPageChange = useCallback(
    async ({ target: { value } }) => {
      setRowsPerPage(prev => ({ ...prev, rowsPerPage: value }));
      await handleGetData({ ...filters, perPage: value, page: 1 });
    },
    [setRowsPerPage, handleGetData, filters]
  );

  const onPageChange = useCallback(
    async (_, e) => {
      setRowsPerPage(prev => ({ ...prev, page: e + 1 }));
      await handleGetData({
        ...filters,
        page: e + 1,
        perPage: rowsPerPage.rowsPerPage,
      });
    },
    [filters, handleGetData, rowsPerPage]
  );

  useEffect(() => {
      setColumnOrder(formatedColumns.map(({ accessorKey }) => accessorKey));
  }, [formatedColumns, columns]);

  return (
    <Row style={{ marginTop: 40 }}>
      <Card loading={fetching || loading} bodyCardPadding="0.3rem 1rem 1rem">
        <MaterialReactTable
          renderEmptyRowsFallback={() => (
            <Text>Nenhum resultado encontrado!</Text>
          )}
          localization={localization}
          initialState={{
            columnPinning: { left: ['remove'], right: ['actions'] },
          }}
          enablePinning={false}
          getRowId={row => row.id}
          columns={formatedColumns}
          fetching={fetching}
          data={fleetData}
          state={{ columnOrder }}
          enableStickyHeader
          enableColumnResizing={!isEditing}
          onColumnOrderChange={setColumnOrder}
          sort
          enableColumnOrdering={!isEditing}
          enableColumnDragging={!isEditing}
          enableSorting={!isEditing}
          enablePagination={false}
          enableColumnActions={false}
          enableHiding={false}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enableGlobalFilter={false}
          enableFilters={false}
          muiTablePaperProps={{
            sx: {
              boxShadow: 'none',
            },
          }}
          muiTableContainerProps={{
            backgroundColor: 'red',
            sx: {
              '&:hover::-webkit-scrollbar': {
                width: 10,
                height: 10,
              },
              '&:hover::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              '&:hover::-webkit-scrollbar-thumb': {
                backgroundColor: theme.colors.medium_gray,
                borderRadius: 2,
              },
              '&:not(:hover)::-webkit-scrollbar': {
                opacity: 0,
                width: 10,
                height: 10,
              },
              '&:not(:hover)::-webkit-scrollbar-thumb': {
                opacity: 0,
              },
              maxHeight: 'calc(100vh - 300px)',
              overscrollBehavior: 'none',
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              padding: '0.4rem',
              fontSize: '0.775rem',
            },
          }}
          muiTableRowProps={{
            sx: {
              height: '25px',
            },
          }}
          muiTableHeadCellDragHandleProps={{
            sx: {
              marginRight: '0.75rem',
            },
          }}
        />
        {!isEditing && (
          <StyledTablePagination
            rowsPerPageOptions={[
              { label: '10', value: 10 },
              { label: '30', value: 30 },
              { label: '50', value: 50 },
            ]}
            labelRowsPerPage="Registros por página:"
            rowsPerPage={rowsPerPage.rowsPerPage}
            component="div"
            count={fetchConfig?.total}
            page={fetchConfig?.page - 1}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            labelDisplayedRows={paginationLabel}
            showFirstButton
            showLastButton
          />
        )}
      </Card>
    </Row>
  );
}
