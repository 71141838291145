import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { cooperplaceApi } from 'services/api';

import { useSnackbar } from 'v3/components/Snackbar';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Upload from 'v3/components/Upload';
import Text from 'v3/components/Text';

export function GRAttachmentsModal({
  isOpen,
  onClose,
  attachments,
  setAttachments,
  fetchAttachments,
}) {
  const params = useParams();
  const snackbar = useSnackbar();
  const [files, setFiles] = useState([]);
  const [loadingUpdateAttachment, setLoadingUpdateAttachment] = useState(false);
  const [errors, setErrors] = useState({});

  function handleClose() {
    setFiles([]);
    setErrors({});
    onClose();
  }

  async function updateAttachment() {
    try {
      if (params.id) {
        setLoadingUpdateAttachment(true);
        const attachmentsFiles = new FormData();

        files.forEach((file, index) => {
          const name = `${Date.now()}${index}.${file.file.type.split('/')[1]}`;
          const newFile = new File([file.file.blob], name, {
            type: file.file.type,
          });
          attachmentsFiles.append('file', newFile);
          attachmentsFiles.append('name', name);
          attachmentsFiles.append('description', file?.file?.name);
          attachmentsFiles.append('type', 'rotograma');
        });
        await cooperplaceApi.post(
          `travels/${params.id}/uploadRotogram`,
          attachmentsFiles
        );
        snackbar.show(
          <Text weight={500} color="dark">
            Rotograma cadastrado com sucesso
          </Text>,
          {
            type: 'success',
          }
        );
        handleClose();
        fetchAttachments();
      } else {
        setAttachments([...attachments, ...files]);

        handleClose();
        fetchAttachments();
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ??
        'Algo deu errado. Tente novamente mais tarde';
      const notificationError = error?.response?.data?.isNoticationError;
      snackbar.show(
        <Text weight={500} color="dark">
          {message}
        </Text>,
        { type: 'error' }
      );
      if (notificationError) {
        handleClose();
        fetchAttachments();
      }
    } finally {
      setLoadingUpdateAttachment(false);
    }
  }

  function onFilesChanged({ target }, hasError) {
    setFiles([]);
    setErrors({});

    if (hasError) {
      setErrors({ file: 'Algum arquivo selecionado é maior que 1MB.' });
      return;
    }

    setFiles(
      [...target.files].map(file => {
        return {
          file: {
            blob: file,
            size: file?.size,
            name: file?.name,
            type: file?.type,
            tempPath: URL.createObjectURL(file),
          },
          description: file.name,
        };
      })
    );
  }

  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="subtitle" color="dark" weight="400">
          Anexos
        </Text>
      }
      body={
        <div>
          <div className="mb-3">
            <Text>Adicione um arquivo</Text>
            <Upload
              name="file"
              label="Arquivo máximo de 1MB"
              accept=".jpg,.jpeg,.png,.pdf"
              value={files}
              multiple
              onChange={onFilesChanged}
              error={errors.file}
              ignoreValidation
            />
          </div>
          <div className="d-flex flex-column align-items-center mt-3">
            <Button
              disabled={!files.length}
              onClick={updateAttachment}
              loading={loadingUpdateAttachment}
            >
              <Text type="regular" weight={500}>
                Adicionar
              </Text>
            </Button>
          </div>
        </div>
      }
    />
  );
}
