import moment from 'moment';
import api, { cooperplaceApi } from 'services/api';
import { currencyOptions } from 'v3/utils/formatter';
import { applyMonetaryMask } from 'v3/utils/functions';

export const MAX_OP = 25;
export const MAX_PRODUCTS = 25;
export const MAX_RANGES = 25;

export const vehicleMapper = {
  implement: 'Tipo de implemento',
  body: 'Carroceria',
  vehicle: 'Tipo de veículo',
};

export function validateOpName(operations, newOp) {
  if (operations?.includes(newOp?.trim().toLowerCase())) {
    return false;
  }
  return true;
}

export function validateProduct(products, prodsNcms) {
  for (let i = 0; i < products.length; i++) {
    const group = products[i];
    for (let j = 0; j < group.length; j++) {
      const item = group[j];
      if (prodsNcms.includes(item.code)) {
        return false;
      }
    }
  }
  return true;
}


export function validateVehicle(oldvehicles, newVehiclesOptions) {
  let boolean = []

  if (oldvehicles.length > 0) {
    oldvehicles.forEach(vehicle => {
      const result = areAllItemsPresent(newVehiclesOptions, vehicle)
      if (result) {
        boolean.push(true)
      } else {
        boolean.push(false)
      }
    })
    return boolean.some(item => item === true)
  }

  return false
}

export function areAllItemsPresent(sourceArray, targetArray) {
  return targetArray.every(targetItem =>
    sourceArray.some(sourceItem => sourceItem.id === targetItem.id && sourceItem.type === targetItem.type)
  );
}

export async function fetchRoutes(search) {
  try {
    const response = await cooperplaceApi.get('gro/routes', {
      params: { search },
    });
    return response.data || [];
  } catch (error) {
    return [];
  }
}

function mapRanges(groups) {
  const mappedGroups = groups?.map(group => {
    return {
      [group.code]: [...group.items],
    };
  });
  return mappedGroups;
}

export function processPolicyData(data) {
  const currencySymbol = data?.currency === 'BRL' ? 'R$' : '$';
  const processedData = data?.map(d => {
    return {
      id: d?.id,
      name: d?.name,
      customers: d?.customers,
      due_date: d?.due_date,
      last_revision_date: d?.last_revision_date,
      active: d?.active,
      currency: currencyOptions.find(item => item.value === d.currency) ?? null,
      operations: d?.operations?.map(op => {
        return {
          id: op?.id,
          name: op?.name,
          products: op?.products?.map(pd => {
            return {
              id: pd?.id,
              products: pd?.products?.map(p => {
                return {
                  id: p?.product_id,
                  value: p?.product_id,
                  name: p?.name,
                  label: `${p?.code} - ${p?.name}`,
                  code: p?.code,
                };
              }),
              routes: pd?.routes,
              responsibility_security: pd?.responsabilities,
              vehicles: pd?.vehicles?.map(mainVh => {
                const sortedRanges = [...mainVh?.ranges];
                sortedRanges.sort(
                  (a, b) => a.policy_range_min - b.policy_range_min
                );
                return {
                  id: mainVh?.id,
                  vehicles: mainVh?.vehicle_types,
                  ranges: sortedRanges?.map(rg => {
                    const groups = mapRanges(rg?.groups);
                    const shootingInitial = rg?.starting_schedule
                      ? {
                        hours: new Date(rg?.starting_schedule).getHours(),
                        minutes: new Date(rg?.starting_schedule).getMinutes(),
                      }
                      : null;
                    const shootingFinalFull = rg?.finishing_schedule
                      ? {
                        hours: new Date(rg?.finishing_schedule).getHours(),
                        minutes: new Date(
                          rg?.finishing_schedule
                        ).getMinutes(),
                      }
                      : null;
                    const rangeObj = {
                      ...rg,
                      policy_range_min: applyMonetaryMask(
                        rg?.policy_range_min,
                        currencySymbol
                      ),
                      policy_range_max: applyMonetaryMask(
                        rg?.policy_range_max,
                        currencySymbol
                      ),
                      starting_schedule: shootingInitial
                        ? `${String(shootingInitial?.hours).padStart(
                          2,
                          '0'
                        )}:${String(shootingInitial?.minutes).padStart(
                          2,
                          '0'
                        )}`
                        : null,
                      finishing_schedule: shootingFinalFull
                        ? `${String(shootingFinalFull?.hours).padStart(
                          2,
                          '0'
                        )}:${String(shootingFinalFull?.minutes).padStart(
                          2,
                          '0'
                        )}`
                        : null,
                      riskAreas: rg?.risk_area?.map(risk => {
                        return {
                          radius: risk?.radius,
                          city: {
                            id: risk?.city_id,
                            lat: risk?.lat,
                            lng: risk?.lng,
                            name: risk?.name,
                          },
                        };
                      }),
                      monitoringEquipaments: rg?.equipments_groups,
                      min_time_antt: rg?.min_time_antt
                        ? String(rg?.min_time_antt).padStart(2, '0')
                        : null,
                      age_traction_max: rg?.age_traction_max
                        ? String(rg?.age_traction_max).padStart(2, '0')
                        : null,
                      age_implement_max: rg?.age_implement_max
                        ? String(rg?.age_implement_max).padStart(2, '0')
                        : null,
                      driver_travel_min: rg?.driver_travel_min
                        ? String(rg?.driver_travel_min).padStart(2, '0')
                        : null,
                      showSearchConsultation: true,
                      showMonitoringEquipaments: true,
                      showManagers: true,
                      showGeneralRules: true,
                      showRiskManager: true,
                      manager_tracker: [],
                    };
                    groups.forEach(group => {
                      rangeObj[Object.keys(group)[0]] = Object.values(group)[0];
                    });
                    return rangeObj;
                  }),
                };
              }),
            };
          }),
        };
      }),
      logs: d?.logs,
      loads_gro: d?.loads_gro,
    };
  });
  return processedData;
}

export async function processSubmissionData(
  active,
  policy,
  policyCurrency,
  policyName,
  policyExpirationDate,
  policyLastRevisionDate,
  policyClients,
  operations
) {

  const payload = {
    id: policy?.id,
    active,
    currency: policyCurrency?.value,
    name: policyName,
    due_date: policyExpirationDate,
    last_revision_date: policyLastRevisionDate
      ? moment(policyLastRevisionDate).format('yyyy-MM-DDTHH:mm')
      : null,
    customers: policyClients?.map(item => {
      return {
        id: item?.id,
        social_name: item?.label,
        document: item?.cgccpf,
      };
    }),
    operations: operations?.map(item => {
      return {
        id: item?.id,
        name: item?.name,
        products: item?.products?.map(pd => {
          return {
            id: pd?.id,
            products: pd?.products?.map(p => {
              return {
                product_id: p?.id,
              };
            }),
            routes: pd?.routes,
            responsabilities: pd?.responsibility_security?.map(rs => {
              return { id: rs.id };
            }),
            vehicles: pd?.vehicles?.map(mainVh => {
              return {
                id: mainVh?.id,
                vehicle_types: mainVh?.vehicles?.map(vh => {
                  return {
                    id: vh?.id,
                    type: vh?.type,
                  };
                }),
                ranges: mainVh?.ranges?.map(range => {
                  return {
                    id: range?.id,
                    policy_range_min: Number(
                      range?.policy_range_min
                        .replace(/[^\d,]/g, '')
                        .replace(/,/g, '.')
                    ),
                    policy_range_max: Number(
                      range?.policy_range_max
                        .replace(/[^\d,]/g, '')
                        .replace(/,/g, '.')
                    ),
                    checklist_validity: range?.checklist_validity,
                    driver_guide: range?.driver_guide,
                    driver_travel_min: Number(range?.driver_travel_min),
                    starting_schedule: range?.starting_schedule,
                    finishing_schedule: range?.finishing_schedule,
                    min_time_antt: Number(range?.min_time_antt),
                    manager_contact: range?.manager_contact,
                    age_traction_max: Number(range?.age_traction_max),
                    age_implement_max: Number(range?.age_implement_max),
                    obs: range?.obs,
                    obs_company: range?.obs_company,
                    tracked: range?.tracked,
                    restrition_third: range?.restrition_third,
                    checklist_tracker: range?.checklist_tracker,
                    risk_area: range?.riskAreas?.map(risk => {
                      return {
                        city_id: risk?.city?.id,
                        lat: risk?.city?.lat,
                        lng: risk?.city?.lng,
                        radius: Number(risk?.radius),
                      };
                    }),
                    equipments_groups: range?.monitoringEquipaments
                      ?.filter(
                        item =>
                          item?.name?.length > 0 ||
                          item?.equipments?.length > 0 ||
                          item?.locators?.length > 0 ||
                          item?.immobilizers?.length > 0 ||
                          item?.baits?.length > 0 ||
                          item?.tecnologies?.length > 0 ||
                          item.bait_quantity > 0
                      )
                      ?.map(eq => {
                        return {
                          id: eq?.id,
                          bait_quantity: eq?.bait_quantity,
                          escorted: eq?.escorted !== null ? eq?.escorted : null,
                          name: eq?.name,
                          tecnologies:
                            eq?.tecnologies?.map(e => {
                              return { id: e?.id };
                            }) || [],
                          equipments:
                            eq?.equipments?.map(e => {
                              return { id: e?.id };
                            }) || [],
                          locators:
                            eq?.locators?.map(l => {
                              return { id: l?.id };
                            }) || [],
                          immobilizers:
                            eq?.immobilizers?.map(i => {
                              return { id: i?.id };
                            }) || [],
                          baits:
                            eq?.baits?.map(b => {
                              return { id: b?.id };
                            }) || [],
                        };
                      }),
                    groups: [
                      {
                        code: 'search_consulting',
                        items:
                          range?.search_consulting?.map(item => {
                            return { id: item?.id, name: item?.name };
                          }) || [],
                      },
                      {
                        code: 'rdo',
                        items:
                          range?.rdo?.map(item => {
                            return { id: item?.id, name: item?.name };
                          }) || [],
                      },
                      {
                        code: 'manager_query',
                        items:
                          range?.manager_query?.map(item => {
                            return { id: item?.id, name: item?.name };
                          }) || [],
                      },
                      {
                        code: 'logistic_sm',
                        items:
                          range?.logistic_sm?.map(item => {
                            return { id: item?.id, name: item?.name };
                          }) || [],
                      },
                      {
                        code: 'security_profile',
                        items:
                          range?.security_profile?.map(item => {
                            return { id: item?.id, name: item?.name };
                          }) || [],
                      },
                      {
                        code: 'manager_tracker',
                        items:
                          range?.manager_tracker?.map(item => {
                            return { id: item?.id, name: item?.name };
                          }) || [],
                      },
                      {
                        code: 'manager_mirror',
                        items:
                          range?.manager_mirror?.map(item => {
                            return { id: item?.id, name: item?.name };
                          }) || [],
                      },
                      {
                        code: 'check_manager',
                        items:
                          range?.check_manager?.map(item => {
                            return { id: item?.id, name: item?.name };
                          }) || [],
                      },
                      {
                        code: 'validity_check_manager',
                        items:
                          range?.validity_check_manager?.map(item => {
                            return { id: item?.id, name: item?.name };
                          }) || [],
                      },
                      {
                        code: 'sensors',
                        items:
                          range?.sensors?.map(item => {
                            return { id: item?.id, name: item?.name };
                          }) || [],
                      },
                    ],
                  };
                }),
              };
            }),
          };
        }),
      };
    }),
  };
  return payload;
}

export async function fetchTrackerTypes(trackersIds, search) {
  try {
    const response = await api.get('select/tracker-types', {
      params: {
        tracker: trackersIds,
        search,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}
