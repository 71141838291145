import React, { useState } from 'react';
import { Button, Modal, Select, Text } from 'v3/components';
import { fetchUserByEmail } from "utils/fetches"
import { useSnackbar } from 'v3/components/Snackbar';
import { cooperplaceApi } from 'services/api';
import { filtersMapper, useFleetManagement } from '../../shared';
import { BodyContainer } from './styles';

function SendEmailsModal() {
  const {
    sendEmailsModal: modalHook,
    loading,
    filters,
    rowsPerPage,
  } = useFleetManagement();
  const [users, setUsers] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const snackbar = useSnackbar();

  const handleClose = () => {
    setUsers([]);
    modalHook.close();
  };

  const handleSendEmails = async () => {
    setIsFetching(true);
    try {
      await cooperplaceApi.post('fleet/management/vehicleManagement/email', {
        userIds: users.map(user => user.id),
        ...filtersMapper(filters, rowsPerPage),
      });
      snackbar.show(<Text>Emails enviados com sucesso</Text>, {
        type: 'success',
      });
    } catch (error) {
      snackbar.show(
        <Text>
          {error?.response?.data?.error ||
            'Erro ao enviar relatório por e-mail'}
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setIsFetching(false);
      handleClose();
    }
  };

  return (
    <Modal
      show={modalHook.isOpen}
      heading="Enviar relatório por e-mail"
      body={
        <BodyContainer>
          <Text type="subtitle" className="text-title">
            Enviar emails:
          </Text>

          <Text>
            Adicione todos os emails dos usuários que gostaria que fosse enviado um
            relatório por e-mail
          </Text>

          <Select.Async
            onSearch={fetchUserByEmail}
            label="Emails dos usuários"
            getOptionLabel={option => `${option.email}`}
            getOptionValue={option => option.id}
            onChange={userOptions => setUsers(userOptions || [])}
            value={users}
            multiple
          />

          <Button loading={loading || isFetching} onClick={handleSendEmails} modal={true}>
            Enviar emails
          </Button>
        </BodyContainer>
      }
      handleClose={handleClose}
    />
  );
}

export default SendEmailsModal;
