import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import ApplicationLayout from 'v3/layouts/Application';
import { BadgeActive, Button, DataTable, Fallback, Text } from 'v3/components';
import { DefaultLoadingComponent } from 'v3/components/List';
import { formatDateTime } from 'v3/utils/formatter';
import { validatePermission } from 'actions';
import Space from 'v3/components/Space';
import { useModal } from 'hooks';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { Filter } from './Filter';
import { Link } from 'react-router-dom';

export function ChecklistList() {
  const history = useHistory();
  const snackbar = useSnackbar();
  const hasPermissionToCreate = validatePermission('CADASTRAR_CHECKLIST');
  const hasPermissionToView = validatePermission('VISUALIZAR_CHECKLIST');
  const [checklists, setChecklists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState();
  const [typeOptions, setTypeOptions] = useState([]);
  const advancedFilters = useModal();
  const [checklistsPerPage, setChecklistsPerPage] = useLocalStorage(
    'checklistsPerPage',
    5
  );
  const [filter, setFilter] = useState({
    perPage: checklistsPerPage,
    page: 1,
    name: '',
    checklistType: [],
    activeFilter: {
      actives: false,
      inactives: false,
    },
  });
  const [advancedFiltersQtd, setAdvancedFiltersQtd] = useState(null);
  function onPageChanged(value) {
    const params = {
      ...filter,
      page: value,
    };
    setFilter(params);
    fetchData(params);
  }
  function onPerPageChanged(value) {
    const params = {
      ...filter,
      page: 1,
      perPage: value,
    };
    setFilter(params);
    fetchData(params);
  }

  async function fetchData(params) {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.get('checklists', {
        params: {
          name: params.name,
          limit: params.perPage,
          page: params.page,
          typeId: params.checklistType.map(item => item.id),
          vehicleTypes: params?.vehicleTypes?.map(item => item?.id) || [],
          bodyTypes: params?.bodyTypes?.map(item => item?.id) || [],
          negotiations: params?.negotiations?.map(item => item?.id) || [],
          questions: params?.questions?.map(item => item?.id) || [],
          clients: params?.clients?.map(item => item?.id) || [],
          inactives: params.activeFilter.inactives,
          actives: params.activeFilter.actives,
          branchOffices: params?.branchOffices?.map(item => item?.id) || [],
        },
      });
      setChecklists(data.data);
      setPagination(data.meta);
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        'Não foi possível recuperar os checklists';
      snackbar.show(<Text>{message}</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  async function fetchChecklistTypes() {
    try {
      const { data } = await cooperplaceApi.get('checklists-types');
      setTypeOptions(data);
    } catch (error) {
      snackbar.show(
        <Text>
          Não foi possível carregar as opções de filtro. Recarregue a página
        </Text>,
        {
          type: 'error',
        }
      );
    }
  }

  function onFilterChange(newFilters) {
    const params = {
      ...filter,
      ...newFilters,
      page: 1,
    };
    setFilter(params);
    fetchData(params);
    if (advancedFilters.isOpen) {
      advancedFilters.close();
    }
  }
  useEffect(() => {
    if (hasPermissionToView) {
      fetchData(filter);
      fetchChecklistTypes();
    }
  }, []);

  useEffect(() => {
    if (!hasPermissionToView) {
      snackbar.show(
        <Text>Você não tem permissão para visualizar checklists</Text>,
        { type: 'error' }
      );
      history.push('/');
    }
  }, [hasPermissionToView]);

  useEffect(() => {
    if (checklistsPerPage !== filter.perPage) {
      setChecklistsPerPage(filter.perPage);
    }
  }, [filter.perPage]);

  return (
    <ApplicationLayout
      title="Checklists"
      RightComponent={
        <Space x="0.5rem">
          <Button variant="secondary" onClick={() => advancedFilters.open()}>
            <Text weight={500} color="dark" type="regular">
              {advancedFiltersQtd
                ? `Filtro avançado (${advancedFiltersQtd})`
                : 'Filtro avançado'}
            </Text>
          </Button>
          {hasPermissionToCreate && (
            <Button
              variant="primary"
              onClick={() => history.push('/checklist/checklists/cadastrar')}
            >
              <Text weight={500} color="white" type="regular">
                Novo checklist
              </Text>
            </Button>
          )}
        </Space>
      }
    >
      <Filter
        onFilterChange={onFilterChange}
        typeOptions={typeOptions}
        showAdvancedFilter={advancedFilters.isOpen}
        onCloseAdvanceFilterModal={() => advancedFilters.close()}
        loading={loading}
        setFilter={setFilter}
        setAdvancedFiltersQtd={setAdvancedFiltersQtd}
      />
      <Row>
        <Col className="my-5">
          <Fallback
            fall={checklists?.length === 0}
            component={
              loading ? (
                <DefaultLoadingComponent />
              ) : (
                <Col xs={12} className="d-flex justify-content-center my-5">
                  <Button disabled>
                    <Text>Nenhum registro encontrado.</Text>
                  </Button>
                </Col>
              )
            }
          >
            <DataTable
              data={checklists}
              loading={loading}
              total={pagination?.total}
              pages={pagination?.last_page || 1}
              currentPage={filter.page}
              onPageChanged={onPageChanged}
              onPerPageChanged={onPerPageChanged}
              perPageOptions={[{ v: 5 }, { v: 15 }, { v: 30 }]}
              columns={[
                {
                  name: 'Status',
                  selector: row =>
                    row?.active ? (
                      <BadgeActive pill light type="success" className="mr-2">
                        <Text type="label" weight={500}>
                          Ativo
                        </Text>
                      </BadgeActive>
                    ) : (
                      <BadgeActive pill light type="error" className="mr-2">
                        <Text weight={500} type="label">
                          Inativo
                        </Text>
                      </BadgeActive>
                    ),
                },
                {
                  name: 'Nome',
                  selector: row => (
                    <>
                      <Text title={row?.name}>
                        {row?.name}
                      </Text>
                      <Row style={{fontSize:11}}>
                        {formatDateTime(row?.checkListLogs[0]?.createdAt)+' - '+row?.checkListLogs[0]?.obs}
                      </Row>
                    </>
                  ),
                },
                {
                  name: 'Incluído em',
                  selector: row => formatDateTime(row?.createdAt),
                },
                {
                  name: 'Tipo',
                  selector: row => row?.checkListType?.name,
                },
                {
                  selector: row => (
                    <Link to={`/checklist/checklists/${row.id}`}>
                      <Button
                        disabled={hasPermissionToView === false}
                      >
                        Detalhes
                      </Button>
                    </Link>
                  ),
                },
              ]}
              withFilterOption={false}
              defaultPerPage={filter.perPage}
            />
          </Fallback>
        </Col>
      </Row>
    </ApplicationLayout>
  );
}
