/* eslint-disable no-extra-boolean-cast */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { validatePermission } from 'actions/index';
import ApplicationLayout from 'v3/layouts/Application';
import { useSnackbar } from 'v3/components/Snackbar';
import { DataTable, Fallback, Button, Text, BadgeActive } from 'v3/components';
import { cooperplaceApi } from 'services/api';
import { Col } from 'react-bootstrap';
import theme from 'v3/theme';
import { DefaultLoadingComponent } from 'v3/components/List';
import { usePermission } from 'hooks';
import Filter from './Filter';

function List() {
  const snackbar = useSnackbar();
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const [loadingCostCenters, setLoadingCostCenters] = useState(false);
  const [costCenters, setCostCenters] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [hasPermissionToCreate, setPermissionToCreate] = useState();
  const hasPermissionToSeeCostCenters = usePermission(
    'VISUALIZAR_CENTRO_CUSTO',
    { redirect: true }
  );
  useState(async () => {
    setPermissionToCreate(validatePermission('CADASTRAR_CENTRO_CUSTO'));
  }, []);

  async function fetchCostCenters() {
    try {
      if (!hasPermissionToSeeCostCenters) return;
      setLoading(true);
      setPage(1);
      const response = await cooperplaceApi.get(`/cost-center?${query}`);
      const { data } = response;
      setCostCenters(data);
    } catch (error) {
      snackbar.show(<Text>Algo deu errado, tentar novamente mais tarde</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCostCenters();
  }, [hasPermissionToSeeCostCenters]);

  useEffect(() => {
    function onPressedKey(e) {
      if (e.key === 'Enter') {
        fetchCostCenters();
      }
    }

    window.addEventListener('keyup', onPressedKey);

    return () => {
      window.removeEventListener('keyup', onPressedKey);
    };
  });

  async function getAndUpdateCostCentersBenner() {
    try {
      setLoadingCostCenters(true);
      setLoading(true);
      const response = await cooperplaceApi.put('/cost-center/benner');
      if (response.data.success) {
        snackbar.show(<Text>Centros de custo importados com sucesso!</Text>, {
          type: 'success',
        });
        setLoadingCostCenters(false);
        setLoading(false);
      }
    } catch (error) {
      setLoadingCostCenters(false);
      setLoading(false);
      console.error(error);
      snackbar.show(
        <Text>Não foi possível importar Centros de Custo da Benner</Text>,
        {
          type: 'error',
        }
      );
    }
  }
  const costCentersData = useMemo(() => {
    const offset = (page - 1) * perPage;
    const costCentersToDisplay = costCenters?.slice(offset, perPage + offset);
    if (costCentersToDisplay === 0 && page > 1) setPage(page - 1);
    return costCentersToDisplay;
  }, [costCenters, page, perPage]);

  return (
    <ApplicationLayout
      title="Centros de custo"
      RightComponent={
        <>
          {hasPermissionToCreate && (
            <>
              <Link to="/centro-de-custo/cadastrar">
                <Button variant="primary" className="py-2 mr-2">
                  <Text weight={500} color="white" type="regular">
                    Novo centro de custo
                  </Text>
                </Button>
              </Link>
              <Button
                loading={loadingCostCenters}
                variant="secondary"
                className="py-2 mr-2"
                onClick={getAndUpdateCostCentersBenner}
              >
                <Text weight={500} color="gray" type="regular">
                  Importar do Benner
                </Text>
              </Button>
            </>
          )}
        </>
      }
    >
      <Filter onChange={fetchCostCenters} loading={loading} />
      <Fallback
        fall={costCenters?.length === 0}
        component={
          <Col
            xs={12}
            className="pt-5 d-flex justify-content-center align-items-center"
          >
            {loading ? (
              <DefaultLoadingComponent />
            ) : (
              <Button colors={theme.colors.primary} size="md" disabled>
                Não há itens a serem exibidos
              </Button>
            )}
          </Col>
        }
      >
        <Col className="mt-5 mb-4">
          <DataTable
            data={costCentersData}
            loading={loading}
            total={costCenters?.length}
            pages={Math.ceil(costCenters?.length / perPage)}
            currentPage={page}
            onPageChanged={setPage}
            onPerPageChanged={v => setPerPage(+v)}
            perPageOptions={[{ v: 10 }, { v: 20 }, { v: 30 }]}
            defaultPerPage={10}
            columns={[
              {
                name: 'Status',
                render: row => (
                  <BadgeActive
                    pill
                    light
                    type={row.deletedAt ? 'error' : 'success'}
                    className="mr-2"
                  >
                    <Text type="label">
                      {row.deletedAt ? 'Inativo' : 'Ativo'}
                    </Text>
                  </BadgeActive>
                ),
              },
              {
                name: 'Identificador',
                selector: row => row?.identifier ?? 'Não informado',
              },
              { name: 'Limite de Agendamento', selector: row => row?.limitSchedule ? 'Sim' : 'Não' },
              { name: 'Mostrar Ordem de Carregamento', selector: row => row?.showLoadingOrder ? 'Sim' : 'Não' },
              { name: 'Nome', selector: row => row?.name },
              {
                name: 'Detalhes',
                render: row => (
                  <Link to={`/centro-de-custo/${row?.id}`}>
                    <Button className="py-2">
                      <Text color="white" type="regular" weight="500">
                        Detalhes
                      </Text>
                    </Button>
                  </Link>
                ),
              },
            ]}
            withFilterOption={false}
          />
        </Col>
      </Fallback>
    </ApplicationLayout>
  );
}

export default List;
