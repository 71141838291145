import React from 'react';

import Space from 'v3/components/Space';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';

import Tooltip from "v3/components/Tooltip"
import theme from 'v3/theme/index';

import { FaQuestionCircle } from 'react-icons/fa';

import Col from 'react-bootstrap/Col';

import { useVehicle } from '../context';

export default function Editable({ isEditing, setEditing, onSave, isLoading, isDisabledManagement }) {
  const vehicle = useVehicle();

  function handleCancel() {
    setEditing(false);
    vehicle.setData(vehicle.prevVehicle);
    vehicle.setErrors({});
  }

  if (isEditing) {
    return (
      <Space x="5px">
        <Button variant="secondary" onClick={handleCancel}>
          <Text color="dark" weight="500" type="regular">
            Cancelar
          </Text>
        </Button>
        <Button variant="success" onClick={onSave} loading={isLoading}>
          <Text color="white" weight={500}>
            Salvar
          </Text>
        </Button>
      </Space>
    );
  }

  if (isDisabledManagement) {
    return (
      <Col style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
        <Button variant="secondary" onClick={() => setEditing(true)} disabled={isDisabledManagement}>
          <Text color="dark" weight="500" type="regular">
            Editar
          </Text>
        </Button>
        <Tooltip
          content={
            <Text type="label" color="white">
              O veículo está cadastrado na gestão de otimização de frota e cargas. A edição de alguns campos está restrita ao seu operador.
            </Text>
          }
        >
          <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
        </Tooltip>
      </Col>
    )
  }

  return (
    <Button variant="secondary" onClick={() => setEditing(true)}>
      <Text color="dark" weight="500" type="regular">
        Editar
      </Text>
    </Button>
  );
}
