import { useModal } from 'hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Card, Text } from 'v3/components';
import {
  CardDetails,
  InputsCardDetails,
  NegotiatedCard,
  PreFreightValues,
  ReceiveClientCardDetails,
} from 'v3/pages/Load/Details/Values/FreightDetailsComponents';
import {
  CardHeaderComponent,
  ExtraFieldsRow,
  ExtraItemRow,
  HeaderTotalSummary,
  ObservationsContainer,
  SummaryRow,
  ValueDetailsRow,
} from 'v3/pages/Load/Register/PriceData/components';
import {
  inputsLoadConditionsToShow,
  shipmentDiscountsToShow,
  shipmentTransferToShow,
} from 'v3/pages/Load/Register/PriceData/utils';
import { makeConversion } from 'v3/utils/formatter';
import { getPercentageFromValue } from 'v3/utils/functions';
import { ExtraFieldsContainer } from 'v3/pages/Load/Register/PriceData/styles';
import { percentage } from 'utils/number';
import LoadExtraFieldsTravelEditing from './loadExtraFieldsModal';

export function LoadFreight({
  load,
  travel,
  editing,
  balance,
  setBalance,
  advanceMoneyPercentage,
  setAdvanceMoneyPercentage,
  advanceMoneyValue,
  setAdvanceMoneyValue,
  margin,
  totalDiscounts,
  setTotalDiscounts,
  setShipperDiscountsExtraFields,
  shipperDiscountsExtraFields,
  issueRate,
  totalShipperTransfer,
  setTotalShipperTransfer,
  shipperTransferExtraFields,
  setShipperTransferExtraFields,
  negotiatorObs,
  setNegotiatorObs,
  errors,
}) {
  const [showDailyRate, setShowDailyRate] = useState(false);
  const [clientCardToggle, setClientCardToggle] = useState(false);
  const [inputsCardToggle, setInputsCardToggle] = useState(false);
  const [discountsCardToggle, setDiscountsCardToggle] = useState(false);
  const [transferCardToggle, setTransferCardToggle] = useState(false);
  const [negotiatedCardToggle, setNegotiatedCardToggle] = useState(false);
  const isBRLCurrency =
    load?.currency === 'BRL' ? true : load?.currency === 'USD' ? false : '';
  const dollarToUse = showDailyRate ? load?.exchangeDay : load?.freightExchange;
  const cooperInsurance = load?.cargInputs?.find(
    item => item.label.toLowerCase() === 'seguro coopercarga' && item.value !== 0
  );
  const hasCooperInsurance = !!cooperInsurance;
  const hasIssueRate = !!issueRate;
  const shouldDisableGrisField =
    travel?.data?.shipperProps?.description_service_level?.toLowerCase() ===
    'agregado ouro';

  const handleBalanceChange = useCallback(
    value => {
      if (value?.length <= 10) {
        let advanceMoney = 0;
        const advancePercentage = Number(value) ? setAdvanceMoneyPercentage : 0;
        if (advancePercentage) {
          advanceMoney = percentage(advanceMoneyPercentage, Number(value));
        }
        setBalance(value);
        setAdvanceMoneyValue(Number.isNaN(advanceMoney) ? 0 : advanceMoney);
      }
    },
    [balance, advanceMoneyPercentage]
  );
  const handleAdvanceMoneyPercentageChange = useCallback(
    value => {
      if (value?.length <= 6) {
        let advanceMoney = 0;
        if (balance) {
          advanceMoney = percentage(Number(value), Number(balance));
        }
        setAdvanceMoneyValue(advanceMoney);
        setAdvanceMoneyPercentage(value);
      }
    },
    [advanceMoneyPercentage, advanceMoneyValue, balance]
  );

  const handleAdvanceMoneyValueChange = useCallback(
    value => {
      if (value?.length <= 10) {
        const advancePercentage = (
          (Number(value) / Number(balance)) *
          100
        )?.toFixed(2);
        setAdvanceMoneyPercentage(advancePercentage);
        setAdvanceMoneyValue(value);
      }
    },
    [balance, advanceMoneyPercentage, advanceMoneyValue]
  );
  return (
    <>
      <PreFreightValues
        load={{
          freightExchange: load?.freightExchange,
          exchangeDay: load?.exchangeDay,
          currency: load?.currency,
          loadValueType: load?.loadValueType,
          negotiationType: load?.negotiationType,
        }}
      />
      <Row>
        <ReceiveClientCardDetails
          load={load}
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          clientCardToggle={clientCardToggle}
          setClientCardToggle={setClientCardToggle}
          showDailyRate={showDailyRate}
          setShowDailyRate={setShowDailyRate}
        />
        <InputsCardDetails
          load={load}
          extraFieldsFieldName="cargInputs"
          totalComputed={load?.totalInputs}
          showDailyRate={showDailyRate}
          cardTitle="Descontos e Insumos Pré-Taxa"
          cardTotalTitle="Valor insumos"
          tooltipContent="Todo valor que precisa descontar antes de tirar a taxa (margem) é alocado neste campo, assim, é chegado no percentual correto para desconto de taxa administrativa (Margem)"
          shouldShowConditions={inputsLoadConditionsToShow}
          hasCooperInsurance={hasCooperInsurance}
          cooperInsurance={cooperInsurance}
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          cardToggle={inputsCardToggle}
          setCardToggle={setInputsCardToggle}
          currentShippingLevel={
            travel?.data?.shipperProps?.description_service_level?.toLowerCase() ===
            'agregado ouro'
          }
        />
        <NegotiatedCard
          cardTitle="Valores negociados"
          cardToggle={negotiatedCardToggle}
          setCardToggle={setNegotiatedCardToggle}
          tooltipContent="Valores negociados com transportador e
cliente a partir dos valores principais"
          load={load}
          isBRLCurrency={isBRLCurrency}
          dollarToUse={dollarToUse}
          balanceValue={balance}
          onChangeBalance={(_event, value) => handleBalanceChange(value)}
          advanceMoneyPercentage={advanceMoneyPercentage}
          onChangeAdvancePercentage={(_event, value) =>
            handleAdvanceMoneyPercentageChange(value)
          }
          advanceMoneyValue={advanceMoneyValue}
          onChangeAdvanceValue={(_event, value) =>
            handleAdvanceMoneyValueChange(value)
          }
          marginValue={margin}
          isEditing={editing}
          fromTravel
        />

        {editing ? (
          <LoadShipperDiscountsCardEditing
            load={load}
            totalComputed={totalDiscounts}
            showDailyRate={showDailyRate}
            cardTitle="Descontos ao transportador"
            cardTotalTitle="Valor Descontos"
            tooltipContent="Se caracteriza todo desconto que será feito depois de tirar a margem (Taxa administrativa)."
            hasIssueRate={hasIssueRate}
            issueRate={issueRate}
            isBRLCurrency={isBRLCurrency}
            dollarToUse={dollarToUse}
            cardToggle={discountsCardToggle}
            setCardToggle={setDiscountsCardToggle}
            setTotalDiscounts={setTotalDiscounts}
            setShipperDiscountsExtraFields={setShipperDiscountsExtraFields}
            shipperDiscountsExtraFields={shipperDiscountsExtraFields}
          />
        ) : (
          <CardDetails
            load={load}
            extraFieldsFieldName="shipmentDiscounts"
            totalComputed={load?.totalShipmentDiscounts}
            showDailyRate={showDailyRate}
            cardTitle="Descontos ao transportador"
            cardTotalTitle="Valor Descontos"
            tooltipContent="Se caracteriza todo desconto que será feito depois de tirar a margem (Taxa administrativa)."
            shouldShowConditions={shipmentDiscountsToShow}
            hasIssueRate={hasIssueRate}
            issueRate={issueRate}
            isBRLCurrency={isBRLCurrency}
            dollarToUse={dollarToUse}
            cardToggle={discountsCardToggle}
            setCardToggle={setDiscountsCardToggle}
            balanceValue={travel?.data?.balance}
            marginValue={travel?.data?.margin}
          />
        )}
        {editing ? (
          <LoadShipperTransferCardEditing
            load={load}
            isBRLCurrency={isBRLCurrency}
            dollarToUse={dollarToUse}
            extraFieldsFieldName="shipmentTransfers"
            setTotalShipperTransfer={setTotalShipperTransfer}
            shipperTransferExtraFields={shipperTransferExtraFields}
            setShipperTransferExtraFields={setShipperTransferExtraFields}
            totalComputed={totalShipperTransfer}
            showDailyRate={showDailyRate}
            cardTitle="Repasse ao transportador"
            cardTotalTitle="Valor Crédito"
            tooltipContent="Valores a serem repassados ao transportador conforme itens definidos, não contempla taxa administrativa sobre o valor."
            shouldShowConditions={shipmentTransferToShow}
            cardToggle={transferCardToggle}
            setCardToggle={setTransferCardToggle}
            hasObservationFields
            negotiatorObs={negotiatorObs}
            setNegotiatorObs={setNegotiatorObs}
            errors={errors}
          />
        ) : (
          <CardDetails
            load={load}
            extraFieldsFieldName="shipmentTransfers"
            totalComputed={load?.totalShipmentTransfer}
            showDailyRate={showDailyRate}
            cardTitle="Repasse ao transportador"
            cardTotalTitle="Valor Crédito"
            tooltipContent="Valores a serem repassados ao transportador conforme itens definidos, não contempla taxa administrativa sobre o valor."
            shouldShowConditions={shipmentTransferToShow}
            isBRLCurrency={isBRLCurrency}
            dollarToUse={dollarToUse}
            cardToggle={transferCardToggle}
            setCardToggle={setTransferCardToggle}
            hasObservationFields
            hasNegotiatorObs
            negotiatorObs={negotiatorObs}
            shouldDisableGrisField={shouldDisableGrisField}
          />
        )}
      </Row>
    </>
  );
}

export function LoadShipperTransferCardEditing({
  isVehicleGoldAggregated,
  shipperTransferExtraFields,
  setShipperTransferExtraFields,
  isBRLCurrency,
  dollarToUse,
  setTotalShipperTransfer,
  errors,
  negotiatorObs,
  setNegotiatorObs,
  load,
  cardToggle,
  setCardToggle,
  extraFieldsFieldName,
  totalComputed,
  hasObservationFields = false,
}) {
  const shipperTransferExtraFieldsModal = useModal();
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue?.toFixed(4)
      : processedValue?.toFixed(2);
  }
  const shouldUseToll =
    shipmentTransferToShow?.includes(load?.tollObservation?.code) &&
    load?.tollValue > 0;
  const shouldUseDischarge =
    shipmentTransferToShow?.includes(load?.dischargeObservation?.code) &&
    load?.dischargeValue > 0;
  const shouldUseGrisAd = shipmentTransferToShow?.includes(
    load?.grissAdObservation?.code
  );
  const shouldUseCharge =
    shipmentTransferToShow?.includes(load?.chargeObservation?.code) &&
    load?.chargeValue > 0;
  const shouldUseFerry =
    shipmentTransferToShow?.includes(load?.ferryObservation?.code) &&
    load?.ferry > 0;
  const totalTransfer = useMemo(() => {
    let total = 0;
    if (shipperTransferExtraFields?.length) {
      total = shipperTransferExtraFields?.reduce((acc, curr) => {
        if (curr?.delete) {
          return acc;
        }
        return acc + Number(curr?.value);
      }, 0);
    }
    if (
      shouldUseToll &&
      load?.tollObservation?.code !== 'client_provides_card'
    ) {
      total += Number(load?.tollValue);
    }
    if (shouldUseDischarge) {
      total += Number(load?.dischargeValue);
    }
    if (shouldUseGrisAd && isVehicleGoldAggregated === false) {
      total += Number(load?.gris);
    }
    if (shouldUseGrisAd) {
      total += Number(load?.adValorem);
    }
    if (shouldUseCharge) {
      total += Number(load?.chargeValue);
    }
    if (shouldUseFerry) {
      total += Number(load?.ferry);
    }
    return total;
  }, [
    shipperTransferExtraFields,
    load?.tollValue,
    load?.dischargeValue,
    load?.gris,
    load?.adValorem,
    load?.chargeValue,
    load?.ferry,
    shouldUseToll,
    shouldUseCharge,
    shouldUseDischarge,
    shouldUseFerry,
    shouldUseGrisAd,
    isVehicleGoldAggregated,
  ]);
  useEffect(() => {
    setTotalShipperTransfer(totalTransfer);
  }, [totalTransfer]);
  const tagsContent = useMemo(() => {
    let tags = '';
    if (
      shouldUseToll &&
      load?.tollObservation?.code !== 'client_provides_card'
    ) {
      tags += 'Pedágio,';
    }
    if (shouldUseDischarge) {
      tags += 'Descarga,';
    }
    if (shouldUseCharge) {
      tags += 'Taxa de Carregamento,';
    }
    if (shouldUseGrisAd && isVehicleGoldAggregated === false) {
      tags += 'Gris,';
    }
    if (shouldUseGrisAd) {
      tags += 'Ad Valorem,';
    }
    if (shouldUseFerry) {
      tags += 'Balsa,';
    }
    if (!!load && load[extraFieldsFieldName]?.length > 0) {
      tags += 'Valores extras,';
    }
    return tags;
  }, [
    load,
    shouldUseToll,
    shouldUseCharge,
    shouldUseDischarge,
    shouldUseFerry,
    shouldUseGrisAd,
    isVehicleGoldAggregated,
  ]);
  const grissPercentage = getPercentageFromValue(load?.gris, load?.cargoValue);
  const adPercentage = getPercentageFromValue(
    load?.adValorem,
    load?.cargoValue
  );

  return (
    <Col xs={12}>
      <LoadExtraFieldsTravelEditing
        extraFields={shipperTransferExtraFields}
        isOpen={shipperTransferExtraFieldsModal.isOpen}
        onClose={shipperTransferExtraFieldsModal.close}
        setExtraFields={setShipperTransferExtraFields}
        loadCurrency={isBRLCurrency ? 'R$' : '$'}
        isBRLCurrency={isBRLCurrency}
        dollarToUse={dollarToUse}
        id="repasse-transportador"
      />
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardHeaderComponent
          label="Repasse ao Transportador"
          isOpen={cardToggle}
          setIsOpen={() => setCardToggle(!cardToggle)}
          tooltipContent="Valores a serem repassados ao transportador conforme itens definidos, não contempla taxa administrativa sobre o valor."
          totalsSection={
            <HeaderTotalSummary
              label="Crédito transportador"
              totalInReal={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInDollar={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
          }
          hasTagsRow
          tagsContent={tagsContent}
        />
        {cardToggle && (
          <>
            {shouldUseToll && (
              <ValueDetailsRow
                label="Pedágio"
                value={load?.tollValue}
                exchangeToUse={dollarToUse}
                obs={load?.tollObservation?.name}
                isBRLCurrency={isBRLCurrency}
                id="pedagio"
              />
            )}
            {shouldUseDischarge && (
              <ValueDetailsRow
                label="Descarga"
                value={load?.dischargeValue}
                exchangeToUse={dollarToUse}
                obs={load?.dischargeObservation?.name}
                isBRLCurrency={isBRLCurrency}
                id="descarga"
              />
            )}
            {shouldUseGrisAd && isVehicleGoldAggregated === false && (
              <ValueDetailsRow
                label="Griss"
                exchangeToUse={dollarToUse}
                value={load?.gris}
                percentageValue={grissPercentage}
                obs={load?.grissAdObservation.name}
                hasTooltip
                tooltipContent="Valor aproximado, pode variar de acordo com o valor do produto na Nota Fiscal"
                isBRLCurrency={isBRLCurrency}
                id="gris"
              />
            )}
            {shouldUseGrisAd && (
              <ValueDetailsRow
                label="Ad valorem"
                exchangeToUse={dollarToUse}
                value={load?.adValorem}
                percentageValue={adPercentage}
                obs={load?.grissAdObservation.name}
                isBRLCurrency={isBRLCurrency}
                id="ad-valorem"
              />
            )}
            {shouldUseCharge && (
              <ValueDetailsRow
                label="Taxa de carregamento"
                exchangeToUse={dollarToUse}
                value={load?.chargeValue}
                obs={load?.chargeObservation.name}
                isBRLCurrency={isBRLCurrency}
                id="taxa-carregamento"
              />
            )}
            {shouldUseFerry && (
              <ValueDetailsRow
                label="Balsa"
                exchangeToUse={dollarToUse}
                value={load?.ferry}
                obs={load?.ferryObservation.name}
                isBRLCurrency={isBRLCurrency}
                id="balsa"
              />
            )}
            <>
              {shipperTransferExtraFields?.length > 0 ? (
                <Text type="regular" weight={500}>
                  Valores extras
                </Text>
              ) : null}
              <ExtraFieldsContainer>
                {shipperTransferExtraFields?.map((item, index) => {
                  if (item?.delete) {
                    return null;
                  }
                  return (
                    <ExtraItemRow
                      key={`${index}_${new Date().getTime()}`}
                      label={item?.label}
                      value={item?.value}
                      dollarToUse={dollarToUse}
                      isBRLCurrency={isBRLCurrency}
                      onSetExtraFields={() =>
                        setShipperTransferExtraFields(
                          shipperTransferExtraFields?.map((item, idx) => {
                            if (idx === index) {
                              return {
                                ...item,
                                delete: true,
                              };
                            }
                            return item;
                          })
                        )
                      }
                      showToDriver={item?.showToDriver}
                      id={`campo-extra-repasse-transportador-${index}`}
                    />
                  );
                })}
              </ExtraFieldsContainer>
            </>
            <SummaryRow
              label="Valor crédito"
              totalInBRL={
                isBRLCurrency ? totalTransfer : convertValue(totalTransfer)
              }
              totalInUSD={
                !isBRLCurrency ? totalTransfer : convertValue(totalTransfer)
              }
              id="valor-credito"
            />
            <ExtraFieldsRow
              id="button-gerenciar-valores-extras-repasse-transportador"
              openModal={() => shipperTransferExtraFieldsModal.open()}
            />
            {hasObservationFields && (
              <ObservationsContainer
                hasNegotiatorObs
                negotiatorValue={negotiatorObs}
                onChangeNegotiatorValue={e => setNegotiatorObs(e.target.value)}
                negotiatorLabel="Observações negociador"
                negotiatorError={errors?.negotiatorObs}
                pcpValue={load?.pcpObservation}
                pcpLabel="Observações pcp"
                shouldDisableFieldPcp
                id="repasse-transportador"
              />
            )}
          </>
        )}
      </Card>
    </Col>
  );
}

function LoadShipperDiscountsCardEditing({
  shipperDiscountsExtraFields,
  setShipperDiscountsExtraFields,
  isBRLCurrency,
  dollarToUse,
  setTotalDiscounts,
  load,
  cardToggle,
  setCardToggle,
  totalComputed,
  issueRate,
  hasIssueRate,
}) {
  const shipperDiscountsExtraFieldsModal = useModal();
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue?.toFixed(4)
      : processedValue?.toFixed(2);
  }

  const totalDiscounts = useMemo(() => {
    let total = 0;
    if (shipperDiscountsExtraFields?.length) {
      total = shipperDiscountsExtraFields.reduce((acc, curr) => {
        if (curr?.delete) {
          return acc;
        }
        return acc + Number(curr?.value);
      }, 0);
    }

    if (issueRate) {
      total += Number(issueRate?.value);
    }

    return total;
  }, [shipperDiscountsExtraFields, load?.ferry, issueRate]);
  useEffect(() => {
    setTotalDiscounts(totalDiscounts);
  }, [totalDiscounts]);
  const tagsContent = useMemo(() => {
    let tags = '';
    if (hasIssueRate) {
      tags += 'Taxa de emissão,';
    }
    if (
      shipperDiscountsExtraFields?.filter(item => item?.delete !== true)
        ?.length > 0
    ) {
      tags += 'Valores extras,';
    }
    return tags;
  }, [load, shipperDiscountsExtraFields]);

  return (
    <Col xs={12}>
      <LoadExtraFieldsTravelEditing
        extraFields={shipperDiscountsExtraFields}
        isOpen={shipperDiscountsExtraFieldsModal.isOpen}
        onClose={shipperDiscountsExtraFieldsModal.close}
        setExtraFields={setShipperDiscountsExtraFields}
        dollarToUse={dollarToUse}
        isBRLCurrency={isBRLCurrency}
        loadCurrency={isBRLCurrency ? 'R$' : '$'}
      />
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardHeaderComponent
          label="Descontos ao Transportador"
          tooltipContent="Se caracteriza todo desconto que será feito depois de tirar a margem (Taxa administrativa)."
          isOpen={cardToggle}
          setIsOpen={() => setCardToggle(!cardToggle)}
          totalsSection={
            <HeaderTotalSummary
              label="Valor descontos"
              totalInReal={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInDollar={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
          }
          hasTagsRow
          tagsContent={tagsContent}
        />
        {cardToggle && (
          <>
            {hasIssueRate && (
              <ValueDetailsRow
                label="Taxa de emissão"
                exchangeToUse={dollarToUse}
                value={issueRate?.value}
                isBRLCurrency={isBRLCurrency}
              />
            )}
            <>
              {shipperDiscountsExtraFields?.filter(
                item => item?.delete !== true
              )?.length > 0 ? (
                <Text type="regular" weight={500}>
                  Valores extras
                </Text>
              ) : null}
              <ExtraFieldsContainer>
                {shipperDiscountsExtraFields?.map((item, index) => {
                  if (item?.delete) {
                    return null;
                  }
                  return (
                    <ExtraItemRow
                      key={`${index}_${new Date().getTime()}`}
                      label={item?.label}
                      value={item?.value}
                      dollarToUse={dollarToUse}
                      isBRLCurrency={isBRLCurrency}
                      onSetExtraFields={() =>
                        setShipperDiscountsExtraFields(
                          shipperDiscountsExtraFields?.map((item, idx) => {
                            if (idx === index) {
                              return {
                                ...item,
                                delete: true,
                              };
                            }
                            return item;
                          })
                        )
                      }
                      showToDriver={item?.showToDriver}
                    />
                  );
                })}
              </ExtraFieldsContainer>
            </>
            <SummaryRow
              label="Valor descontos"
              totalInBRL={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInUSD={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
            <ExtraFieldsRow
              openModal={() => shipperDiscountsExtraFieldsModal.open()}
            />
          </>
        )}
      </Card>
    </Col>
  );
}
