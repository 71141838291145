import React, { useState, useMemo } from 'react';
import Lightbox from 'react-image-lightbox';
import { FaSearch, FaDownload, FaTrash } from 'react-icons/fa';
import Modal from 'v3/components/Modal';
import PDFView from 'v3/components/PDFView';
import StringBuilder from 'utils/StringBuilder';
import Button from 'v3/components/Button';

import * as Styled from 'v3/components/Attachment/styles';

export function Attachment({
  attachment,
  attachments,
  removeAttachment,
  deleteOption = true,
  loading,
}) {
  const [isHovering, setHovering] = useState(false);
  const [isShowingModal, setShowingModal] = useState(false);

  const attachmentsWithoutPDF = useMemo(() => {
    return attachments.filter(item => {
      const filterPDF = item?.url?.split('.');
      if (filterPDF?.[filterPDF?.length - 1] === 'pdf') return null;
      return item;
    });
  }, [attachments]);

  const [lightbox, setLightbox] = useState({
    isOpen: false,
    attachments: attachmentsWithoutPDF,
    index: 0,
  });

  const lightboxIndexes = useMemo(() => {
    const next = (lightbox.index + 1) % lightbox.attachments?.length;
    const previous =
      (lightbox.index + lightbox.attachments.length - 1) %
      lightbox.attachments.length;
    return { next, previous };
  }, [lightbox.index, lightbox.attachments]);

  function onClickPreview(id) {
    const index = id ? attachmentsWithoutPDF.findIndex(a => a.id === id) : 0;

    setLightbox(prev => ({
      ...prev,
      index,
      attachments: attachmentsWithoutPDF,
      isOpen: true,
    }));
  }

  function handleClick() {
    const fullPathArray = attachment?.url.split('.');

    if (fullPathArray[fullPathArray?.length - 1] === 'pdf') {
      setShowingModal(true);
    } else {
      onClickPreview(attachment.id);
    }
  }

  function getImagePath(index, alwaysDisplay) {
    const isSameAsIndex =
      lightbox.index === lightboxIndexes.next &&
      lightbox.index === lightboxIndexes.previous;

    if (!alwaysDisplay && isSameAsIndex && lightbox.attachments.length === 1) {
      return null;
    }
    return lightbox.attachments[index]?.url;
  }

  function getDocumentMetadata() {
    const document = lightbox.attachments[lightbox.index];
    const description = document?.description || 'Sem descrição';
    const type = document?.documentType;
    return { description, type };
  }

  const lightboxTitle = useMemo(() => {
    const builder = new StringBuilder(' ');
    const documentMetadata = getDocumentMetadata();
    builder
      .append(`${lightbox.index + 1}/${lightbox.attachments?.length}`)
      .append('|')
      .append(documentMetadata.description)
      .append(documentMetadata.type);

    return builder.build();
  }, [lightbox.index, lightbox.attachments]);

  const ext = useMemo(() => {
    const fullPathArray = attachment?.url.split('.');
    const ext = fullPathArray.pop();
    return ext
  }, [attachment.url]);

  const icon = useMemo(() => {
    if (ext === 'pdf') {
      return 'url(/images/default-doc.png) center center / 100%'
    }

    if (['xlxs', 'xls', 'csv'].includes(ext)) {
      return 'url(/images/default-excel.png) center 25px / 28% no-repeat'
    }


    return `url('${attachment.url}') center center`
  }, [attachment.url, ext]);

  const enablePreview = useMemo(() => {
    return ['pdf', 'jpg', 'jpeg', 'png'].includes(ext);
  }, [ext]);



  return (
    <div>
      <Modal
        show={isShowingModal}
        dialogClassName="modal-90w"
        onHide={() => setShowingModal(false)}
        heading="Visualizar PDF"
        body={<PDFView file={attachment.url} />}
      />
      <div
        className="position-relative"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          height: 160,
          background: icon,
        }}
      >
        {lightbox.isOpen && (
          <Lightbox
            mainSrc={getImagePath(lightbox.index, true)}
            nextSrc={getImagePath(lightboxIndexes.next)}
            prevSrc={getImagePath(lightboxIndexes.previous)}
            onCloseRequest={() =>
              setLightbox(prev => ({ ...prev, isOpen: false }))
            }
            onMovePrevRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.previous,
              }))
            }
            onMoveNextRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.next,
              }))
            }
            imageTitle={lightboxTitle}
          />
        )}
        {isHovering && (
          <Styled.Backdrop>
            {enablePreview && (
              <button type="button" onClick={handleClick}>
                <FaSearch color="#fff" size={32} />
              </button>
            )}
            <a href={attachment.url} download>
              <FaDownload color="#fff" size={32} />
            </a>
            {deleteOption && (
              <Button
                variant="transparent"
                loading={loading}
                size={0}
                onClick={() => removeAttachment(attachment.id)}
              >
                <FaTrash size={32} />
              </Button>
            )}
          </Styled.Backdrop>
        )}
      </div>
    </div>
  );
}
