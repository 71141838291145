import styled from 'styled-components';
import { Modal } from 'v3/components';

export const ReasonLabel = styled.div`
  width: 100%;
  background-color: ${({ $loadRejected }) =>
    $loadRejected ? '#F64E60' : '#FFF4DE'};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  border-radius: 4px;
`;

export const StyledModal = styled(Modal)`
  && .modal-title {
    width: 100%;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CounterContainer = styled.div`
  display: flex;
  gap: 8px;
  div {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
  }
`;

export const DestinationContainer = styled.div`
  max-height: 150px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.light_gray || '#f1f1f1'};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary || '#888'};
    border-radius: 10px;
    border: 2px solid ${({ theme }) => theme.colors.light_gray || '#f1f1f1'};
    transition: background-color 0.3s ease, border 0.3s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.colors.secondary || '#555'};
    border-color: ${({ theme }) => theme.colors.primary || '#888'};
  }

  scroll-behavior: smooth;
`;

export const ButtonContainer = styled.div`
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  `

