import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { useValidation } from 'hooks';
import { useSnackbar } from 'v3/components/Snackbar';
import Text from 'v3/components/Text';
import api from 'services/api';

import { useVehicle } from '../shared/context';
import SharedData from '../shared/Section';
import Inputs from '../shared/Owner/Inputs';
import Editable from '../shared/Editable';
import { ownerSchema } from '../shared/validator';

import { validatePermission } from 'actions/index';

export default function Main() {
  const vehicle = useVehicle();
  const snackbar = useSnackbar();
  const [errors, { validate }] = useValidation(ownerSchema);

  const [isEditing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    vehicle.setErrors(errors);
  }, [errors]);

  async function saveData() {
    setLoading(true);
    const [isValid, validData] = await validate(vehicle.data);

    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios</Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    const payload = {
      owner_id: validData.owner.id,
      antt_owner: validData.anttOwner.id,
      beneficiary_id: validData.beneficiary.id
    };

    try {
      await api.put(`v3/vehicle/owner/${vehicle.data.id}`, payload);
      vehicle.setPrevVehicle(vehicle.data);
      snackbar.show(<Text>Salvo com sucesso</Text>, { type: 'success' });
    } catch (error) {
      vehicle.setData(vehicle.prevVehicle);
      snackbar.show(<Text>Erro ao salvar</Text>, { type: 'error' });
    } finally {
      setEditing(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!vehicle.data.owner?.id && vehicle.errors.owner) {
      setEditing(true);
    }
  }, [vehicle.errors]);

  return (
    <SharedData
      Title="Proprietário"
      HeaderRightComponent={
        validatePermission('ALTERAR_VEICULOS') &&
        <Editable
          isEditing={isEditing}
          setEditing={setEditing}
          onSave={saveData}
          isLoading={loading}
        />
      }
      loading={vehicle.isFetching}
    >
      {isEditing ? (
        <Inputs />
      ) : (
        <Row>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Proprietário do documento
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.owner?.label || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Beneficiário do veículo
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.beneficiary?.label || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Proprietário RNTRC
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.anttOwner?.label || 'Não informado'}
            </Text>
          </Col>
          <Col xs={12}>
            <Text type="label" color="#464E5F">
              Frota
            </Text>
            <Text type="regular" color="gray" as="p" className="mt-2">
              {vehicle.data?.owner_fleet === 0 ? 'Não integra frota' : 'Integra frota'}
            </Text>
          </Col>
        </Row>
      )}
    </SharedData>
  );
}
