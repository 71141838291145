import * as Yup from 'yup';

const monitoringEquipmentsSchemaMinRequired = Yup.object().shape({
  baits: Yup.array().when('bait_quantity', {
    is: value => value > 0,
    then: Yup.array()
      .transform(current => {
        if (current === null) {
          return [];
        }
        return current;
      })
      .min(1, 'Informe ao menos um modelo de isca').typeError('Informe no mínimo um modelo'),
    otherwise: Yup.array().nullable().optional(),
  }),
  immobilizers: Yup.array().nullable().optional(),
  locators: Yup.array().nullable().optional(),
  tecnologies: Yup.array()
    .nullable()
    .min(1, 'Informe no mínimo uma tecnologia'),
  equipments: Yup.array().when('tecnologies', {
    is: tecnologies => tecnologies?.length > 0,
    then: Yup.array().min(1, 'Informe no mínimo um modelo').typeError('Informe no mínimo um modelo'),
    otherwise: Yup.array().nullable().optional(),
  }),
  name: Yup.string()
    .nullable()
    .min('1', 'Nome com no mínimo 1 caracter')
    .required('Informe nome do grupo')
    .typeError('Informe nome do grupo'),
  escorted: Yup.boolean().when(
    ['baits', 'immobilizers', 'locators', 'equipments', 'tecnologies', 'name'],
    {
      is: (baits, immobilizers, locators, equipments, tecnologies, name) =>
        name?.length > 0 ||
        baits?.length > 0 ||
        immobilizers?.length > 0 ||
        locators?.length > 0 ||
        equipments?.length > 0 ||
        tecnologies?.length > 0,
      then: Yup.boolean()
        .transform(current => {
          if (current === null) {
            return undefined;
          }
          return current;
        })
        .required('Informe se é escoltado'),
      otherwise: Yup.boolean().nullable().optional(),
    }
  ),
});

const monitoringEquipmentsSchemaOptional = Yup.object().shape({
  baits: Yup.array().when('bait_quantity', {
    is: value => value > 0,
    then: Yup.array()
      .transform(current => {
        if (current === null) {
          return [];
        }
        return current;
      })
      .min(1, 'Informe ao menos um modelo de isca'),
    otherwise: Yup.array().nullable().optional(),
  }),
  immobilizers: Yup.array().nullable().optional(),
  locators: Yup.array().nullable().optional(),
  tecnologies: Yup.array().nullable().optional(),
  equipments: Yup.array().when('tecnologies', {
    is: tecnologies => tecnologies?.length > 0,
    then: Yup.array().min(1, 'Informe no mínimo um modelo'),
    otherwise: Yup.array().nullable().optional(),
  }),
  name: Yup.string().when(
    ['baits', 'immobilizers', 'locators', 'equipments', 'tecnologies'],
    {
      is: (baits, immobilizers, locators, equipments, tecnologies) =>
        baits?.length > 0 ||
        immobilizers?.length > 0 ||
        locators?.length > 0 ||
        equipments?.length > 0 ||
        tecnologies?.length > 0,
      then: Yup.string()
        .nullable()
        .min('1', 'Nome com no mínimo 1 caracter')
        .required('Informe nome do grupo')
        .typeError('Informe nome do grupo'),
      otherwise: Yup.string().nullable().optional(),
    }
  ),
  escorted: Yup.boolean().when(
    ['baits', 'immobilizers', 'locators', 'equipments', 'tecnologies', 'name'],
    {
      is: (baits, immobilizers, locators, equipments, tecnologies, name) =>
        name?.length > 0 ||
        baits?.length > 0 ||
        immobilizers?.length > 0 ||
        locators?.length > 0 ||
        equipments?.length > 0 ||
        tecnologies?.length > 0,
      then: Yup.boolean()
        .transform(current => {
          if (current === null) {
            return undefined;
          }
          return current;
        })
        .required('Informe se é escoltado'),
      otherwise: Yup.boolean().nullable().optional(),
    }
  ),
});

const RangesSchema = Yup.object().shape({
  policy_range_min: Yup.string().required('Valor inicial obrigatório!'),
  policy_range_max: Yup.string()
    .transform(current => {
      const parsed = current?.replace(/[^\d,]/g, '').replace(/,/g, '.');
      return Number(parsed) > 0 ? parsed : undefined;
    })
    .required('Valor final obrigatório!'),

  tracked: Yup.boolean()
    .transform(current => {
      if (current === null) {
        return undefined;
      }
      return current;
    })
    .required('Informe se é rastreado'),
  restrition_third: Yup.boolean()
    .transform(current => {
      if (current === null) {
        return undefined;
      }
      return current;
    })
    .required('Informe se é restrito para terceiros'),
  manager_contact: Yup.string()
    .max(255, 'Máximo de 255 caracteres')
    .optional()
    .nullable(),
  driver_guide: Yup.string()
    .max(255, 'Máximo de 255 caracteres')
    .optional()
    .nullable(),
  checklist_validity: Yup.string()
    .max(255, 'Máximo de 255 caracteres')
    .optional()
    .nullable(),
  monitoringEquipaments: Yup.array()
    .nullable()
    .when('tracked', {
      is: true,
      then: Yup.array()
        .of(monitoringEquipmentsSchemaMinRequired)
        .required('Informe pelo menos um grupo de monitoramento')
        .min(1, 'Informe pelo menos um grupo de monitoramento'),
      otherwise: Yup.array().of(monitoringEquipmentsSchemaOptional),
    }),
  manager_tracker: Yup.array().when('tracked', {
    is: true,
    then: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required('Selecione um rastreador'),
          name: Yup.string().required('Selecione um rastreador'),
        })
      )
      .min(1, 'Informe pelo menos um rastreador'),
    otherwise: Yup.array().nullable(),
  }),
});

const VehiclesSchema = Yup.object().shape({
  vehicle_types: Yup.array().of(
    Yup.object().shape({
      type: Yup.string(),
      id: Yup.number(),
    })
  ),
  ranges: Yup.array()
    .of(RangesSchema)
    .required('Mínimo de uma faixa de valor')
    .min(1, 'Mínimo de uma operação'),
});

const RoutesSchema = Yup.object().shape({
  name: Yup.string(),
  origin: Yup.object().shape({
    id: Yup.number(),
    type: Yup.string(),
  }),
  destination: Yup.object().shape({
    id: Yup.number(),
    type: Yup.string(),
  }),
});

const GeneralItemSchema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string(),
});

const ProductsSchema = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required(),
    })
  ),
  routes: Yup.array()
    .of(RoutesSchema)
    .transform(current => {
      if (current === null || current === undefined) {
        return undefined;
      }
      return current;
    })
    .required('Mínimo de uma rota')
    .min(1, 'Mínimo de uma rota'),
  responsibility_security: Yup.array()
    .of(GeneralItemSchema)
    .transform(current => {
      if (current === null || current === undefined) {
        return undefined;
      }
      return current;
    })
    .required('Mínimo de um responsável')
    .min(1, 'Mínimo de um responsável'),
  vehicles: Yup.array()
    .of(VehiclesSchema)
    .required('Mínimo de um veículo')
    .min(1, 'Mínimo de um veículo'),
});

const OperationSchema = Yup.object().shape({
  name: Yup.string()
    .transform(current => {
      if (current === '') {
        return undefined;
      }
      return current;
    })
    .required('Nome obrigatório'),
  products: Yup.array()
    .of(ProductsSchema)
    .required('Mínimo de um produto')
    .min(1, 'Mínimo de um produto'),
});

const CustomersSchema = Yup.object().shape({
  id: Yup.number().required(),
  label: Yup.string().required('Nome obrigatório'),
});

export const PolicySchema = Yup.object().shape({
  currency: Yup.string().required('Informe a moeda da apólice'),
  policy_name: Yup.string()
    .transform(current => {
      if (current === '') {
        return undefined;
      }
      return current;
    })
    .required('Nome obrigatório'),
  due_date: Yup.string()
    .test('not-empty', 'Informe uma data', value => {
      if (!value) {
        return false;
      }
      return true;
    })
    .test('valid-date', 'Formato inválido', value => {
      return /^\d{4}\-\d{2}\-\d{2}$/.test(value);
    })
    .test(
      'valid-future-date',
      ({ originalValue }) => {
        return originalValue !== undefined && originalValue !== ''
          ? 'Data não pode ser anterior/igual à data de hoje'
          : 'Informe uma data';
      },
      value => {
        if (!value) {
          return false;
        }

        const inputDate = new Date(value);
        const currentDate = new Date();
        return inputDate > currentDate;
      }
    ),
  customers: Yup.array()
    .of(CustomersSchema)
    .required('Mínimo de um cliente')
    .min(1, 'Mínimo de um cliente'),
  operations: Yup.array()
    .of(OperationSchema)
    .required('Mínimo de uma operação')
    .min(1, 'Mínimo de uma operação'),
});

export const RiskAreaSchema = Yup.object().shape({
  radius: Yup.string()
    .transform((currentValue, originalValue) => {
      return originalValue === '' || originalValue === null
        ? undefined
        : currentValue;
    })
    .required('Raio obrigatório'),
  city: Yup.object()
    .shape({
      id: Yup.number().required(
        'Cidade não cadastrada na base Cooperplace. Solicite cadastro para prosseguir com apólice'
      ),
      name: Yup.string()
        .transform((currentValue, originalValue) => {
          return originalValue === '' || originalValue === null
            ? undefined
            : currentValue;
        })
        .required('Informe uma cidade'),
      lat: Yup.number().required(
        'Selecione um município utilizando o seletor do google'
      ),
      lng: Yup.number().required(
        'Selecione um município utilizando o seletor do google'
      ),
    })
    .transform((currentValue, originalValue) => {
      return originalValue === '' || originalValue === null
        ? undefined
        : currentValue;
    })
    .required('Informe uma cidade'),
});

export const GRORouteSchema = Yup.object().shape({
  origin: Yup.object().shape({
    id: Yup.number().required('Informe uma origem'),
    type: Yup.string().required('Selecione um tipo de origem'),
    name: Yup.string(),
  }),
  destination: Yup.object().shape({
    id: Yup.number().required('Informe um destino'),
    type: Yup.string().required('Selecione um tipo de destino'),
    name: Yup.string(),
  }),
});

export const attachmentSchema = Yup.object().shape({
  name: Yup.string()
    .max(127, 'Máximo 127 caracteres')
    .required('Nome obrigatório'),
});
