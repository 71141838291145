import React from 'react';
import { Link, Text } from 'v3/components';
import { capitalizeFullname, formatDateTime } from 'v3/utils/formatter';
import { OccurrenceDescriptions } from 'v3/components/OccurrencesModal/OccurrenceInfo/OccurrenceDescriptions';
import {
  GeneralInfo,
  HeaderContainer,
  OccurrenceContentContainer,
  OccurrenceDetailsContainer,
} from '../styles';

export function OccurrenceCard({ occurrence }) {
  return (
    <OccurrenceDetailsContainer>
      <HeaderContainer>
        <Text
          type="header"
          weight={500}
          color="dark"
        >{`Ocorrência ${occurrence.id}`}</Text>
        <Text type="header" weight={500} color="dark">
          {`Status da ocorrência - ${
            occurrence?.occurrencePendency &&
            !occurrence?.occurrenceUserConclusion
              ? 'Pendente'
              : 'Concluída'
          }`}
        </Text>
        <Link
          to={`/viagens/${occurrence?.travelId}`}
          target="_blank"
          withoutIcon
        >
          <Text type="header" color="dark" weight={500}>
            Viagem:{' '}
          </Text>

          <Text
            type="header"
            color="dark"
            weight={400}
            style={{ textDecoration: 'underline' }}
          >
            {`${occurrence?.travelId}`}
          </Text>
        </Link>
      </HeaderContainer>
      <OccurrenceContentContainer>
        <GeneralInfo>
          <div>
            <Text type="body" color="dark" weight={500}>
              Incluído por:{' '}
            </Text>
            <Text type="body" color="dark" weight={400}>
              {capitalizeFullname(occurrence?.occurrenceUserCreated)}
            </Text>
          </div>
          <div>
            <Text type="body" color="dark" weight={500}>
              Data inclusão:{' '}
            </Text>
            <Text type="body" color="dark" weight={400}>
              {formatDateTime(occurrence?.occurrenceCreatedAt)}
            </Text>
          </div>
          <div>
            <Text type="body" color="dark" weight={500}>
              Data início:{' '}
            </Text>
            <Text type="body" color="dark" weight={400}>
              {formatDateTime(occurrence?.occurrenceStartDate)}
            </Text>
          </div>
          <div>
            <Text type="body" color="dark" weight={500}>
              Data término:{' '}
            </Text>
            <Text type="body" color="dark" weight={400}>
              {occurrence?.occurrenceEndDate
                ? formatDateTime(occurrence?.occurrenceEndDate)
                : 'Não informado'}
            </Text>
          </div>
          <div>
            <Text type="body" color="dark" weight={500}>
              Tipo:{' '}
            </Text>
            <Text type="body" color="dark" weight={400}>
              {occurrence?.occurrenceType}
            </Text>
          </div>
          <div>
            <Text type="body" color="dark" weight={500}>
              Grupo Responsável:{' '}
            </Text>
            <Text type="body" color="dark" weight={400}>
              {capitalizeFullname(occurrence?.occurrenceGroupResponsible)}
            </Text>
          </div>
          <div>
            <Text type="body" color="dark" weight={500}>
              Cliente Visualiza:{' '}
            </Text>
            <Text type="body" color="dark" weight={400}>
              {occurrence?.occurrenceDisplayClient ? 'Sim' : 'Não'}
            </Text>
          </div>
          <div>
            <Text type="body" color="dark" weight={500}>
              Valor:{' '}
            </Text>
            <Text type="body" color="dark" weight={400}>
              {occurrence?.occurrenceValue ?? 'Não informado'}
            </Text>
          </div>
          <div>
            <Text type="body" color="dark" weight={500}>
              Possui anexos relacionados:{' '}
            </Text>
            <Text type="body" color="dark" weight={400}>
              {occurrence?.hasAttachments !== null ? 'Sim' : 'Não'}
            </Text>
          </div>
          <div>
            <Link
              to={`/cargas/${occurrence.loadId}`}
              target="_blank"
              withoutIcon
            >
              <Text type="body" color="dark" weight={500}>
                Carga:{' '}
              </Text>

              <Text
                type="body"
                color="dark"
                weight={400}
                style={{ textDecoration: 'underline' }}
              >
                {`${occurrence?.loadId}`}
              </Text>
            </Link>
          </div>
        </GeneralInfo>
        <div>
          <Text type="body" color="dark" weight={500}>
            Itens adicionais:{' '}
          </Text>
          <Text type="body" color="dark" weight={400}>
            {occurrence?.aditionalItems
              ?.map(aditional => {
                return `${aditional?.name}: ${aditional?.value}`;
              })
              .join(' - ')}
          </Text>
        </div>
        <OccurrenceDescriptions
          descriptions={occurrence?.descriptions}
          label="Logs da ocorrência"
        />
      </OccurrenceContentContainer>
    </OccurrenceDetailsContainer>
  );
}
