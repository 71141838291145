import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTravel } from 'v3/pages/Travel';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { Text } from 'v3/components';
import { ToggleDiv } from '../styles';

export function FooterDetails() {
  const travel = useTravel();

  const [toggleObs, setToggleObs] = useState({
    policy: false,
    coopercarga: false,
    driver: false,
    riskManagerContact: false,
  });
  return (
    <Col md={12} className="my-2">
      <Row md={12}>
        <Col md={6} sm={12} className="my-2">
          <ToggleDiv
            onClick={() =>
              setToggleObs(old => ({ ...old, policy: !old?.policy }))
            }
          >
            {!toggleObs?.policy ? <FaArrowUp /> : <FaArrowDown />}
            <Text color="dark" weight={500}>
              Observações apólice
            </Text>
          </ToggleDiv>
          {!toggleObs?.policy ? (
            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel?.data?.policyInfo?.range?.obs || 'Não informado'}
            </Text>
          ) : null}
        </Col>
        <Col md={6} sm={12} className="my-2">
          <ToggleDiv
            onClick={() =>
              setToggleObs(old => ({ ...old, coopercarga: !old?.coopercarga }))
            }
          >
            {!toggleObs?.coopercarga ? <FaArrowUp /> : <FaArrowDown />}
            <Text color="dark" weight={500}>
              Observações Coopercarga
            </Text>
          </ToggleDiv>
          {!toggleObs?.coopercarga ? (
            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel?.data?.policyInfo?.range.obsCompany || 'Não informado'}
            </Text>
          ) : null}
        </Col>
        <Col md={6} sm={12} className="my-2">
          <ToggleDiv
            onClick={() =>
              setToggleObs(old => ({ ...old, driver: !old?.driver }))
            }
          >
            {!toggleObs?.driver ? <FaArrowUp /> : <FaArrowDown />}
            <Text color="dark" weight={500}>
              Observações motorista
            </Text>
          </ToggleDiv>
          {!toggleObs?.driver ? (
            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel?.data?.policyInfo?.range?.driverGuide ||
                'Não informado'}
            </Text>
          ) : null}
        </Col>
        <Col md={6} sm={12} className="my-2">
          <ToggleDiv
            onClick={() =>
              setToggleObs(old => ({
                ...old,
                riskManagerContact: !old?.riskManagerContact,
              }))
            }
          >
            {!toggleObs?.riskManagerContact ? <FaArrowUp /> : <FaArrowDown />}
            <Text color="dark" weight={500}>
              Contatos gerenciadoras
            </Text>
          </ToggleDiv>
          {!toggleObs?.riskManagerContact ? (
            <Text type="regular" color="gray" as="p" className="mt-2">
              {travel?.data?.policy?.policyRange?.managerContact ||
                'Não informado'}
            </Text>
          ) : null}
        </Col>
      </Row>
    </Col>
  );
}
