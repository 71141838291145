import React, { useState, useRef } from 'react';
import FormFile from 'react-bootstrap/FormFile';

import Label from 'components/Label';

import { BsUpload } from 'react-icons/bs';
import Text from '../Text';

import { ContainerFile, IconContainer, TextContainer } from './styles';

const MAX_FILE_SIZE = 1000000;

function UploadInput({
  name,
  label,
  onChange,
  id,
  accept = '*',
  error,
  ignoreValidation,
}) {
  const inputRef = useRef();

  const [sizeFileGreater, setSizeFileGreater] = useState(false);
  const [fileName, setFileName] = useState('');

  function handleFileName(files) {
    if (files?.length > 1) {
      setFileName(`${files?.length} arquivos selecionados`);
    } else {
      const [file] = files;
      setFileName(file?.name);
    }
  }

  function handleChange(event) {
    const files = [...event.target.files];

    const hasFileGreater = files.some(file => file.size > MAX_FILE_SIZE);
    if (!ignoreValidation && hasFileGreater) {
      setSizeFileGreater(true);
      return;
    }

    onChange(event, hasFileGreater);
    handleFileName(files);
    setSizeFileGreater(false);
  }

  return (
    <>
      <Label htmlFor={name} className="textLabel">
        {label}
      </Label>
      <ContainerFile display={12} onClick={() => inputRef.current.click()}>
        <FormFile.Input
          ref={inputRef}
          name={name}
          id={id}
          onChange={handleChange}
          accept={accept}
        />
        <TextContainer>
          <IconContainer>
            <BsUpload size={18} color="#494950" />
          </IconContainer>
          <Text fontSize={14} type="label">
            Selecione
          </Text>
        </TextContainer>

        {error ? (
          <Text color="error" type="label">
            {error}
          </Text>
        ) : sizeFileGreater ? (
          <Text color="error" type="label">
            Limite de tamanho ultrapassado.
          </Text>
        ) : (
          <Text color="#494950" type="label">
            {fileName}
          </Text>
        )}
      </ContainerFile>
    </>
  );
}

export default UploadInput;
