/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { BsEye, BsChatDots } from 'react-icons/bs';
import { FaHistory, FaTruck } from 'react-icons/fa';

import moment from 'moment';

import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import List from 'v3/components/BulletList';
import Tooltip from 'v3/components/Tooltip';

import {
  validateBranchOffice,
  validatePolicyValidity,
  validateStartSchedule,
} from 'v3/validators/loadValidators';
import { formatCurrency } from 'v3/utils/formatter';
import { useSnackbar } from 'v3/components/Snackbar';
import Space from 'v3/components/Space';
import { validatePermission } from '../../../../../actions/index';
import ModalRenewLoad from './ModalRenewLoad';

import * as Controller from './controller';
import * as Styled from './styles';

export default function CardLoad({ load, index }) {
  const history = useHistory();
  const snackbar = useSnackbar();

  const [show, setShow] = useState(false);
  const [showRenewalModal, setShowRenewalModal] = useState(false);

  const handleCloseAllDestinations = () => setShow(false);
  const handleShowAllDestinations = () => setShow(true);
  const loadCurrencyInformation = {
    currencyName: load?.loadCurrency,
    currencySymbol: load?.loadCurrency === 'BRL' ? 'R$' : '$',
  };

  function handleCloseModal() {
    setShowRenewalModal(false);
  }

  const ModalDestinations = () => (
    <Modal
      scrollable
      show={show}
      onHide={handleCloseAllDestinations}
      heading={
        <Text type="header" className="ml-3">
          Todos os destinos
        </Text>
      }
      body={
        <Row md={12}>
          <Col md={6} className="pl-5">
            <List items={Controller.locationsObject(true, load)} />
          </Col>
        </Row>
      }
    />
  );

  function snackbarShow(text, typeShow) {
    snackbar.show(<Text className="text-center">{text}</Text>, {
      type: typeShow,
      duration: 5000,
    });
  }

  const Header = () => (
    <div className="d-flex" style={{ flexWrap: 'wrap', gap: '0.25rem' }}>
      <Text>Carga - {load.id}</Text>
      {load.preLoad ? (
        <div>
          <Styled.Badge pill light type="preload">
            <Text type="label">Pré Carga</Text>
          </Styled.Badge>
        </div>
      ) : null}
      {!!load.deletedAt && (
        <div>
          <Styled.Badge pill light type="error">
            <Text type="label">Cancelada</Text>
          </Styled.Badge>
        </div>
      )}
      {!!load.travel?.id && (
        <div>
          <Styled.Badge pill light type="success">
            <Text type="label">Atendida</Text>
          </Styled.Badge>
        </div>
      )}
      {load?.travel?.travelStatus?.code === 'BLOCKED' && (
        <div>
          <Styled.Badge pill light type="error">
            <Text type="label">Bloqueado</Text>
          </Styled.Badge>
        </div>
      )}
      {!load.travel?.id && !moment(load.dueDate).isAfter(moment()) && (
        <div>
          <Styled.Badge pill light type="warning">
            <Text type="label">Vencida</Text>
          </Styled.Badge>
        </div>
      )}
      <div>
        <Styled.Badge pill outlined type="dark">
          <Styled.VisualizationSpan>
            <Text
              id={`label-total-visualizacoes-${index}`}
              type="label"
              title="Total de visualizações"
            >
              {load.visualizationsCount}
              <BsEye style={{ marginLeft: 3 }} />
            </Text>
          </Styled.VisualizationSpan>
          <Styled.DealSpan>
            <Text
              id={`label-total-negociacoes-${index}`}
              type="label"
              title="Total de negociações"
            >
              {load.dealsCount}
              <BsChatDots style={{ marginLeft: 3 }} />
            </Text>
          </Styled.DealSpan>
          <Styled.DriversSpan>
            <Text
              id={`label-total-motoristas-${index}`}
              type="label"
              title="Total de motoristas notificados"
            >
              {load.totalDriversSentMatch}
              <FaTruck style={{ marginLeft: 3 }} />
            </Text>
          </Styled.DriversSpan>
        </Styled.Badge>
      </div>
    </div>
  );

  return (
    <Col xs={12}>
      <ModalRenewLoad
        show={showRenewalModal}
        load={load}
        dueDate={load.dueDate}
        handleClose={handleCloseModal}
      />
      <Card
        HeaderWrap="nowrap"
        header={<Header />}
        HeaderRightComponent={
          <Space x="0.25rem">
            <Text type="label" color="gray">
              Criado por {load.userCreated && load.userCreated.username} -{' '}
              {moment.utc(load.createdAt).format('DD/MM/YYYY')}
            </Text>
            {!load.deletedAt && !load.travel?.id && (
              <Button
                size="sm"
                onClick={() => {
                  const isValidated = validateStartSchedule(
                    load.dueDate,
                    load.loadOrigins[0].scheduledTime,
                    load.loadDestinations[load.loadDestinations.length - 1]
                      .scheduledTime,
                    snackbarShow,
                    load?.costCenter.limitSchedule
                  );

                  const isGROLoadAndValid =
                    load?.loadPolicy?.id &&
                    validatePolicyValidity(
                      load?.loadPolicy?.policy?.dueDate,
                      snackbarShow
                    );

                  if (load?.loadPolicy?.id && !isGROLoadAndValid) {
                    return;
                  }

                  if (isValidated === true) {
                    validateBranchOffice(
                      history,
                      snackbarShow,
                      load?.branchOffice?.active,
                      load.id
                    );
                  }
                }}
              >
                <Text color="white" type="regular">
                  Atender
                </Text>
              </Button>
            )}
            <Link to={`/cargas/${load.id}`}>
              <Button variant="secondary" size="sm">
                <Text type="regular">Detalhes</Text>
              </Button>
            </Link>
            {validatePermission('CADASTRAR_CARGAS') &&
              (!load?.travel || load?.travel?.statusId === 7) && (
                <Tooltip content={<Text>Renovar</Text>}>
                  <Button
                    variant="info"
                    size="sm"
                    onClick={() => setShowRenewalModal(true)}
                    id={`button-renovar-carga-${index}`}
                  >
                    <FaHistory />
                  </Button>
                </Tooltip>
              )}
          </Space>
        }
      >
        <ModalDestinations />
        <Row>
          <Col lg={6} md={6} xs={12}>
            <Row>
              <Col xs={12} className="load-list-align mb-2">
                <Text className="d-block load-label-align mb-2">Cliente Tomador</Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {load.taker && load.taker.socialName}
                </Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {load.taker && load.taker.cpfCnpj}
                </Text>
              </Col>
              <Col xs={12}>
                <List
                  items={Controller.locationsObject(
                    false,
                    load,
                    handleShowAllDestinations
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Row>
              <Col lg={6} md={6} xs={12} className="mb-3">
                <Text className="d-block load-label-align mb-2">
                  Negociador
                </Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {load?.negotiators?.length > 0
                    ? load?.negotiators?.map(item => item?.username).join(', ')
                    : 'Não informado'}
                </Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {load?.userCreated && load.userCreated.phonenumber}
                </Text>
              </Col>
              <Col lg={6} md={6} xs={12} className="mb-3">
                <Text className="d-block load-label-align mb-2">Valor</Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {load.fareCompany
                    ? formatCurrency(
                      load.fareCompany,
                      loadCurrencyInformation.currencyName,
                      loadCurrencyInformation.currencySymbol
                    )
                    : 'A combinar'}
                </Text>
              </Col>
              <Col lg={6} md={6} xs={12} className="mb-3">
                <Text className="d-block load-label-align mb-2">Produto</Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {load.product && load?.product?.name}
                  {' - '}
                  {load?.productType && load?.productType?.name}
                </Text>
                <Text color="gray" type="regular" className="d-block mb-1">
                  {load.loadWeight && load.loadWeight} Kg
                </Text>
              </Col>
              {load.idExterno && (
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <Text className="d-block load-label-align mb-2">
                    ID Externo
                  </Text>
                  <Text color="gray" type="regular" className="d-block mb-1">
                    {load.idExterno}
                  </Text>
                </Col>
              )}
              <Col xs={12}>
                {load?.tags && load?.tags?.length > 0 && (
                  <>
                    <Text className="d-block load-label-align mb-2">Tags</Text>
                    <div className="d-flex flex-wrap">
                      {load?.tags?.map(tag => (
                        <div className="mr-1 mb-1">
                          <Styled.Badge pill variant="secondary">
                            <Text type="label">{tag?.name}</Text>
                          </Styled.Badge>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}
