import styled, { css } from 'styled-components';
import theme from 'v3/theme';

import Text from '../Text';

export const Container = styled.div`
  width: 100%;
`;

export const Control = styled.div`
  align-items: center;
  background-color: #fff;
  border: 1px solid #e5eaee;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  font-family: Poppins;
  letter-spacing: 0.02em;
  min-height: 44px;
  font-size: 0.875rem;

  ${props =>
    props.children[0].props.isDisabled &&
    css`
      background-color: #e5eaee;
    `}

  ${props =>
    props.children[0].props.isMulti &&
    css`
      height: auto;
    `}

  ${({ error }) =>
    error &&
    css`
      border-color: #f64e60;
    `};

  @media (max-width: 768px) {
    height: 44px;
  }
`;

export const IndicatorsContainer = styled.div`
  position: absolute;
  right: 0;
  top: 1px;
  background-color: #fff;
  border-left: 1px solid #e5eaee;

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: #f64e60 !important;
    `};
`;

export const Label = styled(Text)`
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 3px;
  display: flex;
  white-space: ${props => props.labelWhiteSpace};
`;

export const ToolTipLabel = styled(Text)`
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 3px;
  display: flex;
  text-align: ${props => props.tooltipAlign};
  white-space: ${props => props.toolTipWhiteSpace};
`;
export const OptionLabel = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `};
`;

export const ErrorText = styled(Text)`
  margin-bottom: 5px;
  ${props =>
    props.color === 'error' &&
    css`
      color: #f64e60 !important;
    `};
`;

export const ValueContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 0.275rem 0.5rem;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  max-height: ${props =>
    props.selectValuesContainerMaxHeight
      ? props.selectValuesContainerMaxHeight
      : 'auto'};
  overflow-y: ${props =>
    props.selectValuesContainerOverflowY
      ? props.selectValuesContainerOverflowY
      : 'hidden'};
`;

export const MultiValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
  background-color: #e3e4e7;
  border-radius: 15px;
  padding: 5px 8px;
  & > span {
    white-space: ${props => props.multiValueWhiteSpace};
  }
`;

export const MultiValueRemove = styled.button`
  align-items: center;
  display: flex;
  margin-left: 5px;
  background-color: transparent;
  padding: 0 3px;
`;

export const Placeholder = styled.div`
  margin: 0 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: ${theme.colors.placeholder};
`;

export const SelectAllLink = styled(Text)`
  display: block;
  cursor: pointer;
  text-decoration: underline;
  padding: 4px 4px 2px;
`;
