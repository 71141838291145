import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import Modal from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Upload from 'v3/components/Upload';
import Text from 'v3/components/Text';
import { useProgressBar } from 'v3/components/ProgressBarContext/context';

export function MonitoringAttachmentModal({
  isOpen,
  onClose,
  fetchAttachments,
}) {
  const { setProgressBar, removeProgressBar } = useProgressBar();
  const params = useParams();
  const snackbar = useSnackbar();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  function handleClose() {
    setFiles([]);
    setErrors({});
    onClose();
  }

  async function sendFile(fieldName, file) {
    const formData = new FormData();
    formData.append('file', file);
    setProgressBar(fieldName, `Enviando arquivo ${file.name}`, 0);

    try {
      await cooperplaceApi.post(
        `travels/${params.id}/monitoring-files`,
        formData,
        {
          onUploadProgress: progressEvent => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setProgressBar(
              fieldName,
              `Enviando arquivo ${file.name}`,
              progress
            );
          },
        }
      );
    } finally {
      removeProgressBar(fieldName);
    }
  }

  async function updateAttachment() {
    try {
      setLoading(true);

      const promises = [];
      files.forEach((file, index) => {
        promises.push(sendFile(`file_${index}`, file.file.blob));
      });

      await Promise.all(promises);

      snackbar.show(
        <Text weight={500} color="dark">
          Anexos SM logística cadastrado com sucesso
        </Text>,
        {
          type: 'success',
        }
      );

      handleClose();
      fetchAttachments();
    } catch (error) {
      let message = 'Algo deu errado. Tente novamente mais tarde';
      if (error?.response?.status === 422) {
        message = error?.response?.data?.errors.join('\n');
      } else if (error?.response?.data?.message) {
        message = error?.response?.data?.message;
      }
      snackbar.show(
        <Text weight={500} color="dark">
          {message}
        </Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  function onFilesChanged({ target }, hasError) {
    setFiles([]);
    setErrors({});

    if (hasError) {
      setErrors({ file: 'Algum arquivo selecionado é maior que 1MB.' });
      return;
    }

    setFiles(
      [...target.files].map(file => {
        return {
          file: {
            blob: file,
            size: file?.size,
            name: file?.name,
            type: file?.type,
            tempPath: URL.createObjectURL(file),
          },
          description: file.name,
        };
      })
    );
  }

  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="subtitle" color="dark" weight="400">
          Anexos
        </Text>
      }
      body={
        <div>
          <div className="mb-3">
            <Text>Adicione um arquivo</Text>
            <Upload
              name="file"
              label="Arquivo máximo de 1MB"
              accept=".jpg,.jpeg,.png,.csv,.pdf,.xls,.xlsx"
              value={files}
              onChange={onFilesChanged}
              error={errors.file}
              ignoreValidation
            />
          </div>
          <div className="d-flex flex-column align-items-center mt-3">
            <Button
              disabled={!files.length}
              onClick={updateAttachment}
              loading={loading}
            >
              <Text type="regular" weight={500}>
                Adicionar
              </Text>
            </Button>
          </div>
        </div>
      }
    />
  );
}
