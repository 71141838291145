import styled from 'styled-components';
import Tabs from 'react-bootstrap/Tabs';
import { Nav } from 'react-bootstrap';
import theme from 'v3/theme';

export const BSTabs = styled(Tabs)`
  a {
    padding: ${props => props.linkPadding};
    color: ${props => props.linkColor} !important;

    &.active {
      color: ${props => props.linkActiveColor} !important;
      background: ${props => props.linkActiveBackgroundColor} !important;
      font-weight: ${props => props.linkWeight};
    }
  }
`;

export const BSTabsButton = styled(Nav.Link)`
  padding: ${props => props.linkPadding};
  color: ${props => props.linkColor} !important;

  &.active {
    color: ${props => props.linkActiveColor} !important;
    background: ${props => props.linkActiveBackgroundColor} !important;
    font-weight: ${props => props.linkWeight};
  }

  ${(props) => props.error && `border: 1px solid ${theme.colors.danger} !important;`}
`;

export const TabWrapper = styled.div`
  .nav-tabs {
    border-bottom: none;
  }

  .nav-link {
    border-color: ${props => props.linkBorder};
  }

  .tab-content {
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    border-radius: 0px 5px 5px 5px;
  }
`;
