import React, { useState } from 'react';
import { Button, Input, Select, Text } from 'v3/components';
import { fetchDriver, fetchUsers } from 'utils/fetches';
import { Row, Col } from 'react-bootstrap';
import DatePicker from 'v3/components/DatePicker';

export default function Filter({ setFilters, filters, submit }) {
  const [selectedDriver, setSelectedDriver] = useState();
  const [selectedUserCreated, setSelectedUserCreated] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const phoneUnmask = value => {
    return value.replace(/\D/g, '');
  };

  const formatDateToSave = date => {
    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
  };

  async function handleSubimit() {
    const params = {
      page: 1,
      perPage: filters.perPage,
      ...(filters?.typeId && { typeId: filters?.typeId }),
      ...(filters?.loadId && { loadId: filters?.loadId }),
      ...(phoneNumber && { phonenumber: phoneUnmask(phoneNumber) }),
      ...(startDate && { startDate: formatDateToSave(startDate) }),
      ...(endDate && { endDate: formatDateToSave(endDate) }),
      ...(selectedDriver && { personId: selectedDriver.id }),
      ...(selectedUserCreated && { userCreated: selectedUserCreated.id }),
    };
    setFilters(params);
    await submit(params);
  }

  return (
    <Row className="d-flex align-items-end mb-5">
      <Col lg={2} md={3} sm={6} xs={12} className="mb-3">
        <Select.Async
          modalHeading="Selecione o usuario"
          modalBodyTitle="Enviado por:"
          horizontal
          label="Enviado por"
          labelColor="#fff"
          onSearch={value => fetchUsers(value, 10)}
          value={selectedUserCreated}
          onChange={value => {
            setSelectedUserCreated(value);
          }}
          getOptionLabel={option => option.username}
          getOptionValue={option => option.id}
        />
      </Col>
      <Col lg={2} md={3} sm={6} xs={12} className="mb-3">
        <Select.Async
          modalHeading="Selecione um motorista"
          modalBodyTitle="Motoristas:"
          horizontal
          label="Motorista"
          labelColor="#fff"
          onSearch={value => fetchDriver(value, 10)}
          value={selectedDriver}
          onChange={value => {
            setSelectedDriver(value);
          }}
          getOptionLabel={option => option.social_name}
          getOptionValue={option => option.id}
        />
      </Col>
      <Col lg={2} md={3} sm={6} xs={12} className="mb-3">
        <Input
          label="Numero de Telefone"
          labelColor="#fff"
          value={phoneNumber}
          onChange={e => {
            setPhoneNumber(e.target.value);
          }}
          placeholder="Digite o telefone"
        />
      </Col>

      <Col lg={2} md={3} sm={6} xs={12} className="mb-3">
        <DatePicker
          value={startDate}
          onChange={e => {
            setStartDate(e.target.value);
          }}
          label="Data inicial"
          labelColor="#fff"
        />
      </Col>

      <Col lg={2} md={3} sm={6} xs={12} className="mb-3">
        <DatePicker
          value={endDate}
          onChange={e => {
            setEndDate(e.target.value);
          }}
          label="Data final"
          labelColor="#fff"
        />
      </Col>

      <Col lg={2} md={3} sm={6} xs={12} className="mb-3">
        <Button
          variant="success"
          onClick={() => {
            handleSubimit();
          }}
          className="w-100"
        >
          <Text type="regular" weight={500}>
            Filtrar
          </Text>
        </Button>
      </Col>
    </Row>
  );
}
