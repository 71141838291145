import styled from 'styled-components';

export const FleetManagementHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem 0.5rem;
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: 1rem 0.5rem;
  flex-wrap: wrap;
  align-items: start;
  & > div {
    width: 300px;
  }
`;

export const SecondHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  & > div {
    display: flex;
    gap: 4px;
    flex-shrink: 0;
  }
`;
