import React, { useState } from 'react';
import { ManagersContainer } from 'v3/pages/Policy/styles';
import { Select, Text } from 'v3/components';
import { sanitize } from 'dompurify';
import CheckBox from 'components/CheckBox';
import { cooperplaceFetchSelectOptions } from 'utils/fetches';
import { usePolicyRegister } from '../../context';
import { RangeSectionToggleHeader } from './RangeSectionToggleHeader';

export function ManagersSection({
  opIndex,
  productIndex,
  vehicleIdx,
  rangeIdx,
  range,
}) {
  const { handleAddRangeItem, windowWidth, riskManagersOptions, errors } =
    usePolicyRegister();
  function handleSearchRiskManagers(search) {
    if (!search) {
      return riskManagersOptions;
    }
    const parsed = search?.toLocaleLowerCase();
    return riskManagersOptions?.filter(
      option => option?.name?.toLocaleLowerCase().search(parsed) >= 0
    );
  }
  const [profileOptions, setProfileOptions] = useState([]);
  return (
    <>
      <RangeSectionToggleHeader
        opIndex={opIndex}
        productIndex={productIndex}
        vehicleIdx={vehicleIdx}
        rangeIdx={rangeIdx}
        keyName="showManagers"
        keyValue={range?.showManagers}
        label="Gerenciadoras"
      />

      {range?.showManagers && (
        <ManagersContainer>
          <div>
            <Select.Async
              closeMenuOnSelect={false}
              labelColor="dark"
              label="Perfil segurança"
              value={range?.security_profile}
              placeholder="Busque um perfil"
              multiple
              horizontal={windowWidth <= 992}
              modalHeading="Perfis"
              modalBodyTitle="Busque um perfil"
              onSearch={async search => {
                setProfileOptions([]);
                const data = await cooperplaceFetchSelectOptions(
                  'security-profiles/securityProfilesByCodeNumberOrName',
                  {
                    search: sanitize(search),
                  }
                );
                setProfileOptions(data);
                return data;
              }}
              onBlur={() => setProfileOptions([])}
              options={profileOptions}
              onChange={value => {
                handleAddRangeItem(
                  opIndex,
                  productIndex,
                  vehicleIdx,
                  rangeIdx,
                  'security_profile',
                  value
                );
              }}
              getOptionLabel={option => option.name ?? option?.label}
              getOptionValue={option => option.id}
              selectValuesContainerMaxHeight="240px"
              selectValuesContainerOverflowY="scroll"
            />
            <Select.Async
              labelColor="dark"
              label={`GR para rastreamento ${range?.tracked ? '*' : ''}`}
              value={range?.manager_tracker}
              placeholder="Selecione"
              horizontal={windowWidth <= 992}
              modalHeading="GRs"
              modalBodyTitle="Selecione GR"
              options={riskManagersOptions}
              onChange={value =>
                handleAddRangeItem(
                  opIndex,
                  productIndex,
                  vehicleIdx,
                  rangeIdx,
                  'manager_tracker',
                  value ? [value] : []
                )
              }
              getOptionLabel={option => option.name ?? option?.label}
              getOptionValue={option => option.id}
              selectValuesContainerMaxHeight="200px"
              selectValuesContainerOverflowY="scroll"
              onSearch={search => handleSearchRiskManagers(search)}
              error={
                errors[
                  `operations[${opIndex}].products[${productIndex}].vehicles[${vehicleIdx}].ranges[${rangeIdx}].manager_tracker`
                ]
              }
            />

            <Select.Async
              closeMenuOnSelect={false}
              labelColor="dark"
              label="Espelhamento logístico"
              value={range?.manager_mirror}
              placeholder="Selecione"
              multiple
              horizontal={windowWidth <= 992}
              modalHeading="GRs"
              modalBodyTitle="Selecione um espelhamento"
              options={riskManagersOptions}
              onChange={value =>
                handleAddRangeItem(
                  opIndex,
                  productIndex,
                  vehicleIdx,
                  rangeIdx,
                  'manager_mirror',
                  value
                )
              }
              getOptionLabel={option => option.name ?? option?.label}
              getOptionValue={option => option.id}
              selectValuesContainerMaxHeight="200px"
              selectValuesContainerOverflowY="scroll"
              onSearch={search => handleSearchRiskManagers(search)}
            />
            <Select.Async
              closeMenuOnSelect={false}
              labelColor="dark"
              label="Realizar checklist em gerenciadora"
              value={range?.check_manager}
              placeholder="Selecione"
              multiple
              horizontal={windowWidth <= 992}
              modalHeading="GRs"
              modalBodyTitle="Selecione um espelhamento"
              options={riskManagersOptions}
              onChange={value =>
                handleAddRangeItem(
                  opIndex,
                  productIndex,
                  vehicleIdx,
                  rangeIdx,
                  'check_manager',
                  value
                )
              }
              getOptionLabel={option => option.name ?? option?.label}
              getOptionValue={option => option.id}
              selectValuesContainerMaxHeight="200px"
              selectValuesContainerOverflowY="scroll"
              onSearch={search => handleSearchRiskManagers(search)}
            />
            <Select.Async
              closeMenuOnSelect={false}
              labelColor="dark"
              label="Validar checklist em gerenciadora"
              value={range?.validity_check_manager}
              placeholder="Selecione"
              multiple
              horizontal={windowWidth <= 992}
              modalHeading="GRs"
              modalBodyTitle="Selecione um espelhamento"
              options={riskManagersOptions}
              onChange={value =>
                handleAddRangeItem(
                  opIndex,
                  productIndex,
                  vehicleIdx,
                  rangeIdx,
                  'validity_check_manager',
                  value
                )
              }
              getOptionLabel={option => option.name ?? option?.label}
              getOptionValue={option => option.id}
              selectValuesContainerMaxHeight="200px"
              selectValuesContainerOverflowY="scroll"
              onSearch={search => handleSearchRiskManagers(search)}
            />
            <div>
              <Text as="span" color="dark" type="label" weight={500}>
                Checklist Rastreador
              </Text>
              <div>
                <CheckBox
                  checked={range?.checklist_tracker === 'EXPRESSO' ?? false}
                  onChange={() =>
                    handleAddRangeItem(
                      opIndex,
                      productIndex,
                      vehicleIdx,
                      rangeIdx,
                      'checklist_tracker',
                      range?.checklist_tracker === 'EXPRESSO'
                        ? 'NAO_SELECIONADO'
                        : 'EXPRESSO'
                    )
                  }
                  label="Expresso"
                />
              </div>

              <div>
                <CheckBox
                  checked={range?.checklist_tracker === 'TELEFONICO' ?? false}
                  onChange={() =>
                    handleAddRangeItem(
                      opIndex,
                      productIndex,
                      vehicleIdx,
                      rangeIdx,
                      'checklist_tracker',
                      range?.checklist_tracker === 'TELEFONICO'
                        ? 'NAO_SELECIONADO'
                        : 'TELEFONICO'
                    )
                  }
                  label="Telefônico"
                />
              </div>
            </div>
          </div>
        </ManagersContainer>
      )}
    </>
  );
}
