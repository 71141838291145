import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { Box } from '@material-ui/core';
import theme from 'v3/theme';
import { FaTruck, FaMapMarkedAlt, FaHandshake } from 'react-icons/fa';
import { formatDateTime } from 'v3/utils/formatter';
import { capitalize } from 'pages/relatorios/acompanhamento-viagem/MuiTable/utils';

import { Button } from 'antd';
import { Button as ButtonV3 } from 'v3/components';
import { StyledCell } from './styles';

function formatCurrency(value) {
  const number = parseFloat(value);
  return `R$ ${number.toFixed(2).replace('.', ',')}`;
}

export function Grid({ driverData, setShowCargoOfferModal, setShowAggregationOfferModal, setDriverId }) {
  function getValue(cell, capitalizeText = false) {
    if (capitalizeText) {
      return capitalize(cell.getValue()) ?? 'Não informado';
    }
    return cell.getValue() ?? 'Não informado';
  }
  const columns = useMemo(
    () => [
      {
        header: 'Ofertar',
        accessorKey: 'offer',
        id: 'offer',
        Cell: ({ row }) => {
          return (
            <>
              <ButtonV3
                style={{ marginRight: '10px' }}
                title="Ofertar Carga"
                onClick={() => {setShowCargoOfferModal(true); setDriverId(row.original.id)}}
              >
                <FaTruck
                  size={16}
                  color="white"
                />
              </ButtonV3>
              <ButtonV3
                title="Ofertar Agregamento"
                onClick={() => {setShowAggregationOfferModal(true); setDriverId(row.original.id)}}
              >
                <FaHandshake
                  size={16}
                  color="white"
                />
              </ButtonV3>
            </>
          );
        },
      },
      {
        header: 'Ativo no Portal',
        accessorKey: 'ativo_portal',
        id: 'ativo_portal',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Ativo no App',
        accessorKey: 'ativo_app',
        id: 'ativo_app',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Motorista/CPF',
        accessorKey: 'motorista',
        id: 'motorista',
        Cell: ({ cell, row }) => {
          return (
            <Link
              to={`/motoristas/${row.original.id}`}
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
              target="_blank"
            >
              {getValue(cell)}
            </Link>
          );
        },
      },
      {
        header: 'Telefone',
        accessorKey: 'telefone',
        id: 'telefone',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Telefone cooperplace',
        accessorKey: 'telefone_cooperplace',
        id: 'telefone_cooperplace',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Placa tração',
        accessorKey: 'veiculo_tracao',
        id: 'veiculo_tracao',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Tipo de tração',
        accessorKey: 'tipo_tracao',
        id: 'tipo_tracao',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Placas implementos',
        accessorKey: 'placa_implemento',
        id: 'placa_implemento',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Tipo de implementos',
        accessorKey: 'tipo_implemento',
        id: 'tipo_implemento',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Tipo de carrocerias',
        accessorKey: 'carroceria',
        id: 'carroceria',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Quantidade de viagens',
        accessorKey: 'quantidade_viagens',
        id: 'quantidade_viagens',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '0'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Dias desde a ultima viagem',
        accessorKey: 'ultima_viagem',
        id: 'ultima_viagem',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Faturamento Bruto das viagens do motorista',
        accessorKey: 'faturamento_viagens',
        id: 'faturamento_viagens',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${formatCurrency(
                getValue(cell)
              )}`}
            >
              {cell.getValue() !== null &&
              cell.getValue() !== undefined &&
              cell.getValue() !== ''
                ? formatCurrency(getValue(cell))
                : '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Id da última viagem do motorista',
        accessorKey: 'id_ult_viagem',
        id: 'id_ult_viagem',
        Cell: ({ cell }) => {
          return (
            (getValue(cell) !== "Não informado" ?
            <Link
              to={`/viagens/${getValue(cell)}`}
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
              target="_blank"
            >
              {getValue(cell)}
            </Link>
            : '-')
          );
        },
      },
      {
        header: 'Negociador da última viagem',
        accessorKey: 'negociador_ult_viagem',
        id: 'negociador_ult_viagem',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'PCP da última viagem',
        accessorKey: 'pcp_ult_viagem',
        id: 'pcp_ult_viagem',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Centro de custo da última viagem',
        accessorKey: 'cc_ult_viagem',
        id: 'cc_ult_viagem',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'Ultima localização',
        accessorKey: 'last_location',
        id: 'last_location',
        Cell: ({ row }) => {
          return (
            <StyledCell
              title={`${row.original.longitude} ${row.original.latitude}`}
            >
              {row.original.latitude && row.original.longitude ? (
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${row.original.latitude},${row.original.longitude}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button>
                    <FaMapMarkedAlt size="22" />
                  </Button>
                </a>
              ) : (
                '-'
              )}
            </StyledCell>
          );
        },
      },
      {
        header: 'Data e hora da ultima localização',
        accessorKey: 'data_posicao',
        id: 'data_posicao',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() ? formatDateTime(cell.getValue()) : '-'}
            </StyledCell>
          );
        },
      },
      {
        header: 'avaliação do motorista',
        accessorKey: 'geral',
        id: 'geral',
        Cell: ({ cell }) => {
          return (
            <StyledCell
              title={`${cell.column.columnDef.header}:${' '} ${getValue(cell)}`}
            >
              {cell.getValue() || '-'}
            </StyledCell>
          );
        },
      },
    ],
    []
  );

  function insertNewColumns(columnsOrder, newColumns) {
    const newColumnsOrder = [...columnsOrder];
    newColumns.forEach(item => {
      newColumnsOrder.push(item);
    });
    return newColumnsOrder;
  }

  function initializeColumnOrder() {
    const driverColumnOrderPreference = JSON.parse(
      localStorage.getItem('driverColumnOrderPreference')
    );
    const columnsSlugs = columns.map(item => item.id);
    const newColumnsInGrid = [];
    columnsSlugs.forEach(item => {
      if (!driverColumnOrderPreference?.includes(item)) {
        newColumnsInGrid.push(item);
      }
    });
    if (driverColumnOrderPreference && newColumnsInGrid.length === 0) {
      return driverColumnOrderPreference;
    }
    if (driverColumnOrderPreference && newColumnsInGrid.length > 0) {
      const newOrder = insertNewColumns(
        driverColumnOrderPreference,
        newColumnsInGrid
      );
      return newOrder;
    }
    return columns
      .map(c => c.accessorKey)
      .sort(function (a, b) {
        return a - b;
      });
  }

  function initializeColumnSizing() {
    const driverColumnSizingPreference = JSON.parse(
      localStorage.getItem('driverColumnSizingPreference')
    );
    if (driverColumnSizingPreference) {
      return driverColumnSizingPreference;
    }
    return {};
  }

  const [columnOrder, setColumnOrder] = useState(() => initializeColumnOrder());
  const [columnSizing, setColumnSizing] = useState(() =>
    initializeColumnSizing()
  );

  useEffect(() => {
    localStorage.setItem(
      'driverColumnOrderPreference',
      JSON.stringify(columnOrder)
    );
  }, [columnOrder]);

  useEffect(() => {
    localStorage.setItem(
      'driverColumnSizingPreference',
      JSON.stringify(columnSizing)
    );
  }, [columnSizing]);

  return (
    <MaterialReactTable
      columns={columns}
      data={driverData}
      enableColumnOrdering
      enableColumnDragging
      enableColumnResizing
      enableColumnActions={false}
      renderTopToolbar={({ table }) => <Box style={{ padding: '1rem 0' }} />}
      initialState={{
        density: 'compact',
        pagination: {
          pageSize: 100,
        },
      }}
      state={{ columnOrder, columnSizing }}
      onColumnOrderChange={setColumnOrder}
      onColumnSizingChange={setColumnSizing}
      defaultColumn={{
        maxSize: 400,
        minSize: 80,
        size: 150,
      }}
      enableStickyHeader
      muiTableBodyProps={{
        sx: {
          textAlign: 'center',
        },
      }}
      muiTablePaperProps={{
        sx: {
          boxShadow: 'none',
          zIndex: 0,
          position: 'relative',
        },
      }}
      muiTableContainerProps={{
        sx: {
          maxHeight: '100%',
          '&:hover::-webkit-scrollbar': {
            width: 10,
            height: 10,
          },
          '&:hover::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
          '&:hover::-webkit-scrollbar-thumb': {
            backgroundColor: theme.colors.medium_gray,
            borderRadius: 2,
          },
          '&:not(:hover)::-webkit-scrollbar': {
            opacity: 0,
            width: 10,
            height: 10,
          },
          '&:not(:hover)::-webkit-scrollbar-thumb': {
            opacity: 0,
            backgroundColor: 'transparent',
          },
        },
      }}
      muiTablePaginationProps={{
        labelRowsPerPage: 'Veículos por página',
        rowsPerPageOptions: [50, 100, 500, 1000],
      }}
      localization={MRT_Localization_PT_BR}
      muiTableHeadCellDragHandleProps={{
        sx: {
          marginRight: '0.75rem',
        },
      }}
    />
  );
}
