import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, Input, Modal, Radio, Select, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { fetchBranchOffices, fetchDriver, shippingCompany } from 'utils/fetches';
import { onSearchNegotiator } from 'pages/relatorios/acompanhamento-viagem/utils';
import moment from 'moment';
import { useFleetManagement } from '../../shared';

function FilterBody({ setTemporaryFilters, filtersOptions, temporaryFilters }) {
  const snackbar = useSnackbar();

  const searchBranchOffices = async search => {
    try {
      const branchOffices = await fetchBranchOffices(search);
      return branchOffices;
    } catch (error) {
      snackbar.show(
        <Text>Erro ao buscar filiais, tente novamente mais tarde</Text>,
        { type: 'error' }
      );
      return null;
    }
  };

  const searchOperator = async name => {
    try {
      const operators = await onSearchNegotiator(name);
      return operators;
    } catch (error) {
      snackbar.show(
        <Text>
          Erro ao buscar opções de operador, tente novamente mais tarde
        </Text>,
        { type: 'error' }
      );
      return null;
    }
  };

  const searchDriver = async name => {
    try {
      const drivers = await fetchDriver(name);
      return drivers;
    } catch (error) {
      snackbar.show(
        <Text>
          Erro ao buscar opções de motorista, tente novamente mais tarde
        </Text>,
        { type: 'error' }
      );
      return null;
    }
  };

  const carriers = async name => {
    try {
      const carriers = await shippingCompany(name);
      return carriers;
    } catch (error) {
      snackbar.show(
        <Text>
          Erro ao buscar opções de transportador, tente novamente mais tarde
        </Text>,
        { type: 'error' }
      );
      return null;
    }
  };

  return (
    <Row>
      <Col md={6} sm={12}>
        <Input
          label="Previsão disponibilidade de"
          type="date"
          onChange={({ target: { value: startAvailabilityForecast } }) =>
          {
            setTemporaryFilters(old => ({ ...old, startAvailabilityForecast: moment(startAvailabilityForecast).format('DD/MM/YYYY') }))
          }
          }
          value={moment(temporaryFilters.startAvailabilityForecast, 'DD/MM/YYYY').format('YYYY-MM-DD')}
        />
      </Col>
      <Col md={6} sm={12}>
        <Input
          label="Previsão disponibilidade até"
          type="date"
          onChange={({ target: { value: endAvailabilityForecast } }) =>{
            setTemporaryFilters(old => ({ ...old, endAvailabilityForecast: moment(endAvailabilityForecast).format('DD/MM/YYYY') }))
          }
          }
          value={moment(temporaryFilters.endAvailabilityForecast, 'DD/MM/YYYY').format('YYYY-MM-DD')}
        />
      </Col>
      <Col md={6} sm={12}>
        <Select
          label="Tipo veículo"
          options={filtersOptions.vehicleTypes}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          multiple
          onChange={vehicleTypes =>
            setTemporaryFilters(old => ({
              ...old,
              vehicleTypes: vehicleTypes || [],
            }))
          }
          value={temporaryFilters.vehicleTypes}
        />
      </Col>
      <Col md={6} sm={12}>
        <Select
          label="Tipo implemento"
          options={filtersOptions.implementTypes}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          multiple
          onChange={implementTypes =>
            setTemporaryFilters(old => ({
              ...old,
              implementTypes: implementTypes || [],
            }))
          }
          value={temporaryFilters.implementTypes}
        />
      </Col>
      <Col md={6} sm={12}>
        <Select
          label="Tipo carroceria"
          options={filtersOptions.bodyTypes}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          multiple
          onChange={bodyTypes =>
            setTemporaryFilters(old => ({
              ...old,
              bodyTypes: bodyTypes || [],
            }))
          }
          value={temporaryFilters.bodyTypes}
        />
      </Col>
      <Col md={6} sm={12}>
        <Select
          label="Agregamento"
          options={filtersOptions.aggregation}
          onChange={aggregation =>
            setTemporaryFilters(old => ({ ...old, aggregation }))
          }
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          value={temporaryFilters.aggregation}
        />
      </Col>
      <Col md={6} sm={12}>
        <Select.Async
          label="Filial origem"
          onSearch={searchBranchOffices}
          getOptionLabel={option => `${option.id} - ${option.name}`}
          getOptionValue={option => option.id}
          onChange={branchOffices =>
            setTemporaryFilters(old => ({
              ...old,
              branchOffices: branchOffices || [],
            }))
          }
          value={temporaryFilters.branchOffices}
          multiple
        />
      </Col>
      <Col md={6} sm={12}>
        <Select
          label="Status viagem"
          options={filtersOptions.travelStatus}
          getOptionValue={option => option.id}
          getOptionLabel={option => option.name}
          multiple
          onChange={travelStatus =>
            setTemporaryFilters(old => ({
              ...old,
              travelStatus: travelStatus || [],
            }))
          }
          value={temporaryFilters.travelStatus}
        />
      </Col>
      <Col md={6} sm={12}>
        <Select.Async
          onSearch={searchDriver}
          label="Motorista"
          onChange={drivers =>
            setTemporaryFilters(old => ({ ...old, drivers: drivers || [] }))
          }
          getOptionValue={option => option.id}
          getOptionLabel={option => option.label}
          value={temporaryFilters.drivers}
          multiple
        />
      </Col>
      <Col md={6} sm={12}>
        <Select.Async
          onSearch={carriers}
          label="Transportador"
          getOptionValue={option => option.id}
          getOptionLabel={option => `${option.socialName} - ${option.cpfCnpj}`}
          multiple
          value={temporaryFilters.shipping}
          onChange={shipping =>
            setTemporaryFilters(old => ({ ...old, shipping: shipping || [] }))
          }
        />
      </Col>
      <Col md={6} sm={12}>
        <Select.Async
          onSearch={searchOperator}
          label="Operador"
          getOptionValue={option => option.id}
          getOptionLabel={option => option.username}
          multiple
          value={temporaryFilters.operators}
          onChange={operators =>
            setTemporaryFilters(old => ({ ...old, operators: operators || [] }))
          }
        />
      </Col>
      <Col md={6} sm={12}>
        <Radio.Group
          label="Ativo"
          horizontal
          onChange={({ target: { value: active } }) =>
            setTemporaryFilters(old => ({ ...old, active }))
          }
          value={temporaryFilters.active}
        >
          <Radio value={1}>
            <Text color="dark" type="label">
              Sim
            </Text>
          </Radio>
          <Radio value={0}>
            <Text color="dark" type="label">
              Não
            </Text>
          </Radio>
          <Radio value={-1}>
            <Text color="dark" type="label">
              Todos
            </Text>
          </Radio>
        </Radio.Group>
      </Col>
      <Col md={6} sm={12}>
        <Radio.Group
          horizontal
          label="Com ocorrências pendentes"
          onChange={({ target: { value: withOccurrences } }) =>
            setTemporaryFilters(old => ({ ...old, withOccurrences }))
          }
          value={temporaryFilters.withOccurrences}
        >
          <Radio value={1}>
            <Text color="dark" type="label">
              Sim
            </Text>
          </Radio>
          <Radio value={0}>
            <Text color="dark" type="label">
              Não
            </Text>
          </Radio>
          <Radio value={-1}>
            <Text color="dark" type="label">
              Todos
            </Text>
          </Radio>
        </Radio.Group>
      </Col>
    </Row>
  );
}

export default function AdvancedFilterModal({ show, onClose }) {
  const { filters, filtersOptions, setFilters, handleGetData, loading } =
    useFleetManagement();
  const [temporaryFilters, setTemporaryFilters] = useState(filters);

  const handleClose = () => {
    setTemporaryFilters(filters);
    onClose();
  };

  const handleConfirm = () => {
    setFilters(temporaryFilters);
    handleGetData({
      ...temporaryFilters,
      page: 1
    });
    onClose();
  };

  useEffect(() => {
    setTemporaryFilters(filters);
  }, [filters]);

  return (
    <Modal
      show={show}
      handleClose={handleClose}
      heading="Filtro avançado"
      body={
        <FilterBody
          temporaryFilters={temporaryFilters}
          setTemporaryFilters={setTemporaryFilters}
          filtersOptions={filtersOptions}
        />
      }
      footer={
        <Row>
          <Col style={{ gap: 8 }} className="d-flex justify-content-end">
            <Button loading={loading} onClick={onClose} variant="secondary">
              Cancelar
            </Button>
            <Button loading={loading} onClick={handleConfirm} className="ms-2">
              Aplicar
            </Button>
          </Col>
        </Row>
      }
    />
  );
}
