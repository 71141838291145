import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Select from 'v3/components/Select';
import Radio from 'v3/components/Radio';
import { fetchSelectOptions, LoadContext } from '../controller';

const [, useLoad] = LoadContext;

export default function Monitoring() {
  const load = useLoad();

  const [tracked, setTracked] = useState(false);
  const [riskManager, setRiskManager] = useState([]);
  const [isEscorted, setIsEscorted] = useState(false);

  useEffect(() => {
    const riskManagerParse = riskManager.length > 0 ? riskManager : null;
    load.setData(old => ({
      ...old,
      tracked,
      riskManager: riskManagerParse,
      isEscorted,
    }));
  }, [tracked, riskManager, isEscorted]);

  const trueVar = true;

  return (
    <Card
      className="full-height-card"
      header={
        <Text color="#464E5F" type="header">
          Monitoramento
        </Text>
      }
    >
      <Row>
        <Col lg={6} md={6} xs={12} className="mb-3">
          <Radio.Group
            label="Rastreado"
            onChange={({ target }) => {
              setTracked(target.value);
              if (!target.value) {
                setRiskManager([]);
              }
            }}
            value={tracked}
            horizontal
          >
            <Radio id="radio-rastreado-sim" value>
              <Text color="dark" type="label">
                Sim
              </Text>
            </Radio>
            <Radio id="radio-rastreado-nao" value={false}>
              <Text color="dark" type="label">
                Não
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
        <Col md={6} xs={12} className="mb-3">
          {tracked && (
            <Select.Async
              label="GR para rastreamento *"
              onSearch={search =>
                fetchSelectOptions('risk-manager-for-select', {
                  search,
                })
              }
              value={riskManager}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              onChange={value => {
                if (Array.isArray(value)) {
                  setRiskManager(value);
                } else {
                  setRiskManager(value ? [value] : []);
                }
              }}
              multiple
              id="select-liberacoes-gr"
              error={load?.errors?.riskManager}
            />
          )}
        </Col>

        <Col md={12} xs={12} className="mb-3">
          <Radio.Group
            label="Carga escoltada"
            onChange={({ target }) => setIsEscorted(target.value)}
            value={isEscorted}
            horizontal
          >
            <Radio id="radio-carga-escoltada-sim" value={trueVar}>
              <Text color="dark" type="label">
                Sim
              </Text>
            </Radio>
            <Radio id="radio-carga-escoltada-nao" value={false}>
              <Text color="dark" type="label">
                Não
              </Text>
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
    </Card>
  );
}
