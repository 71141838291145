import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CardRaw from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { validatePermission } from 'actions';
import { formatDateTime } from 'utils/formatter';
import AddAcceptanceCard from '../AddAcceptanceCard';

const hasPermissionToCreate = validatePermission('ADICIONAR_MANUAIS_E_TERMOS');

function AcceptanceCard({ acceptance, handleOpenHistory, reloadAcceptances }) {
  const [isAdding, setIsAdding] = useState(false);

  return (
    <>
      {isAdding ? (
        <AddAcceptanceCard
          acceptance={acceptance}
          setIsAdding={setIsAdding}
          reloadAcceptances={reloadAcceptances}
        />
      ) : (
        <Col md={6}>
          <CardRaw
            header={
              <div>
                <Text transform="uppercase" color="dark" type="regular">
                  {acceptance?.title || 'Não informado'}
                </Text>
              </div>
            }
            HeaderRightComponent={
              <div className="d-flex justify-content-end">
                <Button
                  variant="secondary"
                  className="py-2 mr-2"
                  onClick={async () =>
                    handleOpenHistory(acceptance?.code, acceptance?.title)
                  }
                >
                  <Text>Histórico</Text>
                </Button>
                {hasPermissionToCreate && (
                  <Button
                    variant="success"
                    className="py-2"
                    onClick={() => setIsAdding(true)}
                  >
                    <Text color="" type="regular" weight="500">
                      Adicionar
                    </Text>
                  </Button>
                )}
              </div>
            }
          >
            <Row>
              <Col md={6} className="mb-3">
                <Text className="d-block load-label-align my-2">Descrição</Text>
                <div
                  style={{
                    maxWidth: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Text color="gray" type="regular" className="d-block mb-1">
                    {acceptance?.description || 'Não informado'}
                  </Text>
                </div>
              </Col>
              <Col md={6} className="mb-3">
                <Text className="d-block load-label-align my-2">
                  Incluido por
                </Text>
                <Text type="regular" color="gray">
                  {acceptance?.user?.username || 'Não informado'}
                </Text>
              </Col>
              <Col md={6} className="mb-3">
                <Text className="d-block load-label-align my-2">
                  Incluido em
                </Text>
                <Text type="regular" color="gray">
                  {formatDateTime(acceptance.createdAt) || 'Não informado'}
                </Text>
              </Col>
              <Col md={6} className="mb-3">
                <Text className="d-block load-label-align my-2">Anexo</Text>
                <div
                  style={{
                    maxWidth: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Text type="regular" color="gray">
                    {(
                      <button
                        type="button"
                        onClick={() =>
                          window.open(
                            ` ${process.env.REACT_APP_BASE_STORAGE_URL}${acceptance?.media?.container}/${acceptance?.media?.path}`,
                            '_blank'
                          )
                        }
                        style={{
                          background: 'none',
                          border: 'none',
                          padding: 0,
                          textDecoration: 'underline',
                          color: 'blue',
                          cursor: 'pointer',
                        }}
                      >
                        {acceptance?.media?.name}
                      </button>
                    ) || 'Não informado'}
                  </Text>
                </div>
              </Col>
              <Col md={6} className="mb-3">
                <Text className="d-block load-label-align my-2">Versão</Text>
                <Text type="regular" color="gray">
                  {`V ${acceptance?.version.toFixed(2)}` || 'Não informado'}
                </Text>
              </Col>
              <Col md={6} className="mb-3">
                <Text className="d-block load-label-align my-2">Aceitos</Text>
                <Text type="regular" color="gray">
                  {acceptance?.acceptQuantity || 'Não informado'}
                </Text>
              </Col>
            </Row>
          </CardRaw>
        </Col>
      )}
    </>
  );
}

export default AcceptanceCard;
