import React, { useState } from 'react';
import { Col, Nav, Tab } from 'react-bootstrap';
import { sanitize } from 'dompurify';
import { Button, Fallback, Select, Text } from 'v3/components';
import {
  SectionContentHeader,
  SectionHeader,
  SecurityGeneralInfo,
} from 'v3/pages/Policy/styles';
import { MAX_PRODUCTS, fetchRoutes } from '../../utils';
import { usePolicyRegister } from '../../context';

import { InsertButton } from './InsertButton';
import { Ranges } from './Ranges';
import { SectionManagement } from './SectionManagement';
import { TabButton } from 'v3/components/Tabs';

export function RegisterSection() {
  const {
    policyClients,
    operations,
    handleOpenVehiclesModal,
    handleOpenProductsModal,
    windowWidth,
    handleAddProductItem,
    routeModal,
    setCurrentOpIndex,
    setCurrentPdIndex,
    setCurrentVehicleIndex,
    setDialogStates,
    setDialogPolicyInUse,
    errors,
    hasError,
    responsibilitySecurityOptions,
    operationModal,
    productsModal,
    vehicleModal,
    filterPolicyInUse,
  } = usePolicyRegister();

  function handleSearchResponsability(search) {
    if (!search) {
      return responsibilitySecurityOptions;
    }
    const parsed = search?.toLocaleLowerCase();
    return responsibilitySecurityOptions?.filter(
      option => option?.name?.toLocaleLowerCase().search(parsed) >= 0
    );
  }

  function handleRemoveOperation(opIndex) {
    const currentOperation = operations[opIndex];

    const operationInUse = filterPolicyInUse(
      'policy_operation_id',
      currentOperation?.id
    );
    if (operationInUse.length > 0) {
      setDialogPolicyInUse({
        open: true,
        label: 'operação',
        loads: operationInUse,
      });
      return;
    }

    setCurrentOpIndex(opIndex);
    setDialogStates(old => ({ ...old, op: true }));
  }

  function handleEditProduct(opIndex, productIndex) {
    const currentProduct = operations[opIndex]?.products[productIndex];
    const productInUse = filterPolicyInUse(
      'policy_product_id',
      currentProduct?.id
    );
    if (productInUse.length > 0) {
      setDialogPolicyInUse({
        open: true,
        label: 'produto',
        loads: productInUse,
      });
      return;
    }
    setCurrentOpIndex(opIndex);
    setCurrentPdIndex(productIndex);
    productsModal.open();
  }

  function handleEditVehicle(opIndex, productIndex, vehicleIndex) {
    const currentVehicle =
      operations[opIndex]?.products[productIndex]?.vehicles[vehicleIndex];
    const vehicleInUse = filterPolicyInUse(
      'policy_vehicle_id',
      currentVehicle?.id
    );
    if (vehicleInUse.length > 0) {
      setDialogPolicyInUse({
        open: true,
        label: 'veículo',
        loads: vehicleInUse,
      });
      return;
    }
    setCurrentOpIndex(opIndex);
    setCurrentPdIndex(productIndex);
    setCurrentVehicleIndex(vehicleIndex);
    vehicleModal.open();
  }

  function handleRemoveProduct(opIndex, productIndex) {
    const currentProduct = operations[opIndex]?.products[productIndex];
    const productInUse = filterPolicyInUse(
      'policy_product_id',
      currentProduct?.id
    );
    if (productInUse.length > 0) {
      setDialogPolicyInUse({
        open: true,
        label: 'produto',
        loads: productInUse,
      });
      return;
    }

    setCurrentOpIndex(opIndex);
    setCurrentPdIndex(productIndex);
    setDialogStates(old => ({
      ...old,
      product: true,
    }));
  }

  function handleRemoveVehicle(opIndex, productIndex, vehicleIdx) {
    const currentVehicle =
      operations[opIndex]?.products[productIndex]?.vehicles[vehicleIdx];
    const vehicleInUse = filterPolicyInUse(
      'policy_vehicle_id',
      currentVehicle?.id
    );
    if (vehicleInUse.length > 0) {
      setDialogPolicyInUse({
        open: true,
        label: 'veículo',
        loads: vehicleInUse,
      });
      return;
    }

    setCurrentOpIndex(opIndex);
    setCurrentPdIndex(productIndex);
    setCurrentVehicleIndex(vehicleIdx);
    setDialogStates(old => ({
      ...old,
      vehicle: true,
    }));
  }

  const [routesOptions, setRouteOptions] = useState([]);

  return (
    <Tab.Container
      defaultActiveKey={0}
      style={{
        maxWidth: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        fontSize: '0.75rem',
      }}
      linkActiveColor="dark"
      linkActiveBackgroundColor="grayish_blue"
      linkWeight="500"
      linkPadding="0.5rem 1rem"
    >
      <Nav variant="tabs" style={{
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        fontSize: '0.75rem',
      }}>
        {operations?.map((operation, operationIdx) => {
          return (
            <Nav.Item>
              <TabButton
                eventKey={operationIdx}
                linkActiveColor="dark"
                linkActiveBackgroundColor="grayish_blue"
                linkWeight="500"
                linkPadding="0.5rem 1rem"
                error={hasError(operationIdx)}
              >
                {operation.name}
              </TabButton>
            </Nav.Item>
          )
        })}
      </Nav>
      <Tab.Content>
        {operations?.map((op, opIndex) => {
          return (
            <Tab.Pane
              eventKey={opIndex}
              key={opIndex}
              style={{
                padding: '1rem 0.5rem',
              }}
            >
              <div>
                <SectionContentHeader>
                  <SectionManagement
                    onEditClick={() => {
                      setCurrentOpIndex(opIndex);
                      operationModal.open();
                    }}
                    onRemoveClick={() => handleRemoveOperation(opIndex)}
                    sectionLabel="operação"
                    disabled={!policyClients || policyClients?.length === 0}
                  />
                </SectionContentHeader>
                <SectionHeader>
                  <Text weight={500} color="dark" type="regular">
                    Produtos
                  </Text>
                  <InsertButton
                    onClick={() => {
                      handleOpenProductsModal(opIndex);
                    }}
                    label="Inserir produto"
                    disabled={op?.products?.length === MAX_PRODUCTS}
                    title={`${
                      op?.products?.length === MAX_PRODUCTS
                        ? 'Limite máximo de produtos atingido'
                        : 'Clique para inserir um novo produto'
                    }`}
                  />
                </SectionHeader>
                <Fallback
                  fall={
                    op?.products?.length === 0 ||
                    errors[`operations[${opIndex}].products`]
                  }
                  component={
                    <Col
                      xs={12}
                      className="pt-5 d-flex justify-content-center align-items-center"
                    >
                      {errors[`operations[${opIndex}].products`] ? (
                        <Text weight={500} color="danger">
                          Informe um produto
                        </Text>
                      ) : (
                        <Button size="md" disabled>
                          Não há produtos cadastrados
                        </Button>
                      )}
                    </Col>
                  }
                >
                  <Tab.Container
                    defaultActiveKey={0}
                    style={{
                      maxWidth: '100%',
                      display: 'flex',
                      flexWrap: 'nowrap',
                      overflowX: 'auto',
                      overflowY: 'hidden',
                      fontSize: '0.75rem',
                    }}
                    linkActiveColor="dark"
                    linkActiveBackgroundColor="grayish_blue"
                    linkWeight="500"
                    linkPadding="0.5rem 1rem"
                  >
                    <Nav variant="tabs" style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      overflowX: 'auto',
                      overflowY: 'hidden',
                      fontSize: '0.75rem',
                    }}>
                      {op?.products?.map((product, productIdx) => {
                        return (
                          <Nav.Item>
                            <TabButton
                              eventKey={productIdx}
                              linkActiveColor="dark"
                              linkActiveBackgroundColor="grayish_blue"
                              linkWeight="500"
                              linkPadding="0.5rem 1rem"
                              error={hasError(opIndex, productIdx)}
                            >
                              {product?.products
                                ?.map(
                                  item => item?.label ?? `${item?.code} - ${item?.name}`
                                )
                                ?.join(' | ')
                              }
                            </TabButton>
                          </Nav.Item>
                        )
                      })}
                    </Nav>
                    <Tab.Content>
                      {op?.products?.map((product, productIndex) => {
                        return (
                          <Tab.Pane
                            eventKey={productIndex}
                            key={productIndex}
                            style={{ padding: '1rem 0.5rem' }}
                          >
                            <div>
                              <SectionContentHeader>
                                <SectionManagement
                                  onEditClick={() => {
                                    handleEditProduct(opIndex, productIndex);
                                  }}
                                  onRemoveClick={() =>
                                    handleRemoveProduct(opIndex, productIndex)
                                  }
                                  sectionLabel="produto"
                                />
                              </SectionContentHeader>
                              <div>
                                <SecurityGeneralInfo>
                                  <div className="routeSection">
                                    <Select.Async
                                      closeMenuOnSelect={false}
                                      labelColor="dark"
                                      label="Rota *"
                                      value={product?.routes}
                                      placeholder="Busque uma rota"
                                      multiple
                                      horizontal={windowWidth <= 992}
                                      modalHeading="Rotas"
                                      modalBodyTitle="Busque uma rota"
                                      options={routesOptions}
                                      onBlur={() => setRouteOptions([])}
                                      onSearch={async search => {
                                        setRouteOptions([]);
                                        const data = await fetchRoutes(
                                          sanitize(search)
                                        );
                                        setRouteOptions(data);
                                        return data;
                                      }}
                                      onChange={value =>
                                        handleAddProductItem(
                                          opIndex,
                                          productIndex,
                                          value,
                                          'routes'
                                        )
                                      }
                                      getOptionLabel={option => option.name}
                                      getOptionValue={option => option.id}
                                      selectValuesContainerMaxHeight="240px"
                                      selectValuesContainerOverflowY="scroll"
                                      error={
                                        errors[
                                          `operations[${opIndex}].products[${productIndex}].routes`
                                        ]
                                      }
                                    />
                                    <div>
                                      <button
                                        type="button"
                                        onClick={() => routeModal.open()}
                                      >
                                        <Text color="dark" type="label">
                                          Cadastrar rota
                                        </Text>
                                      </button>
                                    </div>
                                  </div>
                                  <Select.Async
                                    closeMenuOnSelect={false}
                                    labelColor="dark"
                                    label="Responsabilidade seguro *"
                                    value={product?.responsibility_security}
                                    placeholder="CNPJ"
                                    multiple
                                    horizontal={windowWidth <= 992}
                                    modalHeading="CNPJs"
                                    modalBodyTitle="Selecione um cnpj"
                                    options={responsibilitySecurityOptions}
                                    onChange={value =>
                                      handleAddProductItem(
                                        opIndex,
                                        productIndex,
                                        value,
                                        'responsibility_security'
                                      )
                                    }
                                    getOptionLabel={option =>
                                      option.name || option?.label
                                    }
                                    getOptionValue={option => option.id}
                                    selectValuesContainerMaxHeight="240px"
                                    selectValuesContainerOverflowY="scroll"
                                    error={
                                      errors[
                                        `operations[${opIndex}].products[${productIndex}].responsibility_security`
                                      ]
                                    }
                                    onSearch={search =>
                                      handleSearchResponsability(search)
                                    }
                                  />
                                </SecurityGeneralInfo>
                                <SectionHeader>
                                  <Text weight={500} color="dark" type="regular">
                                    Veículos
                                  </Text>
                                  <InsertButton
                                    title="Clique para inserir novos veículos"
                                    onClick={() =>
                                      handleOpenVehiclesModal(opIndex, productIndex)
                                    }
                                    label="Inserir veículo"
                                  />
                                </SectionHeader>
                                <Fallback
                                  fall={
                                    product?.vehicles?.length === 0 ||
                                    errors[
                                      `operations[${opIndex}].products[${productIndex}].vehicles`
                                    ]
                                  }
                                  component={
                                    <Col
                                      xs={12}
                                      className="pt-5 d-flex justify-content-center align-items-center"
                                    >
                                      {errors[
                                        `operations[${opIndex}].products[${productIndex}].vehicles`
                                      ] ? (
                                        <Text color="danger" weight={500}>
                                          Informe no mínimo um veículo
                                        </Text>
                                      ) : (
                                        <Button size="md" disabled>
                                          Não há veículos cadastrados
                                        </Button>
                                      )}
                                    </Col>
                                  }
                                >
                                  <Tab.Container
                                    defaultActiveKey={0}
                                    style={{
                                      maxWidth: '100%',
                                      display: 'flex',
                                      flexWrap: 'nowrap',
                                      overflowX: 'auto',
                                      overflowY: 'hidden',
                                      fontSize: '0.75rem',
                                    }}
                                    linkActiveColor="dark"
                                    linkActiveBackgroundColor="grayish_blue"
                                    linkWeight="500"
                                    linkPadding="0.5rem 1rem"
                                  >
                                    <Nav variant="tabs" style={{
                                      display: 'flex',
                                      flexWrap: 'nowrap',
                                      overflowX: 'auto',
                                      overflowY: 'hidden',
                                      fontSize: '0.75rem',
                                    }}>
                                      {product?.vehicles?.map((vehicle, vehicleIdx) => {
                                        return (
                                          <Nav.Item>
                                            <TabButton
                                              eventKey={vehicleIdx}
                                              linkActiveColor="dark"
                                              linkActiveBackgroundColor="grayish_blue"
                                              linkWeight="500"
                                              linkPadding="0.5rem 1rem"
                                              error={hasError(opIndex, productIndex, vehicleIdx)}
                                            >
                                              {vehicle?.vehicles
                                                ?.map(item => item?.name)
                                                ?.join('-')
                                              }
                                            </TabButton>
                                          </Nav.Item>
                                        )
                                      })}
                                    </Nav>
                                    <Tab.Content>
                                      {product?.vehicles?.map(
                                        (vehicle, vehicleIdx) => {
                                          return (
                                            <Tab.Pane
                                              eventKey={vehicleIdx}
                                              key={vehicleIdx}
                                              style={{ padding: '1rem 0.5rem' }}
                                            >
                                              <SectionContentHeader>
                                                <SectionManagement
                                                  onEditClick={() => {
                                                    handleEditVehicle(
                                                      opIndex,
                                                      productIndex,
                                                      vehicleIdx
                                                    );
                                                  }}
                                                  onRemoveClick={() =>
                                                    handleRemoveVehicle(
                                                      opIndex,
                                                      productIndex,
                                                      vehicleIdx
                                                    )
                                                  }
                                                  sectionLabel="veículo"
                                                />
                                              </SectionContentHeader>
                                              <Ranges
                                                opIndex={opIndex}
                                                productIndex={productIndex}
                                                vehicleIdx={vehicleIdx}
                                                vehicle={vehicle}
                                              />
                                            </Tab.Pane>
                                          );
                                        }
                                      )}
                                    </Tab.Content>
                                  </Tab.Container>
                                </Fallback>
                              </div>
                            </div>
                          </Tab.Pane>
                        );
                      })}
                    </Tab.Content>
                  </Tab.Container>
                </Fallback>
              </div>
            </Tab.Pane>
          );
        })}
      </Tab.Content>
    </Tab.Container>
  );
}
