import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSnackbar } from 'v3/components/Snackbar';
import { cooperplaceApi } from 'services/api';
import { Button, Fallback, Text } from 'v3/components';
import { Col, Row } from 'react-bootstrap';
import theme from 'v3/theme';

import { Attachment } from './Attachment';
import { MonitoringAttachmentModal } from './MonitoringAttachmentModal';

export function MonitoringAttachment() {
  const params = useParams();
  const [attachments, setAttachments] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();

  async function fetchMonitoringFiles() {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get(
        `travels/${params.id}/monitoring-files`
      );
      setAttachments(response.data);
    } catch (error) {
      setAttachments([]);
    } finally {
      setLoading(false);
    }
  }

  async function removeAttachment(id) {
    try {
      setLoading(true);
      await cooperplaceApi.delete(
        `travels/${params.id}/monitoring-files/${id}`
      );
      snackbar.show(
        <Text weight={500} color="dark">
          Anexo SM logística excluído com sucesso
        </Text>,
        {
          type: 'success',
        }
      );

      await fetchMonitoringFiles();
    } catch (error) {
      snackbar.show(
        <Text weight={500} color="dark">
          Não foi possível excluir o anexo
        </Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchMonitoringFiles();
  }, []);

  return (
    <>
      <MonitoringAttachmentModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        fetchAttachments={fetchMonitoringFiles}
        setAttachments={setAttachments}
        attachments={attachments}
      />
      <Fallback
        component={
          <Col xs={12} className="d-flex flex-column align-items-center">
            <Text color={theme.colors.gray} type="label" as="p">
              Não há anexos SM logística cadastrado para essa viagem.
            </Text>
          </Col>
        }
        fall={!attachments?.length}
      >
        <Row>
          {attachments?.map(attachment => {
            return (
              <Col md={6}>
                <Attachment
                  attachment={attachment}
                  attachments={attachments}
                  removeAttachment={removeAttachment}
                  loading={loading}
                />
                <Text type="regular" className="mt-2">
                  {attachment?.description}
                </Text>
              </Col>
            );
          })}
        </Row>
      </Fallback>
      <div className='text-center'>
        {attachments?.length < 2 && (
          <Button onClick={() => setShowModal(true)} size="sm">
            Adicionar
          </Button>
        )}
      </div>
    </>
  );
}
