import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'v3/components/Snackbar';
import { fetchSelectTags } from 'pages/relatorios/acompanhamento-viagem/utils';
import { Button, DatePicker, Input, Select, Text } from 'v3/components';
import { cooperplaceApi } from 'services/api';
import { useModal } from 'hooks';
import { fetchGroupVehicles } from 'utils/fetches';
import debounce from 'lodash.debounce';
import {
  FiltersContainer,
  FleetManagementHeaderContainer,
  SecondHeaderContainer,
} from './styles';
import { useFleetManagement } from '../shared';
import AdvancedFilterModal from './AdvancedFilterModal';
import { validatePermission } from 'actions';
import moment from 'moment';

export default function Filters() {
  const hasPermissionRegiste = validatePermission('CADASTRAR_GESTAO_FROTA');
  const hasPermissionEdit = validatePermission('EDITAR_GESTAO_FROTA');
  const hasPermissionEmail = validatePermission('ENVIAR_EMAIL_GESTAO_FROTA');

  const {
    handleOpenFilterColumns,
    isEditing,
    setIsEditing,
    filtersOptions,
    setFilters,
    filters,
    loading,
    handleGetData,
    newRegisterModal,
    setEditedCells,
    sendEmailsModal,
    handleSave,
  } = useFleetManagement();
  const [dates, setDates] = useState({
    startDate: filters.startDate,
    endDate: filters.endDate,
  });
  const advancedFilterModal = useModal();
  const snackbar = useSnackbar();

  const vehicleType = {
    implement: 'Implemento',
    vehicle: 'Veículo',
  };

  const handleCancel = () => {
    setEditedCells([]);
    setIsEditing(false);
  };

  const handleNewRegister = () => {
    newRegisterModal.open();
  };

  const searchTags = async search => {
    try {
      const tags = await fetchSelectTags({
        not_paginated: true,
        search,
      });
      return tags;
    } catch (error) {
      snackbar.show(
        <Text>Erro ao buscar opções de tags, tente novamente mais tarde</Text>,
        { type: 'error' }
      );
      return null;
    }
  };

  const searchPlates = async search => {
    try {
      const { data: plates } = await cooperplaceApi.get('generic-plates', {
        params: {
          search,
        },
      });

      return plates;
    } catch (error) {
      snackbar.show(
        <Text>Erro ao buscar opções de tags, tente novamente mais tarde</Text>,
        { type: 'error' }
      );
      return null;
    }
  };

  const debouncedStateChange = useCallback(
    debounce(newDates => {
      setFilters(old => ({
        ...old,
        startDate: newDates.startDate,
        endDate: newDates.endDate,
      }));
    }, 300),
    [setFilters]
  );

  useEffect(() => {
    debouncedStateChange(dates);

    return () => {
      debouncedStateChange.cancel();
    };
  }, [dates, debouncedStateChange]);

  useEffect(() => {
    setDates({
      startDate: filters.startDate,
      endDate: filters.endDate,
    });
  }, [filters.startDate, filters.endDate]);

  return (
    <FleetManagementHeaderContainer>
      <FiltersContainer>
        <div>
          <Select
            options={filtersOptions.statusOperator}
            isDisabled={isEditing}
            label="Status Operacional"
            getOptionLabel={option => option.name}
            getOptionValue={option => option.slug}
            onChange={statusOperator =>
              setFilters(old => ({
                ...old,
                statusOperator: statusOperator || [],
              }))
            }
            value={filters.statusOperator}
            multiple
          />
        </div>
        <div>
          <Select.Async
            onSearch={searchTags}
            isDisabled={isEditing}
            onChange={tags => setFilters(old => ({ ...old, tags: tags || [] }))}
            value={filters.tags}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            multiple
            label="Operações"
          />
        </div>
        <div>
          <Select.Async
            onSearch={searchPlates}
            isDisabled={isEditing}
            label="Placas"
            getOptionLabel={option => {
              return `${option.plate} - ${vehicleType[option.vehicleType]}`;
            }}
            getOptionValue={option => option.id}
            onChange={plates =>
              setFilters(old => ({ ...old, plates: plates || [] }))
            }
            value={filters.plates}
            multiple
          />
        </div>
        <div>
          <Select.Async
            onSearch={fetchGroupVehicles}
            getOptionLabel={option => option.description}
            getOptionValue={option => option.id}
            onChange={fleet =>
              setFilters(old => ({ ...old, fleet: fleet || [] }))
            }
            value={filters.fleet}
            isDisabled={isEditing}
            label="Frota"
            multiple
          />
        </div>
        <div>
          <Input
            label="Data geral de"
            type="date"
            disabled={isEditing}
            onChange={({ target: { value: startDate } }) => {
              setDates(old => {
                const newDates = { ...old };    
                if (!startDate || startDate === 'Data inválida' ) {
                  delete newDates.startDate;
                } else {
                  newDates.startDate = moment(startDate).format('DD/MM/YYYY');
                }
            
                return newDates;
              });
            }}
            value={moment(dates.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')}
          />
        </div>
        <div>
          <Input
            label="Data geral até"
            type="date"
            disabled={isEditing}
            onChange={({ target: { value: endDate } }) => {
              setDates(old => {
                const newDates = { ...old };    
                if (!endDate || endDate === 'Data inválida' ) {
                  delete newDates.endDate;
                } else {
                  newDates.endDate = moment(endDate).format('DD/MM/YYYY');
                }
            
                return newDates;
              });
            }}
            value={moment(dates.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')}
          />
        </div>
        <Button
          style={{ marginTop: 31 }}
          disabled={isEditing}
          variant="success"
          loading={loading}
          onClick={() => handleGetData({
            page: 1,
          })}
        >
          Filtrar
        </Button>
        <Button
          style={{ marginTop: 31 }}
          disabled={isEditing}
          variant="secondary"
          onClick={() => advancedFilterModal.open()}
        >
          Filtros avançado
        </Button>
      </FiltersContainer>
      <SecondHeaderContainer>
        <div>
          {!isEditing && hasPermissionRegiste && (
            <Button disabled={isEditing} size="sm" onClick={handleNewRegister}>
              Incluir
            </Button>
          )}
          {isEditing && (
            <Button size="sm" onClick={handleCancel}>
              Cancelar
            </Button>
          )}
          {
            !isEditing && hasPermissionEdit && (
              <Button
                loading={loading}
                onClick={handleSave}
                size="sm"
                variant="success"
              >
                Editar
              </Button>
            )
          }
          {
            isEditing && (
              <Button
                loading={loading}
                onClick={handleSave}
                size="sm"
                variant="success"
              >
                Salvar
              </Button>
            )
          }
        </div>
        <div>
          <Button
            variant="primary"
            onClick={handleOpenFilterColumns}
            size="sm"
            disabled={isEditing}
          >
            <Text type="regular" weight={500} fontSize="0.75rem">
              Seleção de colunas
            </Text>
          </Button>
          {
            hasPermissionEmail && (
              <Button
                disabled={isEditing}
                variant="primary"
                size="sm"
                className="ml-1"
                onClick={sendEmailsModal.open}
              >
                <Text weight={500} color="white" type="regular" fontSize="0.75rem">
                  Email
                </Text>
              </Button>
            )
          }
        </div>
      </SecondHeaderContainer>
      <AdvancedFilterModal
        show={advancedFilterModal.isOpen}
        onClose={advancedFilterModal.close}
      />
    </FleetManagementHeaderContainer>
  );
}
