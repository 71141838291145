import React from 'react';
import * as Styled from './styles';
import theme from 'v3/theme';

export default function Tabs({
  defaultActiveKey,
  children,
  style = {},
  linkColor = '#464e5f',
  linkActiveColor = 'white',
  linkActiveBackgroundColor = 'primary',
  linkWeight = 'normal',
  linkPadding = '10px 60px',
  linkBorder = 'transparent',
  ...rest
}) {
  const linkActColor = theme.colors[linkActiveColor] || linkActiveColor;
  const activeBgColor =
    theme.colors[linkActiveBackgroundColor] || linkActiveBackgroundColor;
  return (
    <Styled.TabWrapper linkBorder={linkBorder}>
      <Styled.BSTabs
        defaultActiveKey={defaultActiveKey}
        style={style}
        linkColor={linkColor}
        linkActiveColor={linkActColor}
        linkActiveBackgroundColor={activeBgColor}
        linkWeight={linkWeight}
        linkPadding={linkPadding}
        {...rest}
      >
        {children}
      </Styled.BSTabs>
    </Styled.TabWrapper>
  );
}

export function TabButton({
  children,
  linkColor = '#464e5f',
  linkActiveColor = 'white',
  linkActiveBackgroundColor = 'primary',
  linkWeight = 'normal',
  linkPadding = '10px 60px',
  linkBorder = 'transparent',
  ...rest
}) {
  const linkActColor = theme.colors[linkActiveColor] || linkActiveColor;
  const activeBgColor =
    theme.colors[linkActiveBackgroundColor] || linkActiveBackgroundColor;
  return (
    <Styled.BSTabsButton 
      linkColor={linkColor}
      linkActiveColor={linkActColor}
      linkActiveBackgroundColor={activeBgColor}
      linkWeight={linkWeight}
      linkPadding={linkPadding}
      {...rest}
    >
      {children}
    </Styled.BSTabsButton>
  );
}
