import React from 'react';
import cooperplaceApi from 'services/apis/cooperplace';

import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import { useSnackbar } from 'v3/components/Snackbar';
import { useClient } from '../shared/context';

export default function ButtonImportNegotiations() {
  const client = useClient();
  const snackbar = useSnackbar();

  async function handleNegotiations() {
    try {
      if (client.data.cgccpf) {
        await cooperplaceApi.get(
          `negotioation/importFromBenner?cpfCnpj=${client.data.cgccpf}`
        );

        snackbar.show(<Text>Negociações atualizadas!</Text>, {
          type: 'success',
        });
      }
    } catch (error) {
      snackbar.show(
        <Text>
          {error.response?.data?.error || 'Erro ao importar negociações'}
        </Text>,
        {
          type: 'error',
        }
      );
    }
  }

  return (
    <Button>
      <Text
        color="white"
        weight={500}
        type="regular"
        onClick={handleNegotiations}
      >
        Importar negociações
      </Text>
    </Button>
  );
}
