export const options = [
  {
    title: 'PCP',
    groups: [
      'viagens em ordem de coleta, ',
      'cargas vencidas, ',
      'cargas não atendidas, ',
      'Carregamentos concluídos, ',
      'Viagens aguardando carregamento, ',
      'viagens reprovadas pela GR.',
      'viagens reprovadas cadastro, ',
      'viagens liberadas e ',
      'solicitação pré carga',
      'pré cargas',
    ],
    parameters: {
      items: [
        {
          component: 'Travels',
          title: 'Viagens em ordem de coleta',
        },
        {
          component: 'ExpiredLoads',
          title: 'Cargas vencidas',
        },
        {
          component: 'AvailableLoads',
          title: 'Cargas não atendidas',
        },
        {
          component: 'TravelsCompletedLoading',
          title: 'Carregamentos concluídos',
        },
        {
          component: 'DeclinedRegisterTravels',
          title: 'Viagens Reprovadas Cadastro',
        },
        {
          component: 'TravelsPendingLoading',
          title: 'Viagens aguardando carregamento',
        },
        {
          component: 'TravelsAvailable',
          title: 'Viagens liberadas (aprovadas Cadastro e GR)',
        },
        {
          component: 'RiskManagerReprovedTravels',
          title: 'Viagens reprovadas GR',
        },
        {
          component: 'PreLoadRequests',
          title: 'Solicitações Pré Carga',
        },
        {
          component: 'PreLoads',
          title: 'Pré Cargas',
        },
      ],
    },
  },
  {
    title: 'NEGOCIAÇÃO',
    groups: [
      'cargas disponíveis, ',
      'viagens em ordem de coleta, ',
      'cargas atendidas por você, ',
      'total faturado por você, ',
      'cargas em negociação e ',
      'cargas aguardando atendimento',
    ],
    parameters: {
      items: [
        {
          component: 'AvailableLoads',
          title: 'Cargas disponíveis',
        },
        {
          component: 'Travels',
          title: 'Ordem de coleta',
        },
        {
          component: 'LoadNegotiator',
          title: 'Cargas atendidas por você',
        },
        {
          component: 'LoadBallance',
          title: 'Total faturado por você',
        },
        {
          component: 'LoadsInNegotiation',
          title: 'Cargas em negociação',
        },
        {
          component: 'LoadsWaitingNegotiation',
          title: 'Cargas aguardando atendimento',
        },
      ],
    },
  },

  {
    title: 'GR',
    groups: [
      'Viagens rastreadas pendente GR, ',
      'viagens atrasadas pendentes GR, ',
      'viagens não rastreadas pendentes GR, ',
      'viagens liberadas porém sem SM preenchido, ',
      'viagens liberadas e ',
      'viagens reprovadas pela GR.',
      'viagens alteradas',
    ],
    parameters: {
      items: [
        {
          component: 'TravelsPendingRiskManager',
          title: 'Viagens rastreadas pendente GR',
        },
        {
          component: 'TravelsUntrackedPedingRiskManagers',
          title: 'Viagens atrasadas pendentes GR',
        },
        {
          component: 'UntrackedTravels',
          title: 'Viagens não rastreadas pendentes GR',
        },
        {
          component: 'ApprovedButWithoutSMTravels',
          title: 'Viagens rastreadas aprovadas sem SM',
        },
        {
          component: 'ApprovedTravels',
          title: 'Viagens liberadas GR',
        },
        {
          component: 'RiskManagerReprovedTravels',
          title: 'Viagens reprovadas GR',
        },
        {
          component: 'SMLogistic',
          title: 'viagens com pendências da SM Logística',
        },
        {
          component: 'AlteredTravels',
          title: 'viagens alteradas',
        },
      ],
    },
  },

  {
    title: 'CADASTRO',
    groups: [
      'viagens liberadas cadastro, ',
      'viagens pendentes cadastro, ',
      'viagens com cadastro de motorista não validado, ',
      'viagens com cadastro de transportadora não validado, ',
      'viagens com cadastro de veículo não validado e ',
      'viagens',
    ],
    parameters: {
      items: [
        {
          component: 'RegisteredTravelsAvailable',
          title: 'Viagens liberadas cadastro',
        },
        {
          component: 'RegisterPendingLiberation',
          title: 'Viagens pendentes cadastro',
        },
        {
          component: 'TravelDriverPending',
          title: 'Viagens com motorista não validado',
        },
        {
          component: 'TravelShippingCompanyPending',
          title: 'Viagens com transportadora não validada',
        },
        {
          component: 'TravelVehiclePending',
          title: 'Viagens com veículo não validado',
        },
        {
          component: 'Travels',
          title: 'Viagens em ordem de coleta',
        },
        {
          component: 'TravelsChangesRequests',
          title: 'Solicitação de ajuste de cadastro',
        },
      ],
    },
  },

  {
    title: 'EMISSÃO',
    groups: ['viagens aguardando emissão e ', 'Viagens aguardando importação de documentos'],
    parameters: {
      items: [
        {
          component: 'AwaitingIssueTravels',
          title: 'Viagens aguardando emissão',
        },
        {
          component: 'EmittedWithExternalId',
          title: 'Viagens aguardando importação de documentos',
        },
      ],
    },
  },

  {
    title: 'PÓS-VENDA',
    groups: [
      'viagens em análise, ',
      'viagens com problemas, ',
      'Viagens em análise sem canhoto anexado, ',
      'viagens em trânsito, ',
      'viagens finalizadas, ',
      'viagens transbordadas, ',
      'viagens canceladas e ',
      'veículos aguardando descarregamento',
    ],
    parameters: {
      items: [
        {
          component: 'TravelsUnderReview',
          title: 'Viagens em análise',
        },
        {
          component: 'TravelsWithIssues',
          title: 'Viagens com problemas',
        },
        {
          component: 'TravelsWithoutDocument',
          title: 'Viagens em análise sem canhoto anexado',
        },
        {
          component: 'OnGoingTravels',
          title: 'Viagens em trânsito',
        },
        {
          component: 'TravelsFinished',
          title: 'Viagens finalizadas',
        },
        {
          component: 'TravelsOverflowed',
          title: 'Viagens transbordadas',
        },
        {
          component: 'VehicleWaitingToUnload',
          title: 'Veículos aguardando descarregamento',
        },
        {
          component: 'CancelledTravels',
          title: 'Viagens canceladas',
        },
      ],
    },
  },
  {
    title: 'Visão Completa da Operação',
    groups: ['Dashboard apresentando visão geral da operação'],
    parameters: {
      items: [
        {
          component: 'AvailableLoads',
          title: 'Cargas não atendidas',
        },
        {
          component: 'ExpiredLoads',
          title: 'Cargas vencidas',
        },
        {
          component: 'Travels',
          title: 'Viagens em ordem de coleta',
        },
        {
          component: 'RegisterPendingLiberation',
          title: 'Viagens pendentes cadastro',
        },
        {
          component: 'DeclinedRegisterTravels',
          title: 'Viagens Reprovadas Cadastro',
        },
        {
          component: 'TravelsPendingRiskManager',
          title: 'Viagens rastreadas pendente GR',
        },
        {
          component: 'UntrackedTravels',
          title: 'Viagens não rastreadas pendentes GR',
        },
        {
          component: 'RiskManagerReprovedTravels',
          title: 'Viagens reprovadas GR',
        },
        {
          component: 'TravelsAvailable',
          title: 'Viagens liberadas (aprovadas Cadastro e GR)',
        },
        {
          component: 'TravelsPendingLoading',
          title: 'Viagens aguardando carregamento',
        },
        {
          component: 'TravelsCompletedLoading',
          title: 'Carregamentos concluídos',
        },
        {
          component: 'AwaitingIssueTravels',
          title: 'Viagens aguardando emissão',
        },
        {
          component: 'EmittedWithExternalId',
          title: 'Viagens aguardando importação de documentos',
        },
        {
          component: 'OnGoingTravels',
          title: 'Viagens em trânsito',
        },
        {
          component: 'VehicleWaitingToUnload',
          title: 'Veículos aguardando descarregamento',
        },
        {
          component: 'TravelsUnderReview',
          title: 'Viagens em análise',
        },
        {
          component: 'TravelsWithoutDocument',
          title: 'Viagens em análise sem canhoto anexado',
        },
        {
          component: 'TravelsFinished',
          title: 'Viagens finalizadas',
        },
        {
          component: 'TravelsOverflowed',
          title: 'Viagens transbordadas',
        },
        {
          component: 'CancelledTravels',
          title: 'Viagens canceladas',
        },
        {
          component: 'LoadsCancelled',
          title: 'Cargas Canceladas',
        },
      ],
    },
  },
  {
    title: 'Personalizar',
    groups: ['crie uma dashboard única, conforme a sua necessidade!'],
    parameters: {
      items: [],
    },
  },
];
