/* eslint-disable import/no-unresolved */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FaExclamationCircle } from 'react-icons/fa';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import DropdownButton from 'v3/components/DropdownButton';
import ApplicationLayout from 'v3/layouts/Application';
import { useSnackbar } from 'v3/components/Snackbar';
import {
  validateStartSchedule,
  validateBranchOffice,
  validatePolicyValidity,
} from 'v3/validators/loadValidators';
import { cooperplaceApi } from 'services/api';
import { validatePermission } from 'actions/index';
import { Card, Badge } from 'v3/components';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { getRate } from 'v3/utils/rate';
import { useModal, usePermission } from 'hooks';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import moment from 'moment';
import { UserContext } from 'contexts/UserContext';
import { UNDER_GRO_ANALYSIS_SLUG } from 'v3/utils/constants';
import { Badge as StyledBadge } from '../List/Card/styles';
import Data from './Data';
import Locale from './Locale';
import Vehicle from './Vehicle';
import Monitoring from './Monitoring';
import Automations from './Automations';
import Integrations from './Integrations';
import CancelLoad from './ModalCancelLoad';
import Visualizations from './Visualizations';
import ModalRenewLoad from '../List/Card/ModalRenewLoad';
import { FreightValues } from './Values/FreightValues';
import { confirmOption } from './ModalCancelLoad/controller';
import { AttemptRegisterLoad } from '../Modals/attemptRegisterLoad';
import { GroAttended } from './Modals/GroAttended';

function RenderLoadLabel({ isTravelBlocked, isTravelReproved, load }) {
  const renderBadge = () => {
    if (isTravelReproved) {
      return (
        <>
          <StyledBadge type="error" className="mx-2" size="sm" pill light>
            <Text type="label">Reprovada</Text>
          </StyledBadge>
        </>
      );
    }

    if (isTravelBlocked) {
      return (
        <StyledBadge type="error" className="mx-2" size="sm" pill light>
          <Text type="label">Bloqueada</Text>
        </StyledBadge>
      );
    }

    return (
      <Badge className="mx-2" size="sm" pill light variant="success">
        <Text type="label">Atendida</Text>
      </Badge>
    );
  };

  return (
    <div>
      {load?.travel && (
        <>
          {renderBadge()}
          <Link to={`/viagens/${load.travel.id}`}>
            <Text type="regular" color="light_blue">
              Viagem {load.travel.travel_number || load.travel.id}
            </Text>
            <HiOutlineExternalLink size={12} className="ml-2" />
          </Link>
        </>
      )}
    </div>
  );
}

export default function Details() {
  const { setEnabledToSelect, changedCompany } = useContext(UserContext);
  const {
    setLoadStatus,
    isTravelReproved,
    isTravelBlocked,
    setLastMarginLiberation,
    setMargins,
    fetchBusinessMarginsValues,
    shippingServiceLevelMapped,
    setItensToFetchMargin,
  } = useContext(BlockedTravelContext);
  const history = useHistory();
  const params = useParams();
  const snackbar = useSnackbar();
  const [load, setLoad] = useState();
  const [destinations, setDestinations] = useState();
  const [origins, setOrigins] = useState();
  const [isAttended, setAttended] = useState();
  const [automations, setAutomations] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isUnauthorized, setIsUnauthorized] = useState({
    unauthorized: false,
    message: '',
  });
  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  const [hasPermissionToCreate, setPermissionToCreate] = useState();
  const [rateResponse, setRateResponse] = useState(null);
  const [loadingRate, setLoadingRate] = useState(null);
  const [showDailyRate, setShowDailyRate] = useState(false);
  const [clientCardToggle, setClientCardToggle] = useState(false);
  const [inputsCardToggle, setInputsCardToggle] = useState(false);
  const [negotiatedCardToggle, setNegotiatedCardToggle] = useState(false);
  const [discountsCardToggle, setDiscountsCardToggle] = useState(false);
  const [transferCardToggle, setTransferCardToggle] = useState(false);
  const [loadingFreightOptions, setLoadingFreightOptions] = useState(false);
  const [clientFreightOptions, setClientFreightOptions] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editingLoadData, setEditingLoadData] = useState(false);
  const [editingMonitoringData, setEditingMonitoringData] = useState(false);
  const [policyDataTrackerOptions, setPolicyDataTrackerOptions] = useState([]);
  const [riskManagerOptions, setRiskManagerOptions] = useState([]);
  const [loadGroRequiredField, setLoadGroRequiredField] = useState({
    tracker: false,
    riskManager: false,
  });
  const [rangeId, setRangeId] = useState(null);
  const [initialRangeId, setInitialRangeId] = useState(null);
  const [newRange, setNewRange] = useState(null);
  const [groAttendedData, setGroAttendedData] = useState(null);
  const [updatedTravelData, setUpdatedTravelData] = useState(null);
  const [cargoValue, setCargoValue] = useState(0);
  const groModal = useModal();

  const [errors, setErrors] = useState({});
  const [tracker, setTracker] = useState();
  const attemptRegisterLoadModal = useModal();
  const [notFoundLoad, setNotFoundLoad] = useState(false);
  const hasPermissionToEditGROAnalysis = validatePermission(
    'EDITAR_CAMPOS_EM_VALIDACAO_GR'
  );
  const isDisabledGROAnalysis = useMemo(() => {
    if (!load?.loadPolicy) {
      return false;
    }

    if (hasPermissionToEditGROAnalysis) {
      return false;
    }

    return load?.travel?.riskApproved === UNDER_GRO_ANALYSIS_SLUG;
  }, [load?.travel?.riskApproved]);

  usePermission('VISUALIZAR_CARGAS', { redirect: true });
  async function handleGetRate(currencyValue) {
    setLoadingRate(true);
    const response = await getRate(currencyValue);
    if (!response.error) {
      setRateResponse(response);
    } else {
      snackbar.show(<Text>{response.message}</Text>, {
        type: 'error',
      });
    }
    setLoadingRate(false);
  }

  async function fetchFreightFieldsOptions() {
    try {
      setLoadingFreightOptions(true);
      const { data } = await cooperplaceApi.get('/load-values-observations');
      setClientFreightOptions(data);
    } catch (error) {
      setClientFreightOptions([]);
    } finally {
      setLoadingFreightOptions(false);
    }
  }

  const [showRenewalModal, setShowRenewalModal] = useState(false);
  function handleCloseRenewalModal(backToList) {
    setShowRenewalModal(false);
    if (backToList) {
      history.push(`/cargas`);
    }
  }

  async function getLoad() {
    setLoading(true);
    try {
      const response = await cooperplaceApi.get(`loads/${params.id}`);
      if (response?.data?.travel) {
        await fetchBusinessMarginsValues(
          response?.data?.costCenter?.businessId
        );
        const [marginValue, advanceValue] = await shippingServiceLevelMapped(
          response?.data?.travel?.shipper?.shippingCompany
            ?.descriptionServiceLevel
        );
        setMargins({
          advance_money_percentage_external:
            response?.data?.advanceMoneyPercentageExternal || advanceValue,
          benner_margin: response?.data?.travel?.benner_margin || marginValue,
        });
      }
      setItensToFetchMargin(prevState => ({
        ...prevState,
        loadId: response?.data?.id,
        mainVehicle:
          response?.data?.travel?.vehicleId ||
          response?.data?.travel?.vehicle?.[0]?.vehicleId ||
          null,
        origin: response?.data?.loadOrigins[0],
        destinations: response?.data?.loadDestinations,
        shippingCompany: response?.data?.travel?.shipper?.shippingCompany,
      }));

      setAutomations(response.data?.automations);
      setDestinations(response.data.loadDestinations);
      setOrigins(response.data.loadOrigins);
      setAttended(response.data.travel);
      setLoad(response.data);
      setRangeId(response.data?.loadPolicy?.policyRangeId);
      setInitialRangeId(response.data?.loadPolicy?.policyRangeId);
      const [lastMarginLiberation] =
        response?.data?.travel?.travelMarginLiberation;
      setLastMarginLiberation(lastMarginLiberation || null);
    } catch (error) {
      if (error?.response?.status === 401) {
        setIsUnauthorized({
          unauthorized: true,
          message: error?.response?.data?.message || '',
        });
      }
      if (error?.response?.status === 404) {
        setNotFoundLoad(true);
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setPermissionToEdit(validatePermission('EDITAR_CARGAS'));
    setPermissionToCreate(validatePermission('CADASTRAR_CARGAS'));
    fetchFreightFieldsOptions();
    handleGetRate('USD');
  }, []);

  useEffect(() => {
    getLoad();
  }, [params.id, changedCompany]);

  useEffect(() => {
    setIsUnauthorized({
      unauthorized: false,
      message: '',
    });
  }, [changedCompany]);

  useEffect(() => {
    if (load) {
      setDestinations(load.loadDestinations);
      setOrigins(load.loadOrigins);
    }
  }, [setLoad]);

  const handleCloseModal = () => setShowModal(false);
  function handleCancelLoad() {
    setShowModal(true);
  }

  function onGoingTravelStatusId(id) {
    return [3, 4, 5, 6, 7, 10, 12].includes(id);
  }

  function snackbarShow(text, typeShow) {
    snackbar.show(<Text className="text-center">{text}</Text>, {
      type: typeShow,
      duration: 5000,
    });
  }

  async function getLoadPolicyRangeData(policyId, rgId) {
    try {
      const { data } = await cooperplaceApi.get(
        `policies/${policyId}/ranges/${rgId}`
      );
      const managerTrackers = data?.groups?.find(
        item => item?.code === 'manager_tracker'
      )?.items;
      const tecnologies = data?.equipments_groups
        .map(group => group.tecnologies)
        ?.flatMap(tec => tec);
      setPolicyDataTrackerOptions(tecnologies);
      setRiskManagerOptions(managerTrackers);
    } catch (error) {
      snackbar.show(
        <Text>
          Não foi possível recuperar opções da faixa da apólice da carga! Tente
          novamente mais tarde
        </Text>,
        { type: 'warning' }
      );
      setEditingLoadData(false);
    }
  }

  useEffect(() => {
    if (load?.loadPolicy?.id && (editingLoadData || editingMonitoringData)) {
      getLoadPolicyRangeData(
        load?.loadPolicy?.id,
        load?.loadPolicy?.policyRangeId
      );
    }
  }, [load?.loadPolicy, editingLoadData, editingMonitoringData]);

  useEffect(() => {
    if (load?.travel?.status) setLoadStatus(load.travel.travelStatus);
    if (!load?.travel) setLoadStatus({});
  }, [load]);

  useEffect(() => {
    if (!isUnauthorized.unauthorized) {
      setEnabledToSelect(false);
    }
  }, [isUnauthorized.unauthorized]);

  async function handleCancelGroAttendedModal() {
    try {
      await getLoad();
      setEditingLoadData(false);
      setGroAttendedData(null);
      setRangeId(initialRangeId);
      setCargoValue(null);
      groModal.close();
    } catch (error) {
      //
    }
  }

  if (notFoundLoad) {
    return (
      <ApplicationLayout>
        <Card>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: 6 }}
            className="text-center"
          >
            <Text type="header" color="error">
              <FaExclamationCircle
                size={26}
                color="#F64E60"
                className="mr-2 mb-1"
              />
              Essa carga não existe!
            </Text>
          </div>
        </Card>
      </ApplicationLayout>
    );
  }

  if (isUnauthorized.unauthorized) {
    setEnabledToSelect(true);
    return (
      <ApplicationLayout>
        <Card>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: 6 }}
            className="text-center"
          >
            <Text type="header" color="error">
              <FaExclamationCircle
                size={26}
                color="#F64E60"
                className="mr-2 mb-1"
              />
              Você não tem permissão para acessar essa carga!
            </Text>
            <Text type="header" color="error">
              {isUnauthorized.message}
            </Text>
          </div>
        </Card>
      </ApplicationLayout>
    );
  }

  return (
    <ApplicationLayout
      title="Detalhes da carga"
      RightComponent={
        <>
          <Button
            variant="secondary"
            onClick={() => history.goBack()}
            className="ml-2"
          >
            <Text weight={500} type="regular">
              Voltar
            </Text>
          </Button>
          <DropdownButton
            disabled={isLoading}
            style={{ display: 'inline', marginLeft: 10 }}
            title="Opções"
            variant="primary"
            className="ml-2"
            id="button-opcoes-carga"
            options={[
              {
                title: 'Atender Carga',
                onClick: () => {
                  const isValidated = validateStartSchedule(
                    load.dueDate,
                    load.loadOrigins[0].scheduledTime,
                    load.loadDestinations[load.loadDestinations.length - 1]
                      .scheduledTime,
                    snackbarShow,
                    load?.costCenter.limitSchedule
                  );

                  const isGROLoadAndValid =
                    load?.loadPolicy?.id &&
                    validatePolicyValidity(
                      load?.loadPolicy?.policy?.dueDate,
                      snackbarShow
                    );

                  if (load?.loadPolicy?.id && !isGROLoadAndValid) {
                    return;
                  }

                  if (isValidated === true) {
                    validateBranchOffice(
                      history,
                      snackbarShow,
                      load?.branchOffice?.active,
                      load.id
                    );
                  }
                },
                disabled: (load && load.travel) || (load && load.deletedAt),
                type: 'item',
                id: 'button-opcoes-carga-atender',
              },
              {
                title: 'Renovar Carga',
                onClick: () => setShowRenewalModal(true),
                disabled:
                  !validatePermission('CADASTRAR_CARGAS') ||
                  (load?.travel && load.travel.statusId !== 7),
                type: 'item',
                id: 'button-opcoes-carga-renovar',
              },
              {
                title: 'Cancelar Carga',
                onClick: () => handleCancelLoad(),
                disabled:
                  !hasPermissionToEdit ||
                  (load && load.deletedAt) ||
                  (load && load.travel && load.travel.statusId !== 7),
                type: 'item',
                id: 'button-opcoes-carga-cancelar',
              },
              {
                type: 'divider',
              },
              {
                title: 'Nova Carga',
                onClick: () => attemptRegisterLoadModal.open(),
                disabled: !hasPermissionToCreate,
                type: 'item',
                id: 'button-opcoes-carga-nova',
              },
            ]}
          />
        </>
      }
    >
      <AttemptRegisterLoad
        isOpen={attemptRegisterLoadModal.isOpen}
        onClose={() => attemptRegisterLoadModal.close()}
      />
      <GroAttended
        loading={isLoading}
        isOpen={groModal.isOpen}
        modalHook={groModal}
        onClose={handleCancelGroAttendedModal}
        groAttendedData={groAttendedData}
        setGroAttendedData={setGroAttendedData}
        newRange={newRange}
        handleCancel={handleCancelGroAttendedModal}
        setUpdatedTravelData={setUpdatedTravelData}
      />
      <CancelLoad
        loadId={params.id}
        handleClose={handleCloseModal}
        show={showModal}
        onConfirm={confirmOption}
      />
      <ModalRenewLoad
        show={showRenewalModal}
        load={load}
        loadId={params.id}
        dueDate={load?.dueDate}
        handleClose={handleCloseRenewalModal}
        backToListOption
      />

      <Row>
        <Col xs={12}>
          <Card>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Text id="label-numero-carga" color="#464E5F" type="header">
                  Número {load?.id}
                </Text>
                {!!load?.deletedAt && (
                  <div>
                    <StyledBadge pill light type="error" className="ml-2">
                      <Text type="label">Cancelada</Text>
                    </StyledBadge>
                  </div>
                )}
                <RenderLoadLabel
                  isTravelBlocked={isTravelBlocked}
                  isTravelReproved={isTravelReproved}
                  load={load}
                />
              </div>
              {load ? (
                <Text
                  id="label-criado-por"
                  type="label"
                  color="gray"
                  className="pr-2"
                >
                  Criado por {load?.userCreated.username} -{' '}
                  {moment.utc(load?.createdAt).format('DD/MM/YYYY')}
                </Text>
              ) : null}
            </div>
            {load?.attended && (
              <div className="d-flex align-items-center justify-content-between">
                <Text />
                <Text type="label" color="gray" className="pr-2">
                  Atendido por {load?.attended?.userCreated.username} -{' '}
                  {moment.utc(load?.attended?.created_at).format('DD/MM/YYYY')}
                </Text>
              </div>
            )}
          </Card>
        </Col>
      </Row>
      <Row xs={12} md={12}>
        <Col xs={12} md={6}>
          <Data
            onGoingTravelStatusId={onGoingTravelStatusId}
            load={load}
            getLoad={getLoad}
            loadingCard={isLoading}
            editingLoadData={editingLoadData}
            setEditingLoadData={setEditingLoadData}
            setLoad={setLoad}
            riskManagerOptions={riskManagerOptions}
            setRiskManagerOptions={setRiskManagerOptions}
            loadGroRequiredField={loadGroRequiredField}
            setLoadGroRequiredField={setLoadGroRequiredField}
            errors={errors}
            setErrors={setErrors}
            tracker={tracker}
            setTracker={setTracker}
            rangeId={rangeId}
            setRangeId={setRangeId}
            initialRangeId={initialRangeId}
            setNewRange={setNewRange}
            groModal={groModal}
            setGroAttendedData={setGroAttendedData}
            cargoValue={cargoValue}
            setCargoValue={setCargoValue}
            updatedTravelData={updatedTravelData}
            setUpdatedTravelData={setUpdatedTravelData}
            isDisabledGROAnalysis={isDisabledGROAnalysis}
          />
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <Col xs={12}>
              <Vehicle
                onGoingTravelStatusId={onGoingTravelStatusId}
                load={load}
                getLoad={getLoad}
                loadingCard={isLoading}
                isDisabledGROAnalysis={isDisabledGROAnalysis}
              />
            </Col>
            <Col xs={12}>
              <Monitoring
                load={load}
                getLoad={getLoad}
                loadingCard={isLoading}
                onGoingTravelStatusId={onGoingTravelStatusId}
                editingMonitoringData={editingMonitoringData}
                setEditingMonitoringData={setEditingMonitoringData}
                errors={errors}
                setErrors={setErrors}
                policyDataTrackerOptions={policyDataTrackerOptions}
                tracker={tracker}
                setTracker={setTracker}
                isDisabledGROAnalysis={isDisabledGROAnalysis}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Locale
          originsData={origins}
          destinationsData={destinations}
          loadId={params.id}
          loadingCard={isLoading}
          isAttended={isAttended}
          getLoad={getLoad}
          groLoadData={
            load?.loadPolicy?.policyId
              ? {
                  policyId: load?.loadPolicy?.policyId,
                  operationId: load?.loadPolicy?.policyOperationId,
                  productId: load?.product?.id,
                }
              : null
          }
          isDisabledGROAnalysis={isDisabledGROAnalysis}
          load={load}
        />
      </Row>
      <Row>
        <FreightValues
          loadingFreightOptions={loadingFreightOptions}
          clientFreightOptions={clientFreightOptions}
          load={load}
          getLoad={getLoad}
          isFetchingLoad={isLoading}
          onGoingTravelStatusId={onGoingTravelStatusId}
          rateResponse={rateResponse}
          loadingRate={loadingRate}
          showDailyRate={showDailyRate}
          setShowDailyRate={setShowDailyRate}
          clientCardToggle={clientCardToggle}
          setClientCardToggle={setClientCardToggle}
          inputsCardToggle={inputsCardToggle}
          setInputsCardToggle={setInputsCardToggle}
          negotiatedCardToggle={negotiatedCardToggle}
          setNegotiatedCardToggle={setNegotiatedCardToggle}
          discountsCardToggle={discountsCardToggle}
          setDiscountsCardToggle={setDiscountsCardToggle}
          transferCardToggle={transferCardToggle}
          setTransferCardToggle={setTransferCardToggle}
        />
        <Integrations
          onGoingTravelStatusId={onGoingTravelStatusId}
          load={load}
          loadId={params.id}
          loadingCard={isLoading}
        />
        <Automations
          onGoingTravelStatusId={onGoingTravelStatusId}
          load={load}
          getLoad={getLoad}
          loadId={params.id}
          loadingCard={isLoading}
          automations={automations}
        />
        <Visualizations
          loadId={params.id}
          isAttended={isAttended}
          loadingCard={isLoading}
        />
      </Row>
    </ApplicationLayout>
  );
}
