import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import { useModal } from 'hooks';
import { cooperplaceApi } from 'services/api';
import { formatDate } from 'v3/utils/formatter';
import Card from 'v3/components/Card';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import { useSnackbar } from 'v3/components/Snackbar';
import Space from 'v3/components/Space';
import Tabs from 'v3/components/Tabs';
import Tab from 'v3/components/Tab';

import { useVehicle } from '../shared/context';
import { MaintenancesModal } from '../shared/Modals/Maintenances';
import { BennerMaintenancesModal } from '../shared/Modals/BennerMaintenance';
import { RenewMaintenancesModal } from '../shared/Modals/RenewMaintenance';
import { validatePermission } from 'actions';

export const Maintenances = () => {
  const vehicle = useVehicle();
  const maintenanceModal = useModal();
  const bennerMaintenancesModal = useModal();
  const renewMaintenanceModal = useModal();
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [modalName, setModalName] = useState('');
  const [allMaintenances, setAllMaintenances] = useState([]);
  const [maintenanceToRenew, setMaintenanceToRenew] = useState({});
  const hasPermissionToImportFromBenner = validatePermission(
    'IMPORTAR_MANUTENCAO_VEICULO_BENNER'
  );

  const vehiclePermission = validatePermission('ALTERAR_VEICULOS');

  function openModal(name) {
    setModalName(name);
  }

  useEffect(() => {
    switch (modalName) {
      case 'Maintenance':
        maintenanceModal.open();
        break;
      case 'Benner':
        bennerMaintenancesModal.open();
        break;
      case 'renewMaintenance':
        renewMaintenanceModal.open();
        break;
      default:
        break;
    }
  }, [modalName]);

  let plates = vehicle?.data?.wagons?.map(wagon => {
    return wagon?.plate;
  });
  if (plates?.length > 0) {
    plates = [vehicle.data.plate, ...plates];
  } else {
    plates = [vehicle?.data?.plate];
  }

  async function getMaintenances() {
    try {
      setLoading(true);
      const requests = plates?.map(plate => {
        return cooperplaceApi.get(`/vehicles/maintenances/${plate}`);
      });
      const resolve = await Promise.all(requests);
      let maintenances = resolve.filter(result => result.data.length > 0);
      if (maintenances.length > 0) {
        maintenances = maintenances?.map(result => {
          return {
            plate: result.data[0]?.plate,
            data: result?.data,
            vehicleType: result.data[0].vehicleType,
          };
        });
        setAllMaintenances(maintenances);
      }
    } catch (error) {
      snackbar.show(
        <Text>Não foi possível recuperar as manutenções do veículo</Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (vehicle?.data?.plate) {
      getMaintenances();
    }
  }, [vehicle.data.plate]);

  return (
    <>
      <MaintenancesModal
        isOpen={maintenanceModal.isOpen}
        onClose={() => {
          maintenanceModal.close();
          setModalName('');
        }}
        plates={plates}
      />
      <BennerMaintenancesModal
        isOpen={bennerMaintenancesModal.isOpen}
        onClose={() => {
          bennerMaintenancesModal.close();
          setModalName('');
        }}
        plates={plates}
      />
      <RenewMaintenancesModal
        isOpen={renewMaintenanceModal.isOpen}
        onClose={() => {
          renewMaintenanceModal.close();
          setModalName('');
        }}
        maintenance={maintenanceToRenew}
      />
      <Card
        header={
          <>
            <Text color="#464E5F" type="header">
              Manutenções
            </Text>
          </>
        }
        HeaderRightComponent={
          <Space x={'1rem'}>
            {hasPermissionToImportFromBenner ? (
              <Button variant="primary" onClick={() => openModal('Benner')}>
                <Text color="white" weight="500" type="regular">
                  Importar do Benner
                </Text>
              </Button>
            ) : null}
            {vehiclePermission && (
              <Button
                variant="primary"
                onClick={() => openModal('Maintenance')}
              >
                <Text color="white" weight="500" type="regular">
                  Adicionar
                </Text>
              </Button>
            )}
          </Space>
        }
      >
        {allMaintenances.length > 0 && !loading ? (
          <Tabs defaultActiveKey={allMaintenances[0].plate}>
            {allMaintenances?.map(vehicleMaintenancesInformation => {
              return (
                <Tab
                  title={
                    vehicleMaintenancesInformation.vehicleType === 'MAIN'
                      ? `${vehicleMaintenancesInformation.plate} - Veículo Principal`
                      : `${vehicleMaintenancesInformation.plate} - Carreta`
                  }
                  eventKey={vehicleMaintenancesInformation.plate}
                >
                  {vehicleMaintenancesInformation?.data &&
                    vehicleMaintenancesInformation?.data?.map(
                      maintenanceInformation => {
                        return (
                          <>
                            <Row
                              className="m-2 p-4"
                              style={{
                                boxShadow: '1px 0 1px rgba(0, 0, 0, 0.16)',
                                display:'flex',
                                justifyContent:'space-between'
                              }}
                            >
                              <Col md={2}>
                                <Text
                                  type="regular"
                                  color="#464E5F"
                                  weight={500}
                                >
                                  Data
                                </Text>

                                <Text
                                  type="regular"
                                  color="dark"
                                  as="p"
                                  className="mt-2"
                                >
                                  {formatDate(maintenanceInformation?.date, {
                                    isUTC: true,
                                  })}
                                </Text>
                              </Col>

                              <Col md={2}>
                                <Text
                                  type="regular"
                                  color="#464E5F"
                                  weight={500}
                                >
                                  Data de finalização
                                </Text>

                                <Text
                                  type="regular"
                                  color="dark"
                                  as="p"
                                  className="mt-2"
                                >
                                  {formatDate(maintenanceInformation?.dateFinal, {
                                    isUTC: true,
                                  })}
                                </Text>
                              </Col>

                              <Col md={2}>
                                <Text
                                  type="regular"
                                  color="#464E5F"
                                  weight={500}
                                >
                                  Vencimento
                                </Text>

                                <Text
                                  type="regular"
                                  color="dark"
                                  as="p"
                                  className="mt-2"
                                >
                                  {formatDate(maintenanceInformation?.dueDate, {
                                    isUTC: true,
                                  }) || 'Não informado'}
                                </Text>
                              </Col>
                              <Col md={5}>
                                <Text
                                  type="regular"
                                  color="#464E5F"
                                  weight={500}
                                >
                                  Local de finalização da manutenção
                                </Text>

                                <Text
                                  type="regular"
                                  color="dark"
                                  as="p"
                                  className="mt-2"
                                >
                                  {maintenanceInformation?.location || 'Não informado'}
                                </Text>
                              </Col>
                              <Col md={8}>
                                <Text
                                  type="regular"
                                  color="#464E5F"
                                  weight={500}
                                >
                                  Descrição
                                </Text>

                                <Text
                                  type="regular"
                                  color="dark"
                                  as="p"
                                  className="mt-2"
                                  wordwrap={true}
                                >
                                  {maintenanceInformation?.description}
                                </Text>
                              </Col>
                              <Col md={2}>
                                {vehiclePermission && (
                                  <Button
                                    onClick={() => {
                                      setMaintenanceToRenew(
                                        maintenanceInformation
                                      );
                                      openModal('renewMaintenance');
                                    }}
                                    disabled={
                                      maintenanceInformation.performedMaintenance ||
                                      false
                                    }
                                    title={
                                      maintenanceInformation.performedMaintenance
                                        ? 'Manutenção já foi renovada'
                                        : ''
                                    }
                                  >
                                    <Text
                                      type="regular"
                                      color="#ffF"
                                      weight={500}
                                    >
                                      Renovar
                                    </Text>
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </>
                        );
                      }
                    )}
                </Tab>
              );
            })}
          </Tabs>
        ) : null}
      </Card>
    </>
  );
};
