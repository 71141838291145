import styled from 'styled-components';
import theme from 'v3/theme';

export const Container = styled.div`
  position: fixed;
  bottom: 22px;
  right: 22px;
  padding: 16px;
  width: 312px;
  min-height: 180px;
  background-color: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 9999;
`;

export const Bar = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ededed;
  border-radius: 5px;
  margin-bottom: 8px;
`;

export const InProgressBar = styled.div`
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  background-color: blue;
  border-radius: 5px;
`;

export const ProgressBarButton = styled.button`
  position: fixed;
  place-items: center;
  bottom: 22px;
  right: 22px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  width: 46px;
  height: 46px;
  z-index: 9999;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    animation: animate 5s linear infinite;
    border-radius: 50%;
  }

  > svg {
    position: relative;
    z-index: 1;
  }

  @keyframes animate {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }

  &:before {
    background: linear-gradient(
      45deg,
      ${theme.colors.danger},
      ${theme.colors.primary}
    );
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    top: -2px;
    left: -2px;
    z-index: -1;
    animation: animate 25s linear infinite;
  }

  &:after {
    background-color: #fff;
    z-index: 0;
  }
`;

export const ProgressBarTotal = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${theme.colors.danger};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  z-index: 1;
  > {
    position: relative;
    z-index: 1;
  }
`;

export const CloseButton = styled.button`
  font-size: 18px;
  background-color: transparent;
  padding: 8 0px;
`;
