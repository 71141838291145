import React from 'react';
import { Button, Modal, Text } from 'v3/components';

export default function ModalConfirmation({ showModal, setShowModal, text, onConfirm }) {
  return (
    <Modal
      show={showModal}
      handleClose={() => setShowModal(false)}
      heading="Confirmação"
      body={
        <div style={{ textAlign: 'center' }}>
          <Text>{text}</Text>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Button
              style={{ marginRight: '10px' }}
              onClick={() => setShowModal(false)}
            >
              <Text>Cancelar</Text>
            </Button>
            <Button
              onClick={() => {
                onConfirm();
                setShowModal(false);
              }}
            >
              <Text>Confirmar</Text>
            </Button>
          </div>
        </div>
      }
    />
  );
}
