import React, { useState, useEffect } from 'react';
import apiReport from 'services/apis/report';
import ApplicationLayoutDriverCatalog from 'v3/layouts/Application/HeaderVechicleControlTower';
import { useSnackbar } from 'v3/components/Snackbar';
import { Button, Text } from 'v3/components';
import { useLocation } from 'react-router-dom';
import { DefaultLoadingComponent } from 'v3/components/List';
import { Col, Row } from 'react-bootstrap';
import { Grid } from './Grid';
import { Filter } from './Filter';
import LoadOfferModal from './LoadOfferModal';
import AggregationOfferModal from './AggregationOfferModal';
import MapDrivers from './MapDrivers';

export default function DriverCatalog() {
  const location = useLocation();
  const snackbar = useSnackbar();
  const [showCargoOfferModal, setShowCargoOfferModal] = useState(false);
  const [showAggregationOfferModal, setShowAggregationOfferModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [areFiltersVisible , setaAreFiltersVisible ] = useState(false);
  const [isMapVisible , setIsMapVisible ] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [isDriverChecked , setIsDriverChecked ] = useState(false)
  const [driversData, setdriversData] = useState([]);
  const [selectedDriverId, setSelectedDriverId] = useState(null)

  async function fetchData() {
    try {
      setLoading(true);
      const query = new URLSearchParams(location.search);
      const response = await apiReport.get(
        `/drivers/location?${query.toString()}`
      );
      setdriversData(response.data.drivers);
    } catch (error) {
      snackbar.show(<Text>Erro ao recuperar motoristas.</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [location.search]);

  return (
    <>
      { selectedDriverId && <LoadOfferModal
        showModal={showCargoOfferModal}
        setShowModal={setShowCargoOfferModal}
        driverId={selectedDriverId}
      />}
     <AggregationOfferModal
        showModal={showAggregationOfferModal}
        setShowModal={setShowAggregationOfferModal}
        driverId={selectedDriverId}
      />
      <ApplicationLayoutDriverCatalog
        title="Easy Frota"
        contentFluid
        isOld
        onlyCenterComponent
      >
        <div style={{ paddingLeft: '50px', marginTop: '10px' }} className="row-cols-md-12">
          <Row className="justify-content-center">
            <Col md={2} sm={12} style={{ display: 'flex', alignItems: 'end', marginBottom: '10px'}}>
              <Button
                style={{ flex: 1 }}
                variant="success"
                loading={loading}
                onClick={() => setShowFilters(!showFilters)}
              >
                <Text type="regular" weight={500}>
                  Filtros
                </Text>
              </Button>
            </Col>
            <Col md={2} sm={12} style={{ display: 'flex', alignItems: 'end', marginBottom: '10px'}}>
              <Button
                style={{ flex: 1 }}
                variant="success"
                loading={loading}
                onClick={() => setIsMapVisible(!isMapVisible)}
              >
                <Text type="regular" weight={500}>
                  Mapa
                </Text>
              </Button>
            </Col>
          </Row>
        </div>
        { showFilters && <div style={{ paddingLeft: '50px' }} className="row-cols-md-12">
          <Filter
            filters={filters}
            setFilters={setFilters}
            toggleFilters={areFiltersVisible}
            setToggleFilters={setaAreFiltersVisible}
            setLoading={setLoading}
          />
        </div>}
        <div style={{ paddingLeft: '50px' }} className="row-cols-md-12">
          <MapDrivers
            showMap={isMapVisible}
            driversData={driversData}
            loading={loading}
            checked={isDriverChecked}
            setChecked={setIsDriverChecked}
          />
        </div>
        <div style={{ paddingLeft: '50px' }}>
          {loading ? (
            <DefaultLoadingComponent />
          ) : (
            <Grid
              driverData={driversData}
              setShowCargoOfferModal={setShowCargoOfferModal}
              setShowAggregationOfferModal={setShowAggregationOfferModal}
              loading={loading}
              setDriverId={setSelectedDriverId}
            />
          )}
        </div>
      </ApplicationLayoutDriverCatalog>
    </>
  );
}
