import styled from 'styled-components';
import { Button } from 'v3/components';

export const AccordionDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
`;

export const DrawerContainer = styled.div`
  min-width: 500px;
  overflow-x: hidden;
`;

export const DrawerHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  padding: 16px;
`;

export const ButtonDrawer = styled(Button)`
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 16px;
  }
`;
