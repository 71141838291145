import React, { useState } from 'react';
import Tab from 'v3/components/Tab';
import Tabs from 'v3/components/Tabs';
import {
  GeneralItems,
  GeneralItemsContainer,
  GeneralItemsSelectInfo,
  GeneralItemsTextInfo,
  ManagersContainer,
  MonitoringEquipmentContainer,
  MonitoringEquipmentCount,
  RiskManagementInfo,
  SearchConsultationContainer,
  StyledCarousel,
  StyledCarouselItem,
  ToggleHeader,
} from '../../styles';
import { Text } from 'v3/components';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Attachments } from './Attachments/Attachment';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';

export function Ranges({ ranges, policyId, refetchPolicy, currencySymbol }) {
  const [toggleSections, setToggleSections] = useState({
    showSearchConsultation: true,
    showMonitoringEquipaments: true,
    showManagers: true,
    showGeneralRules: true,
    showRiskManager: true,
    showAttachments: true,
  });

  return (
    <Tabs
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        overflowY: 'hidden',
        fontSize: '0.75rem',
        WebkitOverflowScrolling: 'touch',
      }}
      linkActiveColor="dark"
      linkActiveBackgroundColor="grayish_blue"
      linkWeight="500"
      linkPadding="0.5rem 1rem"
      linkBorder=" #dee2e6 #dee2e6 #fff"
    >
      {ranges?.map((range, rangeIdx) => {
        return (
          <Tab
            title={`${currencySymbol}${range?.policy_range_min} até ${currencySymbol}${range?.policy_range_max}`}
            eventKey={rangeIdx}
            key={rangeIdx}
            style={{
              padding: '1rem 0.5rem',
            }}
          >
            <RangeContent
              range={range}
              policyId={policyId}
              refetchPolicy={refetchPolicy}
              toggleSections={toggleSections}
              setToggleSections={setToggleSections}
            />
          </Tab>
        );
      })}
    </Tabs>
  );
}

function RangeContent({
  range,
  policyId,
  refetchPolicy,
  toggleSections,
  setToggleSections,
}) {
  return (
    <>
      <SearchConsultation
        range={range}
        toggleSections={toggleSections}
        setToggleSections={setToggleSections}
      />
      <MonitoringEquipaments
        range={range}
        toggleSections={toggleSections}
        setToggleSections={setToggleSections}
      />
      <Managers
        range={range}
        toggleSections={toggleSections}
        setToggleSections={setToggleSections}
      />
      <GeneralRules
        range={range}
        toggleSections={toggleSections}
        setToggleSections={setToggleSections}
      />
      <AttachmentsSection
        range={range}
        policyId={policyId}
        refetchPolicy={refetchPolicy}
        toggleSections={toggleSections}
        setToggleSections={setToggleSections}
      />
      <RiskManagers
        range={range}
        toggleSections={toggleSections}
        setToggleSections={setToggleSections}
      />
    </>
  );
}

function SearchConsultation({ range, toggleSections, setToggleSections }) {
  return (
    <>
      <DetailToggleHeader
        label={' Pesquisa e consulta'}
        toggleKey={'showSearchConsultation'}
        setToggleSections={setToggleSections}
        toggleSections={toggleSections}
      />
      {toggleSections?.showSearchConsultation && (
        <SearchConsultationContainer>
          <div>
            <Text type="label" weight={500} color="dark">
              RDO
            </Text>
            <Text
              color={'dark'}
              type="regular"
              as={'p'}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {range?.rdo?.map(r => r?.name)?.join('\n') || 'Não informado'}
            </Text>
          </div>
          <div>
            <Text type="label" weight={500} color="dark">
              Pesquisa e consulta
            </Text>
            <Text
              color={'dark'}
              type="regular"
              as={'p'}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {range?.search_consulting?.map(s => s?.name)?.join('\n') ||
                'Não informado'}
            </Text>
          </div>
          <div>
            <Text type="label" weight={500} color="dark">
              Gerenciadora para cadastro/consulta
            </Text>
            <Text
              color={'dark'}
              type="regular"
              as={'p'}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {range?.manager_query?.map(s => s?.name)?.join('\n') ||
                'Não informado'}
            </Text>
          </div>
          <div>
            <Text type="label" weight={500} color="dark">
              Gerenciadora de risco para SM logística
            </Text>
            <Text
              color={'dark'}
              type="regular"
              as={'p'}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {range?.logistic_sm?.map(s => s?.name)?.join('\n') ||
                'Não informado'}
            </Text>
          </div>
          <div>
            <Text type="label" weight={500} color="dark">
              Restrição terceiro
            </Text>
            <Text
              color={'dark'}
              type="regular"
              as={'p'}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {range?.restrition_third ? 'Sim' : 'Não'}
            </Text>
          </div>
        </SearchConsultationContainer>
      )}
    </>
  );
}

function MonitoringEquipaments({ range, toggleSections, setToggleSections }) {
  const [index, setIndex] = useState(0);
  return (
    <>
      <DetailToggleHeader
        label={'Grupo de equipamentos de monitoramento'}
        toggleKey={'showMonitoringEquipaments'}
        setToggleSections={setToggleSections}
        toggleSections={toggleSections}
      />
      {toggleSections?.showMonitoringEquipaments && (
        <MonitoringEquipmentContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0.5rem',
            }}
          >
            <MonitoringEquipmentCount>{index + 1}</MonitoringEquipmentCount>
            <div>
              <Text type="label" weight={500} color="dark">
                Rastreado
              </Text>
              <Text
                color={'dark'}
                type="regular"
                as={'p'}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {range?.tracked ? 'Sim' : 'Não'}
              </Text>
            </div>
            <StyledCarousel
              onSelect={(i) => setIndex(i)}
              interval={null}
              prevIcon={
                index > 0 ? (
                <button disabled={range?.monitoringEquipaments?.length === 1}>
                  <FaArrowLeft />
                </button>
                ) : null
              }
              nextIcon={
                index < range?.monitoringEquipaments?.length - 1 ? (
                  <button disabled={range?.monitoringEquipaments?.length === 1}>
                    <FaArrowRight />
                  </button>
                ) : null
              }
              indicators={false}
              slide={false}
            >
              {range?.monitoringEquipaments?.map((group, groupIdx) => {
                return (
                  <StyledCarouselItem>
                    <div>
                      <header>
                        <Text
                          type="regular"
                          weight={500}
                          color={'dark'}
                          as="span"
                        >
                          {group?.name ?? `Grupo atual ${groupIdx + 1}`}
                        </Text>
                      </header>
                      <div className="groupContent">
                        <div>
                          <Text type="label" weight={500} color="dark">
                            Tecnologia (marca rastreador)
                          </Text>
                          <Text
                            color={'dark'}
                            type="regular"
                            as={'p'}
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            {group?.tecnologies
                              ?.map(s => s?.name)
                              ?.join('\n') || 'Não informado'}
                          </Text>
                        </div>
                        <div>
                          <Text type="label" weight={500} color="dark">
                            Modelo Rastreador
                          </Text>
                          <Text
                            color={'dark'}
                            type="regular"
                            as={'p'}
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            {group?.equipments?.map(s => s?.name)?.join('\n') ||
                              'Não informado'}
                          </Text>
                        </div>
                        <div>
                          <Text type="label" weight={500} color="dark">
                            Localizador
                          </Text>
                          <Text
                            color={'dark'}
                            type="regular"
                            as={'p'}
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            {group?.locators?.map(s => s?.name)?.join('\n') ||
                              'Não informado'}
                          </Text>
                        </div>
                        <div>
                          <Text type="label" weight={500} color="dark">
                            Imobilizadores
                          </Text>
                          <Text
                            color={'dark'}
                            type="regular"
                            as={'p'}
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            {group?.immobilizers
                              ?.map(s => s?.name)
                              ?.join('\n') || 'Não informado'}
                          </Text>
                        </div>
                        <div>
                          <Text type="label" weight={500} color="dark">
                            Quantidade de Iscas Necessárias
                          </Text>
                          <Text
                            color={'dark'}
                            type="regular"
                            as={'p'}
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            {group?.bait_quantity || 'Não informado'}
                          </Text>
                        </div>
                        <div>
                          <Text type="label" weight={500} color="dark">
                            Iscas
                          </Text>
                          <Text
                            color={'dark'}
                            type="regular"
                            as={'p'}
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            {group?.baits?.map(s => s?.name)?.join('\n') ||
                              'Não informado'}
                          </Text>
                        </div>
                        <div>
                          <Text type="label" weight={500} color="dark">
                            Escolta
                          </Text>
                          <Text
                            color={'dark'}
                            type="regular"
                            as={'p'}
                            style={{ whiteSpace: 'pre-wrap' }}
                          >
                            {group?.escorted ? 'Sim' : 'Não'}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </StyledCarouselItem>
                );
              })}
            </StyledCarousel>
          </div>
        </MonitoringEquipmentContainer>
      )}
    </>
  );
}

function Managers({ range, toggleSections, setToggleSections }) {
  return (
    <>
      <DetailToggleHeader
        label={'Gerenciadoras'}
        toggleKey={'showManagers'}
        setToggleSections={setToggleSections}
        toggleSections={toggleSections}
      />
      {toggleSections?.showManagers && (
        <ManagersContainer>
          <div>
            <div>
              <Text type="label" weight={500} color="dark">
                Perfil de Segurança
              </Text>
              <Text
                color={'dark'}
                type="regular"
                as={'p'}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {range?.security_profile?.map(s => s?.name)?.join('\n') ||
                  'Não informado'}
              </Text>
            </div>
            <div>
              <Text type="label" weight={500} color="dark">
                Gr para rastreamento
              </Text>
              <Text
                color={'dark'}
                type="regular"
                as={'p'}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {range?.manager_tracker?.map(s => s?.name)?.join('\n') ||
                  'Não informado'}
              </Text>
            </div>
            <div>
              <Text type="label" weight={500} color="dark">
                Espelhamento logístico
              </Text>
              <Text
                color={'dark'}
                type="regular"
                as={'p'}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {range?.manager_mirror?.map(s => s?.name)?.join('\n') ||
                  'Não informado'}
              </Text>
            </div>
            <div>
              <Text type="label" weight={500} color="dark">
                Realizar checklist em gerenciadora
              </Text>
              <Text
                color={'dark'}
                type="regular"
                as={'p'}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {range?.check_manager?.map(s => s?.name)?.join('\n') ||
                  'Não informado'}
              </Text>
            </div>
            <div>
              <Text type="label" weight={500} color="dark">
                Validar checklist em gerenciadora
              </Text>
              <Text
                color={'dark'}
                type="regular"
                as={'p'}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {range?.validity_check_manager?.map(s => s?.name)?.join('\n') ||
                  'Não informado'}
              </Text>
            </div>
            <div>
              <Text type="label" weight={500} color="dark">
                Checklist rastreador
              </Text>
              <Text
                color={'dark'}
                type="regular"
                as={'p'}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {range?.checklist_tracker || 'Não informado'}
              </Text>
            </div>
          </div>
        </ManagersContainer>
      )}
    </>
  );
}

function GeneralRules({ range, toggleSections, setToggleSections }) {
  return (
    <>
      <DetailToggleHeader
        label={'Regras Gerais'}
        toggleKey={'showGeneralRules'}
        setToggleSections={setToggleSections}
        toggleSections={toggleSections}
      />
      {toggleSections?.showGeneralRules && (
        <GeneralItemsContainer>
          <GeneralItems>
            <GeneralItemsTextInfo>
              <div>
                <div>
                  <Text color={'dark'} type="label" weight={500}>
                    Horário rodagem:
                  </Text>
                  <Text
                    color={'dark'}
                    type="regular"
                    as={'p'}
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {range?.starting_schedule && range?.finishing_schedule
                      ? `Das ${range?.starting_schedule} até ${range?.finishing_schedule}`
                      : 'Não informado'}
                  </Text>
                </div>
                <div>
                  <Text color={'dark'} type="label" weight={500}>
                    Tempo (dias) mínimo antt
                  </Text>
                  <Text color={'dark'} type="regular" as={'p'}>
                    {range?.min_time_antt || 'Não informado'}
                  </Text>
                </div>
                <div>
                  <Text color={'dark'} type="label" weight={500}>
                    Idade (anos) máxima tração
                  </Text>
                  <Text color={'dark'} type="regular" as={'p'}>
                    {range?.age_traction_max || 'Não informado'}
                  </Text>
                </div>
                <div>
                  <Text color={'dark'} type="label" weight={500}>
                    Idade (anos)máxima implemento
                  </Text>
                  <Text color={'dark'} type="regular" as={'p'}>
                    {range?.age_implement_max || 'Não informado'}
                  </Text>
                </div>
                <div>
                  <Text color={'dark'} type="label" weight={500}>
                    Quantidade mínima de viagens motorista
                  </Text>
                  <Text color={'dark'} type="regular" as={'p'}>
                    {range?.driver_travel_min || 'Não informado'}
                  </Text>
                </div>
              </div>
            </GeneralItemsTextInfo>
            <GeneralItemsSelectInfo>
              <div>
                <Text type="label" weight={500} color="dark">
                  Sensores / Atuadores
                </Text>
                <Text
                  color={'dark'}
                  type="regular"
                  as={'p'}
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {range?.sensors?.map(s => s?.name)?.join('\n') ||
                    'Não informado'}
                </Text>
              </div>
            </GeneralItemsSelectInfo>
          </GeneralItems>
          <div className="textObs">
            <div>
              <Text type="label" weight={500} color="dark">
                Áreas de Risco
              </Text>
              <Text
                color={'dark'}
                type="regular"
                as={'p'}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {range?.risk_area?.map(s => s?.name)?.join('\n') ||
                  'Não informado'}
              </Text>
            </div>
            <div>
              <Text type="label" weight={500} color="dark">
                Validade Checklist
              </Text>
              <Text color={'dark'} type="regular" as={'p'}>
                {range?.checklist_validity || 'Não informado'}
              </Text>
            </div>
            <div>
              <Text type="label" weight={500} color="dark">
                Observações
              </Text>
              <Text color={'dark'} type="regular" as={'p'}>
                {range?.obs || 'Não informado'}
              </Text>
            </div>
            <div>
              <Text type="label" weight={500} color="dark">
                Observações Coopercarga
              </Text>
              <Text color={'dark'} type="regular" as={'p'}>
                {range?.obs_company || 'Não informado'}
              </Text>
            </div>
          </div>
        </GeneralItemsContainer>
      )}
    </>
  );
}

function AttachmentsSection({
  range,
  toggleSections,
  setToggleSections,
  policyId,
  refetchPolicy,
}) {
  return (
    <>
      <DetailToggleHeader
        label={'Anexos'}
        toggleKey={'showAttachments'}
        setToggleSections={setToggleSections}
        toggleSections={toggleSections}
      />
      {toggleSections?.showAttachments && (
        <Attachments
          attachments={range?.attachments}
          rangeId={range?.id}
          policyId={policyId}
          refetchPolicy={refetchPolicy}
        />
      )}
    </>
  );
}

function RiskManagers({ range, toggleSections, setToggleSections }) {
  return (
    <>
      <DetailToggleHeader
        label={'Gerenciamento de risco'}
        toggleKey={'showRiskManager'}
        setToggleSections={setToggleSections}
        toggleSections={toggleSections}
      />
      {toggleSections?.showRiskManager && (
        <RiskManagementInfo>
          <div>
            <Text type="label" weight={500} color="dark">
              Orientações ao motorista
            </Text>
            <Text color={'dark'} type="regular" as={'p'}>
              {range?.driver_guide || 'Não informado'}
            </Text>
          </div>
          <div>
            <Text type="label" weight={500} color="dark">
              Contato gerenciadora / P.A / GC
            </Text>
            <Text color={'dark'} type="regular" as={'p'}>
              {range?.manager_contact || 'Não informado'}
            </Text>
          </div>
        </RiskManagementInfo>
      )}
    </>
  );
}

function DetailToggleHeader({
  label,
  toggleKey,
  toggleSections,
  setToggleSections,
}) {
  return (
    <ToggleHeader>
      <button
        onClick={() =>
          setToggleSections(old => ({
            ...old,
            [toggleKey]: !old[toggleKey],
          }))
        }
      >
        {toggleSections[toggleKey] ? (
          <AiOutlineArrowUp />
        ) : (
          <AiOutlineArrowDown />
        )}
      </button>
      <Text type="body" color="dark" weight={500}>
        {label}
      </Text>
    </ToggleHeader>
  );
}
