import React, { useState } from 'react';
import { Button, Input, Modal, Select, Text } from 'v3/components';
import { Col, Row } from 'react-bootstrap';
import { fetchOnlyCities } from 'utils/fetches';
import { useSnackbar } from 'v3/components/Snackbar';
import { cooperplaceApi } from 'services/api';
import moment from 'moment';
import * as Yup from 'yup';
import { useFleetManagement } from '../../shared';
import { FooterContainer } from './styles';

const formSchema = Yup.object().shape({
  vehicleManagementId: Yup.number().required(
    'Necessário selecionar um veículo'
  ),
  cityOrigin: Yup.number().required(
    'Necessário selecionar uma cidade de origem'
  ),
  cityDestination: Yup.number().required(
    'Necessário selecionar uma cidade de destino'
  ),
  latOrigin: Yup.number().required(
    'Necessário selecionar uma cidade de origem com latitude'
  ),
  latDestination: Yup.number().required(
    'Necessário selecionar uma cidade de destino com latitude'
  ),
  longOrigin: Yup.number().required(
    'Necessário selecionar uma cidade de origem com longitude'
  ),
  longDestination: Yup.number().required(
    'Necessário selecionar uma cidade de destino com longitude'
  ),
  typeTravel: Yup.string()
    .nullable()
    .required('Necessário selecionar um tipo de viagem'),
  dateOrigin: Yup.string()
    .nullable()
    .test('invalidOriginDate', 'Data inválida', value =>
      value ? moment(value, moment.ISO_8601, true).isValid() : true
    )
    .required('Necessário selecionar uma data'),
  
  dateDestination: Yup.string()
    .nullable()
    .test('invalidDestinationDate', 'Data inválida', value =>
      value ? moment(value, moment.ISO_8601, true).isValid() : true
    )
    .test(
      'isAfterDateOrigin',
      'A data de destino não pode ser anterior à data de origem',
      function (value) {
        const { dateOrigin } = this.parent;
        if (value && dateOrigin) {
          return moment(value, moment.ISO_8601, true).isSameOrAfter(moment(dateOrigin, moment.ISO_8601, true));
        }
        return true;
      }
    )
    .required('Necessário selecionar uma data'),
});

function CreateNewOutsideTravelModal() {
  const {
    newOutsideTravelModal: modalHook,
    selected,
    setSelected,
    handleGetData,
  } = useFleetManagement();
  const [isFetching, setIsFetching] = useState(false);
  const [form, setForm] = useState({
    originCity: null,
    deliveryCity: null,
    collectDate: null,
    deliveryDate: null,
    travelType: null,
  });
  const [errors, setErrors] = useState();
  const snackbar = useSnackbar();

  const handleClose = () => {
    setSelected(null);
    setForm({
      originCity: null,
      deliveryCity: null,
      collectDate: null,
      deliveryDate: null,
      travelType: null,
    });
    modalHook.close();
  };

  const handleConfirm = async () => {
    setIsFetching(true);
    const errorList = {};

    const payload = {
      vehicleManagementId: selected?.id,
      cityOrigin: form?.originCity?.id,
      cityDestination: form?.deliveryCity?.id,
      latOrigin: form?.originCity?.latitude,
      latDestination: form?.deliveryCity?.latitude,
      longOrigin: form?.originCity?.longitude,
      longDestination: form?.deliveryCity?.longitude,
      typeTravel: form?.travelType?.value,
      dateDestination:
        form?.deliveryDate &&
        moment(form.deliveryDate, 'DD/MM/YYYY HH:mm').toISOString(),
      dateOrigin:
        form?.collectDate &&
        moment(form.collectDate, 'DD/MM/YYYY HH:mm').toISOString(),
    };
    try {
      await formSchema.validate(payload, {
        abortEarly: false,
      });

      try {
        await cooperplaceApi.post('fleet/management/travelOutside', payload);
        setForm({
          originCity: null,
          deliveryCity: null,
          collectDate: null,
          deliveryDate: null,
          travelType: null,
        });
        await handleGetData();
        modalHook.close();
      } catch (error) {
        snackbar.show(
          <Text>
            {error?.response?.data?.error
              ? error.response.data.error
              : 'Error ao incluir viagem externa'}
          </Text>,
          {
            type: 'error',
          }
        );
      }
    } catch (error) {
      error.inner.forEach(err => {
        errorList[err.path] = err.message;
      });
      setErrors(errorList);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal
      show={modalHook.isOpen}
      heading={`Inclusão de viagem externa para a placa ${selected?.plate?.plate}`}
      body={
        <Row>
          <Col sm={12} md={8}>
            <Select.Async
              label="Cidade de origem"
              onSearch={fetchOnlyCities}
              onChange={originCity => {
                setForm(prev => ({ ...prev, originCity }));
                setErrors(null);
              }}
              getOptionLabel={option =>
                `${option.name}${option?.province?.name ? ` - ${option.province.name}` : ''
                }`
              }
              getOptionValue={option => option.id}
              value={form.originCity}
              error={
                errors?.cityOrigin || errors?.latOrigin || errors?.longOrigin
              }
            />
          </Col>
          <Col sm={12} md={4}>
          <Input
              label="Data Coleta"
              type="datetime-local"
              onKeyDown={(event) => event.preventDefault()}
              onChange={({ target: { value: collectDate } }) => {
                setForm(prev => ({ ...prev, collectDate: moment(collectDate).format('DD/MM/YYYY HH:mm') }));
                setErrors(null);
              }}
              error={errors?.dateOrigin}
            />
          </Col>
          <Col sm={12} md={8} className="mt-2">
            <Select.Async
              label="Cidade de destino"
              onSearch={fetchOnlyCities}
              onChange={deliveryCity => {
                setForm(prev => ({ ...prev, deliveryCity }));
                setErrors(null);
              }}
              getOptionLabel={option =>
                `${option.name}${option?.province?.name ? ` - ${option.province.name}` : ''
                }`
              }
              getOptionValue={option => option.id}
              value={form.deliveryCity}
              error={
                errors?.cityDestination ||
                errors?.latDestination ||
                errors?.longDestination
              }
            />
          </Col>
          <Col sm={12} md={4} className="mt-2">
            <Input
              label="Data entrega final"
              type="datetime-local"
              onKeyDown={(event) => event.preventDefault()}
              onChange={({ target: { value: deliveryDate } }) => {
                setForm(prev => ({ ...prev, deliveryDate: moment(deliveryDate).format('DD/MM/YYYY HH:mm') }));
                setErrors(null);
              }}
              error={errors?.dateDestination}
            />
          </Col>
          <Col sm={12} md={8} className="mt-2">
            <Select
              options={[
                { label: 'Viagem externa', value: 'OUTSIDE_TRAVEL' },
                { label: 'Viagem vazia', value: 'EMPTY_TRAVEL' },
              ]}
              label="Tipo de viagem"
              onChange={travelType => {
                setForm(prev => ({ ...prev, travelType }));
                setErrors(null);
              }}
              error={errors?.typeTravel}
            />
          </Col>
        </Row>
      }
      footer={
        <FooterContainer>
          <Button loading={isFetching} onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={isFetching} onClick={handleConfirm}>
            Adicionar
          </Button>
        </FooterContainer>
      }
      handleClose={handleClose}
    />
  );
}

export default CreateNewOutsideTravelModal;
