import React, { useState, useEffect } from 'react';
import { Button, Input, Modal, Select, Text } from 'v3/components';
import { Row, Col } from 'react-bootstrap';
import { cooperplaceApi } from 'services/api';

export default function FilterModal({
  setFilters,
  submit,
  showModal,
  setShowModal,
  filters,
}) {
  const [typeLogOptionts, setTypeLogOptions] = useState([]);
  const [selectedWhatsAppTypes, setSelectedWhatsAppTypes] = useState();
  const [loadId, setLoadId] = useState();

  async function handleSubimit() {
    const params = {
      page: 1,
      perPage: filters.perPage,
      ...(filters?.phonenumber && { phonenumber: filters?.phonenumber }),
      ...(filters?.startDate && { startDate: filters?.startDate }),
      ...(filters?.endDate && { endDate: filters?.endDate }),
      ...(filters?.personId && { personId: filters?.personId }),
      ...(filters?.userCreated && { userCreated: filters?.userCreated }),
      ...(selectedWhatsAppTypes && { typeId: selectedWhatsAppTypes.id }),
      ...(loadId && { loadId }),
    };
    setFilters(params);
    await submit(params);
  }

  useEffect(() => {
    async function fetchWhatsAppTypes() {
      try {
        const response = await cooperplaceApi.get('whatsapp_types');
        setTypeLogOptions(response.data);
      } catch (error) {
        setTypeLogOptions([]);
      }
    }
    fetchWhatsAppTypes();
  }, []);

  return (
    <>
      <Modal
        show={showModal}
        handleClose={() => setShowModal()}
        heading="Filtros Avançados"
        body={
          <Row className="d-flex align-items-end justify-content-center mb-4">
            <Col lg={2} md={3} sm={6} xs={12} className="mb-3">
              <Select
                modalHeading="Selecione o tipo de Log"
                modalBodyTitle="Tipo de Log"
                horizontal
                label="Tipo de Log"
                options={typeLogOptionts}
                value={selectedWhatsAppTypes}
                onChange={value => {
                  setSelectedWhatsAppTypes(value);
                }}
                getOptionLabel={option => option.description}
                getOptionValue={option => option.id}
              />
            </Col>

            <Col lg={2} md={3} sm={6} xs={12} className="mb-3">
              <Input
                label="ID da carga"
                value={loadId}
                type="number"
                onChange={e => {
                  setLoadId(e.target.value);
                }}
                placeholder="Digite o ID da Carga"
              />
            </Col>

            <Col lg={2} md={3} sm={6} xs={12} className="mb-3">
              <Button
                variant="success"
                onClick={() => {
                  handleSubimit();
                }}
                className="w-100"
              >
                <Text type="regular" weight={500}>
                  Filtrar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}
