import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FaAdjust,
  FaBars,
  FaCamera,
  FaCheckCircle,
  FaCheckSquare,
  FaImage,
  FaSignature,
} from 'react-icons/fa';
import { Col, Row } from 'react-bootstrap';
import { useModal, usePermission } from 'hooks';
import { BadgeActive, Button, DataTable, Fallback, Text } from 'v3/components';
import ApplicationLayout from 'v3/layouts/Application';

import { validatePermission } from 'actions';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import { DefaultLoadingComponent } from 'v3/components/List';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { RegisterModal } from './RegisterModal';
import { Filter } from './Filter';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'v3/utils/formatter';

export function QuestionsList() {
  usePermission('VISUALIZAR_PERGUNTAS', { redirect: true });
  const hasPermissionToRegisterQuestion = validatePermission(
    'CADASTRAR_PERGUNTAS'
  );
  const snackbar = useSnackbar();
  const history = useHistory();
  const registerModal = useModal();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [pagination, setPagination] = useState({});
  const [questionTypes, setQuestionTypes] = useState([]);
  const [questionsPerPage, setQuestionsPerPage] = useLocalStorage(
    'questionsPerPage',
    5
  );

  const [filter, setFilter] = useState({
    question: '',
    typeId: [],
    page: 1,
    perPage: questionsPerPage,
    activeFilter: {
      actives: false,
      inactives: false,
    },
  });

  const questionTypeOptions = [
    {
      slug: 'descriptive',
      emoji: <FaBars />,
    },
    {
      slug: 'unique',
      emoji: <FaCheckCircle />,
    },
    {
      slug: 'multiple',
      emoji: <FaCheckSquare />,
    },
    {
      slug: 'image',
      emoji: <FaImage />,
    },
    {
      slug: 'logic',
      emoji: <FaAdjust />,
    },
    {
      slug: 'video',
      emoji: <FaCamera />,
    },
    {
      slug: 'signature',
      emoji: <FaSignature />,
    },
  ];

  const mapEmojisToData = (data, options) => {
    return data.map(item => {
      const option = options.find(option => option.slug === item.slug);

      if (option) {
        return {
          ...item,
          emoji: option.emoji,
        };
      }

      return item;
    });
  };

  async function fetchQuestionTypes() {
    try {
      setLoading(true);
      const response = await cooperplaceApi.get('questions-types');
      const responseWithEmojis = mapEmojisToData(
        response.data,
        questionTypeOptions
      );
      setQuestionTypes(responseWithEmojis);
    } catch (error) {
      snackbar.show(
        <Text>
          Não foi possível carregar opções de filtro. Recarregue a página
        </Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchQuestionTypes();
  }, []);

  const fetchQuestions = useCallback(async params => {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.get(`questions`, {
        params: {
          question: params?.question,
          typeId: params?.typeId?.map(item => item?.id),
          page: params?.page,
          limit: params?.perPage,
          actives: params?.activeFilter.actives,
          inactives: params?.activeFilter.inactives,
        },
      });
      setQuestions(data?.data);
      setPagination(data?.meta);
    } catch (error) {
      snackbar.show(
        <Text>
          Não foi possível carregar as perguntas. Tente novamente mais tarde.
        </Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  }, []);

  function handleOpenRegisterModal() {
    return registerModal.open();
  }
  function handleCloseRegisterModal() {
    if (loading) {
      return;
    }
    return registerModal.close();
  }

  useEffect(() => {
    fetchQuestions(filter);
  }, []);

  function handleSelectQuestion(id) {
    history.push(`/checklist/perguntas/${id}`);
  }
  function onPageChanged(value) {
    const params = {
      ...filter,
      page: value,
    };
    setFilter(params);
    fetchQuestions(params);
  }

  function onPerPageChanged(value) {
    const params = {
      ...filter,
      page: 1,
      perPage: value,
    };
    setFilter(params);
    fetchQuestions(params);
  }

  function onFilterChange(newFilters) {
    const params = {
      ...filter,
      ...newFilters,
      page: 1,
    };
    setFilter(params);
    fetchQuestions(params);
  }

  useEffect(() => {
    if (filter.perPage !== questionsPerPage) {
      setQuestionsPerPage(filter.perPage);
    }
  }, [filter.perPage]);

  return (
    <ApplicationLayout
      title="Gerenciamento de perguntas para checklist"
      RightComponent={
        hasPermissionToRegisterQuestion && (
          <Button onClick={handleOpenRegisterModal}>
            <Text type="regular" weight={500}>
              Nova Pergunta
            </Text>
          </Button>
        )
      }
    >
      <Filter
        onFilterChange={onFilterChange}
        loading={loading}
        questionTypes={questionTypes}
      />
      <Row>
        <Col className="my-5">
          <Fallback
            fall={questions?.length === 0 || loading}
            component={
              loading ? (
                <DefaultLoadingComponent />
              ) : (
                <Col
                  xs={12}
                  className="align-items-center d-flex flex-column my-5"
                >
                  <Button disabled>
                    <Text type="regular">Sem registros encontrados.</Text>
                  </Button>
                </Col>
              )
            }
          >
            <DataTable
              columns={[
                {
                  name: 'Status',
                  selector: row =>
                    row?.active ? (
                      <BadgeActive pill light type="success" className="mr-2">
                        <Text type="label" weight={500}>
                          Ativo
                        </Text>
                      </BadgeActive>
                    ) : (
                      <BadgeActive pill light type="error" className="mr-2">
                        <Text weight={500} type="label">
                          Inativo
                        </Text>
                      </BadgeActive>
                    ),
                },
                {
                  name: 'Pergunta',
                  selector: row => (
                    <>
                      <Text title={row?.question}>
                        {row?.question}
                      </Text>
                      <Row style={{fontSize:11}}>
                        {formatDateTime(row?.logs[0]?.createdAt)+' - '+row?.logs[0]?.obs}
                      </Row>
                    </>
                  ),
                },
                {
                  name: 'Tipo',
                  selector: row => row?.type?.name,
                },
                {
                  selector: row => (
                    <Link to={`/checklist/perguntas/${row.id}`}>
                      <Button>
                        Detalhes
                      </Button>
                    </Link>
                  ),
                },
              ]}
              data={questions}
              loading={loading}
              total={pagination?.total}
              pages={pagination?.last_page || 1}
              currentPage={pagination?.current_page}
              onPageChanged={onPageChanged}
              onPerPageChanged={onPerPageChanged}
              withFilterOption={false}
              perPageOptions={[{ v: 5 }, { v: 15 }, { v: 30 }]}
              defaultPerPage={filter?.perPage}
            />
          </Fallback>
        </Col>
      </Row>
      <RegisterModal
        isOpen={registerModal.isOpen}
        onClose={handleCloseRegisterModal}
      />
    </ApplicationLayout>
  );
}
